import { useEffect, useRef, useState } from "react";
import { loadTossPayments } from '@tosspayments/payment-sdk'
import { nanoid } from "nanoid";

const clientKey = 'test_ck_YPBal2vxj814G9q57wAr5RQgOAND'

const Test = () => {
    
    useEffect(() => {
        loadTossPayments(clientKey).then(tossPayments => {
            // ------ 카드 등록창 호출 ------
            tossPayments.requestBillingAuth('카드', { 
              // 결제 정보 파라미터
              // 더 많은 결제 정보 파라미터는 결제창 Javascript SDK에서 확인하세요.
              // https://docs.tosspayments.com/reference/js-sdk#requestbillingauth카드-결제-정보
              customerKey: 'XHmGhwd-ICANyujJSauKW', // 고객 ID로 상점에서 만들어야 합니다. 빌링키와 매핑됩니다. 자세한 파라미터 설명은 결제 정보 파라미터 설명을 참고하세요.
              successUrl: `${location.origin}/success"`, // 카드 등록에 성공하면 이동하는 페이지(직접 만들어주세요)
              failUrl: `${location.origin}/fail`,       // 카드 등록에 실패하면 이동하는 페이지(직접 만들어주세요)
            }).catch(function (error) {
              if (error.code === 'USER_CANCEL') {
                // 결제 고객이 결제창을 닫았을 때 에러 처리
              }
            })
          })
    }, []);

    return (
       <></>
    );
}

export default Test;