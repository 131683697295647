import { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { COUNTRIES } from '../constant/country';
import MyMenu from './MyMenu';
import ComPayments from './ComPayments';
import { AuthAction, SubscriptionAction } from "../redux/action";
import Header from "./Header";
import { history } from "../history";
import Loader from "./Loader";
import { useTranslation } from 'react-i18next';

const SubPayments = ({ }) => {
    const [t] = useTranslation(['translation']);
    const user = JSON.parse(localStorage.getItem('user'));
    const [loading, setLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [country, setCountry] = useState('');

    useEffect(() => {
        if (!user) {
            history.push('/');
        }
    }, []);

    const getCountryName = (code) => {
        var c = COUNTRIES.find(x => x.countryCode === code);
        return c ? c.name : <i>Undefined</i>;
    }

    return (
        <>
            <Header />
            <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-300 text-white" data-image-src="/assets/img/bg1.jpg" style={{ backgroundImage: "url('/assets/img/bg1.jpg')" }}>
                <div className="container pt-14 pt-md-15 pb-4 pb-md-3">
                    <div className="row gx-lg-9">
                        <div className="col-lg-9">
                            <div className="app-avatar">
                                <img className="avatar" src={"/assets/img/img_avatar_dark.svg"} alt={'My Account'} />
                            </div>
                            <div className="app-title">
                                <h2 className="fs-15 text-opacity60">{`${user?.name}'s Profile` }</h2>
                                <h3 className="display-5 mf-300 b-0 text-white mb-1">{t('My Account')}</h3>
                            </div>
                        </div>
                        <div className="col-lg-3" style={{ display: 'none' }}>
                            <div className="search-form mt-2 mt-md-1 sch-box">
                                <input id="" type="text" className="form-control sch-box" placeholder="Search..." />
                                <label htmlFor="">Search...</label>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wrapper">
                <div className="container py-15 py-md-6 pb-13 tb-account">
                    <MyMenu menu="payments" user={user}/>
                    <section className="wrapper">
                        <h2>{t('결제 이력')}</h2>
                        <div className="table-responsive mt-4" style={{
                                borderTop: "1px solid #8574ff",
                                borderColor: "#8574ff"
                            }}></div>
                        <ComPayments />
                    </section>
                </div>
            </section>
            <Loader isShow={showLoader} />
        </>
    )
}

const mapState = (state) => {
    const pays = state.SubscriptionReducer.pays;

    return { pays }
}

const mapDispatch = dispatch => ({
});

export default connect(mapState, mapDispatch)(SubPayments);
