import Privacy20230815 from "../contents/Privacy20230815_en";
import Privacy20231211 from "../contents/Privacy20231211_en";
import Privacy20241015 from "../contents/Privacy20241015_en";

const PrivacyENPrev = ({ date }) => {

    return <>
        {date === "2024-10-15" && <Privacy20241015 />}
        {date === "2023-12-11" && <Privacy20231211 />}
        {date === "2022-08-15" && <Privacy20230815 />}
    </>
}
export default PrivacyENPrev;
