const initialState = {
	sub: {},
	subs : [],
	pays : [],
	subPc : {},
	subPcs : [],
};

export const SubscriptionConstant = {
	SUB_GETALL : "SUB_GETALL",
	SUB_GET : "SUB_GET",
	PAY_GETALL : "PAY_GETALL",
	PAY_GET : "PAY_GET",
	GET_REGISTERED_PCS : "GET_REGISTERED_PCS",
	UPDATE_REGISTERED_PCS: "UPDATE_REGISTERED_PCS",
	GET_SUB : "GET_SUB"
};

export const SubscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SubscriptionConstant.SUB_GETALL : 
			return { ...state, subs: action.items }
        case SubscriptionConstant.PAY_GETALL : 
			return { ...state, pays: action.items }
		case SubscriptionConstant.GET_REGISTERED_PCS : 
			return { ...state, subPcs: action.items }
		case SubscriptionConstant.GET_SUB :
			return { ...state, sub: action.item }
		case SubscriptionConstant.UPDATE_REGISTERED_PCS : 
			return { ...state, 
				subPc : action.item,
				subPcs: state.subPcs.map(x => x.rtid == action.item.rtid ? action.item : x) }
        default:
            return state;
    }
}
