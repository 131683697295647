import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { UserAction } from "../redux/action";

import Loader from "./Loader";
 
const SettingAccountMarketing = ({ auth, UpdateUser, GetMe }) => {
    const [t] = useTranslation();
    const [loading, setLoading] = useState(false);
    const agreeItem = useMemo(() => auth?.termpol?.find(x => x.code === "marketing" && x.checked) || {}, [auth?.termpol]);
    const isAgree = useMemo(() => auth?.marketingAgree || false, [auth?.marketingAgree]);
    const [isProcessing, setIsProcessing] = useState({
        show: false,
        title: "",
        contents: "",
        type: ""
    });

    const agreeMarketing = useCallback(async () => {
        const termpol = auth?.termpol || [];
        termpol.push({
            code: "marketing",
            contentId: "marketing",
            accepted: new Date().getTime(),
            checked: true
        });
        const payload = {
            _id: auth?._id,
            termpol,
            marketingAgree: true,
        };
        setLoading(true);
        await UpdateUser(payload);
        await GetMe();
        setLoading(false);
    }, [GetMe, UpdateUser, auth?._id, auth?.termpol, t]);
 
    const disagreeMarketing = useCallback(async () => {
        let termpol = auth?.termpol || [];
        termpol = termpol.filter(x => x.code !== "marketing");
        const payload = {
            _id: auth?._id,
            termpol,
            marketingAgree: false,
        };
        setLoading(true);
        await UpdateUser(payload);
        await GetMe();
        setLoading(false);
    }, [GetMe, UpdateUser, auth?._id, auth?.termpol, t]);
 
    return <>
        {<Loader isShow={loading} />}
        <div className='fw-bolder'>
        {isAgree && <div className="d-flex align-items-center justify-content-between">
            <div>
                {t("Agreed")}
            </div>
            <div className="text-muted">
                <small>{moment(agreeItem?.accepted).format("LL")}</small>
            </div>
            <div className="text-end">
                <button className="btn btn-xs btn-danger rounded-pill btn-x-lg" onClick={() => setIsProcessing({type: "disagree", show: true, title:t("Refusal to receive marketing information"), contents: <div>{t("Would you like to refuse consent for receiving marketing information?")}</div> })} style={{ borderRadius: "24px" }}>{t("button.marketing.toDisagree")}</button>
            </div>
        </div>}
        {!isAgree && <div className="d-flex align-items-center justify-content-between">
            <div>
                {t("Disagreed")}
            </div>
            <div className="text-end">
                <button className="btn btn-xs btn-soft-dark rounded-pill btn-x-lg" onClick={() => setIsProcessing({type: "agree", show: true, title:t("Agreement to receive marketing information"), contents: <div>{t("Would you like to consent to receiving marketing information?")}</div>})} style={{ borderRadius: "24px" }}>{t("button.marketing.toAgree")}</button>
            </div>
        </div>}
        </div>
        {isProcessing.show && <>
            <div className="modal fade show" id="" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: isProcessing.show ? 'block' : "none" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="container text-center">
                                <div className="">
                                    <div className="mb-3" style={{ fontSize : '20px', fontWeight : 700, lineHeight : "43.2px", color : "#343F52" }}>{isProcessing.title}</div>
                                    <div style={{ fontSize : '15px', fontWeight : 400, lineHeight : "18px", color : "##60697B" }}>{isProcessing.contents}</div>
                                </div>
                                <div className="mt-6">
                                <div className="justify-content-end d-flex">
                                    <button className="btn btn-sm btn-gray py-2" onClick={() =>  setIsProcessing({ show: false, title: "", contents: "" })} style={{ borderRadius: "24px" }}>{t("닫기")}</button>
                                    <button className="btn btn-sm btn-primary ms-2 py-2" onClick={() => { (isProcessing.type == "agree" ? agreeMarketing() : disagreeMarketing()); setIsProcessing({ show: false, title: "", contents: "" });}} style={{ borderRadius: "24px" }}>{t("확인")}</button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show" style={{ display: isProcessing.show ? 'block' : "none" }}></div>
        </>}
    </>
};
 
const mapState = (state) => {
    const auth = state.AuthReducer.user;
    return { auth };
};
 
const mapDispatch = dispatch => ({
    UpdateUser: (info) => dispatch(UserAction.Update(info)),
    GetMe: () => dispatch(UserAction.GetMe()),
});
 
export default connect(mapState, mapDispatch)(SettingAccountMarketing);