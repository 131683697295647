import { useEffect, useState } from "react";
import { history } from "../history";
import SimpleLoader from "../components/SimpleLoader.js";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

const PaymentsOkmsg = () => {
    const [t] = useTranslation(['translation']);
    const { type } = useParams();
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState("");

    useEffect(() => {
        setLoading(type === "wait");
        setResult(type === "success" ?  "success" : "fail");
    }, [type]);

    console.log(loading);
    console.log(result);

    return <section className="h-100 d-flex align-items-center wrapper bg-white main-visual">
        <div className="container text-center">
            {loading && <SimpleLoader title={t("구독을 등록하고 있습니다.")} message={
                <div>
                    <div>{t("창을 닫거나 이동하지 마세요.")}</div>
                    <div>{t("잠시만 기다려주세요.")}</div>
                </div>
            } />}
            {!loading && <div>
                {result === 'success' && <div className="text-center">
                        <div>
                            <div>{t("모든 처리가 완료되었습니다.")}</div>
                            <div>{t("이제 구독한 소프트웨어를 사용할 수 있습니다.")}</div>
                        </div>
                        <button onClick={() => history.push("/subscriptions")} className="btn btn-info mt-2">{t("My subscriptions")}</button>
                </div>}
                {result !== 'success' && <div className="text-center">
                    <h3>{t("구독을 위한 결제가 취소 되었습니다.")}</h3>
                        <div className="pt-1 pb-3">
                            {t("결제가 정상적으로 이루어지지 않았습니다.")}
                        </div>
                    <div>
                        <button onClick={() => history.push("/subscriptions")} className="btn btn-info">{t("My subscriptions")}</button>
                    </div>
                </div>}
            </div>}
        </div>
    </section>
}

export default PaymentsOkmsg;
