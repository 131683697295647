import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { GroupAction, UserAction } from "../redux/action";

const UserConsent = ({ showTerms, hideTerms, acceptTerms, appInfo, termsCode }) => {
    const [scrollDom, setScrollDom] = useState();
    const [t,] = useTranslation(['translation']);
    const terms = useMemo(() => {
        if (!appInfo?.terms || !termsCode) {
            return {};
        }
        const _terms = appInfo?.terms[termsCode];
        return _terms || {}
    }, [appInfo?.terms, termsCode])
    const [isAllScroll, setIsAllScroll] = useState(false);

    useEffect(() => {
        setIsAllScroll(false);
    }, [termsCode]);

    const agree = () => {
        acceptTerms();
        hideTerms();
    }

    const handlerOnScroll = useCallback(() => {
        const dom = scrollDom;
        if (!dom) {
            return;
        }
        if (dom.scrollTop + dom.clientHeight > dom.scrollHeight - 10) {
            setIsAllScroll(true);
        }
    }, [scrollDom]);

    useEffect(() => {
        showTerms && scrollDom && handlerOnScroll();
    }, [scrollDom, showTerms])

    return (
        <>
            <div className="modal fade show" id="" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: showTerms ? 'block' : "none" }}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header p-5 pb-0">
                            <h3>{terms?.doc?.title || ""}</h3>
                            <button className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => hideTerms()}></button>
                        </div>
                        <div className="modal-body py-5 px-0 pt-2">
                            {terms?.doc?.contents && <>
                                <div className="abox px-5" onScroll={handlerOnScroll} ref={setScrollDom}>
                                    <div className="agree-content">
                                        <div style={{ paddingBottom: 10 }} dangerouslySetInnerHTML={{ __html: terms?.doc?.contents || "" }} />
                                    </div>
                                </div>
                            </>}
                            <div className="mt-6 px-5">
                                <div className="text-center pb-0 mb-0">
                                    <p className="my-1">{t('동의하시면, 결제 화면으로 이동됩니다.')}</p>
                                    <a
                                        className={`btn btn-primary btn-icon btn-icon-start rounded-pill btn-lg ${isAllScroll ? "" : "disabled"}`}
                                        onClick={() => agree()}
                                    >
                                        <i className="uil uil-check"></i> {t('AGREE')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show" style={{ display: showTerms ? 'block' : "none" }}></div>
        </>
    )
};

const mapState = (state) => {
    return {};
};

const mapDispatch = dispatch => ({
    CreateGroup: (data) => dispatch(GroupAction.CreateWithoutAuth(data)),
    CreateUser: (data) => dispatch(UserAction.CreateWithoutAuth(data))
});

export default connect(mapState, mapDispatch)(UserConsent);
