const initialState = {
	items: [],
	item: {},
	others: [],
	status: "",
	calledLocaleUserIds: [],
};

export const UserConstant = {
	CREATE: "USER_CREATE",
	CREATED: "USER_CREATED",
	UPDATE: "USER_UPDATE",
	UPDATEPASSWORD: "USER_UPDATEPASSWORD",
	UPDATED: "USER_UPDATED",
	REMOVED: "USER_REMOVED",
	GETALL: "USER_GETALL",
	GET: "USER_GET",
	APPEND_USER: "APPEND_USER",
	STATUSCLEAR: "CODE_STATUSCLEAR",
	ADD_CALLED_USER_ID: "ADD_CALLED_USER_ID",
	GET_OTHER: "USER_GET_OTHER",
	SET_ENABLE: "USER_SET_ENABLE",
	UNREGISTER: "USER_UNREGISTER",		
};

export const UserReducer = (state = initialState, action) => {
	switch (action.type) {
		case UserConstant.SET_ENABLE:
			return {
				...state,
				items: state.items.map(x => x._id === action._id ? { ...x, Enabled: action.Enabled } : x)
			}
		case UserConstant.GET_OTHER:
			return {
				...state,
				others: [...state.others, action.item]
			}
		case UserConstant.ADD_CALLED_USER_ID:
			return {
				...state,
				calledLocaleUserIds: [...state.calledLocaleUserIds, action.item]
			}
		case UserConstant.CREATE:
			return {
				...state,
				items: [...state.items, action.item],
				item: action.item,
				status: UserConstant.CREATED
			};
		case UserConstant.GETALL:
			return { ...state, items: action.items };
		case UserConstant.UPDATEPASSWORD:
			return {
				...state,
				status: UserConstant.UPDATED
			};
		case UserConstant.UPDATE:
			return {
				...state,
				item: action.item,
				items: state.items.map(x => x._id === action.item._id ? { ...x, ...action.item } : x),
				status: UserConstant.UPDATED
			};
		case UserConstant.REMOVED:
			return {
				...state,
				item: {},
				status: UserConstant.REMOVED
			};
		case UserConstant.UNREGISTER:
			return {
				...state,
				item: state.item?._id === action._id ? {} : state.item,
				items: state.items.filter(x => x._id !== action._id)
			}
		case UserConstant.APPEND_USER:
			return {
				...state,
				items: [...state.items, ...action.items]
			}
		case UserConstant.GET:
			return {
				...state,
				item: action.item,
				items: state.items.filter(x => x._id === action.item._id).length > 0 ?
					state.items.map(x => x._id == action.item._id ? action.item : x) :
					[...state.items, action.item],
				status: ""
			};
		case UserConstant.STATUSCLEAR:
			return { ...state, status: "" };

		default:
			return state
	}
}