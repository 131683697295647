import * as AWS from 'aws-sdk';
import { AppConfig, JWT } from '../config';
import { DoDB } from './ddb';
import axios from 'axios';
import { PERIOD_UNIT } from '../constant/payment';
import { t } from 'i18next';
import { DISPLAY_PERIOD_TYPE, WIDE_UNIT } from '../components/PeriodStr';
import moment from 'moment';

export const getApiUrl = (type, region = null) => {
    const sandboxApiTypes = ['user', 'project', 'group'];
    const sandboxKey = window?.localStorage?.getItem("SANDBOX_KEY");
    try {
        if (!region) {
            let user = window?.localStorage?.getItem('user');
            if (user) {
                user = JSON.parse(user);
                if (user.region) {
                    region = user.region;
                }
            }
        }
    } catch (error) {
    }

    if (!region) region = 'ap-northeast-2';

    let envSuffix = window.env === 'production' ? '' : ('-' + window.env);

    if (sandboxKey && sandboxApiTypes.includes(type)) {
        envSuffix = '-' + sandboxKey;
    }

    if (getEnv() === "production") {
        return `https://api-${region}-${type}.rayteams.com`;
    } else {
        return `https://api-${region}-${type}.raydevelop.com`;
    }
}

export const getApiManagerUrl = (type, region = null) => {

    try {
        if (!region) {
            let user = window?.localStorage?.getItem('user');
            if (user) {
                user = JSON.parse(user);
                if (user.region) {
                    region = user.region;
                }
            }
        }
    } catch (error) {
    }

    if (!region) region = 'ap-northeast-2';

    if (getEnv() === "production") {
        return `https://api-manager-${type}.rayteams.com`;
    } else {
        return `https://api-manager-${type}.raydevelop.com`;
    }
}


export const getAuthApiUrl = (suffix = "") => {
    const sandboxKey = window?.localStorage?.getItem("SANDBOX_KEY");
    return !sandboxKey ?
        "https://auth.raysmiler.co.kr" + suffix : 
        "https://r69d1vle4j.execute-api.ap-northeast-2.amazonaws.com/test" + suffix
};

export const rayTeamsClientDownloadURL = async () => {
    const list = await DoDB("query",  {
        TableName: 'rayrnd-applications',
        KeyConditionExpression: '#name=:name and begins_with(#type,:v)',
        FilterExpression: 'is_active=:true',
        ExpressionAttributeNames: { '#name': 'name', '#type': 'type' },
        ExpressionAttributeValues: {
            ':name': 'RayLink',
            ':true': true,
            ':v': 'v:production:'
        }
    })
    const data = {
        Items: list,
    };

    // const data = await dc.query(params).promise();
    
    return versionSort(data.Items.filter(x => x?.full_file?.direct_link))[0].full_file?.direct_link;
}

export const versionSort = (arr, nested = 'version', reverse = false) => {
    let result = arr;
    try {
        result = arr.sort((b, a) => a[nested].localeCompare(b[nested], undefined, { numeric: true }));
        if (reverse) {
            result = result.reverse();
        }
    } catch (error) {
        console.log('ERROR[versionSort]: ', error);
    }
    return result;
};

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
}


export const moveTo = (history, e, path) => {
    if (e) {
        e.preventDefault();
    }
    history.push(path);
}

export const getVersion = (version) => {

    // const split = version?.split('.') || [];

    // let converted = '';

    // split.forEach((x, idx) => {
    //     if (idx <= 2)
    //         converted += x + (idx !== 2 ? '.' : '');
    // })

    return 'v' + version;
}

export const setRtConfig = async () => {
    const cPath = "https://ray-data" + 
        (getEnv() === "production" ? "" : "-development") + 
        ".s3.ap-northeast-2.amazonaws.com/rayrnd/applications/RayLink/applicationConfig.json" + 
        `?t=${new Date().getTime()}`;
    const ret = await axios.get(cPath);
    window.rtconfig = ret.data ||  {};
    return ret.data;
};

export const getRAYTeamsConfig = async () => {
    try {
        const ret = await axios.get("https://ray-data" + (getEnv() === "production" ? "" : "-development") + ".s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/ray/config.json");
        window.rayteamsconfig = ret.data || {};
    } catch { }
};

export const payableSQA = async () => {
    if (getEnv() !== "production") {
        return true;
    }
    try {
        const payables = window?.rtconfig?.payables || "";
        const user = JSON.parse(localStorage.getItem('user'));
        const email = user?.email;
        if (!email) {
            return false;
        }
        const payusers = payables ? payables?.split(",").map(x => x.trim()) : [];
        let payable = payusers.length === 0 || payusers.includes(email);
        return payable;
    } catch (error) {
        return true;
    }
};

const payableKRUsers = [
    "sungwon.bang@raymedical.co.kr",
    "2manbok@gmail.com",
    "boo01@test.test",
    "boo02@test.test",
    "au01@test.test",
    "goo01@test.test",
    "de01@test.test",
    "goo02@test.test",
    "dev01@test.test",
    "dev02@test.test",
    "dev03@test.test",
    "sqa_dev_ko_01@test.test",
    "sqa_dev_ko_02@test.test",
    "sqa_dev_de_01@test.test",
    "sqa_dev_de_02@test.test",
    "testp88@naver.com",
    "ja01@test.test",
    "pre.clinic@test.test",
    "pre.de.clinic@test.test",
];
export const getIsSubscribableKr = (payable = true) => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        const email = user?.email;
        if (!email) {
            return false;
        }
        if (user?.countryCode === "KR") {
            payable = payableKRUsers.includes(email);
        }
        return payable;    
    } catch (error) {
        return true;
    }
};

export const getEnv = () => {
    if (location.href.indexOf("localhost") > -1) {
        return "development";
    } else if (location.href.indexOf("raydevelop") > -1) {
        return "development";
    }

    return "production";
}

export const DownloadApp = async (name) => {
    console.log(name);
    const apiPath = getEnv() === "production" ?
        "https://api-rsp-application.rayteams.com/app/" + name + "/downloadlatest?full=yes" : 
        "https://api-rsp-application.raydevelop.com/app/" + name + "/downloadlatest?full=yes";
    const ret = await axios.get(apiPath);
    if(ret.data.status === "success"){
        window.open(ret.data.data.url, name + " download");
    }
}

export const getMinifiedObj = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
        const _val = obj[key];
        if (["string", "number", "boolean"].includes(typeof(_val))) {
            acc[key] = _val;
        }
        return acc;
    }, {});
};

export const getRealAppName = (_name) => {
    if (_name === "ConverterForExocad") {
        return "ExocadConverter";
    }
    return _name;
};

export const calcPeriodStr = (str) => {
    if (!str) {
        return {};
    }
    const unit = str.replace(/\d/g, "");
    const num = Number(str.replace(/[^\d]/g, ""));
    if (!num) {
        return {};
    }
    const unitForCount = PERIOD_UNIT[unit];
    if (!unitForCount) {
        return {};
    }
    const days = num * unitForCount;
    const month = ~~(days / 30);
    const year = ~~(days / 365);
    const result = { days, month, year, unit, value: num };
    return result;
};

const getWideUnit = (periodSet) => {
    if (periodSet.year > 0) {
        return WIDE_UNIT.YEAR;
    } else if (periodSet.month > 0) {
        return WIDE_UNIT.MONTH;
    } else {
        return WIDE_UNIT.DAY;
    }
};
export const periodStr = ({ periodSet, excludeOne = false }) => {
    if (!periodSet?.days) {
        return "";
    }
    const wideUnit = getWideUnit(periodSet);
    if (!excludeOne) {
        if (wideUnit ===  WIDE_UNIT.YEAR) {
            if (periodSet.year === 1) {
                return t("{{period}}{{periodUnitYear}}", {
                    period: periodSet.year,
                    periodUnitYear: t("년"),
                });
            } else if (periodSet.year > 1) {
                return t("{{period}}{{periodUnitYears}}", {
                    period: periodSet.year,
                    periodUnitYears: t("년"),
                });
            }
        }
        if (wideUnit === WIDE_UNIT.MONTH) {
            if (periodSet.month === 1) {
                return t("{{period}}{{periodUnitMonth}}", {
                    period: periodSet.month,
                    periodUnitMonth: t("개월"),
                });
            } else if (periodSet.month > 1) {
                return t("{{period}}{{periodUnitMonths}}", {
                    period: periodSet.month,
                    periodUnitMonths: t("개월"),
                });
            }
            
        }
        if (wideUnit === WIDE_UNIT.DAY) {
            if (periodSet.days === 1) {
                return t("{{period}}{{periodUnitDay}}", {
                    period: periodSet.days,
                    periodUnitDay: t("일"),
                });
            } else if (periodSet.days > 1) {
                return t("{{period}}{{periodUnitDays}}", {
                    period: periodSet.days,
                    periodUnitDays: t("일"),
                });
            }
        }
    } else {
        if (wideUnit ===  WIDE_UNIT.YEAR) {
            if (periodSet.year === 1) {
                return t("{{periodUnitYear}}", {
                    periodUnitYear: t("연"),
                });
            } else {
                return t("{{period}}{{periodUnitYears}}", {
                    period: periodSet.year,
                    periodUnitYears: t("년"),
                });
            }
        }
        if (wideUnit === WIDE_UNIT.MONTH) {
            if (periodSet.month === 1) {
                return t("{{periodUnitMonth}}", {
                    periodUnitMonth: t("월"),
                });
            } else {
                return t("{{period}}{{periodUnitMonths}}", {
                    period: periodSet.month,
                    periodUnitMonths: t("개월"),
                });
            }
        }
        if (wideUnit === WIDE_UNIT.DAY) {
            if (periodSet.days === 1) {
                return t("{{periodUnitDay}}", {
                    periodUnitDay: t("일"),
                });
            } else {
                return t("{{period}}{{periodUnitDays}}", {
                    period: periodSet.days,
                    periodUnitDays: t("일"),
                });
            }
        }
    }
    
    return "";
};

export const convertToKoreanNumber = (num) => {
    var result = '';
    var digits = ['영','일','이','삼','사','오','육','칠','팔','구'];
    var units = ['', '십', '백', '천', '만', '십만', '백만', '천만', '억', '십억', '백억', '천억', '조', '십조', '백조', '천조'];
    
    var numStr = num.toString();
    var numLen = numStr.length;
    
    for(var i=0; i<numLen; i++) {
      var digit = parseInt(numStr.charAt(i));
      var unit = units[numLen-i-1];

      if(i === numLen-1 && digit === 1 && numLen !== 1) {
        result += '일';
      } else if(digit !== 0) {
        result += digits[digit] + unit;
      } else if(i === numLen-5) {
        result += '만';
      }
    }
    
    return result;
};

export const getProductEmailInfo = async (pid) => {
    const list = await DoDB("query",  {
        TableName: "rayteams-product",
        KeyConditionExpression: "#_id = :_id and #sk = :sk",
        ExpressionAttributeNames: { 
            '#_id': '_id', 
            '#sk': 'sk' 
        },
        ExpressionAttributeValues: {
            ':_id': pid,
            ':sk': "info",
        }
    })
    const data = {
        Items: list,
    };

    return data?.Items && data.Items.length > 0 ? data.Items[0]?.emailTemplates : [];
};

export const getEmailTemplate = async (cid, lang) => {
    const list = await DoDB("query",  {
        TableName: "rayteams-manager-contents",
        KeyConditionExpression: "#_id = :_id and #sk = :sk",
        ExpressionAttributeNames: { 
            '#_id': '_id', 
            '#sk': 'sk' 
        },
        ExpressionAttributeValues: {
            ':_id': cid,
            ':sk': `lang:${lang}`,
        }
    })
    const data = {
        Items: list,
    };

    return data?.Items && data.Items.length > 0 ? data.Items : [];
};

// {{product.title}} - 상품명
// {{user.name}} - 이름
// {{product.refunddate}} - 환불 요청일
export const sendEmail = async (cid = "", lang, contentObject) => {
    const emailTemplate = cid != "" ? await getEmailTemplate(cid, lang) : ""
    moment.locale(lang ||  "en");
    const user_Info = localStorage?.getItem("user") && JSON.parse(localStorage.getItem("user"));
    const url = "https://api-manager-user." + (getEnv() === 'production' ? "rayteams.com" : "raydevelop.com") + "/sns/notification";
    const refContents = {
        user: {
            name: user_Info?.name
        },
        product: {
            title: contentObject?.title,
            refunddate: moment(contentObject?.rfreqtime).format("LL"),
        }
    }
    let email = {
        subject: "[Ray] 결제 수단이 정상적으로 등록 되었습니다.",
        contents: `${user_Info?.name}님,</br> 안녕하세요, 주식회사 레이 입니다. </br></br> 고객님 께서 등록 하신 결제 수단이 정상적으로 등록 되었습니다. </br></br>추가 문의 사항이나 도움이 필요하시면 언제든지 support@rayteams.com로 연락 주시기 바랍니다.</br></br>감사합니다.</br></br></br>주식회사 레이 드림`,
        force: true
    };
    if (emailTemplate[0] && emailTemplate[0]?.title) {
        email = {
            subject: replaceVariablesText(emailTemplate[0].title, refContents),
            contents: replaceVariablesText(emailTemplate[0].content, refContents),
            force: true
        }
    }
    const sendMailParams = {
        data: {
            userId: user_Info._id,
            pushTypes: ["email"],
            email: email,
            emailTemplateCode: "default",
        }
    }
    const sentRet = await axios.post(url, sendMailParams, JWT());
};

export const replaceVariablesText = (doc, dataSet) => {
    if (!doc || typeof doc !== "string" || typeof dataSet !== "object") {
        return doc;
    }
    const valList = [...(new Set(doc.match(/({{.*?}})/g)))];
    for (const val of valList) {
        const targetVal = _.get(dataSet, val.replace(/[{|}]/g, "").trim());
        if (["string", "number"].includes(typeof(targetVal)) || targetVal === null) {
            doc = doc.replace(new RegExp(val, "g"), targetVal === null ? "" : targetVal);
        }
    }
    return doc;
};