import { getEnv } from './lib/function';

export const JWT = () => {
    return {
        headers: {
            token: localStorage.getItem('userToken'),
            'x-ray-device': 'WEB'
        }
    }
}

const getServerId = (str) => {
    var n = localStorage.getItem("serverId");
    if (n && n !== "undefined") {
        str = str.indexOf("localhost") > -1 ? "https://apidev.raysmiler.co.kr/api" : str;
        var arr = str.split('.');
        arr[0] = arr[0] + "-" + n;
        return arr.join(".");
    }
    return str;
}

export const AppConfig = {        
    GOOGLE_MAP_KEY: 'AIzaSyBQnUIOBn8Z5x58kX-eiAmRCQfYciNq6o4',
    
    DYNAMO_DB_ACCESS_KEY: "",
    DYNAMO_DB_SECRET_KEY: "",

    //DYNAMO_DB_ACCESS_KEY: getEnv() === 'production' ? 'AKIAV6JBTAHFK6K4N2EF' : 'AKIA55BEW5XOS2QI2OVK',
    //DYNAMO_DB_SECRET_KEY: getEnv() === 'production' ? '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' : 'H0ecoTMk/NVnJx2SMZWAIuOSA3JikHzf3sD8F8DU',

    MAIL_SEND_ACCESS_KEY: getEnv() === 'production' ? 'AKIAV6JBTAHFMOH33JXZ' : 'AKIA55BEW5XOS2QI2OVK',
    MAIL_SEND_SECRET_KEY: getEnv() === 'production' ? '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' : 'H0ecoTMk/NVnJx2SMZWAIuOSA3JikHzf3sD8F8DU',
    
    S3_PATH: 'https://rayteams-pub-data.s3.ap-northeast-2.amazonaws.com/',
    S3_ACCESS_KEY: getEnv() === 'production' ? 'AKIAV6JBTAHFG47DJEIO' : 'AKIAV6JBTAHFMOH33JXZ',
    S3_SECRET_KEY: getEnv() === 'production' ? '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' : 'H0ecoTMk/NVnJx2SMZWAIuOSA3JikHzf3sD8F8DU',
    S3_BUCKET_NAME: 'rayteams-pub-data',

    PAYPAL_CLIENT_ID: 'AcgQywJgoHlnFcQSkg94GU9lDEEQWmedVEun33I0WQ0HpZrgC27w1OO8q6WzXx4yZaunhU49gbQuHjda',
    PAYPAL_SECRET: 'EOdBgVtKLl6BrlsRKuPd3kjpm7mc0DAob2qKaCO-C1Mb3jH22xPukeIXfbOxOIQaXxx0UFrCPEmMwIP9',
    
    TOSS_CLIENT_KEY: 'test_ck_YPBal2vxj814G9q57wAr5RQgOAND',
    TOSS_SECRET_KEY: 'test_sk_GKNbdOvk5rkaYApm9y43n07xlzmj',
    
    STRIPE_PUBLISHABLE_KEY: 'pk_test_51MmR7NCBIqssotRkomQ2OhKY0NLBDu4CrZ9UMF5RdQ5jIP0eeuGCYABu2Kx2O6xjQgHy0Z8vAdHywg6bKCOoZHUl00rmsZjm5U',
    STRIPE_SECRET_KEY: 'sk_test_51MmR7NCBIqssotRkG74ulSjsUJl9vkfwLj3UM3zlMkderNTYWBlDcj1o3Nua5hokXDA9JtMQXNe1KEs1kbn8nd9W00mC3Sip5r'
};
