import { useMemo } from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';


const FileExpIcon = ({ name, exp, className, style, size, ..._rest }) => {
    const _exp = useMemo(() => {
        let value = ";"
        if (typeof name === "string" && name?.trim()) {
            value = name.trim().split(".").at(-1);
        }
        if (value) {
            return value;
        }
        if (typeof exp === "string" && exp?.trim()) {
            return exp;
        }
        return "File";
    })

    return <div className={className} style={style}>
        <FileIcon
            extension={_exp}
            {...defaultStyles[_exp]}
            {..._rest}
        />
    </div>
};

export default FileExpIcon;