import React, { useEffect, useState } from "react";
import axios from 'axios';
import { connect, useDispatch } from "react-redux";
import { useLocation,  } from "react-router";
import Header from "./Header";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getApiUrl } from '../lib/function'
import Loader from "./Loader";
import { AuthConstant, ProgramConstant, AlertConstant } from '../redux/reducer';
import { history } from "../history";
import { UserAction } from "../redux/action/UserAction";
import queryString from 'query-string';

const OAuth20 = ({ Login }) => {
    const [t] = useTranslation(['translation']);
    const [data, setData] = useState({ email: '', password: '', autoLogin: true });
    const [errMsg, setErrMsg] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const { _appId } = useParams();
    const { subPage, param } = useParams();
    const [showBaaDpa, setShowBaaDpa] = useState(false);
    const [baaDpa, setBaaDpa] = useState("");
    const [accept, setAccept] = useState({ terms: false, privacy: false, baadpa: false });
    const [htmlContents, setHtmlContents] = useState({ baadpa: '' });
    const { search } = useLocation();
    const r_url = queryString.parse(search);

    console.log('redirect_url', search);
    console.log('r_url', r_url);
    const loginHandler = async () => {
        setShowLoader(true);
        const API_URL = getApiUrl('user');
        const ret = await axios.post(API_URL + '/oauth20/login', { data });
        if(ret.data?.data?.code){
            if(r_url?.redirect_url){
                window.location = r_url?.redirect_url.indexOf('?') > -1 ?
                r_url?.redirect_url + "&code=" + ret.data?.data?.code : 
                r_url?.redirect_url + "?code=" + ret.data?.data?.code;
            }
        }else{
            setErrMsg(ret.data?.error);
        }
        setShowLoader(false);
    }

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    }

    return (
        <>
            <div className="content-wrapper rt-site image-wrapper bg-image bg-overlay bg-overlay-400 bg-content" data-image-src="/assets/img/bg_signin.jpg" style={{ backgroundImage: 'url(/assets/img/bg_signin.jpg)', height: '100%' }}>
            <section className="wrapper text-white">
                <div className="container pt-12 pb-10 pt-md-21 pb-md-18 signin" style={{ zIndex: 5, position: 'relative' }}>
                    <div className="d-flex align-items-center w-100 justify-content-center">
                        <div className="signup" style={{ width : '500px' }}>
                            <div className="card">
                                <div className="card-body p-11 text-center text-dark">
                                    <h1 className="mb-5 text-center">RAYTeams</h1>
                                    <p className="mb-0 text-start">{t('로그인하시려면 이메일과 비밀번호를 입력해주세요.')}</p>
                                    <p className="mb-6 text-start">{t('계정이 없으신가요?')} <a href="#" className="hover" onClick={(e) => { e.preventDefault(); history.push('/signup') }}>{t('회원가입')}</a></p>
                                    <form className="text-start mb-8">
                                        <div className="form-floating mb-4">
                                            <input type="email" name="email" value={data.email} className="form-control" placeholder={t('이메일')} id="loginEmail" onChange={handleChange} onKeyDown={(e) => e.key === 'Enter' && loginHandler()} />
                                            <label htmlFor="loginEmail">{t('이메일')}</label>
                                        </div>
                                        <div className="form-floating password-field mb-4">
                                            <input
                                                type={!showPassword ? "password" : "text"}
                                                name="password"
                                                className="form-control"
                                                placeholder={t('비밀번호')}
                                                value={data.password}
                                                id="loginPassword"
                                                onChange={handleChange}
                                                onKeyDown={(e) => e.key === 'Enter' && loginHandler()}
                                            />
                                            <span className="password-toggle" onClick={() => setShowPassword(!showPassword)}><i className={`uil uil-eye${showPassword ? '-slash' : ''}`}></i></span>
                                            <label htmlFor="loginPassword">{t('비밀번호')}</label>
                                        </div>
                                        <p className="text-danger mt-n2" >{errMsg && errMsg}</p>                                        
                                        <a className="btn btn-primary rounded-pill btn-login w-100 mb-2" onClick={loginHandler}>{t('로그인')}</a>
                                        
                                    </form>
                                    <p className="mb-1 text-end forgot-pw">
                                        <a href="#" className="hover" onClick={(e) => { e.preventDefault(); history.push('/forgotpw') }}>{t('비밀번호 찾기')}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Loader isShow={showLoader} />
        </div>
        </>
    );
}

export default OAuth20;
