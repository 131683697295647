import React, { useEffect, useState } from 'react';

const ResetPassword = () => {

    useEffect(() => {
        document.getElementsByTagName('footer')[0].style.display = 'none';
    }, []);

    return (
        <div className="content-wrapper rt-site image-wrapper bg-image bg-overlay bg-overlay-400 bg-content" data-image-src="/assets/img/bg_signin.jpg" style={{ backgroundImage: 'url(/assets/img/bg_signin.jpg)', height: '100%' }}>
            <section className="wrapper text-white">
                <div className="container pt-12 pb-10 pt-md-21 pb-md-18 signin" style={{ zIndex: 5, position: 'relative' }}>
                    <div className="row gx-0 gy-8 align-items-center">
                    <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-6 content text-center text-lg-start signin-logo" data-cues="slideInDown" data-group="page-title" data-delay="600" data-disabled="true">
                            <a href="#" onClick={(e) => { e.preventDefault(); history.push('/') }}>
                                <img src="/assets/img/bi_rayteams_light.svg" className="mb-5" alt="RAYTeams" data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                    style={{
                                        animationName: 'slideInDown',
                                        animationDuration: '700ms',
                                        animationTimingFunction: 'ease',
                                        animationDelay: '600ms',
                                        animationDirection: 'normal',
                                        animationFillMode: 'both'
                                    }}
                                />
                            </a>
                            <p className="lead fs-lg lh-sm" data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                style={{
                                    animationName: 'slideInDown',
                                    animationDuration: '700ms',
                                    animationTimingFunction: 'ease',
                                    animationDelay: '900ms',
                                    animationDirection: 'normal',
                                    animationFillMode: 'both'
                                }}
                            >
                                <span className="underline-2 violet">Log in</span> or sign up for RAYTeams.<br />One account for RAYTeams Cloud and client.
                            </p>
                        </div>
                        <div className="col-lg-5 offset-lg-1 signup">
                            <div className="card">
                                <div className="card-body p-11 text-center text-dark">
                                    <h2 className="mb-3 text-start">Forgot Password?</h2>
                                    <p className="mb-4 text-start"><span className="text-primary">Please check your email.</span><br />We sent to the email address with verification code.</p>
                                    <form className="text-start mb-3">
                                        <div className="form-floating mb-2">
                                            <input type="email" className="form-control" value="junyoung.hong@raymedical.co.kr" readOnly={true} />
                                            <label>Email</label>
                                        </div>
                                        <div className="form-floating mb-2">
                                            <input type="email" className="form-control" placeholder="Email" />
                                            <label>Verification code *</label>
                                        </div>
                                        <div className="form-floating password-field mb-6">
                                            <input type="password" className="form-control" placeholder="Password" />
                                            <span className="password-toggle"><i className="uil uil-eye"></i></span>
                                            <label>New Password *</label>
                                        </div>
                                        <p className="text-danger mt-n4" style={{ display: 'none' }}>email, password, name fields is empty.</p>
                                        <div className="row">
                                            <div className="col-12 col-md-3 order-1 mt-2 mt-lg-0 mt-md-0">
                                                <a className="btn btn-soft-primary rounded-pill btn-login w-100">Prev</a>
                                            </div>
                                            <div className="col-12 col-md-9 order-2">
                                                <a className="btn btn-primary rounded-pill btn-login w-100">Submit</a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ResetPassword;