import { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from "react-select";
import { COUNTRIES, getRegion } from "../constant/country";
import { history } from '../history';
import { formAddressApi, fromAddress } from '../lib/geocode';
import PrivacyEN from "../lib/privacy_en";
import PrivacyKO from "../lib/privacy_ko";
import { emailRegex, passwordRegex, passwordRegexLower, passwordRegexNumber, passwordRegexSpecial, passwordRegexUpper } from "../lib/regex";
import { AppAction, AuthAction, GroupAction, UserAction } from "../redux/action";
import Loader from "./Loader";


const SignUpForm = ({ userData, setUserData, officeData, setOfficeData, htmlContents, setHtmlContents, setSignUpComplete, CreateGroup, CreateUser, GetAppDocs, GetAppDoc, baadpaInfo, baadpaInfos }) => {
    const [t, i18n] = useTranslation(['translation']);

    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);
    const [accept, setAccept] = useState({ terms: false, privacy: false, baadpa: false });
    const [joinType, setJoinType] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [showBaaDpa, setShowBaaDpa] = useState(false);
    const [baaDpa, setBaaDpa] = useState("");

    useEffect(() => {
        console.log("signUpForm", baadpaInfos);
        //if (!appInfo)
        GetAppDocs('RayLink');
    }, [])

    useEffect(() => {
        console.log("appInfo : ===", baadpaInfos);
    }, [baadpaInfos])

    useEffect(() => {
        console.log("appInfoInfo : ======", baadpaInfo);
        if(baadpaInfo?.data?.length > 0)
            setBaaDpa(baadpaInfo?.data[0]?.contents);
    }, [baadpaInfo])

    useEffect(() => {
        setJoinType(officeData.grouptype[0]);
    }, [officeData]);

    const changeCountryCode = (e) => {
        const countryCode = e.value;
        const region = getRegion(countryCode)?.region || '';
        setOfficeData({ ...officeData, type: '', countryCode, _id: '', name: '', address: '', postalCode: '', tel: '', region });
        console.log("country Code : ====", officeData);
        // 국가별
        // 위탁 계약서 : 한국
        // BAA : 미국
        // DPA : 한국 / 미국 제외 전국가
        let code = "privacyConstantKor";
        if (countryCode == "KR") {
            code = "privacyConstantKor";
        } else if (countryCode == "US") {
            code = "BAA";
        } else {
            code = "DPA";
        }
        let baaDpaCode = baadpaInfos && baadpaInfos.data.filter(f => f.code == code )[0]?.lastDoc[0]?.type;
        getBaaDpa(baaDpaCode);
        setAccept({ ...accept, baadpa: false })
    }

    const getBaaDpa = (paramId) => {
        GetAppDoc(paramId);
    }


    const getCoords = async () => {
        const countryCode = officeData.countryCode;
        const address = officeData.address;

        let coords;

        if (countryCode === 'CN' || countryCode === 'HK') {
            const apiDomain = 'https://api-ap-east-1-user.rayteams.com';
            const language = 'zh-' + countryCode;
            const geocodeRet = await formAddressApi(address, language, apiDomain);
            coords = geocodeRet.coords;
        } else {
            let response;
            try {
                response = await fromAddress(address);
            } catch (error) {

            }
            coords = response?.coords;
        }

        return coords;
    }

    // TODO:개인정보 위탁 관련 정보 iubenda update 확인
    // rayteams client 와 스펙 동일하게
    const createIuBendaConsent = async () => {
        let formData = {
            subject: {
                email: userData.email,
                full_name: userData.name
            },
            proofs: [
                {
                    content: htmlContents.terms,
                    form: "terms form"
                },
                {
                    content: htmlContents.policy,
                    form: "user form"
                }
            ],
            ip_address: window?.localStorage?.getItem('RAYLINK_LOCALIP')
        };

        if(joinType == "clinic") {
            formData.proofs.push({
                content: htmlContents.baadpa,
                form: baadpaInfo?.data[0]?.title
            })
        }
        
        await AuthAction.CreateIuBendaConsent(formData);
    };

    const signUpRequest = async () => {
        setShowLoader(true);

        const validationPass = await validation();

        if (validationPass) {
            const coords = await getCoords();
            if (coords) {
                // group 생성시 baa/dpa 동의 항목 추가, clinic 만
                let officeFormData = {
                    ...officeData,
                    coords: [coords.lng, coords.lat]
                };

                if (joinType == "clinic") {
                    officeFormData = {
                        ...officeFormData, 
                        dataProcessing: {
                            code: baadpaInfo?.data[0]?.type?.split(":")[3],
                            contentId: baadpaInfo?.data[0]?.type || "",
                            accepted: new Date().getTime(),
                        }
                    };
                };

                setOfficeData(officeFormData);
                const response = await CreateGroup(officeFormData);
                if (response.status === 'success') {
                    const uLen = await UserAction.GetUserIdsByGroupIdNoAuth(response.data._id, response.data.region);
                    const time = new Date().getTime();
                    let termpol = [
                        {
                            accepted: time,
                            checked: true,
                            code: "term",
                            contentId: "term_id"
                        },
                       {
                            accepted: time,
                            checked: true,
                            code: "privacy",
                            contentId: "privacy_id"
                        },
                       {
                            accepted: time,
                            checked: true,
                            code: "ag_openInfo",
                            contentId: "ag_openInfo"
                        }
                    ];

                    if (joinType == "clinic") {
                        termpol.push(
                            {
                                accepted: time,
                                checked: true,
                                code: baadpaInfo?.data[0]?.type?.split(":")[3],
                                contentId: baadpaInfo?.data[0]?.type || ""
                            }
                        )
                    }

                    if(accept?.marketing) {
                        termpol.push(
                            {
                                accepted: time,
                                checked: true,
                                code: "marketing",
                                contentId: "marketing"
                            }
                        )
                    }
                    
                    let data = {
                        ...userData,
                        countryCode: response.data.countryCode,
                        region: response.data.region,
                        groupId: response.data._id,
                        grouptype: response.data.grouptype ?? [],
                        type: [uLen.data.length > 0 ? 'user' : 'manager'],
                        termpol: termpol
                    }

                    if(accept?.marketing) {
                        data = {
                            ...data,
                            marketingAgree: true
                        }
                    };

                    const ret = await CreateUser(data);

                    if (ret.status === 'success') {
                        await GroupAction.UpdateHasMember(response.data._id, true, userData.region);
                        await createIuBendaConsent();
                        setSignUpComplete(true);
                    } else {
                        setErrMsg(ret.error);
                    }
                }
            } else {
                setErrMsg(t('Not find address coords. Please re-enter your address.'));
                setShowLoader(false);
                return;
            }
        }

        setShowLoader(false);
    }

    const validation = async () => {
        if (officeData.countryCode === '') {
            setErrMsg(t('국가를 선택해주세요.'));
            return false;
        }

        if (officeData.name === '') {
            setErrMsg(t('기관이름을 입력해주세요.'));
            return false;
        }

        if (officeData.address === '') {
            setErrMsg(t('주소를 입력해주세요.'));
            return false;
        }

        if (userData.name === '') {
            setErrMsg(t('이름을 입력해주세요.'));
            return false;
        }

        if (!emailRegex.test(userData.email)) {
            setErrMsg(t('올바르지 않은 형식의 이메일입니다.'));
            return false;
        }

        const ret = await UserAction.CheckEmail(userData.email);

        if (ret.status === 'success') {
            if (ret.data.exist) {
                setErrMsg(t('중복된 이메일이 존재합니다.'));
                return false;
            }
        }
        else {
            setErrMsg(t('오류가 발생하였습니다.'));
            return false;
        }

        if (!passwordRegex.test(userData.password)) {
            setErrMsg(t('패스워드 생성규칙에 위배됩니다.'));
            return false;
        }

        if (!accept.terms) {
            setErrMsg(t('약관에 동의해주세요.'));
            return false;
        }

        if (!accept.privacy) {
            setErrMsg(t('개인보호 정책에 동의해주세요.'));
            return false;
        }

        if (!accept.baadpa && joinType === "clinic") {
            setErrMsg(t('개인정보 전송 및 사용에 동의해주세요.'))
            return false;
        }

        return true;
    }

    return (
        <div className="content-wrapper rt-site image-wrapper bg-image bg-overlay bg-overlay-400 bg-content" data-image-src="/assets/img/bg_signin.jpg" style={{ backgroundImage: 'url(/assets/img/bg_signin.jpg)', height: '100%' }}>
            <section className="wrapper text-white">
                <div className={`container pt-12 pb-10 pt-md-21 pb-md-18 signin ${baaDpa && joinType == "clinic" && officeData.countryCode != ""  ? "signup-4" : "signup-2"}`} style={{ zIndex: 5, position: 'relative' }}>
                    <div className="row gx-0 gy-8 align-items-center">
                        <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-6 content text-center text-lg-start signin-logo" data-cues="slideInDown" data-group="page-title" data-delay="600" data-disabled="true">
                            <a href="#" onClick={(e) => { e.preventDefault(); history.push('/') }}>
                                <img src="/assets/img/bi_rayteams_light.svg" className="mb-5" alt="RAYTeams" data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                    style={{
                                        animationName: 'slideInDown',
                                        animationDuration: '700ms',
                                        animationTimingFunction: 'ease',
                                        animationDelay: '600ms',
                                        animationDirection: 'normal',
                                        animationFillMode: 'both'
                                    }}
                                />
                            </a>
                            <p className="lead fs-lg lh-sm" data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                style={{
                                    animationName: 'slideInDown',
                                    animationDuration: '700ms',
                                    animationTimingFunction: 'ease',
                                    animationDelay: '900ms',
                                    animationDirection: 'normal',
                                    animationFillMode: 'both'
                                }}
                            >
                                Log in or <span className="underline-2 violet">sign up</span> for RAYTeams.<br />One account for RAYTeams Cloud and client.
                            </p>
                        </div>
                        <div className="col-lg-6 signup">
                            <div className="card">
                                <div className="card-body p-11 text-center text-dark pt-9">
                                    <h2 className="mb-2 text-start">{t('회원가입')}</h2>
                                    <p className="text-start mb-0">
                                        {t('이미 기관이 가입되어 있다면 해당 기관의 매니저 계정의 유저가 로그인하여 당신을 초대해야 합니다.')}
                                    </p>
                                    <form className="text-start mb-3">
                                        <div className="d-flex flex-row px-0 mt-0 py-4 align-items-center">
                                            <div>
                                                <div className="btn-circle me-2 align-items-center d-flex">
                                                    <img src={`/assets/img/signup-${joinType.toLowerCase()}.svg`} className="signup-type" />
                                                </div>
                                            </div>
                                            <div>
                                                <h4 className="mb-0">
                                                    {joinType === "clinic" && t('치과 회원')}
                                                    {joinType === "lab" && t('기공소 회원')}
                                                    {joinType === "other" && t('기타 기관 회원')}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-select-wrapper mb-2" style={{ width: '50%' }}>
                                                <Select
                                                    options={COUNTRIES.map(x => { return { label: x.name, value: x.countryCode } })}
                                                    placeholder={t('국가') + ' *'}
                                                    onChange={changeCountryCode}
                                                    styles={{
                                                        control: (styles) => ({
                                                            ...styles,
                                                            border: '1px solid rgba(8, 60, 130, 0.07)',
                                                            height: '52px',
                                                            backgroundColor: '#fefefe',
                                                            fontSize: '0.75rem',
                                                            fontWeight: '500 !important',
                                                            color: '#60697b !important',
                                                            borderRadius: '0.4rem',
                                                            boxShadow: '0rem 0rem 1.25rem rgb(30 34 40 / 4%)',
                                                            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;'
                                                        }),
                                                    }}
                                                    value={COUNTRIES.filter(x => x.countryCode === officeData.countryCode).map(z => { return { label: z.name, value: z.countryCode } })}
                                                />
                                            </div>
                                            <div className="form-floating mb-2 ps-0" style={{ width: '50%' }}>
                                                <input
                                                    type="text"
                                                    className="form-control "
                                                    placeholder={(joinType === 'clinic' && t('치과명')) || (joinType === 'lab' && t('기공소명')) || (joinType === 'other' && t('기관명') || "")}
                                                    onChange={(e) => setOfficeData({ ...officeData, name: e.target.value })}
                                                    value={officeData.name}
                                                />
                                                <label htmlFor="" className="ps-4">{(joinType === 'clinic' && t('치과명')) || (joinType === 'lab' && t('기공소명')) || (joinType === 'other' && t('기관명'))} *</label>
                                            </div>
                                        </div>
                                        <div className="form-floating mb-2">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Address"
                                                onChange={(e) => setOfficeData({ ...officeData, address: e.target.value })}
                                                value={officeData.address}
                                            />
                                            <label htmlFor="">{t('주소')} *</label>
                                        </div>
                                        <div className="row">
                                            <div className="form-floating mb-2" style={{ width: '50%' }}>
                                                <input
                                                    name="name"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('이름')}
                                                    onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                                                    value={userData.name}
                                                />
                                                <label className="ps-7">{t('이름')} *</label>
                                            </div>
                                            <div className="form-floating mb-2 ps-0" style={{ width: '50%' }}>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder={t('이메일')}
                                                    onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                                                    value={userData.email}
                                                />
                                                <label className="ps-4">{t('이메일')} *</label>
                                            </div>
                                        </div>
                                        <div className="form-floating password-field mb-2">
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 250 }}
                                                overlay={<Tooltip style={{ maxWidth: '100%' }}><PasswordGuide password={userData.password} /></Tooltip>}                                                
                                                >
                                                <input
                                                    type={!showPassword ? "password" : "text"}
                                                    className="form-control"
                                                    placeholder={t('비밀번호')}
                                                    onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                                                    value={userData.password}
                                                />
                                            </OverlayTrigger>

                                            <span className="password-toggle" onClick={() => setShowPassword(!showPassword)}><i className={`uil uil-eye${showPassword ? '-slash' : ''}`}></i></span>
                                            <label htmlFor="loginPassword">{t('비밀번호')} *</label>
                                        </div>
                                        <div className="form-check mb-2">
                                            <input className="form-check-input" type="checkbox" id="chkAcceptTerms" checked={accept.terms} readOnly={true} onClick={() => accept.terms ? setAccept({ ...accept, terms: false }) : setShowTerms(true)} />
                                            <label className="form-check-label" htmlFor="chkAcceptTerms">
                                                {i18n.language === 'en' && <>I accept the <a href="#" onClick={(e) => { e.preventDefault(); setShowTerms(true) }}>Terms and Conditions</a>.</>}
                                                {i18n.language === 'ko' && <><a href="#" onClick={(e) => { e.preventDefault(); setShowTerms(true) }}>이용약관</a>에 동의합니다.</>}
                                            </label>
                                        </div>
                                        <div className="form-check mb-2">
                                            <input className="form-check-input" type="checkbox" value="" id="chkAcceptPrivacy" checked={accept.privacy} readOnly={true} onClick={() => accept.privacy ? setAccept({ ...accept, privacy: false }) : setShowPrivacy(true)} />
                                            <label className="form-check-label" htmlFor="chkAcceptPrivacy">
                                                {i18n.language === 'en' && <>I confirm that I have read the <a href="#" onClick={(e) => { e.preventDefault(); setShowPrivacy(true) }}>Privacy Policy</a> and I agree to it including the transfer and use of my personal data.</>}
                                                {i18n.language === 'ko' && <><a href="#" onClick={(e) => { e.preventDefault(); setShowPrivacy(true) }}>개인정보보호정책</a>을 확인하였으며, 개인정보 전송 및 사용에 동의합니다.</>}
                                                
                                            </label>
                                        </div>
                                        {baaDpa && joinType === "clinic" && officeData.countryCode != "" &&
                                        <div className={`form-check ${baaDpa && joinType === "clinic" ? "mb-2" : "mb-6"}`}>
                                            <input className="form-check-input" type="checkbox" value="" id="chkAcceptBaaDpa" checked={accept.baadpa} readOnly={true} onClick={() => accept.baadpa ? setAccept({ ...accept, baadpa: false }) : setShowBaaDpa(true)} />
                                            <label className="form-check-label" htmlFor="chkAcceptBaaDpa">
                                                {i18n.language === 'en' && <>I confirm that I have read the <a href="#" onClick={(e) => { e.preventDefault(); setShowBaaDpa(true) }}>{baadpaInfo?.data[0]?.title}</a> and I agree to it including the transfer and use of my personal data.</>}
                                                {i18n.language === 'ko' && <><a href="#" onClick={(e) => { e.preventDefault(); setShowBaaDpa(true) }}>{baadpaInfo?.data[0]?.title}</a>을 확인하였으며, 개인 정보 전송 및 사용에 동의합니다.</>}
                                                
                                            </label>
                                        </div>
                                        }
                                        <div className={`form-check mb-6`}>
                                            <input className="form-check-input" type="checkbox" id="chkAcceptMarketing" checked={accept.marketing} onClick={() => accept.marketing ? setAccept({ ...accept, marketing: false }) : setAccept({ ...accept, marketing: true })} />
                                            <label className="form-check-label" htmlFor="chkAcceptMarketing">
                                                {t("(Optional) I agree to receive marketing information.")}
                                                
                                            </label>
                                        </div>

                                        {errMsg && <p className="text-danger mt-n4">{errMsg}</p>}
                                        
                                        <div className="row">
                                            <div className="col-12 col-md-3 order-1 mt-2 mt-lg-0 mt-md-0">
                                                <a className="btn btn-soft-primary rounded-pill btn-login w-100" onClick={() => setOfficeData({ ...officeData, grouptype: [] })}>{t('이전')}</a>
                                            </div>
                                            <div className="col-12 col-md-9 order-2">
                                                <a className="btn btn-primary rounded-pill btn-login w-100" onClick={()=> signUpRequest()}>
                                                    {
                                                        joinType === "clinic" && t('치과 회원으로 가입') ||
                                                        joinType === "lab" && t('기공소 회원으로 가입') ||
                                                        joinType === "other" && t('기타 기관 회원으로 가입') 
                                                    }
                                                </a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Loader isShow={showLoader} />

            <TermsAndConditions
                showTerms={showTerms}
                hideTerms={() => setShowTerms(false)}
                acceptTerms={() => setAccept({ ...accept, terms: true })}
                htmlContents={htmlContents}
                setHtmlContents={(data) => setHtmlContents(data)}
            />

            <PrivacyPolicy
                showPrivacy={showPrivacy}
                hidePrivacy={() => setShowPrivacy(false)}
                acceptPrivacy={() => setAccept({ ...accept, privacy: true })}
                htmlContents={htmlContents}
                setHtmlContents={(data) => setHtmlContents(data)}
            />

            {baaDpa && <BaaDpa
                showBaaDpa={showBaaDpa}
                hideBaaDpa={() => setShowBaaDpa(false)}
                acceptBaaDpa={() => setAccept({ ...accept, baadpa: true })}
                htmlContents={htmlContents}
                setHtmlContents={(data) => setHtmlContents(data)}
                baadpaInfo={baadpaInfo}
            />}

        </div>
    )
}

const PasswordGuide = ({ password }) => {
    const [t] = useTranslation();
    const [allPass, setAllPass] = useState(false);
    const [lowerCase, setLowerCase] = useState(false);
    const [upperCase, setUpperCase] = useState(false);
    const [lengthCase, setLengthCase] = useState(false);
    const [numberCase, setNumberCase] = useState(false);
    const [specialCase, setSpecialCase] = useState(false);

    useEffect(() => {
        const target = (password || '').trim();
        setLowerCase(passwordRegexLower.test(target));
        setUpperCase(passwordRegexUpper.test(target));
        setLengthCase(target.length >= 8);
        setNumberCase(passwordRegexNumber.test(target));
        setSpecialCase(passwordRegexSpecial.test(target));
        setAllPass(passwordRegex.test(target));
    }, [password]);

    return <>
        <div className="text-start">
            <div>{t("비밀번호 생성규칙")}</div>
            <ul className='mt-2 p-0' style={{ listStyleType: 'none', fontWeight: 'normal', fontSize: '0.8rem' }}>
                <li style={{ color: lengthCase ? '#fff' : '#ffea00' }}>
                    <img src={`/assets/img/validation_${lengthCase ? 'ok' : 'error'}.svg`} style={{ verticalAlign: 'middle' }} />&nbsp;{t("최소 8자리 이상")}
                </li>
                <li style={{ color: upperCase ? '#fff' : '#ffea00' }}>
                    <img src={`/assets/img/validation_${upperCase ? 'ok' : 'error'}.svg`} style={{ verticalAlign: 'middle' }} /> {t("1개 이상의 대문자")}
                </li>
                <li style={{ color: lowerCase ? '#fff' : '#ffea00' }}>
                    <img src={`/assets/img/validation_${lowerCase ? 'ok' : 'error'}.svg`} style={{ verticalAlign: 'middle' }} /> {t("1개 이상의 소문자")}
                </li>
                <li style={{ color: numberCase ? '#fff' : '#ffea00' }}>
                    <img src={`/assets/img/validation_${numberCase ? 'ok' : 'error'}.svg`} style={{ verticalAlign: 'middle' }} /> {t("1개 이상의 숫자")}
                </li>
                <li style={{ color: specialCase ? '#fff' : '#ffea00' }}>
                    <img src={`/assets/img/validation_${specialCase ? 'ok' : 'error'}.svg`} style={{ verticalAlign: 'middle' }} /> {t("1개 이상의 특수문자")}
                </li>
                <li className="mb-n2" style={{ color: allPass ? '#fff' : '#ffea00' }}>
                    <img src={`/assets/img/validation_${allPass ? 'ok' : 'error'}.svg`} style={{ verticalAlign: 'middle' }} /> {t("모든 규칙 통과")}
                </li>
            </ul>
        </div>
    </>
}

const TermsAndConditions = ({ showTerms, hideTerms, acceptTerms, htmlContents, setHtmlContents }) => {
    const ref = useRef();
    const [t, i18n] = useTranslation(['translation']);
    const [isBottom, setIsBottom] = useState(false);

    useEffect(() => {
        setHtmlContents({ ...htmlContents, terms: document.getElementsByClassName('agree-txt')[0].innerHTML });
    }, []);

    const onScroll = () => {
        if (ref.current) {
            const { scrollTop, scrollHeight, clientHeight } = ref.current;

            if (scrollTop + clientHeight + 10 >= scrollHeight)
                setIsBottom(true);
            else
                setIsBottom(false);   
        }
    };

    const agree = () => {
        if (isBottom) {
            acceptTerms();
            hideTerms();
        }
    }

    return (
        <>
            <div className="modal fade show" id="" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: showTerms ? 'block' : "none" }}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => hideTerms()}></button>
                            <div className="alert alert-danger alert-icon mb-6" role="alert">
                                <i className="uil uil-exclamation-triangle"></i> {t('스크롤을 내려 문서를 끝까지 읽으셔야, 동의에 체크하실 수 있습니다.')}
                            </div>
                            <div className="abox" onScroll={onScroll} ref={ref}>                       
                                <div className="agree-content">                                    
                                    {i18n.language === 'ko' && <TermsAndConditionsKR />}
                                    {i18n.language === 'en' && <TermsAndConditionsEN />}
                                </div>
                            </div>

                            <div className="mt-6">
                                <p className="text-center pb-0 mb-0">
                                    <a className={`btn btn-${isBottom ? 'primary' : 'gray'} btn-icon btn-icon-start rounded-pill btn-lg`} onClick={() => agree()}>
                                        <i className="uil uil-check"></i> {t('위의 내용을 확인하였고, 내용에 동의합니다.')}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show" style={{ display: showTerms ? 'block' : "none" }}></div>
        </>
    )
}
const TermsAndConditionsKR = () => {
    return (
        <div className="agree-txt" style={{ paddingBottom: 10 }}>
            <h2 className="mb-4">이용약관 </h2>
            <div>
                <h3>제1조(목적)</h3>
                이 약관은 주식회사 레이(이하 “회사”)가 운영하는 RAYTeams(이하”플랫폼”)에서 무료로 제공하는 서비스(이하 “서비스”)를 이용함에 있어 플랫폼과 회원의 권리, 의무 및 책임사항, 서비스 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다. <br />
                이 약관은 회원과 회사 사이에 체결된 계약으로 회원의 플랫폼 이용에 적용됩니다. 다음 계약조건을 읽고 숙지하시기 바라며, 귀하가 세부 계약 조건에 대하여 명확한 이해를 토대로 “동의”를 클릭함으로써 귀하가 서비스에 접속하거나 이를 이용하는 경우, 본 약관이 적용된다는 사실에 관하여 귀하는 명시적으로 동의하게 됩니다. <br />
                회사는 플랫폼 서비스 제공자로서 본 약관에 기재된 계약조건들에 따라 귀하가 귀하의 업무 컨텐츠(예를 들어, 귀하의 이름 및 소속기관명, 주소, 직업, 연락처, 이메일, 비밀번호 및 환자의 스캔데이터, 해당 서비스 이용 시 회원 간 계약이행을 위해 상대방에게 전달되는 메모사항 및 채팅 등의 부가 정보)를 저장하고 이를 이용하도록 허용합니다.
                <h3 className="pt-5">제2조(용어의 정의)</h3>
                이 약관에서 사용하는 용어의 정의는 다음과 같습니다. <br /><br />
                <ul className="list-unstyled">
                    <li>1.	“서비스 플랫폼”이란 회사가 회원들 간의 파트너십 체결을 위해 양 당사자 상호 간에 이용약관에 대한 동의를 토대로 파트너십 요청 및 수락하는 과정을 통한 계약체결 과정 및 이행에 필요한 서비스를 회원에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 거래를 할 수 있도록 설정한 가상의 영업장(https://www.rayteams.com) 등(회사가 운영하는 웹사이트 및 모바일 웹, 앱 등을 모두 포함)을 뜻합니다. </li>
                    <li>2.	“서비스”란 치과와 기공소 간의 커뮤니케이션을 효율적으로 도와줄 수 있도록 최적화된 workflow를 제공하는 것을 말합니다. </li>
                    <li>3.	“컨텐츠”란 게시물을 포함하여, 회원 간 소통 시 생성되는 각종 데이터 등을 말합니다. 컨텐츠는 서비스 이용 과정에서 발생하거나 접하게 되는 스캔 이미지, X-ray 영상, 분석 및 계획 데이터, 문서 텍스트, 그래픽, 사진, 메시지 및 기타 유사 형태의 모든 정보를 의미합니다.</li>
                    <li>4.	“회원”이란 플랫폼에 회원가입을 통해 회원 등록을 한 자로서, 이 약관에 따라 회사와 서비스 이용계약을 체결하고 계속적으로 플랫폼에서 제공하는 서비스를 이용할 수 있는 자를 말합니다.</li>
                    <li>5.	“아이디(ID)”라 함은 회원의 식별과 서비스 이용을 위하여 회원이 설정하고 회사가 승인하여 등록된 전자우편주소를 뜻합니다.</li>
                    <li>6.	“비밀번호(password)”라 함은 회원의 동일성 확인과 회원의 권익 및 비밀보호를 위하여 회원 스스로가 설정하여 플랫폼에 등록한 문자와 숫자 등의 조합을 뜻합니다. </li>
                </ul>
                제2조에서 정의되지 않은 용어의 의미는 관련법령 및 일반적인 거래관행을 따릅니다.
                <h3 className="pt-5">제3조 (약관 동의 명시와 설명 및 개정)</h3>
                <ul className="list-unstyled">
                    <li>1.	회사는 이 약관의 내용과 상호 및 대표자 성명, 영업장 소재지 주소(회원의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 전자우편번호, 사업자등록번호, 통신판매업 신고번호, 개인정보관리책임자 등을 회원이 쉽게 알 수 있도록 플랫폼의 메인 화면 하단에 게시합니다. 다만, 약관의 내용은 회원이 연결화면을 통해 볼 수 있도록 할 수 있습니다. </li>
                    <li>2.	회사는 합리적인 사유가 발생하면 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 전자문서 및 전자거래기본법, 전자금융거래법, 전자서명법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 소비자기본법 등 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다. 이 약관에 동의하는 것은 회사가 운영하는 인터넷 사이트를 정기적으로 방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다.</li>
                    <li>3.	회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 개정약관의 시행일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자의 권리, 의무에 중대한 영향을 주는 변경의 경우에는 적용일자 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 플랫폼은 개정 전 내용과 개정 후 내용을 명확하게 비교하여 회원이 알기 쉽도록 표시합니다. </li>
                    <li>4.	전항에 의해 변경된 약관은 관련 법령에 특별한 규정 및 기타 부득이한 사유가 없는 한 그 적용일자 이전으로 소급하여 적용하지 아니합니다. </li>
                    <li>5.	본 조 제3항에 따라 공지된 적용일자 이후에 회원이 명시적으로 거부의사를 표명하지 않을 경우에는 개정된 약관에 동의하는 것으로 간주하며, 변경된 약관에 동의하지 않는 회원은 회원 탈퇴를 요청할 수 있습니다. 제3항의 조치에도 불구하고 약관의 개정 사실을 알지 못함으로써 발생하는 회원의 피해에 대하여 회사는 책임을 지지 않습니다. </li>
                    <li>6.	회사는 제공하는 서비스 내의 개별 서비스에 대한 별도의 약관 또는 이용조건을 둘 수 있으며, 개별 서비스에서 별도로 적용되는 약관에 대한 동의는 회원이 개별 서비스를 이용할 경우 별도의 동의절차를 거치게 됩니다. 이 경우 개별서비스에 대한 약관 등이 이 약관에 우선합니다.</li>
                    <li>7.	이 약관의 규정이 대한민국의 강행법규와 상충하는 경우에는 그 강행법규에 따르고, 이 경우 다른 조항의 효력에는 영향을 미치지 아니합니다. </li>

                </ul>
                <h3 className="pt-5">제4조 (서비스 대상 및 이용 가능성)</h3>
                <ul className="list-unstyled">
                    <li>1.	서비스는 치과의사 및 치기공사에 한하여 이용이 허용됩니다. 단, 회원이 치과의사 및 치기공사의 지시 및 감독하에 업무를 수행하는 경우, 치과의사 및 치기공사의 요청에 따라 회원에게 서비스 아이디가 제공되며, 해당 치과의사 및 치기공사의 책임하에 회원의 서비스 이용이 허용됩니다. 서비스는 18세 미만 또는 관련 국가 법령에 따른 연령 미만의 미성년자의 개인정보를 고의로 수집 및 사용하지 않습니다. </li>
                    <li>2.	서비스의 적법한 이용을 위해 회원은 회원이 거주하는 국가 또는 서비스를 이용하는 국가를 포함하여 한국 또는 그 밖의 해당 관할국의 법률에 의하여 서비스를 이용하는 행위가 금지되는 자가 아님을 보증합니다. </li>
                    <li>3.	서비스의 원활한 이용을 위해 안정적인 인터넷 접속, 호환 가능한 기기 및 소프트웨어와 해당 소프트웨어의 주기적인 업데이트가 필요 할 수 있습니다. </li>
                    <li>4.	나라 및 지역에 따라 서비스 및 그 일부의 이용이 가능하지 않을 수 있습니다. 회원은 본 약관이 허용하는 범위내에서 회원의 결정에 따라 서비스를 이용해야 하며 적용되는 법률을 준수할 것을 보증합니다. </li>
                </ul>
                <h3 className="pt-5">제5조 (서비스의 제공 및 변경)  </h3>
                <ul className="list-unstyled">
                    <li>1.	회사는 치과와 기공소 간의 효율적인 커뮤니케이션이 가능하도록 최적화된 workflow를 무상으로 제공합니다. </li>
                    <li>2.	회사는 서비스의 구성 및 내용의 변경 등이 있는 경우 변경된 서비스의 적용일자 7일 이전부터 적용일 전일까지 플랫폼 화면에 서비스 변경사유 및 내용을 공지합니다. </li>
                    <li>3.	회사는 회원에게 서비스의 중대한 변경에 대해 30일이전부터 적용일자 전일까지 플랫폼 화면에 변경 사유 및 내용을 공지한다는 조건하에, 필요 시, 서비스를 수정할 수 있으며, 회원의 서비스 사용에 대해 새로운 또는 부가적인 조건을 설정할 권한을 가집니다. 다만, 법, 규정 또는 정부의 활동으로 인해 발생된 상황들로 인한 경우, 사용자 프라이버시 또는 기술 문제를 다루기 위한 경우, 다른 사용자에게 방해가 되는 서비스를 피하기 위한 경우, 또는 자연재해, 참사, 전쟁 등의 불가항력적인 상황 이외의 다른 유사한 상황들로 인해 서비스를 변경하는 것은 부당하지 않다는 점에 동의합니다. </li>
                </ul>
                <h3 className="pt-5">제6조 (서비스의 중단)</h3>
                <ul className="list-unstyled">
                    <li>1.	회사는 천재지변 또는 이에 준하는 불가항력, 컴퓨터, 서버 등 정보통신설비의 보수점검, 교체 또는 고장, 통신두절 등 운영상 상당한 이유가 있는 경우 부득이하게 서비스의 전부 또는 일부를 중단할 수 있습니다. </li>
                    <li>2.	회사는 서비스 운영 또는 개선을 위해 상당한 필요성이 있는 경우 서비스의 전부 또는 일부를 수정, 변경 또는 종료할 수 있습니다. 무료로 제공되는 서비스의 전부 또는 일부를 수정, 변경 또는 종료하게 된 경우 관련 법령에 특별한 규정이 없는 한 별도의 보상을 하지 않습니다. </li>
                    <li>3.	회사는 유료서비스에 한하여, 제1항, 2항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 회원이 입은 손해에 대하여 배상합니다. 단, 회사의 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다. </li>
                    <li>4.	사업종목의 전환, 사업의 종료 또는 포기, 회사의 폐업, 합병, 분할, 영업양도 등의 이유로 서비스를 더 이상 제공할 수 없는 경우에는 회사는 30일 이상의 사전 유예기간을 두고 공지합니다. </li>
                </ul>
                <h3 className="pt-5">제7조 (회원가입)</h3>
                <ul className="list-unstyled">
                    <li>1.	서비스는 치과의사 및 치기공사에 한하여 이용이 허용됩니다. </li>
                    <li>2.	회원은 서비스 이용을 위해 계정을 만들어야 합니다. 회원 가입을 희망하는 자는 회사가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로써 회원가입을 신청합니다. </li>
                    <li>3.	회사는 전항과 같이 회원으로 가입할 것을 신청한 자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다. 만약, 회사가 회원가입신청의 승인을 거부하거나 유보하는 경우, 회사는 이를 제9조에 따라 회원가입 신청자에게 통지합니다.<br /><br />
                        <ul>
                            <li>가.	가입신청자가 이전에 회원자격을 상실한 적이 있는 경우</li>
                            <li>나.	회원의 요청에 의하여 탈퇴한 때로부터 1개월이 지나지 아니한 경우</li>
                            <li>다.	가입신청자가 기입한 회원정보에 허위 기재, 누락 또는 오기가 있는 경우</li>
                            <li>라.	회사가 본인확인 절차를 실시할 경우에 본인 확인이 되지 않거나 본인이 아님이 확인된 경우</li>
                            <li>마.	가입신청자가 18세 미만 또는 관련 국가 법령에 따른 연령 미만의 미성년자인 경우</li>
                            <li>바.	기타 가입신청자가 회원으로 등록하는 것이 플랫폼의 기술상 불가능하거나, 플랫폼의 운영 또는 유지, 관리에 현저한 지장을 준다고 판단되는 경우 </li><br />
                        </ul>
                    </li>
                    <li>4.	회원가입계약의 성립시기는 회사의 승낙으로 가입 신청이 완료된 시점으로 합니다. </li>
                    <li>5.	회원은 회원정보에 변경이 있는 경우, 회원정보 수정 등의 방법으로 해당 변경사항을 지체없이 반영하여야 합니다. 회원정보 수정을 하지 않음으로써 발생하는 회원의 손해에 대하여 회사는 아무런 책임을 부담하지 않습니다. </li>
                    <li>6.	회원의 아이디(ID)와 비밀번호(password)등의 계정정보에 대한 관리 책임은 회원 본인에게 있으며, 회원은 본인의 계정정보를 제3자에게 제공, 공개하거나 이용하도록 해서는 아니됩니다. 회원이 자신의 아이디(ID) 및 비밀번호(password)의 유출 또는 제3자 사용에 대해 인지하고도 비밀번호를 변경하지 않는 경우 또는 이와 같은 사정을 회사에 통지하지 않거나 회사의 조치에 응하지 않은 경우 등 회원이 본 조의 의무를 준수하지 않아 발생하는 모든 손실이나 손해 등 불이익에 대한 책임은 회원에게 있습니다. </li>
                    <li>7.	회원은 어떠한 목적으로도 서비스 또는 그 일부의 복제, 복사, 재생산, 판매, 재판매, 대여 또는 거래를 하지 않을 것에 동의합니다. </li>
                    <li>8.	회원은 회원의 계정에 대한 어떠한 보안 침입에 대하여 회사에 지체없이 통지하는데 동의하며, 회원의 계정 및/또는 비밀번호를 다른 사람과 공유해서는 아니됩니다. 회사는 합리적인 기술을 사용하면서 적절한 주의를 기울이겠지만, 회원의 이러한 규정을 준수하지 않는 데에 따른 계정의 무단 사용에서 기인하는 어떠한 손해에 대하여 책임을 부담하지 않습니다. </li>
                </ul>
                <h3 className="pt-5">제8조 (회원 탈퇴 및 자격 상실 등)</h3>
                <ul className="list-unstyled">
                    <li>1.	회원은 회사에 언제든지 탈퇴를 요청할 수 있으며, 회사는 회원의 탈퇴요청 시, 지체없이 회원탈퇴를 처리합니다. 회원탈퇴 시 회원자격이 상실되며, 회사가 제공하는 각종 혜택 등이 소멸됩니다. </li>
                    <li>2.	회원 탈퇴 시, 회원이 저장한 데이터는 즉시 파기되며, 회원은 탈퇴하기 전에 플랫폼에 저장한 데이터 백업을 직접 수행해야 합니다.</li>
                    <li>3.	회원은 본 약관에 따라 허용되는 목적 내에서만 서비스를 사용함에 동의하며, 관련 법령, 규제 또는 해당 관할국가/지역에서 일반적으로 인정되는 관행의 범위 내에서 서비스를 사용함에 동의합니다. </li>
                    <li>4.	회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원의 자격을 제한 또는 상실 시킬 수 있으며, 회원자격을 상실할 경우 회원으로서의 모든 혜택은 전부 소멸됩니다. <br /><br />
                        <ul>
                            <li>가.	본 약관 또는 본 약관에서 언급 및/또는 서비스에 게시된 다른 정책 또는 가이드라인의 위반</li>
                            <li>나.	사법집행기관, 사법기관 또는 기타 정부기관의 요청이나 명령</li>
                            <li>다.	회원에 대한 서비스의 제공이 불법 또는 불법이 될 수 있는 경우</li>
                            <li>라.	적법한 치과의사 또는 치기공사가 아닌 경우 등 회원정보에 허위 내용을 입력하거나 타인의 정보를 도용한 경우 </li>
                            <li>마.	불법적인 행위에 대한 회원의 참여 </li>
                            <li>바.	다른 사람의 플랫폼 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우 </li>
                            <li>사.	예상치 못한 기술 또는 보안문제 발생 </li>
                            <li>아.	플랫폼을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우 </li>
                            <li>자.	계정이 1년간 휴면상태에 있는 경우</li><br />
                        </ul>
                    </li>
                    <li>5.	회사가 회원 자격을 제한시킨 후 동일한 행위가 2회 이상 반복되거나 10일 이내에 그 사유가 시정되지 아니하는 경우 회사는 회원자격을 상실 시킬 수 있습니다. </li>
                    <li>6.	회사가 회원 자격을 상실시키는 경우에는 회원등록을 말소할 수 있으며, 이 경우 회사는 이를 회원에게 제9조에 따라 통지하고, 회원등록 말소 전 최소한 10일 이상의 기간을 정하여 소명할 기회를 부여합니다. </li>
                    <li>7.	상기와 같은 모든 종료의 효과로서, 회원계정의 종료에 따라 해당 계정 및 컨텐츠 이외에도 회원은 서비스 및 그 일부에 대한 모든 접속 권한을 잃게 될 수 있습니다. 또한, 회사는 일정 기간이 지난 후에 해당 계정의 일부로서 또는 그 안에 저장되어 있는 정보 및 자료를 삭제할 것입니다. </li>
                </ul>
                <h3 className="pt-5">제9조 (회원에 대한 통지)</h3>
                <ul className="list-unstyled">
                    <li>1.	회사가 회원에 대한 통지를 하는 경우, 회원이 회사와 미리 약정하여 지정한 방법에 따라 전자우편, SMS, 전화, 팩스 등으로 할 수 있습니다.</li>
                    <li>2.	회사는 불특정다수 회원에 대한 통지의 경우 7일 이상 플랫폼의 공지사항(고객센터)에 게시함으로써 개별 통지를 갈음할 수 있습니다. 단, 회원의 권리, 의무에 중대한 영향을 주는 변경의 경우에는 적용일자 30일 이상의 사전 유예기간을 두고 공지합니다. </li>
                </ul>
                <h3 className="pt-5">제10조 (회사의 의무)</h3>
                <ul className="list-unstyled">
                    <li>1.	회사는 이 약관이 금지하거나 관련 법령 기타 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고 안정적으로 서비스를 제공하는데 최선을 다합니다. </li>
                    <li>2.	회사는 회원이 안전하게 서비스를 이용할 수 있도록 회원의 개인정보(민감정보 포함)보호를 위한 보안시스템을 갖추어야 하며, 개인정보처리방침을 공개하고 준수합니다. 회사는 회원의 개인정보를 본인의 동의 없이 제3자에게 누설, 배포하지 않습니다. 다만, 관계법령에 의해 국가기관의 요구나 전기통신관련법령 및 정보통신윤리위원회의 요청 등 법률의 규정에 따른 적법한 절차에 의한 경우는 그러하지 아니합니다. </li>
                    <li>3.	회사는 안정적인 서비스의 제공을 위하여 설비에 장애가 생기거나 네트워크 장애 등의 문제가 발생한 때에는 부득이한 사유가 없는 한 지체없이 이를 수리 또는 복구합니다. </li>
                    <li>4.	회사는 회원이 동의하지 않는 영리목적의 광고성 전자우편 등을 발송하지 않습니다. </li>
                    <li>5.	회사는 회원으로부터 제기되는 의견이나 불만이 객관적으로 정당하다고 인정될 경우에는 신속히 처리하여야 합니다. 다만, 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 통보하여야 합니다. </li>
                    <li>6.	회사는 본 약관의 집행 및 준수를 위해 합리적으로 필요한 조치를 취할 권리가 있습니다. 회원은 아래의 경우에 한해 회원에 대하여 법률적인 책임을 지지 않고 회사가 합리적으로 필요하다는 판단과 적절한 조치하에 회원의 계정 정보 및 업무 컨텐츠에 대한 접속하거나 또는 사법기관 등 규제당국의 정당하고 적법한 요구가 있을 시, 관련 법률상 허용되는 범위내에서 해당기관에 공개하는 데 명시적으로 동의합니다.<br /><br />
                        <ul>
                            <li>(1) 법령 및 규정의 준수 목적에 필요한 경우, (2) 본 약관의 집행에 필요한 경우, (3) 기술적 문제의 발견 및 해결에 필요한 경우, 또는 (4) 회사, 회원 및/또는 제3자의 권리 및 재산을 지키기 위해 법적으로 필요가 있는 경우</li><br />
                        </ul>
                    </li>

                </ul>
                <h3 className="pt-5">제11조 (회원의 의무)</h3>
                <ul className="list-unstyled">
                    <li>1.	회원은 이 약관의 규정, 회사의 이용안내 사항 및 관련 법령 등을 준수하여야 하며, 기타 회사 업무에 방해되는 행위를 하여서는 안 됩니다. </li>
                    <li>2.	회원은 회사에서 제공하는 서비스를 본래의 이용 목적 이외의 용도로 사용하거나 다음 각 호에 해당하는 행위를 해서는 안됩니다. <br /><br />
                        <ul>
                            <li>가.	적법한 치과의사 또는 치기공사가 아니면서 그러한 것처럼 진술하는 행위</li>
                            <li>나.	가입신청 또는 정보변경을 목적으로 회사에 개인정보 등록 시 실명이 아닌 정보 또는 다른 사람의 정보를 사용하거나 허위 사실을 기재하는 행위 </li>
                            <li>다.	회사의 영업 활동을 방해하거나 플랫폼을 이용하여 부당한 이득을 취하는 등 통상적인 전자상거래 관행에 부합하지 않는 거래상의 부정 행위 </li>
                            <li>라.	회사가 제공하는 서비스 이용방법에 의하지 아니하고 비정상적인 방법으로 서비스를 이용하거나 회사의 정보처리시스템에 접근하는 행위 </li>
                            <li>마.	불법행위, 타인의 개인정보 침해, 의료정보관련 법령 위반 컨텐츠를 업로드, 다운로드, 게시, 이메일 발송, 전송, 저장 또는 그 외의 방식으로 제공하는 행위 </li>
                            <li>바.	회원 본인이 아닌 다른 사람 또는 단체로 가장하는 것. 회원은 자신을 다른 사람, 단체, 다른 회원, 직원 등으로 가장 또는 사칭하거나 그 밖에 개인 또는 단체의 관계를 허위표시 할 수 없습니다. </li>
                            <li>사.	환자의 동의 없이 또는 의료정보를 불법으로 수집, 제공하는 것 </li>
                            <li>아.	저작권 또는 기타 지적재산권 침해에 관여하는 것(업로드할 권리가 없는, 컨텐츠를 업로드하는 것 포함) 또는 영업비밀을 공개하는 것, 비밀유지 약정, 비공개 약정 또는 근로계약을 위반하여 영업비밀 또는 비밀정보를 공개하는 것 </li>
                            <li>자.	서비스(또는 그 일부) 또는 다른 컴퓨터 소프트웨어나 하드웨어의 정상적인 운영을 방해 또는 제한하거나 해를 끼치도록 설계된 바이러스나 컴퓨터 코드, 파일 또는 프로그램을 포함하는 자료를 업로드, 게시, 이메일 발송, 전송, 저장 또는 기타의 방법으로 제공하는 것 </li>
                            <li>차.	어떤 불법적인 행위를 하거나 계획하는 것 </li>
                            <li>카.	다른 서비스 이용자들의 개인정보, 의료정보를 수집, 저장하는 것. 또는 이를 상기 금지된 행위 중 어느 하나와 관련하여 이용하는 것 </li>
                            <li>타.	회사가 제공하는 서비스와 관련 없거나 비합리적인 요청, 공서양속에 반하는 행위 등으로 회사의 업무를 방해하는 행위</li>
                            <li>파.	기타 위 각 호에 준하여 회사 또는 제3자의 권리를 침해 또는 침해할 우려가 있거나, 전자상거래 질서를 위협하는 행위 </li><br />
                        </ul>
                    </li>
                    <li>3.	회사는 전항을 위반한 회원에 계약 해제, 회원 자격의 제한 등의 조치 및 민, 형사상의 법적조치를 취할 수 있습니다. </li>
                    <li>4.	회원은 서비스를 이용하여 업로드, 다운로드, 이메일 발송, 전송, 저장 또는 그 외의 방식으로 제공한 모든 컨텐츠에 대한 책임을 부담합니다. 회사는 서비스를 통해 게시된 컨텐츠를 통제하지 않을 뿐만 아니라 컨텐츠의 품질, 무결성, 정확성을 보증하지 않으며, 회원은 서비스 및 컨텐츠의 이용에 따른 위험을 전적으로 감수한다는 점에 이해하고 동의합니다. </li>
                    <li>5.	회원에 의해 제출 또는 게시된 모든 컨텐츠는 회원이 전적으로 책임을 지며, 제3자의 지적재산권을 포함하는 다른 당사자의 권리를 침해 또는 훼손하거나 어떠한 법률도 위반하지 않고 권리의 침해 또는 기타 불법적인 행위에 해당하지 않는다는 것을 보증합니다. </li>
                    <li>6.	회원은 본인이 서비스를 이용하면서 생성, 수신, 전송 등의 방식으로 발생된 모든 데이터 및 업무 컨텐츠를 관련 법령에 따라 적법하게 회원의 책임하에 회원의 컴퓨터 또는 다른 기기에 백업할 책임이 있음을 명확히 이해하고 동의합니다. 회사는 서비스를 제공함에 있어 적절한 주의와 합리적인 수준의 기술을 사용하겠지만, 회원이 서비스를 통해 저장 또는 접속한 어떤 컨텐츠도 의도하지 않은 손상 또는 손실되지 않을 것이라는 점을 보증하지 않습니다. </li>
                    <li>7.	회원은 미국 의료정보보호법(HIPAA)에 정의된 Covered Entities 및/또는 Business associate에 해당하며, 회원은 회사의 서비스 및 기타 구성을 이용하여 US Federal Code 45 C.F.R § 160.103에 명시된 의료정보(PHI)를 불법으로 생성, 유지, 수신 및 전송하지 않을 것을 보증합니다. </li>
                </ul>
                <h3 className="pt-5">제12조 (개인정보보호) </h3>
                회사는 관련법령이 정하는 바에 따라 회원의 계정정보를 포함한 회원의 개인정보를 보호하기 위한 정책을 수립하고 개인정보보호 책임자를 지정하여 이를 게시합니다. 회원의 개인정보보호는 관련 법령 및 회사가 정하는 개인정보처리방침에 정한 바를 따릅니다.<br /><br />
                <ul className="list-unstyled">
                    <li>1.	회사는 회원의 개인정보 수집 시 최소 수집의 원칙에 따라 서비스 제공을 위하여 필요한 최소한의 개인정보를 수집합니다. </li>
                    <li>2.	회사는 회원의 개인정보를 수집, 이용하는 때에는 당해 회원에게 그 목적을 고지하고 동의를 받습니다.</li>
                    <li>3.	회사는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용 목적이 발생한 경우 또는 제3자에게 제공하는 경우에도 사전에 해당 회원에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다. </li>
                    <li>4.	회사가 본 조 제2항과 제3항에 의해 회원의 동의를 받는 경우에는 개인정보 관리책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은 자, 제공목적 및 제공할 정보의 내용) 등 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 개인정보호법에서 규정한 사항을 미리 명시하거나 고지해야 하며 회원은 언제든지 이 동의를 철회할 수 있습니다. </li>
                    <li>5.	회원은 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 회사는 이에 대에 지체없이 필요한 조치를 취할 의무를 집니다. 회원이 오류의 정정을 요구한 경우에는 회사는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다. </li>
                    <li>6.	회사는 개인정보 보호를 위하여 회원의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며, 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 회원의 손해에 대하여 모든 책임을 집니다. </li>
                    <li>7.	회사 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.</li>
                    <li>8.	회사는 개인정보의 수집, 이용, 제공에 관한 동의란을 미리 선택한 것으로 미리 설정해두지 않습니다. 또한 개인정보의 수집, 이용, 제공에 관한 동의 거절 시 제한되는 서비스를 구체적으로 명시하고, 필수 수집항목이 아닌 개인정보의 수집, 이용, 제공에 관한 회원의 동의 거절을 이유로 회원가입을 거절하지 않습니다. </li>
                    <li>9.	플랫폼 이외의 링크된 사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다. 링크된 사이트 및 상품들을 제공하는 제3자의 개인정보 취급과 관련하여서는 해당 사이버몰 및 제3자의 개인정보처리방침을 확인할 책임은 회원에게 있으며, 회사는 이에 대하여 책임을 부담하지 않습니다. </li>
                    <li>10.	회사는 다음과 같은 경우에 법이 허용하는 범위 내에서 회원의 개인정보를 제3자에게 제공할 수 있습니다.<br /><br />
                        <ul>
                            <li>가.	수사기관이나 기타 정부기관으로부터 적법한 절차에 따른 정보제공을 요청받은 경우</li>
                            <li>나.	회원이 관련 법령을 위반하는 등 부정행위를 확인하기 위해 필요한 경우</li>
                            <li>다.	기타 관련 법령에 의해 요구되는 경우 </li><br />
                        </ul>
                    </li>
                    <li>11.	회사는 환자정보/민감정보와 관련된 모든 데이터를 안전하게 암호화하여 저장 및 전송하며, 개인정보보호법, 의료법과 HIPAA규정 및 GDPR 규정등 Data Privacy Laws and Regulations을 준수합니다.</li>
                </ul>
                <h3 className="pt-5">제13조 (저작권 및 상표권 등의 지적재산권 이용제한)</h3>
                <ul className="list-unstyled">
                    <li>1.	회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다.</li>
                    <li>2.	회사와 회원 간의 서비스에 관한 별도 계약에 따라 부여되는 경우를 제외하면, 회사는 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라 계정, 아이디, 컨텐츠 등을 이용할 수 있는 이용권한만을 부여하며, 회원은 서비스를 이용함으로써 얻은 정보 중 회사의 지적재산권에 해당하는 정보를 회사의 사전 승낙 없이 복제, 송신, 전송, 출판, 배포, 발송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.
                        회원이 회사가 제공하는 서비스를 이용하면서 작성한 각종 게시물의 저작권은 회원 본인에게 있으며, 해당 게시물의 저작권 침해를 비롯한 민, 형사상 모든 책임은 회원 본인이 집니다.
                    </li>
                    <li>3.	본 약관에서 허용하는 서비스 이용을 제외하고 소프트웨어 또는 서비스 전부 또는 일부를 이용하거나, 다른 사람의 저작물을 별도의 허락 없이 사용하는 것은 엄격하게 금지됩니다. 이는 다른 사람의 지적재산권을 침해하는 것이며, 회원은 저작권 침해로 인하여 손해배상 등 민형사상의 책임을 부담할 수 있습니다.</li>
                    <li>4.	RAYTeams, 레이 로고 및 기타 본 사이트에 포함된 제품 및/또는 서비스 명칭은 회사의 상표 또는 등록 상표입니다. 회사는 이에 대한 권리를 가지고 있으며, 다른 제품 및 회사의 명칭은 그 해당 사용자의 상표일 수 있습니다.</li>
                    <li>5.	회사는 지적재산권을 포함한 타인의 권리를 존중하며 사용자들도 마찬가지로 행동해주시기를 기대합니다. 회사는 필요한 경우 그 재량에 따라 타인의 권리를 침해하거나 위반하는 사용자에 대하여 사전 통지 없이 서비스 이용 제한조치를 취할 수 있습니다.  </li>
                </ul>
                <h3 className="pt-5">제14조 (책임의 제한)</h3>
                <ul className="list-unstyled">
                    <li>1.	회사는 플랫폼 서비스 제공자로서, 이미 생산된 정보를 “있는 그대로(As-Is)” 중개 또는 매개합니다. 회원이 작성한 컨텐츠 등이나 서비스를 통해 연결되는 제3자 도메인 사이트는 해당 정보의 제공자가 독립적으로 작성 또는 제작한 정보이기 때문에, 서비스에서 중개 또는 매개된 정보라도 그 정보의 정확성, 신뢰성 또는 적법성은 원칙적으로 해당 정보를 작성 및 제작한 당사자가 보장하고 그에 따른 책임을 부담합니다. </li>
                    <li>2.	회사는 회사 서비스를 매개로 전달되었더라도 회사가 작성 또는 제작하지 않는 정보에 대하여, 관련 법령에 별도의 규정이 있거나 회사가 해당정보를 “있는 그대로(As-Is)” 중개 또는 매개하지 않는 특별한 사정이 없는 한, 그 정확성, 신뢰성 또는 적법성에 관한 어떠한 보증도 하지 않습니다. 그리고 회원이 이를 신뢰함으로써 입게 된 손해에 대해 관련 법령에서 별도로 규정하였거나 회사의 고의 또는 과실이 있는 경우에 한하여 배상책임을 부담합니다. </li>
                    <li>3.	회사는 회원 간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다. </li>
                    <li>4.	회사는 무료로 제공되는 서비스 이용과 관련하여 관련법령에 특별한 규정이 없는 한 책임을 지지 않습니다. </li>
                    <li>5.	회사는 유료 서비스 이용이 불가하거나 이용에 장애가 있는 경우, 회사는 유료서비스 회원에게 그 손해를 배상합니다. 다만, 회사의 고의 또는 과실 없이 회원에게 발생한 손해에 대해서는 책임을 지지 않습니다. 회원이 이용불가 또는 이용 장애의 사실을 회사에 접수한 이후 72시간 이내에 정상화된 경우, 회사는 이에 대한 책임을 지지 않습니다. </li>
                    <li>6.	회원이 서비스를 이용함에 있어 행한 불법행위 또는 본 약관을 위반한 행위로 회사가 당해 회원 외의 제3자로부터 손해배상청구 또는 소송 등 각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 회사를 면책 시켜야 하며, 회사가 면책되지 못한 경우 당해 회원은 그로 인하여 회사에 발생한 손해를 배상할 책임이 있다.  </li>
                    <li>7.	일부 관할국가의 법률이 특정한 보증의 배제를 허용하지 않거나 명시적으로 금지하는 경우, 아래의 책임제한 규정은 적용되지 않을 수 있습니다. 회사는 서비스를 제공 및 유지하는데 있어 합리적인 수준의 기술을 사용하고 적절한 주의를 기울입니다. <br /><br />
                        <ul>
                            <li>가.	회사는 서비스가 중단되지 않고, 오류가 발생하지 않을 것이라고 보증하지 아니하며, 회원은 회사가 본 약관에서 정한 통지의무를 이행한다는 조건하에, 합리적으로 필요한 경우 일정기간 동안 서비스를 변경 및 중단하거나 본 약관의 조건에 따라 서비스를 취소할 수 있다는 사실에 동의합니다. </li>
                            <li>나.	회원은 “있는 그대로(As-Is)”, 그리고 “이용 가능한 그대로(As-Available)”의 상태로 서비스가 제공되는 것에 대해 분명히 이해하고 동의합니다.</li>
                            <li>다.	회사는 서비스가 바이러스로 인한 공격, 해킹 등의 보안적인 위협으로부터 안전하다는 점에 대하여 진술하거나 보증하지 아니하며, 이와 관련된 어떤 책임도 부담하지 않습니다. 따라서, 회원은 서비스를 통해 업로드, 다운로드 방식으로 얻은 자료들의 손실에 대한 책임은 회원에게 있음에 동의합니다. </li>
                            <li>라.	회사는 국가별 의료정보 관련법령에 준하는 보안적 조치를 취할 의무가 없으며, 회원이 서비스를 통하여 의료정보를 업로드, 다운로드, 게시, 이메일 발송, 전송, 저장 또는 그 외의 방식으로 이용, 제공하는 경우, 이와 관련된 어떤 책임도 지지 않습니다. </li>
                            <li>마.	GDPR<br />
                                EEA내의 회원인 경우, 회원은 적법한 업무 수행을 위해 Data Privacy 관련 규정, 적절한 수준의 보안, 기술적, 물리적, 관리적 보호조치 등의 데이터 보호를 위한 방법에 대하여 검토해야 합니다. 회사는 관련 법령에서 명시적으로 정한 경우를 제외하고, 회원의 GDPR 규정 위반으로 인한 어떠한 책임도 부담하지 아니하며 회원은 서비스를 이용하는 동안 개인정보주체의 정보를 보호하기 위해 GDPR에서 요구하는 책임과 의무를 부담함에 동의합니다. <br />
                                회사는 개인정보보호책임자를 지정하여 내부정보보호활동 관리 및 개인정보보호 관련법규 준수 상황을 확인하고 있습니다. 또한, GDPR 준수를 위해 유럽 내 이용자의 정보는 유럽 내에 암호화하여 보관하고 있으며, 데이터 보관을 위한 기술적 조치를 취하고 있습니다. <br />
                                회사는 환자정보/민감정보와 관련된 모든 데이터는 안전하게 암호화되어 저장 및 전송되며, 의료법과 HIPAA규정 및 GDPR 규정을 준수합니다.<br /><br />

                                담당자: RAYTeams 개인정보 보호책임자<br />
                                연락처: <a href="mailto:privacy@rayteams.com">privacy@rayteams.com</a><br /><br />

                                회원은 환자정보의 수집 절차, DPO 임명 등 GDPR에서 부과하고 있는 개별 조치를 취할 의무가 있으며, 이러한 GDPR 의무위반에 대한 전적인 책임은 회원에게 있음에 동의합니다.
                            </li><br />
                        </ul>
                    </li>
                </ul>
                <h3 className="pt-5">제15조 (분쟁해결)</h3>
                <ul className="list-unstyled">
                    <li>1.	회사는 회원이 제출하는 불만사항 및 의견을 우선적으로 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 즉시 통보해 드립니다. </li>
                    <li>2.	회원은 본 약관의 해석 및 계약관련 회사와 회원 간의 분쟁에 대하여, 국제사법 규정을 배제하고, 대한민국 법률이 적용된다는 점과 수원지방법원이 대인관할권 및 전속관할권을 갖는 것에 대하여 명확히 이해하고 동의합니다. <br />
                        만약, 회원이 한국에 거주하지 않고, 대한민국 영토내에서 서비스에 접속하지 아니했으며, EU국가, 스위스 또는 미국인에 해당한다면, 회원의 법정 거주지의 법원이 관할권을 가지며 법정거주지의 법률이 적용됩니다.
                    </li>
                </ul>
                <h3 className="pt-5">전자계약 체결</h3>
                회원이 서비스를 이용하는 것은 회원이 전자적인 계약을 체결하고 거래할 권한을 행사하는 것을 포함합니다. 회원은 자신이 전자적으로 제출하는 행위가 그 계약에 구속될 의사나 그 거래의 비용을 지불할 의사가 있다고 해석되는 것에 동의합니다. 회원의 계약과 전자적 제출에 구속된다는 의사표시에는 취소의 통지, 약관, 계약 등을 포함하여 회원이 서비스를 이용하면서 시작된 모든 거래와 관련된 기록도 포함됩니다. 회원의 전자기록에 접근하고 유지하려면 회원은 회원의 전적인 책임하에 특정 하드웨어와 소프트웨어를 보유하고 있어야 합니다. <br /><br />
                이 약관은 2022년 8월 15일부터 시행합니다.
            </div>
        </div>
    )
}
const TermsAndConditionsEN = () => {
    return (
        <div className="agree-txt" style={{ paddingBottom: 10 }}>
            <h2 className="mb-4">Terms and Conditions</h2>
            <h3>1. Purpose</h3>
            This “Terms and Conditions” (hereinafter referred to as the “Agreement”) apply to the rights, obligations and responsibilities of the Users and the service platform called “RAYTeams” (hereinafter referred to as the “Platform”) operated by Ray Co., Ltd. (hereinafter referred to as the “Company”) when using the service (hereinafter referred as the “Service”) provided free of charge. This binding agreement between the User and the Company governs the User's use of the Platform and the Service.  <br /><br />
            PLEASE READ AND UNDERSTAND THE FOLLOWING TERMS AND CONDITIONS, AND YOU EXPRESSLY AGREE THAT THESE TERMS AND CONDITIONS APPLY TO YOU IF YOU ACCESS OR USE THE SERVICES BY CLICKING “AGREE” WITH A CLEAR UNDERSTANDING OF THE DETAILED TERMS AND CONDITIONS <br /><br />
            As a platform service provider, the Company allows you to access to your work contents (for example, your name and affiliated institution name, address, occupation, contact information, email, password, scan data, and additional information such as memos and chats delivered to the other party to fulfill the contract between Users when using the Service) to store and use only in accordance with the terms and conditions set forth in this Agreement).
            <h3 className="pt-5">2. Definition </h3>
            Definitions of terms used in this Agreement are as follows.<br /><br />
            <ul>
                <li>1.	“Service Platform” refers to a virtual business place (https://www.rayteams.com) including websites, mobile web, apps that the Company operates to provide the Users with the necessary services for the process of signing and implementation of the partnership based on the consent of the two parties. operated by the Company, mobile web, and apps that is set up by the Company so that transactions can be made using information and communication facilities such as a computer in order to provide Users with services necessary for the contract signing process and implementation through the process of requesting and accepting partnership based on mutual agreement on the terms of service between the two parties to enter into a partnership.  </li>
                <li>2. “Service” refers to providing an optimized workflow to help the communication between dental clinics and laboratories efficiently.</li>
                <li>3.	“Content” refers to various of data generated during communication between Users, including posts. Content means all information in the type of scanned images, X-ray images, analysis and planning data, document texts, graphics, photos, messages and other similar types that are generated or encountered while using the Service.</li>
                <li>4.	“User” or “Users” refer to a person who has registered as a user through membership registration on the Platform, and who can continue to use the Services provided by the Platform after signing the terms of service with the Company in accordance with this Agreement.</li>
                <li>5.	“ID” means the registered e-mail address set by the User and approved by the Company for the User’s identification and service use.</li>
                <li>6.	“Password” means a combination of letters and numbers, etc., set by the User and registered on the Platform in order to confirm the identity of the User and to protect the rights and interests and confidentiality of the member.  </li>
            </ul>
            The meaning of terms not defined in Article 2 follows related laws and general business practices.
            <h3 className="pt-5">3. Explanation and Revisions of Terms and Conditions </h3>
            <ul>
                <li>1.	The Company shall be responsible to post at the bottom of the main page of the Platform so that Users can easily know the contents of this Agreement, name of the Company and representative, business location address (including the address where member complaints can be handled), phone number, e-mail address, business registration number, mail-order business report number, privacy officer. However, the contents of this Agreement may be viewed by the User through the linked page. Accepting these Terms means that you agree to periodically visit the Company's operated Internet site to confirm any changes to the terms and conditions.</li>
                <li>2.	When a reasonable cause occurs, the Company may revise this Agreement to the extent that it does not violate relevant laws such as the Act on Consumer Protection in Electronic Commerce, the Act on the Regulation of Terms of Service, the Framework Act on Electronic Documents and Electronic Transactions, the Electronic Financial Transactions Act, the Electronic Signature Act, and the Act on Promotion of Information and Communications Network Utilization and Information Protection, the Consumer Basic Act. Accepting these Terms and Conditions means that you agree to periodically visit the Company’s operated website to check for changes to this Agreement. </li>
                <li>3.	When the Company revises this Agreement, it shall be notified from 7 days before the effective date of the revised terms and conditions to the day before the effective date by specifying the effective date and the reason for the revision with the current Agreement. However, in the case of a change that has a significant impact on the rights and obligations of Users, notice shall be given with a grace period of at least 30 days prior to the effective date. In this case, the Company clearly explains the changed terms and conditions by displaying it in an easy-to-understand manner for Users.</li>
                <li>4.	The terms and conditions changed in accordance with the preceding paragraph shall not be applied retroactively before the effective date, unless there are special provisions or other unavoidable reasons in the relevant laws and/or regulations.</li>
                <li>5.	If a User does not specifically express his/her intention to refuse after the effective date announced in accordance with Paragraph 3 of this section, he/she is deemed to agree to the revised terms and conditions, and Users who do not agree to the amended terms and conditions may request membership withdrawal. Despite the measures set forth in Paragraph 3, the Company is not responsible for any damage to Users caused by not knowing the fact of the amendment of the terms and conditions.</li>
                <li>6.	The Company may have separate terms and conditions for individual services within the Services it provides, and consent to the terms and conditions applied separately for individual services shall go through a separate consent process when a User uses individual services. In this case, the terms and conditions for individual services take precedence over this Agreement.</li>
                <li>7.	In the event that the provisions of this Agreement conflict with the compulsory laws of the Republic of Korea, the compulsory laws shall govern, and in this case, the effect of other provisions shall not be affected.</li>

            </ul>
            <h3 className="pt-5">4. Availability of Service </h3>
            <ul>
                <li>1.	The Service is only allowed to be used by Dentists and Dental technicians. However, if you perform work under the direction and supervision of a dentist or dental technician, a service ID is provided to you at the request of the dentist or the dental technician, and your use of the Service is permitted under the responsibility of the dentist or the dental technician. The Company does not knowingly collect, store, and use personal information of minors under the age of 18 or under the age in accordance with applicable regulations and laws of the country concerned.</li>
                <li>2.	For the lawful and legitimate use of the Service, Users agree and warrant that he/she is not a person prohibited from using the Service under the laws of Korea or other applicable jurisdictions, including the country where he/she resides or the country where he/she uses the Service.</li>
                <li>3.	Stable Internet access, compatible devices and software, and periodic updates of the software may be required for smooth use of the Service.</li>
                <li>4.	The Service or some of it may not be available depending on the country or region. A User warrants that he/she shall use the Services at your own discretion to the extent permitted by this Agreement and shall comply with the applicable laws.</li>
            </ul>
            <h3 className="pt-5">5. Change of Service</h3>
            <ul>
                <li>1.	The Company provides an effective workflow free of charge to enable efficient communication between Dental clinics and Dental laboratories.</li>
                <li>2.	If there is a change in the composition and content of the Service, the Company shall notify Users of the reason and the change on the Platform screen from 7 days before the effective date to the day before the effective date.</li>
                <li>3.	The Company may modify the Service, if necessary, under the condition that the Company notifies the Users of the reason and details of the change on the Platform screen from 30 days before the date of application to the day before the effective date of any significant change in the Service and the Company reserves the right to set new or additional conditions. However, Users acknowledge and  agree that it is not be unreasonable to change the Services due to circumstances caused by laws, regulations, or government activities, to address Users’ privacy or technical issues; to avoid services that interfere with other Users; and/or to protect against natural disasters, catastrophes, wars or similar circumstances.</li>
            </ul>
            <h3 className="pt-5">6. Suspension of Service</h3>
            <ul>
                <li>1.	The Company may inevitably suspend all or part of the Service if there are significant reasons for operation, such as natural disasters or force majeure, maintenance, replacement or failure of information and communication facilities such as computers and servers, or communication interruption. </li>
                <li>2.	The Company may modify or terminate all or part of the Service if there is a significant need for service operation or improvement. If all or part of the free Service is modified or terminated, no any compensation shall be made unless there are special provisions in the relevant laws or regulations. </li>
                <li>3.	Only for paid Services, the Company compensates for damages suffered by Users due to the temporary suspension of the provision of Services due to the reasons of paragraphs 1 and 2. However, this is not the case if it is proved that there is no intention or gross negligence of the Company.</li>
                <li>4.	In the event that the Service can no longer be provided due to the change of business category, the kind or abandonment of business, Company’s closure, merger, division, transfer of business, the Company shall notify Users with a grace period of at least 30 days in advance. </li>
            </ul>
            <h3 className="pt-5">7. Registration</h3>
            <ul>
                <li>1.	The Service is only allowed to be used by Dentists and Dental technicians legitimate in the relevant jurisdictions</li>
                <li>2.	The Users shall create an account to use the Service. Those who wish to sign up for membership apply by filling in User information according to the registration form set by the Company and expressing their intention to agree to this Agreement. You agree that you will provide truthful and accurate information when registering to use the Service. </li>
                <li>3.	The Company accepts a registration for the person who applied for membership as a member as described in the preceding paragraph, unless he/she falls under any of the following subparagraphs. If the Company rejects or suspends the approval of the membership application, the Company shall notify him/her of the related grounds in accordance with Article 9:<br /><br />
                    <ul>
                        <li>A.	if the Company previously cancelled your account as a result of you being in breach of these terms and conditions;</li>
                        <li>B.	If one month has not passed since the member's request for withdrawal;</li>
                        <li>C.	If there is a false entry, omission or error in the user information entered by the applicant for membership; </li>
                        <li>D.	If the Company cannot verify the identity or it is confirmed that the person is not the person when the Company verifies the identity; </li>
                        <li>E.	If the applicant is under the age of 18 or a minor under the laws of the relevant country; and/or
                        </li><li>F.	If it is determined that it is impossible for the applicant to register as a member due to the technical issues of the Platform, or that it significantly interferes with the operation, maintenance, or management of the Platform.</li><br />
                    </ul>
                </li>
                <li>4.	The membership contract shall be established when the application for membership is completed with the approval of the Company. </li>
                <li>5.	If there is a change in member information, the User shall update the change without delay by modifying User information. The Company is not responsible for any damage to Users arising from not modifying User information. </li>
                <li>6.	 A User is solely responsible for maintaining the confidentiality of the User’s password and account information such as ID, and the User is solely responsible for all activities that occur under your password on or through your account. Under no circumstances shall the User provide or reveal his/her account information to anyone else. In case the User does not change the password timely even though he/she is aware of the leakage of his/her ID and password or the use by a third party, or if the User does not notify the Company of such circumstances or does not respond to the Company's actions, he/she is responsible for any loss or damage caused by non-compliance with the obligations of these terms and conditions.  </li>
                <li>7.	Users agree not to duplicate, copy, reproduce, sell, resell, rent or trade the Service or any part thereof for any purpose.</li>
                <li>8.	A User agrees to notify the Company without delay of any security breach into his/her account, and his/her account and/or password shall not be shared with anyone else. The Company shall use reasonable technology and take due care, but shall not be liable for any damages resulting from the unauthorized use of the account due to the User's non-compliance with these terms and conditions.</li>
            </ul>
            <h3 className="pt-5">8. Membership Withdrawal and Cancellation</h3>
            <ul>
                <li>1.	 A User may request the Company to withdraw from membership at any time, and the Company shall process the User’s withdrawal without delay when the User requests for withdrawal. When a User cancel his/her membership, his/her membership qualifications are lost, and the various benefits provided by the Company are extinguished.</li>
                <li>2.	When the User withdraws from membership, the data stored by the User is immediately destroyed, so the User shall perform a self-backup of the data stored on the Platform before membership withdrawal.</li>
                <li>3.	Users agree to use the Service only for the purposes permitted under this Agreement, and agree to use the Service within the scope of applicable laws, regulations, or generally accepted practice in the applicable jurisdiction.</li>
                <li>4.	If  A User falls under any of the following reasons, the Company may limit or cancel the User’s membership, and if the User loses the membership, all benefits as the User shall be extinguished:<br /><br />
                    <ul>
                        <li>A.	Violation of this Agreement or any other policies or guidelines referred to in this Agreement and/or posted on the Service;</li>
                        <li>B.	Requests or orders from law enforcement agencies, other competent government agencies, or any judicial bodies;</li>
                        <li>C.	If the action or attempt of providing the Service to the User is or may be illegal;</li>
                        <li>D.	If the User enters false information in user information or steals another person's identify, such as if he/she is not a legitimate Dentist or Dental technician; </li>
                        <li>E.	The User participation in illegal activities; </li>
                        <li>F.	If it is threatening the rules of e-commerce, such as interfering with other people's use of the Platform or stealing the information;</li>
                        <li>G.	Unexpected technical or security issues;</li>
                        <li>H.	In the event of using the Platform to conduct an act prohibited by laws or this Agreement or contrary to public morals; and/or
                        </li><li>I.	If the account has been dormant for 1 year.</li><br />
                    </ul>
                </li>
                <li>5.	If the same problematic action is repeated twice or more after the Company restricts the membership, or if the cause is not corrected within 10 days, the Company may cancel the membership.</li>
                <li>6.	In the event that the Company cancels membership, the Company notifies him/her in accordance with Article 9, and gives him/her an opportunity to explain by setting a period of at least 10 days before the membership cancellation. </li>
                <li>7.	As an effect of such withdrawal or cancellation as above, upon termination of the User account, the User may lose all access rights to the Service and parts thereof in addition to the account and contents. In addition, the Company shall delete the information and materials stored in or as part of the account after a certain period of time.</li>
            </ul>
            <h3 className="pt-5">9. Notification </h3>
            <ul>
                <li>1.	When the Company notifies  a User, it can be done by e-mail, text message, phone, fax or so according to the method specified by the User in advance with the Company.</li>
                <li>2.	In the case of a notice to an unspecified number of Users, the Company may substitute individual notice by posting it on the notice board (customer center) of the Platform for more than 7 days. However, in the case of changes that significantly affect the rights and obligations of Users, notice is given with a grace period of at least 30 days prior to the effective date.</li>
            </ul>
            <h3 className="pt-5">10. Obligations of the Company</h3>
            <ul>
                <li>1.	The Company does not engage in acts that are prohibited by this Agreement or that are contrary to applicable laws or public morals, and does its best to provide the Services continuously and stably as stipulated in this Agreement.</li>
                <li>2.	The Company shall take appropriate technical and organizational measures to protect Users' personal information (including special categories of personal data) so that Users can safely use the Service. The Company shall have the Privacy Policy posted to the website and comply with it. The Company does not disclose or distribute Users' personal information to third parties without their consent. However, this is not the case in accordance with legal procedures under the provisions of relevant laws, such as the legitimate request of competent judicial and law enforcement agencies. </li>
                <li>3.	In order to provide stable Service, the Company shall repair or restore it without delay when there is a failure in the facility or network failure, unless there is an unavoidable reason,.</li>
                <li>4.	The Company does not send advertising e-mails for commercial purposes that Users do not agree to.</li>
                <li>5.	The Company shall promptly deal with complaints raised by Users if they are objectively justified. However, if prompt processing is difficult, the User shall be notified of the reason and processing schedule.</li>
                <li>6.	The Company has the right to take measures reasonably necessary to enforce and comply with this Agreement. Only in the following cases, a User expressly consent that the Company shall not be liable to the User and the Company determines that it is reasonably necessary and takes appropriate measures to access his/her account information and business contents, or to disclosure to the appropriate authorities to the extent specifically required or permissible under applicable laws and regulations upon a legitimate request from a regulatory and/or judicial authority:
                    <ul>
                        <li>(1) when necessary for the purpose of compliance with applicable laws and regulations; (2) when reasonably necessary for the enforcement of this Agreement; (3) when necessary for the discovery and solution of technical and/or security problems; and/or (4) legally necessary to protect the rights and property of the Company, its Users and/or third parties.</li><br />
                    </ul>
                </li>

            </ul>
            <h3 className="pt-5">11. Obligations of Users</h3>
            <ul>
                <li>1.	Users shall comply with the provisions of this Agreement, the Company's guidelines for use, and related laws and regulations, and shall not engage in any acts that interfere with the Company's business.</li>
                <li>2.	Users shall not use the Services provided by the Company for purposes other than the permitted purpose of use, or engage in any of the following acts:<br /><br />
                    <ul>
                        <li>A.	Making false statements as if he/she is a Dentist or Dental Technician when he/she is not a legitimate Dentist or Dental Technician;</li>
                        <li>B.	Providing other people's information, or false information when registering personal information with the Company for the purpose of applying for membership or changing information;</li>
                        <li>C.	Misconduct in transactions inconsistent with normal e-commerce practices, such as interfering with the Company's business activities or unfairly taking advantage of the Platform; </li>
                        <li>D.	Using the Service in breach of this Agreement or accessing the Information Processing System of the Company other than the method of using the Service provided by the Company; </li>
                        <li>E.	Uploading, downloading, posting, e-mailing, transmitting, or storing contents that are illegal, infringe on other people's data privacy rights, or violate medical information-related laws;</li>
                        <li>F.	Impersonating a person or group other than the User himself/herself; Users may not impersonate themselves as other persons, groups, other members, other employees, etc., or otherwise misrepresent the relationship of individuals or organizations;</li>
                        <li>G.	Collecting or providing medical information without the consent of the patient or illegally;</li>
                        <li>H.	Engaging in copyright or other intellectual property right infringement (including uploading content without the right to upload) or disclosing trade secrets or confidential information in violation of any confidentiality agreement, nondisclosure agreement or employment contract;</li>
                        <li>I.	Uploading, posting, e-mailing, transmitting, storing or using other methods to provide any material that contains viruses or computer code, files or programs designed to interfere with, limit, or harm the normal operation of the Service (or any part thereof) or other computer software or hardware;</li>
                        <li>J.	Doing or planning to do any illegal activity;</li>
                        <li>K.	Collecting and storing personal information and medical information of other Service Users, or using it in connection with any of the prohibited acts listed in this Section11.2;</li>
                        <li>L.	Interfering with the Company's business by making unrelated or unreasonable requests for Services provided by the Company, or acts against public morals; and/or</li>
                        <li>M.	Other acts that infringe or threaten the rights of the Company or a third party or threaten the rules of e-commerce in accordance with the above items.</li><br />
                    </ul>
                </li>
                <li>3.	The Company may take measures such as membership cancellation, membership restriction , and civil and criminal legal measures against Users who violate this Section 11.2.</li>
                <li>4.	Users are responsible for all contents uploaded, downloaded, emailed, transmitted, stored or otherwise provided using the Service. Users understand and agree that the Company does not control the contents posted through the Service, nor does it guarantee the quality, integrity, or accuracy of the contents, and that Users take the full risk of using the Service and contents.</li>
                <li>5.	Users are solely responsible for all contents submitted or posted by themselves, guarantee that they do not infringe or impair the rights of other parties, including the intellectual property rights of third parties, or violate any law, and they do not commit any illegal acts. </li>
                <li>6.	Users clearly understand and agree that Users are responsible for backing up all data and business contents generated by generation, reception or transmission, etc. while using the Service to the User’s computer or other device under the User’s full responsibility in accordance with the applicable laws and regulations. The Company shall use reasonable care and skill in providing the Service, but does not guarantee that any contents stored or accessed by the Users through the Service  will not suffer from unintended damage or lost. </li>
                <li>7.	Users acknowledge and guarantee that they are Covered Entities and/or Business Associates as defined in the U.S. Health Insurance Portability and Accountability Act (HIPAA), and Users warrant that they do not illegally create, maintain, receive or transmit using the Company's Services to obtain Protected Health Information (PHI) specified in US Federal Code 45 C.F.R § 160.103.</li>
            </ul>
            <h3 className="pt-5">12. Privacy</h3>
            The Company establishes a Privacy Policy to protect User’s privacy, including User’s account information, in accordance with relevant laws and regulations, and designates a person in charge of personal information protection and publishes it. The privacy protection of Users is governed by the relevant laws and regulations and the Privacy Policy set by the Company.<br /><br />
            <ul>
                <li>1.	The Company collects the minimum personal information necessary to provide Services in accordance with the principle of minimum collection when collecting personal information of Users.</li>
                <li>2.	When the Company collects and uses a User’s personal information, the Company notifies the User of the purpose and obtains consent.</li>
                <li>3.	The Company cannot use the collected personal information for any purposes other than the purpose, and even if a new purpose of use occurs or if it is provided to a third party, the Company shall notify the User in advance of the purpose and obtain consent. However, exceptions are made if there are other provisions in the relevant laws and regulations.</li>
                <li>4.	If the Company obtains the User’s consent pursuant to paragraphs 2 and 3 of this agreement, the Company shall specify or notify in advance the identity of the person in charge of personal information management (organization, name, and other contact information), the purpose of collection and use of information, and matters related to providing personal information to a third party (recipient, purpose of provision, and content of information to be provided), etc. stipulated in the Act on Promotion of Information and Communications Network Utilization and Information Protection and the Personal Information Protection Act, and the User can withdraw the consent at any time.</li>
                <li>5.	Users can request to view and correct errors in their personal information held by the Company at any time, and the Company is obliged to take necessary measures without delay. If a User requests correction of an error, the Company does not use the personal information until the error is corrected.</li>
                <li>6.	For the protection of personal information, the Company shall limit the persons handling the personal information of Users to a minimum, and take all responsibility for the damage of the User arising from loss, theft, leakage, providing personal information to a third party without consent, falsification, etc.</li>
                <li>7.	The Company or a third party receiving personal information from it destroys the personal information without delay when the purpose of collecting or receiving personal information has been achieved.</li>
                <li>8.	The Company does not pre-select the consent box for collection, use, and providing of personal information. In addition, when a User refuses to consent to the collection, use, and providing of personal information, the restricted service, if any, shall be specified in detail, and membership shall not be refused because of the User’s refusal to consent to the collection, use, and providing of personal information that is not a mandatory collection item.</li>
                <li>9.	The Company's Privacy Policy does not apply to linked sites other than the Platform. Regarding the handling of personal information by third parties that provide linked sites and products, Users are responsible for checking the privacy policy of the linked sites and the third parties, and the Company is not responsible for this.</li>
                <li>10.	The Company may provide User’s personal information to a third party within the scope permitted by the applicable law in the following cases:<br /><br />
                    <ul>
                        <li>A.	When a request is made to provide information to appropriate authorities such as regulatory, investigative or judicial authorities to the extent specifically required or permissible under applicable laws and regulations;</li>
                        <li>B.	When it is necessary to check for suspicious misconducts which violate relevant and applicable laws and regulations; and/or</li>
                        <li>C.	When required by other relevant laws.</li><br />
                    </ul>
                </li>
                <li>11.	The Company securely encrypts, stores and transmits all data related to patient information including special categories of personal information, and complies with the Data Privacy Laws and Regulations such as the Personal Information Protection Act, Medical Act, HIPAA and GDPR Regulations.</li>
            </ul>
            <h3 className="pt-5">13. Restrictions on the Use of IP Rights </h3>
            <ul>
                <li>1.	Copyrights and other intellectual property rights for works created by the Company belong to the Company.</li>
                <li>2.	Except for cases granted under a separate service contract between the Company and a User, the Company grants  Users only the right to use the account, ID, and contents in accordance with the terms and conditions set by the Company in relation to the Service, Users shall not use the information or provide a third party with the information which belongs to the Company's intellectual property rights among the information obtained by using the Service for commercial purposes by copying, sending, transmitting, publishing, distributing, sending or other methods without the prior consent of the Company.
                    The copyright of various posts written or generated by a User while using the Service provided by the Company belongs to the User, and the User is responsible for all civil and criminal liabilities including copyright infringement of the posts.</li>
                <li>3.	Except for the use of Services permitted in this Agreement, it is strictly prohibited to use all or part of the software or Services, or to use other people's works without separate permission from a legitimate owner. This is infringing on the intellectual property rights of others, and Users may be liable for civil and criminal liabilities such as compensation for damages due to copyright  and/or other IP related infringement.</li>
                <li>4.	RAYTeams, the Ray logo and other product and/or service names contained on this site are trademarks or registered trademarks of the Company. The Company reserves the right to do so, and other products and Company names may be trademarks of their respective owners.</li>
                <li>5.	The Company respects the rights of others, including intellectual property rights, and expects Users to do the same. If necessary, the Company may, at its discretion, take measures to restrict the use of Services without prior notice to Users who infringe or violate the rights of others.</li>
            </ul>
            <h3 className="pt-5">14. LIMITATION OF LIABILITY </h3>
            <ul>
                <li>1.	AS A PLATFORM SERVICE PROVIDER, THE COMPANY MEDIATES ALREADY PRODUCED INFORMATION “AS-IS”. SINCE THE CONTENT CREATED BY THE USER OR THE THIRD-PARTY DOMAIN SITE CONNECTED THROUGH THE SERVICE IS INFORMATION INDEPENDENTLY CREATED OR PRODUCED BY THE PROVIDER OF THE INFORMATION, THE PARTY WHO CREATED AND PRODUCED THE INFORMATION GUARANTEES AND ASSUMES RESPONSIBILITY FOR THE ACCURACY, RELIABILITY OR LEGALITY OF THE INFORMATION IN PRINCIPLE EVEN IF IT IS MEDIATED BY THE SERVICE.</li>
                <li>2.	EVEN IF THE INFORMATION NOT PREPARED OR PRODUCED BY THE COMPANY IS TRANSMITTED THROUGH THE SERVICE OF THE COMPANY, UNLESS THERE ARE SEPARATE REGULATIONS IN THE RELEVANT LAWS OR SPECIAL CIRCUMSTANCES IN WHICH THE COMPANY DOES NOT MEDIATE THE INFORMATION “AS-IS”, NO WARRANTY IS MADE AS TO ITS ACCURACY, RELIABILITY OR LEGALITY. IN ADDITION, THE COMPANY SHALL BE LIABLE FOR DAMAGES SUFFERED BY THE USER AS A RESULT OF TRUSTING IT ONLY IF IT IS SEPARATELY STIPULATED IN THE RELEVANT LAWS OR IF THERE IS AN INTENTION OR NEGLIGENCE OF THE COMPANY.</li>
                <li>3.	THE COMPANY IS EXEMPTED FROM LIABILITY IN THE CASE OF TRANSACTIONS BETWEEN USERS OR BETWEEN USERS AND THIRD PARTIES THROUGH THE SERVICE AS A MEDIUM.</li>
                <li>4.	THE COMPANY IS NOT RESPONSIBLE FOR THE USE OF SERVICES PROVIDED FREE OF CHARGE UNLESS THERE ARE SPECIAL PROVISIONS IN THE RELEVANT LAWS AND REGULATIONS.</li>
                <li>5.	IF THE PAID SERVICE IS UNABLE TO USE OR THERE IS AN OBSTACLE TO THE USE, THE COMPANY COMPENSATES THE PAID SERVICE USER FOR THE DAMAGE. HOWEVER, THE COMPANY IS NOT RESPONSIBLE FOR ANY DAMAGE CAUSED TO THE USER WITHOUT INTENTION OR NEGLIGENCE OF THE COMPANY. THE COMPANY IS NOT RESPONSIBLE FOR THE FACT THAT THE USER IS UNABLE TO USE OR IF IT IS RECOVERED WITHIN 72 HOURS AFTER RECEIVING THE FACT OF USE FAILURE TO THE COMPANY. </li>
                <li>6.	IF THE COMPANY RECEIVES VARIOUS OBJECTIONS, SUCH AS CLAIMS FOR DAMAGES OR LAWSUITS, FROM A THIRD PARTY OTHER THAN THE USER DUE TO AN ILLEGAL ACT COMMITTED BY THE USER IN USING THE SERVICE OR AN ACT IN VIOLATION OF THIS AGREEMENT, THE USER SHALL TAKE RESPONSIBILITY AND EXPENSE FOR INDEMNIFYING THE COMPANY AND IF THE COMPANY IS NOT EXEMPTED, THE USER IS RESPONSIBLE FOR COMPENSATING THE COMPANY FOR DAMAGE CAUSED BY IT.</li>
                <li>7.	IF THE LAWS OF SOME JURISDICTIONS DO NOT ALLOW OR EXPRESSLY PROHIBIT THE EXCLUSION OF CERTAIN WARRANTIES, THE DISCLAIMER BELOW MAY NOT APPLY. THE COMPANY SHALL USE A REASONABLE LEVEL OF TECHNOLOGY AND EXERCISE DUE CARE IN PROVIDING AND MAINTAINING THE SERVICES. <br /><br />
                    <ul>
                        <li>A.	THE COMPANY DOES NOT GUARANTEE THAT THE SERVICE SHALL NOT BE INTERRUPTED OR THAT ERRORS SHALL NOT OCCUR, AND THE USERS AGREE ON THE CONDITION THAT THE COMPANY FULFILLS THE NOTICE OBLIGATION SET FORTH IN THIS AGREEMENT THAT THE COMPANY MAY CHANGE OR SUSPEND THE SERVICE FOR A CERTAIN PERIOD OF TIME IF REASONABLY NECESSARY OR MAY CANCEL THE SERVICE SUBJECT TO THIS AGREEMENT.</li>
                        <li>B.	USERS CLEARLY UNDERSTAND AND AGREE THAT THE SERVICES ARE PROVIDED ON AN “AS-IS” AND “AS-AVAILABLE” BASIS.</li>
                        <li>C.	THE COMPANY DOES NOT REPRESENT OR GUARANTEE THAT THE SERVICE IS SAFE FROM SECURITY THREATS SUCH AS VIRUS ATTACKS AND HACKING, AND DOES NOT BEAR ANY RESPONSIBILITY IN THIS REGARD. THEREFORE, THE USER AGREES THAT THE USER IS RESPONSIBLE FOR THE LOSS OF DATA OBTAINED BY UPLOADING OR DOWNLOADING THROUGH THE SERVICE.</li>
                        <li>D.	THE COMPANY HAS NO OBLIGATION TO TAKE SECURITY MEASURES IN ACCORDANCE WITH EACH NATIONAL MEDICAL INFORMATION RELATED LAWS, AND IF HE USER UPLOADS, DOWNLOADS, POSTS, E-MAILS, TRANSMITS, STORES, OR USES OTHER METHODS OR PROVIDES MEDICAL INFORMATION THROUGH THE SERVICE, THE COMPANY ASSUMES NO RESPONSIBILITY IN THIS REGARD.</li>
                        <li>E.	GDPR<br />
                            IN THE CASE OF HE USER WITHIN THE EEA, THE USER SHALL REVIEW THE DATA PRIVACY-RELATED REGULATIONS, APPROPRIATE LEVEL OF SECURITY, AND METHODS FOR DATA PROTECTION SUCH AS TECHNICAL, PHYSICAL, AND ADMINISTRATIVE SAFEGUARDS IN ORDER TO PERFORM LEGAL DUTIES. EXCEPT AS EXPRESSLY STIPULATED IN THE RELEVANT LAWS AND REGULATIONS, THE COMPANY DOES NOT BEAR ANY RESPONSIBILITY FOR THE USER’S VIOLATION OF GDPR REGULATIONS, AND THE USER AGREES TO FULFILL THE RESPONSIBILITIES AND OBLIGATIONS REQUIRED BY THE GDPR TO PROTECT THE PERSONAL INFORMATION SUBJECT'S INFORMATION WHILE USING THE SERVICE.<br /><br />
                            THE COMPANY HAS APPOINTED A PERSONAL INFORMATION PROTECTION OFFICER TO MANAGE INTERNAL INFORMATION PROTECTION ACTIVITIES AND CHECK COMPLIANCE WITH LAWS RELATED TO PERSONAL INFORMATION PROTECTION. IN ADDITION, TO COMPLY WITH THE GDPR, THE INFORMATION OF USERS IN EUROPE IS ENCRYPTED AND STORED IN EUROPE, AND TECHNICAL MEASURES ARE TAKEN TO STORE THE DATA.
                            THE COMPANY STORES AND TRANSMITS ALL DATA RELATED TO PATIENT INFORMATION/SENSITIVE INFORMATION SECURELY ENCRYPTED, AND COMPLIES WITH THE MEDICAL ACT, HIPAA REGULATIONS AND GDPR REGULATIONS.<br /><br />

                            CONTACT PERSON: RAYTEAMS PRIVACY OFFICER <br />
                            CONTACT: <a href="mailto:privacy@rayteams.com">PRIVACY@RAYTEAMS.COM</a><br /><br />

                            USERS ARE OBLIGATED TO TAKE INDIVIDUAL MEASURES IMPOSED BY THE GDPR, SUCH AS THE PROCEDURE FOR COLLECTING PATIENT INFORMATION INCLUDING SPECIAL CATEGORIES OF PERSONAL DATA AND APPOINTMENT OF A DPO, AND AGREE THAT USERS ARE SOLELY RESPONSIBLE FOR THE VIOLATION OF GDPR OBLIGATIONS.</li><br />
                    </ul>
                </li>
            </ul>
            <h3 className="pt-5">15. Dispute Resolution</h3>
            <ul>
                <li>1.	The Company do its best to handle complaints submitted by Users. However, if prompt handling is difficult, the Users shall be notified of the reason and processing schedule immediately.</li>
                <li>2.	 Users clearly understands and agrees that in relation to the interpretation of this Agreement and any disputes arising from this Agreement between the Company and a User, excluding conflict of laws, the laws of the Republic of Korea are applied, and that the Suwon District Court located in Gyeonggi-do of the Republic of Korea has a personal and exclusive jurisdiction.
                    If a User does not reside in the Republic of Korea, does not access the Service within the territory of the Republic of Korea, and belongs to a citizen/resident in the EU countries, Switzerland or the United States, the court of the User’s legal residence shall have a jurisdiction and its law shall apply.  </li>
            </ul>
            <h3 className="pt-5"> 16. Electronic Contracting </h3>
            The User’s acceptance of this Agreement and use of the Service confirms and assures his/her ability and intention to enter into a legally binding electronic agreement and to make transactions in an electronic manner. The User acknowledges and agrees that his/her electronic submissions shall be construed to be bound as a legally binding agreement. <br /><br />

            <p>These Terms and Conditions shall come into effect on August 19, 2022.</p>
        </div>
    )
}

const PrivacyPolicy = ({ showPrivacy, hidePrivacy, acceptPrivacy, htmlContents, setHtmlContents }) => {
    const ref = useRef();
    const [isBottom, setIsBottom] = useState(false);

    const [t, i18n] = useTranslation(['translation']);

    useEffect(() => { 
        setHtmlContents({ ...htmlContents, policy: document.getElementsByClassName('agree-txt')[0].innerHTML });        
    }, []);

    const onScroll = () => {
        if (ref.current) {
            const { scrollTop, scrollHeight, clientHeight } = ref.current;

            if (scrollTop + clientHeight + 10 >= scrollHeight)
                setIsBottom(true);
            else
                setIsBottom(false);   
        }
    };

    const agree = () => {
        if (isBottom) {
            acceptPrivacy();
            hidePrivacy();
        }
    }

    return (
        <>
            <div className="modal fade show" id="" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: showPrivacy ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => hidePrivacy()}></button>
                            <div className="alert alert-danger alert-icon mb-6" role="alert">
                                <i className="uil uil-exclamation-triangle"></i> {t('스크롤을 내려 문서를 끝까지 읽으셔야, 동의에 체크하실 수 있습니다.')}
                            </div>
                            <div className="abox" onScroll={onScroll} ref={ref}>       
                                <div className="agree-content">
                                    {i18n.language === "en" && <PrivacyEN contentOnly={true} />}
                                    {i18n.language === "ko" && <PrivacyKO contentOnly={true} />}
                                </div>
                            </div>
                            <div className="mt-6">
                                <p className="text-center pb-0 mb-0">
                                    <a className={`btn btn-${isBottom ? 'primary' : 'gray'} btn-icon btn-icon-start rounded-pill btn-lg`} onClick={() => agree()}>
                                        <i className="uil uil-check"></i> {t('위의 내용을 확인하였고, 내용에 동의합니다.')}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show" style={{ display: showPrivacy ? 'block' : 'none' }}></div>
        </>
    )
}
const PrivacyPolicyKR = () => {
    return (
        <div className="agree-txt" style={{ paddingBottom: 10 }}>
            <h2 className="mb-4">Privacy Policy</h2>
            <div>
                <h4>1. 개인정보처리방침의 의의</h4>
                <p>주식회사 레이(이하 “회사”)는 개인정보 보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률을 준수하고 이용자의 개인정보를 보호하기 위해 최선을 다하고 있습니다. 회사는 이용자의 개인정보를
                    중요시하며, 개인정보처리방침을 통하여 회사가 이용자로부터 제공받는 개인정보를 어떠한 용도와 방식으로 이용하고 있으며, 개인정보 보호를 위해 어떠한 조치를 취하고 있는지 알려드립니다.</p>
                <p>본 개인정보처리방침은 본 방침이 게시된 웹사이트(https://www.rayteams.com), 그리고 소프트웨어 및 등록과정에서 회사가 이용자의 개인정보를 수집, 사용, 공개, 이전 및 저장하는 방법과
                    이용자의 개인정보를 보호하기 위해 취하는 조치를 뜻하며, 레이팀즈 및 관련 제반 서비스(모바일 웹/앱 포함)에 적용됩니다.</p>
                <p>본 방침은 2022년 8월 19일부터 시행됩니다. 추후 본 방침을 개정하는 경우 웹사이트 공지사항 또는 이메일 등의 방법을 통하여 공지하겠습니다.</p>
                <h4 className="pt-5">2. 수집하는 개인정보 및 수집방법</h4>
                <p>서비스를 이용하기 위해 회원 가입을 할 경우, 회사는 서비스 이용을 위해 필요한 최소한의 개인정보를 수집합니다.</p>
                <ul>
                    <li><strong>회원가입 시점에 회사가 이용자로부터 수집하는 개인정보는 아래와 같습니다.</strong><br />
                        <p className="pt-2">회사는 연락처 데이터베이스를 관리하기 위하여 귀하의 이름, ID(이메일), 전화번호, 치과 이름, 기공소 이름 및 주소를 수집하고 있습니다.<br />회사는
                            귀하가 자발적으로 이메일이나 다른 경로로 제공한 정보에 대해 접근/수집만 할 수 있으며, 귀하에 관한 개인정보 수집과 관련된 사항은 적용되는 법률에 따라 통보됩니다. 회사는 이 정보를
                            누구에게도 판매하거나 대여하지 않습니다.</p>
                    </li>
                    <li><strong>서비스 이용 과정에서 이용자로부터 수집하는 개인정보는 아래와 같습니다. </strong><br /><br />
                        <table className="table">
                            <colgroup>
                                <col width="8%" />
                                <col width="12%" />
                                <col width="40%" />
                                <col width="40%" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>유형</th>
                                    <th>구분</th>
                                    <th>수집/이용 항목</th>
                                    <th>수집/이용 목적</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center" rowSpan="3">필수</td>
                                    <td className="text-center" rowSpan="2">회원 공통</td>
                                    <td>이름, ID(이메일), 비밀번호, 전화번호, 치과 또는 기공소명, 주소</td>
                                    <td>이용자 식별, 계약이행을 위한 연락, 서비스 이용에 따른 정보 제공(고지사항 전달, 본인의사 확인, 서비스관련상담, 민원사항 처리, 유의사항 등), 서비스 만족도
                                        조사</td>
                                </tr>
                                <tr>
                                    <td>이름, ID(이메일), 서비스이용기록, 아이피 주소(IP Address), 접속로그</td>
                                    <td>부정거래방지</td>
                                </tr>
                                <tr>
                                    <td className="text-center">치과/기공소<br />구성 회원</td>
                                    <td>이름, ID(이메일), 비밀번호, 전화번호, 치과 또는 기공소명, 주소</td>
                                    <td>이용자 식별, 중복가입방지, 계약이행을 위한 연락, 서비스 이용에 따른 정보 제공(고지사항 전달, 본인의사 확인, 서비스관련상담, 민원사항 처리, 유의사항 등),
                                        서비스 만족도 조사</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>추가로 개인정보를 수집할 경우에는 해당 개인정보 수집 시점에서 이용자에게 ‘수집하는 개인정보 항목, 개인정보의 수집 및 이용 목적, 개인정보의 보관기간’에 대해 안내 드리고 동의를
                            받습니다. </p>
                    </li>
                    <li><strong>서비스 이용 과정에서 IP 주소, 쿠키, 서비스 이용 기록, 기기정보, 위치정보가 생성되어 수집될 수 있습니다.</strong>
                        <p className="pt-2">구체적으로 1) 서비스 이용 과정에서 이용자에 관한 정보를 자동화된 방법으로 생성하여 이를 저장(수집)하거나, 2) 이용자 기기의 고유한 정보를 원래의 값을
                            확인하지 못하도록 안전하게 변환하여 수집합니다. <br />이와 같이 수집된 정보는 개인정보와의 연계 여부 등에 따라 개인정보에 해당할 수 있고, 개인정보에 해당하지 않을 수도 있습니다.
                        </p>
                    </li>
                    <li><strong>회사는 아래의 방법을 통해 개인정보를 수집합니다. </strong>
                        <ul>
                            <li>회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우, 해당 개인정보를 수집합니다. </li>
                            <li>고객센터를 통한 상담 과정에서 웹페이지, 메일, 팩스, 전화 등을 통해 이용자의 개인정보가 수집될 수 있습니다. </li>
                            <li>오프라인에서 진행되는 이벤트, 세미나 등에서 서면을 통해 개인정보가 수집될 수 있습니다. </li>
                            <li>회사와 제휴한 외부 기업이나 단체로부터 개인정보를 제공받을 수 있으며, 이러한 경우에는 개인정보보호법에 따라 제휴사에서 이용자에게 개인정보 제공 동의 등을 받은 후에 회사에
                                제공합니다. </li>
                            <li>기기정보와 같은 생성정보는 PC웹, 모바일 웹/앱 이용 과정에서 자동으로 생성되어 수집될 수 있습니다. </li>
                        </ul>
                    </li>
                </ul>
                <h4 className="pt-5">3. 수집한 개인정보의 이용 </h4>
                <p>회사가 수집한 개인정보는 다음과 같은 목적에 활용됩니다. 회사는 제반 서비스(모바일 웹/앱 포함)의 회원관리, 서비스 개발, 제공 및 향상, 안전한 인터넷 환경 구축 등 아래의 목적으로만 개인정보를
                    이용합니다. </p>
                <ul>
                    <li>회원 가입 의사의 확인, 이용자 식별, 회원탈퇴 의사의 확인 등 회원관리를 위하여 개인정보를 이용</li>
                    <li>이용자의 질문에 관한 답변 제공 등 회원 대응 </li>
                    <li>통계 및 모니터링 목적 </li>
                    <li>컨텐츠 등 기존 서비스 제공에 더하여, 인구통계학적 분석, 서비스 방문 및 이용기록의 분석 등 기존 서비스 개선 </li>
                    <li>분쟁조정을 위한 기록 보존, 민원처리 등 이용자 보호 기타 마케팅 계획 수립 </li>
                    <li>약관 개정 등의 고지사항 전달 </li>
                    <li>법령 및 회사 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지</li>
                    <li>보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는 서비스 이용 환경 구축</li>
                </ul>
                <p>회사는 사전 동의가 얻어진 경우에만 귀하의 개인 데이터를 사용하여 마케팅 자료를 발송합니다. </p>
                <h4 className="pt-5">4. 개인정보의 제공 및 위탁 </h4>
                <p>회사는 이용자의 사전 동의 없이 개인정보를 외부에 제공하지 않습니다. 단, 이용자가 외부 제휴사의 서비스를 이용하기 위하여 개인정보 제공에 직접 동의를 한 경우, 그리고 관련 법령에 의거해 회사에
                    개인정보 제출 의무가 발생한 경우, 이용자의 생명이나 안전에 급박한 위험이 확인되어 이를 해소하기 위한 경우에 한하여 개인정보를 제공하고 있습니다. </p>
                <p>회사는 귀하의 요청을 이행하는 데 필요한 경우와 법률에 의거하여 귀하의 정보 제공이 요청된 경우, 정보주체의 별도의 동의를 받은 경우, 통계작성 및 학술연구 등의 목적을 위해 비식별화 처리하여 제공하는
                    경우, 조약과 그 밖의 국제협정의 이행을 위하여 외국정부 등에 제공하기 위하여 필요한 경우, 범죄의 수사 및 재판 업무 수행, 보호처분 집행을 위하여 필요한 경우를 제외하고 귀하의 정보를 회사 외부의
                    제3자와 공유하지 않습니다. </p>
                <p>회사는 서비스 제공을 위해 필요한 업무 중 일부를 외부 업체에 위탁하고 있으며 위탁 받은 업체가 개인정보를 안전하게 처리하도록 필요한 사항을 개인정보처리위탁계약서를 통해 규정하고 관리 및 감독을 하고
                    있습니다. 개인정보의 위탁은 회원 탈퇴 및 계약해지 시 종료됩니다. </p>
                <p>개인정보 수탁자 및 위탁업무 내용은 아래와 같고, 필요한 경우 수탁업체가 추가되거나 변경될 수 있으며, 이 경우 본 개인정보 처리방침에 변경 사항을 반영하여 고지합니다. </p>
                <table className="table">
                    <colgroup>
                        <col width="20%" />
                        <col width="40%" />
                        <col width="40%" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>수탁자</th>
                            <th>위탁사무 및 목적</th>
                            <th>보유 및 이용기간</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-center">Amazon Web Services, Inc. </td>
                            <td className="text-center">데이터 보관 업무</td>
                            <td className="text-center">목적 달성 후 즉시 파기</td>
                        </tr>
                    </tbody>
                </table>
                <h4 className="pt-5">4.1 개인정보의 국외이전</h4>
                <p>개인정보 처리위탁 중 국외법인에서 처리하는 위탁업무는 아래와 같습니다. </p>
                <p>회사는 재난, 재해 상황에도 중단 없는 서비스 제공을 위해 국내외에 데이터를 안전하게 백업(보관)합니다. 국외 데이터 보관 업무를 처리하는 AWS는 회사의 엄격한 통제 하에 데이터 관리 업무를
                    수행합니다. </p>
                <table className="table">
                    <thead>
                        <tr>
                            <th>이전되는<br />개인정보의<br />항목</th>
                            <th>이전되는 국가</th>
                            <th>이전 일시</th>
                            <th>이전 방법</th>
                            <th>이전받는 자</th>
                            <th>이전받는 자의 정보<br />관리책임자 연락처</th>
                            <th>이전받는 자의<br />개인정보 이용 목적</th>
                            <th>이전받는 자의<br />개인정보 보유 및 이용기간</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-center">서비스 이용 중 수집되는 개인정보 및 로그 정보</td>
                            <td>1. 미국 (오하이오, 버지니아, 캘리포니아, 오레곤)<br />2. 인도 (뭄바이)<br />3. 한국 (서울)<br />4. 싱가폴<br />5. 호주 (시드니)<br />6. 일본
                                (도쿄)<br />7. 캐나다<br />8. 독일 (프랑크푸르트)<br />9. 아일랜드<br />10. 영국 (런던)<br />11. 프랑스 (파리)<br />12. 스웨덴 (스톡홀름)<br />13.
                                바레인<br />14. 브라질 (상파울로)</td>
                            <td className="text-center">서비스 제공 과정 중 수시로 이전</td>
                            <td className="text-center">온라인</td>
                            <td className="text-center">AWS</td>
                            <td className="text-center">AWS Korea Privacy<br />aws-korea-privacy@amazon.com </td>
                            <td className="text-center">RAYTeams 서비스 제공을 위한 데이터 저장</td>
                            <td className="text-center">이용자의 서비스 가입기간 동안 보유</td>
                        </tr>
                    </tbody>
                </table>
                <h4 className="pt-5">5. 개인정보의 보유기간 및 파기 </h4>
                <ul>
                    <li><strong>개인정보의 보유기간</strong><br />
                        <p className="pt-3">회사는 원칙적으로 이용자의 개인정보를 회원 탈퇴 시 지체없이 파기하고 있습니다. 다만, 회사는관계 법령의 규정 및 회사 내부 방침에 의하여 개인정보를 보존할
                            필요가 있는 경우 일정한 기간 동안 해당 개인정보를 보관합니다</p>
                        <p>또한, 회사는 개인정보보호법 제39조의6 및 같은 법 시행령 제49조의5에 따라, 1년간 또는 이용자가 지정한 기간 동안 서비스 이용 기록이 없는 경우 해당 이용자를 휴면회원으로 전환하며,
                            휴면회원으로 전환될 경우 관련된 정보를 파기하거나 별도의 DB에 분리 보관합니다. </p>
                        <p>회사는 휴면 전환 30일 전까지 휴면 예정 회원에게 개인정보 파기에 대해서는 개인정보가 파기되는 사실, 파기 예정일 및 파기되는 개인정보의 항목을, 분리 보관에 대해서는 별도 분리 보관되는
                            사실 및 분리 보관 예정일, 별도 분리 보관하는 개인정보 항목을 이메일로 알리겠습니다. </p>
                        <p>단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정 기간 정보의 보관의무를 규정하는 경우는 아래와 같습니다. 회사는 이 기간 동안 법령의 규정에 따라
                            개인정보를 보관합니다. </p>
                        <ul>
                            <li>가. 관계법령의 규정이 있는 경우<ul className="list-unstyled">
                                <li>1) 계약 또는 청약철회 등에 관한 기록: 5년 (전자상상거래 등에서의 소비자보호에 관한 법률)</li>
                                <li>2) 대금결제 및 재화 등의 공급에 관한 기록: 5년 (전자상거래 등에서의 소비자보호에 관한 법률)</li>
                                <li>3) 소비자의 불만 또는 분쟁처리에 관한 기록: 3년 (전자상거래 등에서의 소비자보호에 관한 법률)</li>
                                <li>4) 이용자의 로그기록, 접속지 추적자료, 웹사이트 방문기록: 3개월 (통신비밀보호법)</li>
                                <li>5) 전자금융거래법에 의한 전자금융에 관한 기록: 5년 (전자문서 및 전자거래 기본법) </li>
                            </ul>
                            </li>
                            <li>나. 회사 내부 방침에 따라 보유하는 개인정보의 보존 기간<ul className="list-unstyled">
                                <li>1) 개별적으로 이용자의 동의를 받은 경우: 동의를 받은 기간 </li>
                                <li>2) 서비스 부정 가입 및 이용 식별을 위해 필요한 정보(아이디, 기기정보, 서비스 이용 로그 및 수집 항목, 탈퇴일시, 탈퇴사유): 관련 분쟁 해결 시점까지
                                </li>
                                <li>3) 회사와 이용자 간에 민원, 소송 등 분쟁이 발생한 경우에 그 보유기간 내에 분쟁이 해결되지 않을 경우: 해당 분쟁 해결 시점까지 </li>
                                <li>4) 탈퇴회원 개인정보: 탈퇴일로부터 3개월 </li>
                                <li>5) 콜센터 및 홈페이지 A/S 접수, 상담 시 수집된 개인정보: 수집일로부터 5년 </li>
                                <li>6) 홈페이지 문의 시 수집된 개인정보: 수집일로부터 3년 </li>
                            </ul>
                            </li>
                        </ul>
                    </li>
                    <li className="pt-5"><strong>개인정보의 파기</strong><br />
                        <p className="pt-3">회원탈퇴, 서비스 종료, 이용자에게 동의 받은 개인정보 보유기간의 도래와 같이 개인정보의 수집 및 이용목적이 달성된 개인정보는 재생이 불가능한 방법으로
                            파기하고 있습니다.<br />회사는 법령 등에 따라 보관하는 경우, 별도의 데이터베이스로 전송되며 법률에 의해 제공되지 않은 한 보유 목적으로만 사용되며 명시된 기간 내에 폐기됩니다.
                            <br />전자적 파일 형태의 경우 복구 및 재생이 되지 않도록 기술적인 방법을 이용하여 안전하게 삭제하며, 출력물 등은 분쇄하거나 소각하는 방식 등으로 파기합니다. </p>
                    </li>
                </ul>
                <h4 className="pt-5">6. 이용자 및 법정대리인의 권리와 행사방법</h4>
                <p>귀하는 다음과 같은 정보보호 권리를 가집니다:</p>
                <ul>
                    <li>귀하는 개인정보의 접근, 수정, 업데이트, 처리정지 또는 삭제를 요청할 수 있습니다. 그러나, 회사는 법률에 특별한 조항이 있거나 법적 의무를 준수할 필요가 있는 경우, 혹은 다른 사람을 해할
                        위험이 있거나 타인의 재산이나 이익을 부당하게 침해할 위험이 있는 경우 귀하의 요청을 유예할 수 있습니다. </li>
                    <li>귀하는 귀하의 개인정보 처리에 이의를 제기할 수 있으며, 귀하의 개인정보 처리를 제한하도록 요청할 수 있습니다. </li>
                    <li>회사가 귀하의 동의 하에 귀하의 개인정보를 수집하고 처리한 경우 귀하는 언제든지 귀하의 동의를 철회할 수 있습니다.<br />귀하의 동의 철회는 철회 이전에 수행한 모든 처리의 적법성에 영향을
                        미치지 않으며, 동의가 아닌 합법적인 처리 근거에 의거하여 수행된 귀하의 개인정보 처리에도 영향을 미치지 않습니다.<br />이 권리를 행사하고자 하는 고객은 support@rayteams.com로
                        이메일을 발송하거나 “경기도 성남시 분당구 판교역로 221 주식회사 레이”로 우편을 발송해 귀하의 정보 변경, 수정, 혹은 통지 요청을 할 수 있습니다. <br />빠른 기간 내에 귀하의 요청에
                        답변 드리겠습니다.</li>
                </ul>
                <h4 className="pt-5">7. 개인정보보호를 위한 회사의 노력</h4>
                <p>회사는 이용자의 개인정보를 안전하게 관리하기 위하여 최선을 다하며, 개인정보보호법에서 요구하는 수준 이상으로 개인정보를 보호하고 있습니다. </p>
                <p>회사는 관리적, 기술적, 물리적 조치를 취하여 수집된 개인정보가 제3자에 의해 무단으로 접근, 사용 또는 공개되지 않도록 귀하의 개인정보를 보호하고 있습니다. 회사는 데이터 보호를 위해 내부관리계획을
                    수립하고 실행 중에 있습니다. 귀하가 제공하는 개인정보는 허가 받지 않은 접근, 사용 또는 공개로부터 보호되며 서버에 안전하게 보관됩니다. </p>
                <p>추가적인 기술적 조치로는 개인정보 처리시스템에 대한 접근 권한 관리, 고유한 식별정보 암호화, 보안 프로그램 설치, 접속기록의 보관, 암호화 등이 있습니다. </p>
                <ul>
                    <li className="pt-5"><strong>개인정보보호 내부관리계획을 수립, 시행하고 있습니다.</strong>
                        <p className="pt-3">개인정보 보호책임자의 지정 등 개인정보 보호 조직의 구성 및 운영에 관한 사항을 포함하여 개인정보 내부관리계획을 수립하고, 매년 내부관리계획을 잘 시행하고
                            있는지를 점검하고 있습니다. </p>
                    </li>
                    <li className=""><strong>개인정보에 대한 접근 통제 및 접근 권한 제한 조치를 취하고 있습니다.</strong>
                        <p className="pt-3">개인정보에 대한 불법적인 접근을 차단하기 위해 개인정보 처리시스템에 대한 접근 권한의 부여, 변경, 말소 등에 관한 기준을 수립하여 시행하고 있으며,
                            침입차단시스템을 설치, 운영하고 있습니다. 또한 개인정보를 처리하는 직원을 최소한으로 관리하며, 개인정보처리시스템에서 개인정보의 다운로드가 가능한 직원들의 업무용 PC에 대해 외부
                            인터넷망과 내부망을 분리하여 개인정보유출을 줄이고 있습니다.</p>
                    </li>
                    <li className=""><strong>개인정보를 안전하게 저장, 전송할 수 있는 암호화 조치를 취하고 있습니다.</strong>
                        <p className="pt-3">법령에서 암호화를 요구하고 있는 비밀번호, 고유식별정보 외에 이메일, 주소, 전화번호등을 추가로 암호화하여 보관하고 있습니다. 또한 암호화 통신 등을 통하여
                            네트워크 상에서 개인정보를 안전하게 송수신하고 있습니다. </p>
                    </li>
                    <li className=""><strong>개인정보 접속기록의 보관 및 위조, 변조 방지를 위한 조치를 취하고 있습니다.</strong></li>
                </ul>
                <h4 className="pt-5">8. 개인정보의 국외이전 </h4>
                <p>회사는 서비스 제공 목적 및 이용자의 편의 증진 등을 위하여 이용자의 정보를 국외로 전송하거나, 국외에서 관리할 수 있습니다. <br />이 개인정보 보호정책은 개인정보를 다른 국가로 이전하는 경우에도
                    적용됩니다. 회사는 개인정보의 국외 이전의 경우 귀하의 동의를 득하며, 귀하의 개인정보가 보호되도록 적절한 보호 조치를 취합니다.</p>
                <h4 className="pt-5">9. 쿠키정책</h4>
                <p>당사는 귀하가 서비스에 최대한 쉽고, 효율적이고, 유용하게 접근할 수 있기를 바랍니다. 이를 위해 당사는 쿠키 및 유사한 기술을 사용하여 사용자 환경을 개선하고, 사이트 보안을 향상합니다.</p>
                <p>쿠키는 귀하가 거의 모든 웹사이트를 방문할 때 귀하의 기기에 자동으로 생성되는 작은 텍스트 파일입니다. 쿠키는 귀하의 인터넷 브라우저에 의해서 저장되며, 귀하의 인터넷 사용에 대한 기본 정보를
                    포함합니다. 귀하가 웹사이트를 다시 방문할 때마다 웹사이트에서 귀하의 기기를 인식하고, 개인화된 콘텐츠를 제공하여, 사용자 환경을 개선할 수 있도록 귀하의 브라우저에서 쿠키를 웹사이트에 다시
                    전송합니다. 또한, 당사는 귀하의 로그인 정보를 저장하는 데 쿠키를 사용하므로, 귀하는 서비스를 이용할 때 로그인 정보를 반복해서 다시 입력할 필요가 없습니다. 그 밖의 쿠키를 사용하여 귀하의 선호도를
                    파악할 수 있습니다.</p>
                <p>당사에서는 필수 쿠키만 사용합니다. 필수 쿠키는 웹사이트에 액세스하여 둘러보고 웹사이트의 모든 기능을 이용하는 데 필요합니다. 이러한 쿠키가 없으면 웹사이트가 제대로 작동하지 않으므로 중요한 특정 기능을
                    이용할 수 없습니다. 예를 들어, 당사에서는 방문 중에 로그인 상태를 유지하는 데 쿠키를 사용하므로, 사이트에서 다른 페이지에 액세스하기 위해 반복적으로 로그인할 필요가 없습니다. 또한, 당사는
                    부정행위를 감지하고 예방하기 위해 필수 쿠키를 사용할 수 있습니다. 필수 쿠키는 웹사이트의 기능에 액세스하여 이를 이용하는 데 필요하므로, 쿠키 동의 도구를 통해 해제할 수 없습니다. </p>
                <h4 className="pt-5">10. 개인정보 보호책임자 및 열람청구 접수/처리 부서 안내 </h4>
                <p>회사는 귀하의 개인정보를 보호하고 개인정보와 관련한 민원을 처리하기 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. 귀하는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을
                    당사 개인정보 보호책임자에게 신고하실 수 있습니다. 개인정보 보호책임자가 귀하의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.</p>
                <ul>
                    <li><strong>개인정보 보호책임자</strong></li>
                    <li className="pt-1">이름: 이민규 </li>
                    <li>소속 및 직책: 소프트웨어 개발본부 </li>
                    <li>직위: 상무 </li>
                    <li>이메일: <a href="mailto:privacy@rayteams.com">privacy@rayteams.com</a></li>
                </ul>
                <ul>
                    <li><strong>개인정보 보호담당자</strong></li>
                    <li className="pt-1">이름: 방성원</li>
                    <li>소속: 소프트웨어 개발본부</li>
                    <li>직위: 수석</li>
                    <li>전화: +82-31-605-1000</li>
                    <li>이메일: <a href="mailto:privacy@rayteams.com">privacy@rayteams.com</a></li>
                </ul>
                <p>기타 개인정보 침해에 대한 신고나 상담이 필요한 경우에 아래 기관에 문의 가능합니다. <br />개인정보침해신고센터 (<a href="https://privacy.kisa.or.kr/"
                    target="_blank">privacy.kisa.or.kr</a> / 국번없이 118)<br />대검찰청 사이버수사과 (<a href="https://www.spo.go.kr"
                        target="_blank">www.spo.go.kr</a> / 국번없이 1301)<br />경찰청 사이버안전지킴이 (<a
                            href="http://www.police.go.kr/www/security/cyber.jsp"
                            target="_blank">http://www.police.go.kr/www/security/cyber.jsp</a> / 국번없이 182)</p>
                <h4 className="pt-5">11. 개정 전 고지의무</h4>
                <p>본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소 7일 전에 ‘공지사항’을 통해 사전 공지할 것입니다.<br />다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자
                    권리의 중대한 변경이 발생할 때에는 최소 30일 전에 공지하며, 필요 시 이용자 동의를 다시 받을 수도 있습니다. </p>
                <p>공고일자: 2022년 8월 10일<br />시행일자: 2022년 8월 15일</p>
            </div>
        </div>
    )
}
const PrivacyPolicyEN = () => {
    return (
        <div style={{ paddingBottom: 10 }}>
            <h2 className="mb-4">Privacy Policy</h2>
            <h4>1. Significance of the Privacy Policy</h4>
            <p>Ray Co., Ltd. (hereinafter referred to as “Ray") complies with the Personal Information Protection Act, the Act
                on Promotion of Information and Communications Network Use and Information Protection, etc., and is doing its
                best to protect users' personal information. Ray values the personal information of Users and has created a
                Privacy Policy to inform Users of the purpose and method of using personal information provided by Users and
                what measures are being taken to protect personal information.</p>
            <p>This Privacy Policy refers to how we collect, use, disclose, transfer and store your personal information and the
                measures we take to protect your personal information on the website where this policy is posted
                (https://www.rayteams.com) and in the software and registration process, and it applies to Rayteams and all
                related services (including mobile web/app).This policy will take effect on August 2022, 8. If we revise this
                policy in the future, we will notify you through website notice or e-mail.</p>
            <p>This Policy is effective as of August 19, 2022. If we revise this Policy in the future, we will notify you via a
                notice on our website or by email.</p>
            <h4 className="pt-5">2. Personal Information to be Collected and Methods of Collection</h4>
            <p>When you sign up to use the service, Ray collects the minimum personal information necessary to use the service.</p>
            <ul>
                <li><strong>The personal information collected by Ray from users at the time of membership registration is as
                    follows.</strong><br />
                    <p className="pt-2">Ray collects your name, ID (email), telephone number, dental name, laboratory name, and
                        address to maintain our contact database. Ray may only access/collect information that you voluntarily
                        provide by email or other channels, and you will be notified of any matters related to the collection of
                        personal information in accordance with applicable law. Ray does not sell or rent this information to
                        anyone.</p>
                </li>
                <li>
                    <strong>Personal information collected from users in the process of using the service is asfollows.</strong><br /><br />
                    <table className="table">
                        <colgroup>
                            <col width="8%" />
                            <col width="12%" />
                            <col width="40%" />
                            <col width="40%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>type</th>
                                <th>category</th>
                                <th>Items Collected/Used</th>
                                <th>Purpose of collection/use</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-center" rowSpan="3">Essential</td>
                                <td className="text-center" rowSpan="2">All Members</td>
                                <td>Name, ID (email), password, telephone number, dental or laboratory name, address</td>
                                <td>User identification, contact for contract performance, provision of information according to
                                    service use (delivery of notices, confirmation of personal opinion, consultation related to
                                    service, handling of complaints, precautions, etc.), service satisfaction survey</td>
                            </tr>
                            <tr>
                                <td>Name, ID (e-mail), service use record, IP address, access log</td>
                                <td>Prevention of an illegal transaction</td>
                            </tr>
                            <tr>
                                <td className="text-center">Members of Clinic/Laboratory</td>
                                <td>Name, ID (email), password, telephone number, dental or laboratory name, address</td>
                                <td>User identification, prevention of duplicate registrations, contact for contract
                                    performance, provision of information according to service use (delivery of notices,
                                    confirmation of personal opinion, service-related consultation, handling of complaints,
                                    precautions, etc.), service satisfaction survey</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>When additional personal information is collected, the user will be informed of the "items of personal
                        information to be collected, the purpose of collection and use of personal information, and the
                        retention period of personal information" at the time of collection of the personal information and
                        obtain consent.</p>
                </li>
                <li><strong>IP address, cookies, service use records, device information, and location information may be
                    generated and collected in the process of using the service.</strong>
                    <p className="pt-2">Specifically, 1) information about the user is generated in an automated way and stored
                        (collected) in the process of using the service, or 2) unique information on the user's device is safely
                        converted so that the original value cannot be checked. The information collected in this way may or may
                        not correspond to personal information depending on whether it is linked to personal information.</p>
                </li>
                <li><strong>Ray collects personal information through the following methods.</strong>
                    <ul>
                        <li>In the process of membership registration and service use, if the user agrees to the collection of
                            personal information and enters the information directly, the personal information will be
                            collected.</li>
                        <li>During the consultation process through the customer center, the user's personal information may be
                            collected through the web page, e-mail, fax, phone, etc.</li>
                        <li>Personal information may be collected in writing at offline events, seminars, etc.</li>
                        <li>Personal information may be provided by external companies or organizations affiliated with Ray, and
                            in this case, it will be provided to Ray after obtaining consent from the partner to provide
                            personal information to the user in accordance with the Personal Information Protection Act.</li>
                        <li>Generated information such as device information may be automatically generated and collected in the
                            process of using PC web and mobile web/app.</li>
                    </ul>
                </li>
            </ul>
            <h4 className="pt-5">3. Use of Collected Personal Information</h4>
            <p>The personal information collected by Ray is used for the following purposes. Ray uses personal information only
                for membership management of various services (including mobile web/app), service development, provision and
                enhancement, and establishment of a safe Internet environment.</p>
            <ul>
                <li>Use of personal information for member management, such as confirmation of intention to join the member
                    registration, user identification, and confirmation of intention to withdraw the membership</li>
                <li>Member response, such as providing answers to users' questions</li>
                <li>For statistical and monitoring purposes</li>
                <li>In addition to providing existing services such as content, improving existing services such as demographic
                    analysis, analysis of service visits, and usage records</li>
                <li>Preservation of records for conciliation of disputes, protection of users such as handling of complaints,
                    etc. Establishment of other marketing plans</li>
                <li>Delivery of notices such as amendments to the terms and conditions</li>
                <li>Restriction of use for members who violate laws and company terms of use, prevention and sanctions for acts
                    that interfere with the smooth operation of the service, including fraudulent use, and prevention of account
                    theft and illegal transactions.</li>
                <li>Establishment of a service use environment that users can use without fear in terms of security, privacy,
                    and safety</li>
            </ul>
            <p>We will only use your personal data to send you marketing materials if you have obtained your prior consent.</p>
            <h4 className="pt-5">4. Provision and consignment of personal information</h4>
            <p>Ray does not provide personal information to external parties without the prior consent of the user, except when
                the user has directly consented to the provision of personal information in order to use the services of an
                external partner, and when Ray is obligated to submit personal information in accordance with applicable laws
                and regulations, and when there is an immediate danger to the life or safety of the user and it is necessary to
                resolve it.</p>
            <p>Ray does not share your information with third parties outside of Ray except as necessary to fulfill your
                request, when the provision of your information is required by law, when separate consent is obtained from the
                data subject, when a de-identified form is offered for purposes such as statistical compilation and academic
                research, when necessary to provide to foreign governments for the implementation of treaties and other
                international agreements, when necessary to conduct criminal investigations and trials, and when necessary to
                enforce protective orders.</p>
            <p>Ray entrusts some of the tasks necessary for the provision of services to external companies, and stipulates the
                matters necessary for the entrusted company to safely process personal information through the personal
                information processing entrustment agreement, and manages and supervises it. The entrustment of personal
                information is terminated upon withdrawal of membership or termination of the contract.</p>
            <p>The trustees of personal information and the contents of the entrusted tasks are as follows, and if necessary,
                the trustees may be added or changed, and in this case, this Privacy Policy will be notified to reflect the
                changes.</p>
            <table className="table">
                <colgroup>
                    <col width="20%" />
                    <col width="40%" />
                    <col width="40%" />
                </colgroup>
                <thead>
                    <tr>
                        <th>Trustee</th>
                        <th>Outsourcing and purpose</th>
                        <th>Retention and use period</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="text-center">Amazon Web Services, Inc. </td>
                        <td className="text-center">Data storing</td>
                        <td className="text-center">Destroy immediately after achieving the purpose</td>
                    </tr>
                </tbody>
            </table>
            <h4 className="pt-5">4.1 International Transfer of Personal Information</h4>
            <p>The consignment of personal information processing is handled by overseas subsidiaries as follows.</p>
            <p>Ray safely backs up (stores) data domestically and internationally to provide uninterrupted service even in the
                event of a disaster or catastrophe. AWS, which handles overseas data storage, performs data management tasks
                under strict control of Ray.</p>
            <table className="table">
                <thead>
                    <tr>
                        <th>Items of personal information transferred</th>
                        <th>Countries to which you are transferred</th>
                        <th>Transfer date and time</th>
                        <th>Transfer method</th>
                        <th>Transferee</th>
                        <th>Contact information of the transferee's information manager</th>
                        <th>Purpose of use of personal information of the transferee</th>
                        <th>Period of retention and use of the transferee's personal information</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="text-center">Personal information and log information collected during service use</td>
                        <td>1. United States (Ohio, Virginia, California, Oregon)<br />2. India (Mumbai)<br />3. Korea (Seoul)<br />4.
                            Singapore<br />5. Australia (Sydney)<br />6. Japan (Tokyo)<br />7. Canada<br />8. Germany (Frankfurt)<br />9.
                            Ireland<br />(10). United Kingdom (London)<br />11. France (Paris)<br />12. Sweden (Stockholm)<br />13.
                            Bahrain<br />(14). Brazil (Sao Paulo)<br /></td>
                        <td className="text-center">Transfer from time to time during the course of service provision</td>
                        <td className="text-center">Online</td>
                        <td className="text-center">AWS</td>
                        <td className="text-center">AWS Korea Privacy<br />aws-korea-privacy@amazon.com </td>
                        <td className="text-center">Data storage for the provision of RAYTeams services</td>
                        <td className="text-center">Retained during the user's subscription period</td>
                    </tr>
                </tbody>
            </table>
            <h4 className="pt-5">5. Retention period and destruction of personal information</h4>
            <ul>
                <li><strong>Retention period of personal information</strong><br />
                    <p className="pt-3">In principle, Ray destroys users' personal information without delay when withdrawing from
                        membership. However, if it is necessary to preserve personal information in accordance with the
                        provisions of related laws and regulations and Ray’s internal policy, Ray will keep the personal
                        information for a certain period of time.</p>
                    <p>In addition, in accordance with Article 39-6 of the Personal Information Protection Act and Article 49-5
                        of the Enforcement Decree of the same law, Ray converts the user to a dormant member if there is no
                        record of service use for one year or the period specified by the user, and if the user is converted to
                        a dormant member, the relevant information is destroyed or stored separately in a separate DB.</p>
                    <p>At least 30 days prior to the dormancy transition, Ray will notify the dormant member by email of the
                        fact that the personal information will be destroyed, the date of destruction, and the items of personal
                        information to be destroyed in the case of personal information destruction, and the fact that the
                        personal information will be kept separately in the case of separate storage, the date of separate
                        storage, and the items of personal information to be kept separately in the case of separate storage.
                    </p>
                    <p>However, when a separate consent is obtained from the user for the period of personal information
                        storage, or in the event that a law stipulates the obligation to store information for a certain period
                        of time, the Company shall store personal information in accordance with the provisions of the law
                        during this period.</p>
                    <ul>
                        <li>A. If there are provisions of related laws and regulations<ul className="list-unstyled">
                            <li>1) Records on contract or subscription withdrawal: 5 years (Act on Consumer Protection in
                                Electronic Commerce, etc.)</li>
                            <li>2) Records on payment and supply of goods: 5 years (Act on Consumer Protection in Electronic
                                Commerce, etc.)</li>
                            <li>3) Records of consumer complaints or dispute handling: 3 years (Act on Consumer Protection
                                in Electronic Commerce, etc.)</li>
                            <li>4) User's log records, access point tracking data, and website visit records: 3 months (Act
                                on the Protection of Communications Secrets)</li>
                            <li>5) Records on electronic finance under the Electronic Financial Transactions Act: 5 years
                                (Electronic Documents and Electronic Transactions Basic Act)</li>
                        </ul>
                        </li>
                        <li>B. Retention period of personal information retained in accordance with the company's internal
                            policies<ul className="list-unstyled">
                                <li>1) When the consent of the user is obtained individually: Period of consent obtained</li>
                                <li>2) Information necessary for identification of unauthorized subscription and use of the
                                    service (ID, device information, service use logs and collected items, date of withdrawal,
                                    reason for withdrawal): Until the relevant dispute is resolved</li>
                                <li>3) In the event of a dispute such as a complaint or lawsuit between the company and the
                                    user, if the dispute is not resolved within the retention period: Until the time of
                                    resolution of the dispute</li>
                                <li>4) Personal information of withdrawn members: 3 months from the date of withdrawal</li>
                                <li>5) Personal information collected at call center and website A/S reception and consultation:
                                    5 years from the date of collection</li>
                                <li>6) Personal information collected when inquiring on the website: 3 years from the date of
                                    collection.</li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li className="pt-5"><strong>Destruction of Personal Information</strong><br />
                    <p className="pt-3">Personal information that has achieved the purpose of collecting and using personal
                        information, such as membership withdrawal, service termination, and the arrival of the personal
                        information retention period agreed to by the user, is destroyed in a way that cannot be
                        reproduced.<br />If Ray keeps it in accordance with laws and regulations, it will be transferred to a
                        separate database, used only for retention purposes unless provided by law, and will be disposed of
                        within the specified period.<br />In the case of electronic file types, it is safely deleted using
                        technical methods to prevent recovery and reproduction, and printouts are destroyed by shredding or
                        incineration.</p>
                </li>
            </ul>
            <h4 className="pt-5">6. Rights of users and legal representatives and how to exercise them</h4>
            <p>You have the following data protection rights:</p>
            <ul>
                <li>You may request to access, correct, update, suspend or delete your personal information. However, we may
                    suspend your request if there are special provisions in the law, if we need to comply with a legal
                    obligation, or if there is a risk of harm to another person or unreasonable infringement of the property or
                    interests of another person.</li>
                <li>You may object to the processing of your personal information and request that we restrict the processing of
                    your personal information.</li>
                <li>Where we have collected and processed your personal information with your consent, you may withdraw your
                    consent at any time.<br />The withdrawal of your consent will not affect the lawfulness of any processing
                    carried out prior to the withdrawal, nor will it affect the processing of your personal information carried
                    out on lawful processing grounds other than consent.<br />If you wish to exercise this right, you may request
                    to change, correct, or notify of your information by sending an email to support@rayteams.com or by writing
                    to "Ray, 221 Pangyo Yeok-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea".<br />We will respond to
                    your request as soon as possible.</li>
            </ul>
            <h4 className="pt-5">7. Ray’s Efforts to Protect Personal Information</h4>
            <p>Ray is committed to the secure management of your personal information and protects it beyond what is required by
                the Personal Data Protection Act.</p>
            <p>Ray takes administrative, technical, and physical measures to protect your personal information from unauthorized
                access, use, or disclosure by third parties. Ray has established and is implementing an internal management plan
                for data protection. The personal information you provide is protected from unauthorized access, use or
                disclosure and is stored securely on our servers.</p>
            <p>Additional technical measures include managing access rights to personal information processing systems,
                encrypting unique identifiers, installing security programs, and keeping and encrypting access records.</p>
            <ul>
                <li className="pt-5"><strong>Ray has established and implemented an internal management plan for personal
                    information protection.</strong>
                    <p className="pt-3">Ray establishes an internal management plan for personal information, including matters
                        related to the composition and operation of the personal information protection organization, such as
                        the appointment of a person in charge of personal information protection, and check whether the internal
                        management plan is well implemented every year.</p>
                </li>
                <li className=""><strong>Ray takes measures to control access and restrict access to personal information.</strong>
                    <p className="pt-3">In order to block illegal access to personal information, Ray has established and
                        implemented standards for granting, changing, and canceling access to the personal information
                        processing system, and has installed and operated an intrusion prevention system. In addition, Ray
                        minimizes the management of employees who process personal information and reduces the leakage of
                        personal information by separating the external Internet network from the internal network for the work
                        PCs of employees who can download personal information from the personal information processing system.
                    </p>
                </li>
                <li className=""><strong>Ray takes encryption measures to safely store and transmit personal information.</strong>
                    <p className="pt-3">In addition to passwords and unique identification information required by laws and
                        regulations, e-mails, addresses, and phone numbers are additionally encrypted and stored. In addition,
                        we safely transmit and receive personal information on the network through encrypted communication.</p>
                </li>
                <li className=""><strong>Ray takes measures to keep personal information access records and prevent falsification
                    and alteration.</strong></li>
            </ul>
            <h4 className="pt-5">8. International Transfer of Personal Information</h4>
            <p>Ray may transmit or manage the user's information overseas to provide services and improve the convenience of
                users. <br />This Privacy Policy applies to the transfer of personal information to other countries. The Company
                will obtain your consent in the event of an international transfer of personal information and will take
                appropriate protective measures to ensure that your personal information is protected.</p>
            <h4 className="pt-5">9. Cookie Policy</h4>
            <p>Ray wants to make your access to our Services as easy, efficient, and useful as possible. To do this, we use
                cookies and similar technologies to improve your user experience and enhance site security. </p>
            <p>Cookies are small text files that are automatically generated on your device when you visit almost any website.
                Cookies are stored by your internet br/owser and contain basic information about your internet usage. Each time
                you return to a website, your br/owser sends a cookie back to the website so that the website can recognize your
                device, provide you with personalized content, and improve your user experience. We also use cookies to store
                your login information so that you do not have to re-enter your login information over and over again when using
                the Services. We may use other cookies to help us understand your preferences.</p>
            <p>Ray only uses essential cookies. Essential cookies are required to access and navigate the Website and use all of
                its features. Without these cookies, the Website will not function properly and certain important features will
                not be available to you. For example, we use cookies to keep you logged in during your visit, so you do not have
                to log in repeatedly to access different pages on the Website. We may also use essential cookies to detect and
                prevent fraud. Strictly necessary cookies are required to access and use the features of the website and cannot
                be turned off through the cookie consent tool.</p>
            <h4 className="pt-5">10. Information on the person in charge of personal information protection and the department that
                receives/processes access requests</h4>
            <p>Ray designates a person in charge of personal information protection as follows to protect your personal
                information and handle complaints related to personal information. You may report all complaints related to
                personal information protection that arise while using Ray’s services to our Personal Information Protection
                Officer. The Privacy Officer will provide you with a prompt and sufficient response to your complaint.</p>
            <ul>
                <li><strong>Chief Privacy Officer</strong></li>
                <li className="pt-1">Name: Lee Min-kyu</li>
                <li>Affiliation &amp; Position: Software Development Division</li>
                <li>Job Title: Managing Director</li>
                <li>Email address: privacy@rayteams.com</li>
            </ul>
            <ul>
                <li><strong>Personal Information Protection Officer</strong></li>
                <li className="pt-1">Name: Bang Seongwon</li>
                <li>Department: Software Development Division</li>
                <li>Job Title: Professional</li>
                <li>Phone: +82-31-605-1000</li>
                <li>Email address: privacy@rayteams.com</li>
            </ul>
            <p>If you need to report or consult about other personal information infringements, you can contact the following
                organizations.<br />Personal Information Infringement Report Center (privacy.kisa.or.kr / 118 without area code)
                Cyber Investigation Division of the Supreme Prosecutors' Office (www.spo.go.kr / 1301 without area code)
                National Police Agency Cyber Safety Keeper (http://www.police.go.kr/www/security/cyber.jsp / 182 without area
                code)</p>
            <h4 className="pt-5">11. Obligation to notify before revision</h4>
            <p>If there are additions, deletions, or revisions to this Privacy Policy, we will notify you in advance through
                'Notice' at least 7 days before the revision.<br />However, if there is a significant change in the user's rights,
                such as a change in the items of personal information to be collected or the purpose of use, etc., the user will
                be notified at least 30 days in advance, and if necessary, the user's consent may be obtained again.</p>
            <p>Date of Announcement: August 10, 2022, <br />Date of Implement: August 10, 2022</p>
        </div>
    )
}

const BaaDpa = ({ showBaaDpa, hideBaaDpa, acceptBaaDpa, htmlContents, setHtmlContents, baadpaInfo }) => {
    const ref = useRef();
    const [isBottom, setIsBottom] = useState(false);

    const [t, i18n] = useTranslation(['translation']);

    useEffect(() => { 
        setHtmlContents({ ...htmlContents, baadpa: document.getElementsByClassName('agree-content')[0].innerHTML });        
    }, []);

    const onScroll = () => {
        if (ref.current) {
            const { scrollTop, scrollHeight, clientHeight } = ref.current;

            if (scrollTop + clientHeight + 10 >= scrollHeight)
                setIsBottom(true);
            else
                setIsBottom(false);   
        }
    };

    const agree = () => {
        if (isBottom) {
            acceptBaaDpa();
            hideBaaDpa();
        }
    }

    return (
        <>
            <div className="modal fade show" id="" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: showBaaDpa ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => hideBaaDpa()}></button>
                            <div className="alert alert-danger alert-icon mb-6" role="alert">
                                <i className="uil uil-exclamation-triangle"></i> {t('스크롤을 내려 문서를 끝까지 읽으셔야, 동의에 체크하실 수 있습니다.')}
                            </div>
                            <div className="abox" onScroll={onScroll} ref={ref}>       
                                <div className="agree-content">
                                    {baadpaInfo?.data[0]?.title && <h2 className="mb-4 text-center">{baadpaInfo?.data[0]?.title}</h2>}
                                    <div dangerouslySetInnerHTML={{ __html: baadpaInfo?.data?.length > 0 && baadpaInfo.data[0].contents }}></div>
                                </div>
                            </div>
                            <div className="mt-6">
                                <p className="text-center pb-0 mb-0">
                                    <a className={`btn btn-${isBottom ? 'primary' : 'gray'} btn-icon btn-icon-start rounded-pill btn-lg`} onClick={() => agree()}>
                                        <i className="uil uil-check"></i> {t('위의 내용을 확인하였고, 내용에 동의합니다.')}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show" style={{ display: showBaaDpa ? 'block' : 'none' }}></div>
        </>
    )
}

const mapState = (state) => {
    const baadpaInfo = state.AppReducer.baadpaInfo;
    const baadpaInfos = state.AppReducer. baadpaInfos;
	return { baadpaInfo, baadpaInfos };
};

const mapDispatch = dispatch => ({
    CreateGroup: (data) => dispatch(GroupAction.CreateWithoutAuth(data)),
	CreateUser: (data) => dispatch(UserAction.CreateWithoutAuth(data)),
    GetAppDocs: () => dispatch(AppAction.GetAppDocs()),
    GetAppDoc: (paramId) => dispatch(AppAction.GetAppDoc(paramId)),
})

export default connect(mapState, mapDispatch)(SignUpForm);
