
const GuidancePrompt = ({ children, hasLoading = false, title, subTitle }) => {

    return <>
        <div className="d-flex flex-column align-items-center">
            {hasLoading && <>
                <div>
                    <div className="loadingio-spinner-dual-ball-sw6sftzohqk" style={{
                        position: "initial",
                    }}>
                        <div className="ldio-rhxguy7x4r" style={{
                            position: "initial",
                        }}>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </>}
            {title && <>
                <div className="fs-22 fw-bolder text-center" style={{ color: "#343F52" }}>
                    {title}
                </div>
            </>}
            {subTitle && <div className="mt-2">
                {typeof subTitle !== "string" && <>{subTitle}</>}
                {typeof subTitle === "string" && <>
                    <div className="fs-16 fw-bolder text-center" style={{ color: "#60697B" }}>
                        {subTitle}
                    </div>
                </>}
            </div>}
            {children && <div className="mt-4">
                {children}
            </div>}
        </div>
    </>
};

export default GuidancePrompt;