import React, { useEffect, useState } from "react";
import axios from 'axios';
import { connect, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import Header from "./Header";
import { useTranslation } from 'react-i18next';
import { rayTeamsClientDownloadURL, getApiUrl } from '../lib/function'
import queryString from 'query-string';
import { AuthConstant, ProgramConstant, AlertConstant } from '../redux/reducer';
import { history } from "../history";
import { UserAction } from "../redux/action/UserAction";

const SSO = () => {
    const [t] = useTranslation(['translation']);
    const { search } = useLocation();
    const q = queryString.parse(search);
    const dispatch = useDispatch();

    useEffect(() => {        
        if(q?.token){
            console.log(q?.token);
            login(q?.token, q?.redirect_url);

            //console.log(q?.email);
            //login(q?.token, q?.email);
        }else{
            //history.push("/");
        }
    }, []);

    const fromBinary = (encoded) => {
        const binary = atob(encoded);
        const bytes = new Uint8Array(binary.length);
        for (let i = 0; i < bytes.length; i++) {
            bytes[i] = binary.charCodeAt(i);
        }
        return String.fromCharCode(...new Uint16Array(bytes.buffer));
    }


    const login = async (token, redirect_url = "") => {
        try{
            const u = JSON.parse(decodeURIComponent(escape(atob(token.split(".")[1]))));
            localStorage.setItem('user', JSON.stringify(u));
			localStorage.setItem('userToken', token);
            const user = await dispatch(UserAction.GetMe());
			dispatch({ type: AuthConstant.LOGIN_SUCCESS, user: user, userToken: token });
            if(redirect_url){
                history.push(redirect_url);
            }else{
                history.push("/");
            }
        }catch{
            history.push("/");
        }
        //const apiUrl = getApiUrl("user") + "/refreshtoken";;
        //return axios.post(apiUrl, {
        //    refreshtoken: token,
        //    email: email
        //}).then((response) => {
        //    const newToken = response.data.data?.AuthenticationResult || response.data.data?.data?.AuthenticationResult;
        //    console.log(newToken);
        //    localStorage.setItem('userToken', newToken.IdToken);

		//	localStorage.setItem('userToken', ret.data.data.jwt);
		//	localStorage.setItem('MYLAB_AUTH_REFRESHTOKEN', ret.data.data.token.RefreshToken);
		//	localStorage.setItem('RAYLINK_USER_EMAIL', udatajson.email);
		//	localStorage.setItem('AUTO_LOGIN', data.autoLogin ? "Y" : "");
        //})
    }

    return (
        <>
            <Header />
            <section className="h-100 d-flex align-items-center wrapper image-wrapper bg-overlay bg-image bg-white main-visual">
                <div className="container">
                    <div className="text-center">
                        <h3>{t("안전하게 이동중입니다.")}</h3>
                        <div>
                            <div>{t("작업이 끝나면 자동으로 화면이 이동됩니다.")}</div>
                            <div>{t("잠시만 기다려주세요.")}</div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SSO;
