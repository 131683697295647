export const APPPERM = [
	{ code: 'project_read', label: 'Read Cases'},
	{ code: 'project_modify', label: 'Modify Cases'},
	{ code: 'project_file_read', label:'Read Case Files'},
	{ code: 'project_file_modify', label: 'Modify Case Files'},
	{ code: 'auth_read', label: 'Read Login User'},
	{ code: 'ws_push', label: 'Push Chat or Alarm'},
	{ code: 'group_read', label: 'Read Institute'},
	{ code: 'group_modify', label: 'Modify Institute'}
];

export const APPCATEGORY = [
    { value: 'app', label: 'Application' },
    { value: 'converter', label: 'Converter' },
    { value: 'util', label: 'Utility' },
    { value: 'productivity', label: 'Productivity' },
    { value: 'education', label: 'Education' },
    { value: 'etc', label: 'Etc' },
	{ value: "service", label: "Service"},
	{ value: "inAppFeature", label: "In-App Feature"},
];

export const FEATUREDAPPS = [
	{ seq: 0, appCode: 'rayface' },
	{ seq: 1, appCode: 'RAYFusion' },
	{ seq: 2, appCode: 'OrthoSimulator' },	
	{ seq: 3, appCode: 'RAYDENTStudio' },	
	{ seq: 4, appCode: 'RayLogCenter' },
	{ seq: 5, appCode: 'ExocadConverter' },	
]
