import { useEffect } from 'react';
import { AppAction } from '../../redux/action';
import Header from '../Header';
import { connect } from "react-redux";
import { moveTo, payableSQA, getIsSubscribableKr } from '../../lib/function';
import { history } from '../../history';
import { useTranslation } from 'react-i18next';
import { APPCATEGORY } from '../../constant/appconst';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { BoardAction } from '../../redux/action/BoardAction';
import moment from 'moment';

const SupportContentAside = ({ appList, GetAppList, selected, callback }) => {
    const { t, i18n } = useTranslation(['translation']);
    const { bid, contentId } = useParams();
    const [ topMenu, setTopMenu] = useState({});
    const [ items, setItems] = useState([]);
    const [menus, setMenus] = useState([]);

    useEffect(() => {
        if(selected.menu){
            getItem()
        }
    }, [selected, i18n.language]);

    const getItem = async () => {
        const retItems = await BoardAction.GetSupportBoardAllSections(i18n.language);
        const top = retItems.find(x => x.sk === "mn:" + selected.menu.split(":")[1] + ":" + i18n.language);
        const retContents = await BoardAction.GetSupportBoardSectionItems(top?.sk?.replace(":" + i18n.language, ""), i18n.language);
        setItems(retContents);
        setTopMenu(top);
        setMenus(retItems.filter(x =>
            x.sk !== top.sk && 
            x.sk.replace(":" + i18n.language, "").indexOf(top.sk.replace(":" + i18n.language, "")) === 0
        ));
    }

    const tree = (a, b) => {
		const aParts = a.split(':');
		const bParts = b.split(':');
		for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
			if (aParts[i] !== bParts[i]) {
				return aParts[i].localeCompare(bParts[i]);
			}
		}
		return aParts.length - bParts.length;
	}

	const depth = (sk) => {
		if(sk.split(":").length === 2)
			return <></>
		const ret = [];
		for(let i = 0; i < sk.split(":").length - 4; i++){
			ret.push(<span className='ms-2'></span>)
		}
		return ret;
	}

    return <>
        <aside className="col-xl-4 doc-sidebar pb-10" style={{ paddingRight : "16px" }}>
            <div className="widget">
                <h6 className="widget-title fs-17 mb-2">{topMenu?.title}</h6>
                <hr />
                {menus.map(x => x.sk).sort(tree).map((x, idx) => <div key={idx}
                    className={'list-group-item cursor-pointer py-2 fs-16' + (selected.menu + ":" + i18n.language === x ? " fw-bolder text-primary" : " text-black")}
                    onClick={() => { callback && callback(menus.find(m => m.sk === x));  }}>
                    {depth(x)}{menus.find(m => m.sk === x).title}
                    <div className='list ms-2'>
                        {items.filter(item => item.menu + ":" + i18n.language === x).map((item, index) => <div 
                            style={{ cursor : "pointer" }}
                            onClick={() => history.push("/support/view/" + item._id)}
                            className={(selected._id === item._id ? "text-primary fw-bolder " : "text-black fw-normal ") + " my-2 fs-14"} key={index}>
                            {item.title}
                        </div>)}
                    </div>
                </div>)}
            </div>
            {/*<div class="card shadow-none bg-soft-yellow mt-5">
                <div class="card-body">
                    <p class="card-text text-center text-navy">Still have more questions? Please contact us.</p>
                    <div class="btn btn-yellow rounded-pill text-center w-100 shadow-none"><a href="/contact/#inquiry" class="text-white" target="_blank">1:1 Inquiry</a></div>
                </div>
            </div>*/}
        </aside>
        {/*<div class="quick-btn">
            <div class="row g-6">
                <div class="col-lg-4">
                    <div class="card shadow-none bg-soft-yellow">
                        <div class="card-body">
                            <p class="card-text text-center text-navy">Check out the latest additions and updates to RAYTeams.</p>
                            <div class="btn btn-yellow rounded-pill text-center w-100 shadow-none"><a href="/community/" class="text-white" target="_blank">What's new</a></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="card shadow-none bg-soft-yellow">
                        <div class="card-body">
                            <p class="card-text text-center text-navy">Learn how to use RAYTeams and get answers to your questions.</p>
                            <div class="btn btn-yellow rounded-pill text-center w-100 shadow-none"><a href="/help-center/" class="text-white" target="_blank">Help Center</a></div>
                        </div>
                    </div>
                    </div>
                    <div class="col-lg-4">
                    </div>
                </div>
            </div>
        </div>*/}
    </>
}

const mapState = (state) => {
    const appList = state.AppReducer.appList;
    return { appList };
}

const mapDispatch = dispatch => ({
    GetAppList: (lang) => dispatch(AppAction.GetAppList(lang)),
})

export default connect(mapState, mapDispatch)(SupportContentAside);
