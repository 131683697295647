import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const DISPLAY_PERIOD_TYPE = {
    WIDE: "WIDE",
    DAYS: "DAYS",
};
export const WIDE_UNIT = {
    YEAR: "year",
    MONTH: "month",
    DAY: "day",
};
// periodSet = {} || {days: 1, month: 0, year: 0, unit: 'd'}
const PeriodStr = ({ periodSet, type = DISPLAY_PERIOD_TYPE.WIDE, includesOne = false }) => {
    const [t] = useTranslation();
    const wideUnit = useMemo(() => {
        if (periodSet.year > 0) {
            return WIDE_UNIT.YEAR;
        } else if (periodSet.month > 0) {
            return WIDE_UNIT.MONTH;
        } else {
            return WIDE_UNIT.DAY;
        }
    }, [periodSet]);

    return <>
        {type === DISPLAY_PERIOD_TYPE.WIDE && <>
            {wideUnit === WIDE_UNIT.YEAR && <>
                {periodSet.year === 1 && <>
                    {t("{{period}}{{periodUnitYear}}", {
                        period: periodSet.year,
                        periodUnitYear: t("년"),
                    })}
                </>}
                {periodSet.year === 1 && <>
                    {t("{{period}}{{periodUnitYears}}", {
                        period: periodSet.year,
                        periodUnitYears: t("년"),
                    })}
                </>}
            </>}
            {wideUnit === WIDE_UNIT.MONTH && <>
                {includesOne && <>
                    {t("{{period}}{{periodUnitMonth}}", {
                        period: periodSet.month,
                        periodUnitMonth: t("개월"),
                    })}
                </>}
                {!includesOne && <>
                    {periodSet.month === 1 && <>
                        {t("월")}
                    </>}
                    {periodSet.month > 1 && <>
                        {t("{{period}}{{periodUnitMonths}}", {
                            period: periodSet.month,
                            periodUnitMonths: t("개월"),
                        })}
                    </>}
                </>}
            </>}
            {wideUnit === WIDE_UNIT.DAY && <>
                {includesOne && <>
                    {t("{{period}}{{periodUnitDay}}", {
                        period: periodSet.days,
                        periodUnitDay: t("일"),
                    })}
                </>}
                {!includesOne && <>
                    {periodSet.days === 1 && <>
                        {t("일")}
                    </>}
                    {periodSet.days > 1 && <>
                        {t("{{period}}{{periodUnitDays}}", {
                            period: periodSet.days,
                            periodUnitDays: t("일"),
                        })}
                    </>}
                </>}
            </>}
        </>}
    </>
};

export default PeriodStr;