import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import en from './lang/en';
import ko from './lang/ko';
import de from './lang/de';
import jp from './lang/jp';
import ru from './lang/ru';
import zhchs from './lang/zh-chs';
import zhcht from './lang/zh-cht';

const resources = {
    en: { translation: en },
    de: { translation: de },
    jp: { translation: jp },
    ru: { translation: ru },
    zhchs: { translation: zhchs },
    zhcht: { translation: zhcht },
    ko: { translation: ko },
};
i18n
    .use(detector)
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem('i18nextLng') || 'en',
        keySeparator: ".",
        interpolation: { escapeValue: false }
    });

export default i18n;