import axios from "axios"
import { AppConfig, JWT } from "../../config";
import { getEnv } from "../../lib/function";
import { DoDB } from "../../lib/ddb";
import i18n from "i18next";
import _ from "lodash";
import { AuthConstant, PaymentsConstant } from "../reducer";

// PAYPAL
const PayPalAccessToken = async () => {
    const response = await axios({
        url: `https://api-m.sandbox.paypal.com/v1/oauth2/token`,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        auth: {
            username: AppConfig.PAYPAL_CLIENT_ID,
            password: AppConfig.PAYPAL_SECRET
        },
        data: {
            'grant_type': 'client_credentials'
        }
    });

    return response.data?.access_token;
}

const PayPalCreateSubscription = async (plan_id, product_id, userInfo) => {

    const plan = await PayPalPlanDetail(plan_id);

    const product_info = {
        id: plan?.product_id,
        product_id,
        name: plan?.name,
        price: plan?.billing_cycles[0]?.fixed_price?.value,
        currency: plan?.billing_cycles[0]?.fixed_price?.currency_code,
    }

    const params = `&pg=paypal&product_info=${encodeURIComponent(JSON.stringify(product_info))}&user_info=${encodeURIComponent(JSON.stringify(userInfo))}`;

    const access_token = await PayPalAccessToken();

    const response = await axios({
        url: 'https://api-m.sandbox.paypal.com/v1/billing/subscriptions',
        method: 'post',
        headers: {
            'Authorization': 'Bearer ' + access_token,
            'Content-Type': 'application/json',
            'Prefer': 'return=representation'
        },
        data: {
            plan_id,
            // quantity: 1,
            subscriber: {
                email_address: userInfo.email
            },
            auto_renewal: true,
            application_context: {
                return_url: `${location.origin}/payments-result/?result=success${params}`,
                cancel_url: `${location.origin}/payments-result/?result=cancel${params}`,
            }
        }
    });

    return response;
}

const PayPalCancelSubscription = async (sub_id, product_id, reason = '', token) => {
    try {
        const access_token = await PayPalAccessToken();

        const response = await axios({
            url: `https://api-m.sandbox.paypal.com/v1/billing/subscriptions/${sub_id}/cancel`,
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json',
                'Prefer': 'return=representation'
            },
            data: {
                reason: reason || ''
            }
        });

        if (response.status === 204) {
            await axios({ url: `https://api-manager-license.raydevelop.com/products/unsubscribe/${product_id}`, headers: { token }, method: 'post' });
            return '';
        }
    } catch (err) {
        console.log('error', err);
        return err.response.data.message;
    }

}

const PayPalPlanDetail = async (plan_id) => {
    const access_token = await PayPalAccessToken();

    const response = await axios({
        url: `https://api-m.sandbox.paypal.com/v1/billing/plans/${plan_id}`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + access_token,
            'Content-Type': 'application/json',
            'Prefer': 'return=representation'
        }
    });

    return response?.data;
}

//#region STRIPE
const StripeCreateSubscription = async (customer_id, price_id, paymentMethodId, userInfo) => {
    let response;

    try {
        response = await axios({
            url: 'https://api.stripe.com/v1/subscriptions',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${AppConfig.STRIPE_SECRET_KEY}`
            },
            data: {
                customer: customer_id,
                items: [{ price: price_id }],
                paymentMethod: paymentMethodId,
                invoice_settings: {
                    default_payment_method: paymentMethodId
                }
            }
        });
    } catch (error) {
        response = error?.response.data;
    }

    if (response?.data.object === 'subscription' && response?.data.id) {
        const product_id = response?.data.plan.product;
        const info = await StripeSearchProductByProductId(product_id);

        const product_info = {
            id: product_id,
            name: info.name,
            price: response?.data.plan.amount / 100,
            currency: response?.data.plan.currency,
        }

        SubscriptionComplete('stripe', response?.data, product_info, userInfo);
    }
}

const StripeCancelSubscription = async (subscription_id) => {
    const response = await axios({
        url: `https://api.stripe.com/v1/subscriptions/${subscription_id}`,
        method: 'delete',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${AppConfig.STRIPE_SECRET_KEY}`
        }
    });

    return response?.data;
}

const StripeResumeSubscription = async (subscription_id) => {
    const response = await axios({
        url: `https://api.stripe.com/v1/subscriptions/${subscription_id}/resume`,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${AppConfig.STRIPE_SECRET_KEY}`
        },
        data: {
            id: subscription_id
        }
    });

    return response?.data;
}

const StripeCreateCustomer = async (name, email) => {
    const response = await axios({
        url: 'https://api.stripe.com/v1/customers',
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${AppConfig.STRIPE_SECRET_KEY}`
        },
        data: {
            name,
            email,
        }
    });

    return response?.data;
}

const StripeSearchCustomerByEmail = async (email) => {
    const response = await axios({
        url: 'https://api.stripe.com/v1/customers/search',
        method: 'get',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${AppConfig.STRIPE_SECRET_KEY}`
        },
        params: {
            query: `email:'${email}'`,
        }
    });

    return response?.data.data.length > 0 ? response?.data.data[0] : {};
}

const StripeSearchPriceByPriceId = async (price_id) => {
    const response = await axios({
        url: `https://api.stripe.com/v1/prices/${price_id}`,
        method: 'get',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${AppConfig.STRIPE_SECRET_KEY}`
        }
    });

    return response?.data ? response.data : {};
}

const StripeSearchProductByProductId = async (product_id) => {
    const response = await axios({
        url: `https://api.stripe.com/v1/products/${product_id}`,
        method: 'get',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${AppConfig.STRIPE_SECRET_KEY}`
        }
    });

    return response?.data ? response?.data : {};
}

const StripePaymentIntents = async (amount) => {
    const response = await axios.post('https://api.stripe.com/v1/payment_intents', {
        amount: amount,
        currency: 'usd',
        automatic_payment_methods: { enabled: true }
    }, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${AppConfig.STRIPE_SECRET_KEY}`
        }
    });

    return response;
}
//#endregion

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const CreateBillingSub = async (token, data) => {
    const response = await axios({
        url: `https://api-manager-license.${getEnv() === "production" ? "rayteams" : "raydevelop"}.com/products/createbillingsub`,
        method: 'post',
        data: { data },
        headers: {
            token,
            'x-ray-device': 'WEB'
        }
    });

    return response;
}

const TossPublishBillingKey = async (authKey, customerKey, product_id) => {
    const response = await axios({
        method: 'POST',
        url: 'https://api.tosspayments.com/v1/billing/authorizations/issue',
        headers: {
            'Authorization': `Basic ${btoa(AppConfig.TOSS_SECRET_KEY + ':')}`,
            'Content-Type': 'application/json',
        },
        data: {
            authKey,
            customerKey
        }
    });

    let result = {};

    if (response.status === 200)
        result = response.data;

    return result;
}

const TossPayments = async (billingKey, data, product_info, user_info) => {
    const response = await axios({
        method: 'POST',
        url: `https://api.tosspayments.com/v1/billing/${billingKey}`,
        headers: {
            'Authorization': `Basic ${btoa(AppConfig.TOSS_SECRET_KEY + ':')}`,
            'Content-Type': 'application/json',
        },
        data
    })

    if (response.data.status === "DONE") {
        await SubscriptionComplete('toss', billingKey, product_info, user_info);
    }
}


// FUNCTIONS
const SubscriptionComplete = async ({ pg, subscription_id, product_info, user_info, bid, cardNumber }) => {
    const withoutTrial = user_info.withoutTrial;
    const pi = await GetProductItem(product_info.product_id);

    // 전달된 유저 객체에서 필요한 값만 뽑음, 기본 user와는 자료구조가 다름 (추후 변경해야함)
    // 전달된 값 = client에서 만든 값 + 상품에 대한 커스덤 값 + 서버에서 가져온 유저 정보 - object | array 값들
    const user = _.pick(user_info, [
        "countryCode",
        "email",
        "uEmail",
        "groupId",
        "name",
        "region",
        "_id",
        "sk",
        "sub",
        "withoutTrial", // 결제 시 추가된 값
        "user_id", // 결제 시 추가된 값
    ]);

    const appitems = pi?.appitems;
    const subperiod = pi?.subperiod;

    let period;
    if (product_info.trialperiod && !withoutTrial) {
        period = parseInt(product_info.trialperiod);
    } else {
        if (subperiod?.toLowerCase().indexOf('y') > -1) {
            period = parseInt(subperiod.replace('y', '')) * 365;
        }
        if (subperiod?.toLowerCase().indexOf('m') > -1) {
            period = parseInt(subperiod.replace('m', '')) * 30;
        }
        if (subperiod?.toLowerCase().indexOf('d') > -1) {
            period = parseInt(subperiod.replace('d', ''));
        }
    }
    const lang = i18n.language;
    const data =
    {
        user: user,
        group: { _id: user_info.groupId, countryCode: user_info.countryCode },
        product: {
            _id: product_info.id,
            sk: product_info.sk,
            title: product_info.name || product_info.title,
            realprice: product_info.price || product_info.realprice,
            punit: product_info.currency || product_info.punit,
            appitems,
            licenseType: withoutTrial ? "normal" : "trial",
            license: true,
            count: 1,
            period,
        },
        options: {
            trial: withoutTrial ? false : true,
            withoutTrial,
            lang: lang,
        },
        payresult: {
            pg,
            success: true,
            realprice: withoutTrial ? (product_info.price || product_info.realprice) : 0,
            punit: product_info.currency || product_info.punit,
        },
        toUsers: [
            {
                ...user,
                notyMethods: ["alarm", "email"],
            }
        ]
    }
    if (subscription_id) {
        data.payresult.subid = subscription_id;
    }
    if (bid) {
        data.payresult.bid = bid;
        data.payresult.cardNumber = cardNumber;
    }
    if (product_info?.trialperiod) {
        data.product.trialperiod = product_info.trialperiod;
    }
    if (product_info?.trialperiodtype) {
        data.product.trialperiodtype = product_info.trialperiodtype;
    }
    if (product_info?.plan_id) {
        data.product.plan_id = product_info.plan_id;
    }
    console.log("PaymentsAction@SubscriptionComplete", data);

    const ret = await axios.post(`https://api-manager-license.${getEnv() === 'production' ? 'rayteams' : 'raydevelop'}.com/subscription/user`, data);
}

const GetUserInfo = async (_id) => {
    const list = await DoDB("query",  {
        TableName: 'rayteams-user',
        KeyConditionExpression: '#id=:id and #sk=:sk',
        ExpressionAttributeNames: { '#id': '_id', '#sk': 'sk' },
        ExpressionAttributeValues: { ':id': _id, ':sk': 'info' }
    })
    const data = {
        Items: list,
    };

    return data.Items.length ? data.Items[0] : {};
}

const GetUserInfoWithScope = async (scope) => {
    const list = await DoDB("query",  {
        TableName: 'rayteams-user',
        KeyConditionExpression: '#id=:id and #sk=:sk',
        ExpressionAttributeNames: { '#id': '_id', '#sk': 'sk' },
        ExpressionAttributeValues: { ':id': _id, ':sk': scope }
    })
    const data = {
        Items: list,
    };

    return data.Items.length ? data.Items[0] : {};
}

const GetAllUserPaymentsInfos = (_id) => async (dispatch) => {
    const list = await DoDB("query",  {
        TableName: 'rayteams-user',
        KeyConditionExpression: '#id = :id and begins_with(#sk, :sk)',
        ExpressionAttributeNames: { '#id': '_id', '#sk': 'sk' },
        ExpressionAttributeValues: { ':id': _id, ':sk': "payments:" }
    })

    let issuerList = [];
    if (list && list.length > 0) {
        issuerList = await DoDB("query",  {
            TableName: 'rayteams-paymentgateway',
            KeyConditionExpression: '#id = :id and begins_with(#sk, :sk)',
            ExpressionAttributeNames: { '#id': '_id', '#sk': 'sk' },
            ExpressionAttributeValues: { ':id': "toss", ':sk': "issuerCode:" }
        })
    }

    const lng = localStorage.getItem('i18nextLng') || 'eng';
    const data = {
        Items: list.map(x => {
            const issuerInfo = issuerList.find(f => f.sk === `issuerCode:${x.issuerCode}`);
            return {
                ...x,
                issuer: issuerInfo?.korName,
                bgColor: issuerInfo?.bgColor
            }
        }),
    };

    if(data && data.Items.length > 0) {
        dispatch({ type: PaymentsConstant.PAY_INFOS_GETALL, items: data.Items });
    } else {
        dispatch({ type: PaymentsConstant.PAY_INFOS_GETALL, items: [] });
    }
};

const GetUserPaymentsInfo = (_id, payments) => async (dispatch) => {
    const list = await DoDB("query",  {
        TableName: 'rayteams-user',
        KeyConditionExpression: '#id = :id and #sk = :sk',
        ExpressionAttributeNames: { '#id': '_id', '#sk': 'sk' },
        ExpressionAttributeValues: { ':id': _id, ':sk': payments }
    })

    let issuerList = [];
    if (list && list.length > 0) {
        issuerList = await DoDB("query",  {
            TableName: 'rayteams-paymentgateway',
            KeyConditionExpression: '#id = :id and begins_with(#sk, :sk)',
            ExpressionAttributeNames: { '#id': '_id', '#sk': 'sk' },
            ExpressionAttributeValues: { ':id': "toss", ':sk': "issuerCode:" }
        });
    }

    const lng = localStorage.getItem('i18nextLng') || 'eng';
    const data = {
        Items: list.map(x => {
            const issuerInfo = issuerList.find(f => f.sk === `issuerCode:${x.issuerCode}`);
            return {
                ...x,
                issuer: issuerInfo?.korName,
                bgColor: issuerInfo?.bgColor
            }
        }),
    };

    if(data && data.Items.length > 0) {
        dispatch({ type: PaymentsConstant.PAY_INFO_GET, item: data.Items[0] });
    } else {
        dispatch({ type: PaymentsConstant.PAY_INFO_GET, item: {} });
    }
};

const UpdateUserPaymentsInfo = (paymentsInfo) => async (dispatch) => {
    const paymentsInfoParams = {
        TableName: "rayteams-user",
        Key: {
            _id: paymentsInfo?._id,
            sk: paymentsInfo?.sk
        },
        UpdateExpression: "SET #isDeleted = :isDeleted, #updated = :updated",
        ExpressionAttributeNames: {
            "#isDeleted": "isDeleted",
            "#updated": "updated",
        },
        ExpressionAttributeValues: {
            ":isDeleted": true,
            ":updated": new Date().getTime()
        }
    };
    const updatePaymentsInfo = await DoDB("update",paymentsInfoParams);
    if (updatePaymentsInfo) {
        let updateInfo = {
            ...paymentsInfo,
            isDeleted: true
        }
        console.log("PaymentsConstant.UPDATE_PAY_INFO : ======", updateInfo); 
        dispatch({ type: PaymentsConstant.UPDATE_PAY_INFO, item: updateInfo })
    }
};

const ChangeUserPaymentsInfo = (paymentsInfo) => async (dispatch) => {
    const paymentsInfoParams = {
        TableName: "rayteams-user",
        Key: {
            _id: paymentsInfo?._id,
            sk: "info"
        },
        UpdateExpression: "SET #payments = :payments, #updater = :updater, #updated = :updated",
        ExpressionAttributeNames: {
            "#payments": "payments",
            "#updated": "updated",
            "#updater": "updater",
        },
        ExpressionAttributeValues: {
            ":payments": paymentsInfo?.sk,
            ":updated": new Date().getTime(),
            ":updater": paymentsInfo?._id
        }
    };
    const updatePaymentsInfo = await DoDB("update",paymentsInfoParams);
    if (updatePaymentsInfo) {
        let updateInfo = {
            ...paymentsInfo,
        }
        console.log("PaymentsConstant.UPDATE_PAY_INFO : ======", updateInfo);
        var changedUserData = JSON.parse(localStorage.getItem("user"));
        changedUserData.payments = paymentsInfo?.sk
        localStorage.setItem('user', JSON.stringify(changedUserData));
        dispatch({ type: PaymentsConstant.UPDATE_PAY_INFO, item: updateInfo });
        dispatch({ type: AuthConstant.SET_USER, item: changedUserData });
    }
}

const GetAllPaymentsIssuers = (pgCode) => async (dispatch) => {
    const list = await DoDB("query",  {
        TableName: 'rayteams-paymentgateway',
        KeyConditionExpression: '#id = :id and begins_with(#sk, :sk)',
        ExpressionAttributeNames: { '#id': '_id', '#sk': 'sk' },
        ExpressionAttributeValues: { ':id': pgCode, ':sk': "issuerCode:" }
    })
    const data = {
        Items: list,
    };

    if(data && data.Items.length > 0) {
        dispatch({ type: PaymentsConstant.PAY_ISSUER_INFO_GETALL, items: data.Items });
    } else {
        dispatch({ type: PaymentsConstant.PAY_ISSUER_INFO_GETALL, items: [] });
    }
};

const GetProductItem = async (product_id) => {
    const ret = await axios.get(`https://api-manager-license.${getEnv() === 'production' ? 'rayteams' : 'raydevelop'}.com/license/getproductitem/${product_id}`);

    if (ret.data?.status === 'success')
        return ret.data?.data[0];
    else
        return {}
}

const CancelPGSubscription = async ({ subId, bId, productId, reason = '', reasons, token }) => {
    try {
        const url = `https://api-manager-license.${getEnv() === 'production' ? 'rayteams' : 'raydevelop'}.com/subscription/product/cancel`;
        const axiosConfig = { headers: { token } };
        const formData = { data: { productId: productId, subId: subId, bId, reason, reasons } };
        const ret = await axios.post(url, formData, axiosConfig);
        if (ret.data?.status === "success") {
            return ret.data.data;
        }
        return false
    } catch (error) {
        console.log('ERROR[CancelPGSubscription]', error?.response?.data || error?.toString());
        return false;
    }
};

const CreateTossBillingKey = async (data) => {
    try {
        const url = `https://api-manager-license.${getEnv() === 'production' ? 'rayteams' : 'raydevelop'}.com/payment/toss/billingauth`;
        const token = window?.localStorage?.getItem("userToken");
        const axiosConfig = { headers: { token } };
        const formData = { data: {
            authKey: data.authKey,
            product_sk: data.sk?.replace("price:", ""),
            withoutTrial: data.withoutTrial,
            productInfo: data.productInfo,
            alreadyPaymentsInfo: data?.alreadyPaymentsInfo
        } };
        const ret = await axios.post(url, formData, axiosConfig);
        if (ret.data?.status === "success") {
            return ret.data.data;
        }
        return false
    } catch (error) {
        console.log('ERROR[CreateTossBillingKey]', error?.response?.data || error?.toString());
        return false;
    }
};

const ChangeTossBillingKey = async (data) => {
    try {
        const url = `https://api-manager-license.${getEnv() === 'production' ? 'rayteams' : 'raydevelop'}.com/payment/toss/billingauth`;
        const token = window?.localStorage?.getItem("userToken");
        const axiosConfig = { headers: { token } };
        const formData = { data: {
            authKey: data.authKey,
            payInfoChange: true
        } };
        const ret = await axios.post(url, formData, axiosConfig);
        if (ret.data?.status === "success") {
            return ret.data.data;
        }
        return false
    } catch (error) {
        console.log('ERROR[ChangeTossBillingKey]', error?.response?.data || error?.toString());
        return false;
    }
};

const RequestRefundSubscription = async (data, token) => {
    try {
        const url = `https://api-manager-license.${getEnv() === 'production' ? 'rayteams' : 'raydevelop'}.com/subscription/refundrequest`;
        const axiosConfig = { headers: { token } };
        const ret = await axios.post(url, data, axiosConfig);
        if (ret.data?.status === "success") {
            return ret.data.data;
        }
        return false
    } catch (error) {
        console.log('ERROR[RequestRefundSubscription]', error?.response?.data || error?.toString());
        return false;
    }
};

const RevokeLicense = async (productId, userId, token) => {
    try {
        const url = `https://api-manager-license.${getEnv() === 'production' ? 'rayteams' : 'raydevelop'}.com/license/${productId}/revokelicense`;
        const axiosConfig = { headers: { token } };
        const ret = await axios.post(url, {
            data : { userId : userId }
        } , axiosConfig);
        if (ret.data?.status === "success") {
            return ret.data.data;
        }
        return false
    } catch (error) {
        console.log('ERROR[RequestRefundSubscription]', error?.response?.data || error?.toString());
        return false;
    }
};

const GetProductFeedback = async (fbId) => {
    try {
        const url = `https://api-manager-license.${getEnv() === 'production' ? 'rayteams' : 'raydevelop'}.com/feedback/getitems`;
        const ret = await axios.post(url, {
            data : { _id : fbId }
        });
        if (ret.data?.status === "success") {
            return ret.data.data;
        }
        return false
    } catch (error) {
        console.log('ERROR[RequestRefundSubscription]', error?.response?.data || error?.toString());
        return false;
    }
};

const VoteProductFeedback = async (fbId, items) => {
    try {
        const url = `https://api-manager-license.${getEnv() === 'production' ? 'rayteams' : 'raydevelop'}.com/feedback/voteitems`;
        const ret = await axios.post(url, {
            data : { _id : fbId, sks : items }
        });
        if (ret.data?.status === "success") {
            return ret.data.data;
        }
        return false
    } catch (error) {
        console.log('ERROR[RequestRefundSubscription]', error?.response?.data || error?.toString());
        return false;
    }
};

export const PaymentsAction = {
    PayPalAccessToken,
    PayPalCreateSubscription,
    PayPalCancelSubscription,
    PayPalPlanDetail,
    StripeCreateCustomer,
    StripeSearchCustomerByEmail,
    StripeCreateSubscription,
    StripeCancelSubscription,
    StripeResumeSubscription,
    StripeSearchPriceByPriceId,
    StripeSearchProductByProductId,
    StripePaymentIntents,
    GetUserInfo,
    SubscriptionComplete,
    CreateBillingSub,
    TossPublishBillingKey,
    TossPayments,
    GetProductItem,
    CreateTossBillingKey,
    CancelPGSubscription,
    RequestRefundSubscription,
    RevokeLicense,
    GetProductFeedback,
    VoteProductFeedback,
    GetUserInfoWithScope,
    GetAllUserPaymentsInfos,
    GetUserPaymentsInfo,
    GetAllPaymentsIssuers,
    ChangeTossBillingKey,
    UpdateUserPaymentsInfo,
    ChangeUserPaymentsInfo
}
