import { useEffect } from 'react';
import { AppAction } from '../../redux/action';
import Header from '../Header';
import { connect } from "react-redux";
import { moveTo, payableSQA, getIsSubscribableKr } from '../../lib/function';
import { history } from '../../history';
import { useTranslation } from 'react-i18next';
import { APPCATEGORY } from '../../constant/appconst';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { BoardAction } from '../../redux/action/BoardAction';
import moment from 'moment';
import SupportContentAside from './SupportContentAside';

const SupportContentView = ({ appList, GetAppList }) => {
    const { t, i18n } = useTranslation(['translation']);
    const { contentId } = useParams();
    const [ sec, setSec] = useState({});
    const [ subsec, setSubSec] = useState([]);
    const [ items, setItems] = useState([]);
    const [ item, setItem] = useState({});

    useEffect(() => {
        getData()
    }, [item.menu, i18n.language]);

    useEffect(() => {
        if(contentId){
            getItem()
        }
    }, [contentId, i18n.language]);

    const getItem = async () => {
        const ret = await BoardAction.GetSupportBoardContents(contentId, i18n.language);
        if(ret._id && ret.sk){
            console.log(ret._id, ret.sk);
            BoardAction.UpdateReadCount(ret._id, ret.sk);
        }
        setItem(ret);
    }

    const getData = async () => {
        console.log(item.menu);
        const ret = await BoardAction.GetSupportBoardSection(item.menu, i18n.language);
        const retItems = await BoardAction.GetSupportBoardSectionItems(item.menu, i18n.language);
        setSubSec(ret.filter(x => x.sk !== item.menu + ":" + i18n.language));
        setItems(retItems);
        setSec(ret.find(x => x.sk === item.menu + ":" + i18n.language));
    }

    return (
        <>
            <Header />
            <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-300 text-white help-center" style={{ backgroundImage: "url('../../../assets/img/bg1.jpg')"}} >
                <div className="container pt-14 pt-md-15 pb-4 pb-md-3">
                    <div className="row gx-lg-9">
                        <div className="col-lg-9">
                            <div className="app-avatar">
                                <img className="avatar" src="../../../assets/img/app-icon/img_rayteams.png" alt="rayteams" />
                            </div>
                            <div className="app-title">
                                <h2 className="fs-15 text-opacity60">RAYTeams Cloud</h2>
                                <h3 className="display-5 mf-300 b-0 text-white mb-1">{t("Help Center")}</h3>
                                </div>
                        </div>
                        <div className="col-lg-3">
                            {/*<div className="search-form mt-2 mt-md-1 sch-box">
                                <input id="" type="text" className="form-control sch-box" placeholder="Search..." />
                                <label for="">Search...</label>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </section>
            <div className="container manual">
                <p className="my-2">
                    <a href="#" onClick={() => history.push("/support") } className="text-purple hover"><i className="uil uil-arrow-left"></i><span className="ms-1">Help Center Home</span></a>
                </p>
                <div className="row">
                    <SupportContentAside selected={item}/>
                    <div className="col-xl-8 order-2 pb-10">
                        <section className="wrapper">
                            <h2 className="">{item.title}</h2>
                            <div className="applies-to-text-container mb-5">
                                <span className="publish-date-label me-2">{t("등록일")}</span>
                                <span className="publish-date">{moment(item.updated).format("LLL")}</span>
                            </div>
                            <div className="board-content">
                                <div className="card-body">
                                    <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                </div>
                            </div>
                        </section>
                        <hr />
                    </div>
                </div>
            </div>
            {/*<section className="wrapper">
                <div className="container pb-11 pt-11 pt-md-14">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                            <p className="text-center"><img src="/assets/img/logo-light.png" className="rounded-2 w-10" alt="RAYTeams" /></p>
                            <h2 className="display-6 mb-6 text-center">All services to dental, RAYTeams.</h2>
                            <p className="text-center mb-6 text-secondary">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                            <p className="text-center"><a className="btn btn-md btn-primary rounded-pill text-white text-decoration-none mb-1" onClick={(e) => moveTo(history, e, "/aboutcloud")}>RAYTeams Download</a> </p>
                        </div>
                    </div>
                </div>
            </section>*/}
        </>
    )
}

const mapState = (state) => {
    const appList = state.AppReducer.appList;
    return { appList };
}

const mapDispatch = dispatch => ({
    GetAppList: (lang) => dispatch(AppAction.GetAppList(lang)),
})

export default connect(mapState, mapDispatch)(SupportContentView);
