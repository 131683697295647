const TermOfUseEN = () => {

    return (
        <section className="wrapper bg-gray">
            <div className="container pt-6 pt-md-12 pb-14">
                <div className="row gx-0">
                    <div className="col-xl-12">
                        <section className="wrapper policy">
                            <h1 className="display-3 mb-6">Terms and Conditions</h1>
                            <div className="bg-light p-10 pt-5 rounded">
<div className="">
	<h3>1. Purpose</h3>
	This “Terms and Conditions” (hereinafter referred to as the “Agreement”) apply to the rights, obligations and responsibilities of the Users and the service platform called “RAYTeams” (hereinafter referred to as the “Platform”) operated by Ray Co., Ltd. (hereinafter referred to as the “Company”) when using the service (hereinafter referred as the “Service”) provided free of charge. This binding agreement between the User and the Company governs the User's use of the Platform and the Service.  <br/><br/>
	PLEASE READ AND UNDERSTAND THE FOLLOWING TERMS AND CONDITIONS, AND YOU EXPRESSLY AGREE THAT THESE TERMS AND CONDITIONS APPLY TO YOU IF YOU ACCESS OR USE THE SERVICES BY CLICKING “AGREE” WITH A CLEAR UNDERSTANDING OF THE DETAILED TERMS AND CONDITIONS <br/><br/>
	As a platform service provider, the Company allows you to access to your work contents (for example, your name and affiliated institution name, address, occupation, contact information, email, password, scan data, and additional information such as memos and chats delivered to the other party to fulfill the contract between Users when using the Service) to store and use only in accordance with the terms and conditions set forth in this Agreement).
	<h3 className="pt-5">2. Definition </h3>
	Definitions of terms used in this Agreement are as follows.<br/><br/>
	<ul>
		<li>1.	“Service Platform” refers to a virtual business place (https://www.rayteams.com) including websites, mobile web, apps that the Company operates to provide the Users with the necessary services for the process of signing and implementation of the partnership based on the consent of the two parties. operated by the Company, mobile web, and apps that is set up by the Company so that transactions can be made using information and communication facilities such as a computer in order to provide Users with services necessary for the contract signing process and implementation through the process of requesting and accepting partnership based on mutual agreement on the terms of service between the two parties to enter into a partnership.  </li>
		<li>2. “Service” refers to providing an optimized workflow to help the communication between dental clinics and laboratories efficiently.</li>
		<li>3.	“Content” refers to various of data generated during communication between Users, including posts. Content means all information in the type of scanned images, X-ray images, analysis and planning data, document texts, graphics, photos, messages and other similar types that are generated or encountered while using the Service.</li>
		<li>4.	“User” or “Users” refer to a person who has registered as a user through membership registration on the Platform, and who can continue to use the Services provided by the Platform after signing the terms of service with the Company in accordance with this Agreement.</li>
		<li>5.	“ID” means the registered e-mail address set by the User and approved by the Company for the User’s identification and service use.</li>
		<li>6.	“Password” means a combination of letters and numbers, etc., set by the User and registered on the Platform in order to confirm the identity of the User and to protect the rights and interests and confidentiality of the member.  </li>
	</ul>
	The meaning of terms not defined in Article 2 follows related laws and general business practices.
	<h3 className="pt-5">3. Explanation and Revisions of Terms and Conditions </h3>
	<ul>
		<li>1.	The Company shall be responsible to post at the bottom of the main page of the Platform so that Users can easily know the contents of this Agreement, name of the Company and representative, business location address (including the address where member complaints can be handled), phone number, e-mail address, business registration number, mail-order business report number, privacy officer. However, the contents of this Agreement may be viewed by the User through the linked page. Accepting these Terms means that you agree to periodically visit the Company's operated Internet site to confirm any changes to the terms and conditions.</li>
		<li>2.	When a reasonable cause occurs, the Company may revise this Agreement to the extent that it does not violate relevant laws such as the Act on Consumer Protection in Electronic Commerce, the Act on the Regulation of Terms of Service, the Framework Act on Electronic Documents and Electronic Transactions, the Electronic Financial Transactions Act, the Electronic Signature Act, and the Act on Promotion of Information and Communications Network Utilization and Information Protection, the Consumer Basic Act. Accepting these Terms and Conditions means that you agree to periodically visit the Company’s operated website to check for changes to this Agreement. </li>
		<li>3.	When the Company revises this Agreement, it shall be notified from 7 days before the effective date of the revised terms and conditions to the day before the effective date by specifying the effective date and the reason for the revision with the current Agreement. However, in the case of a change that has a significant impact on the rights and obligations of Users, notice shall be given with a grace period of at least 30 days prior to the effective date. In this case, the Company clearly explains the changed terms and conditions by displaying it in an easy-to-understand manner for Users.</li>
		<li>4.	The terms and conditions changed in accordance with the preceding paragraph shall not be applied retroactively before the effective date, unless there are special provisions or other unavoidable reasons in the relevant laws and/or regulations.</li>
		<li>5.	If a User does not specifically express his/her intention to refuse after the effective date announced in accordance with Paragraph 3 of this section, he/she is deemed to agree to the revised terms and conditions, and Users who do not agree to the amended terms and conditions may request membership withdrawal. Despite the measures set forth in Paragraph 3, the Company is not responsible for any damage to Users caused by not knowing the fact of the amendment of the terms and conditions.</li>
		<li>6.	The Company may have separate terms and conditions for individual services within the Services it provides, and consent to the terms and conditions applied separately for individual services shall go through a separate consent process when a User uses individual services. In this case, the terms and conditions for individual services take precedence over this Agreement.</li>
		<li>7.	In the event that the provisions of this Agreement conflict with the compulsory laws of the Republic of Korea, the compulsory laws shall govern, and in this case, the effect of other provisions shall not be affected.</li>

	</ul>
	<h3 className="pt-5">4. Availability of Service </h3>
	<ul>
		<li>1.	The Service is only allowed to be used by Dentists and Dental technicians. However, if you perform work under the direction and supervision of a dentist or dental technician, a service ID is provided to you at the request of the dentist or the dental technician, and your use of the Service is permitted under the responsibility of the dentist or the dental technician. The Company does not knowingly collect, store, and use personal information of minors under the age of 18 or under the age in accordance with applicable regulations and laws of the country concerned.</li>
		<li>2.	For the lawful and legitimate use of the Service, Users agree and warrant that he/she is not a person prohibited from using the Service under the laws of Korea or other applicable jurisdictions, including the country where he/she resides or the country where he/she uses the Service.</li>
		<li>3.	Stable Internet access, compatible devices and software, and periodic updates of the software may be required for smooth use of the Service.</li>
		<li>4.	The Service or some of it may not be available depending on the country or region. A User warrants that he/she shall use the Services at your own discretion to the extent permitted by this Agreement and shall comply with the applicable laws.</li>
	</ul>
	<h3 className="pt-5">5. Change of Service</h3>
	<ul>
		<li>1.	The Company provides an effective workflow free of charge to enable efficient communication between Dental clinics and Dental laboratories.</li>
		<li>2.	If there is a change in the composition and content of the Service, the Company shall notify Users of the reason and the change on the Platform screen from 7 days before the effective date to the day before the effective date.</li>
		<li>3.	The Company may modify the Service, if necessary, under the condition that the Company notifies the Users of the reason and details of the change on the Platform screen from 30 days before the date of application to the day before the effective date of any significant change in the Service and the Company reserves the right to set new or additional conditions. However, Users acknowledge and  agree that it is not be unreasonable to change the Services due to circumstances caused by laws, regulations, or government activities, to address Users’ privacy or technical issues; to avoid services that interfere with other Users; and/or to protect against natural disasters, catastrophes, wars or similar circumstances.</li>
	</ul>
	<h3 className="pt-5">6. Suspension of Service</h3>
	<ul>
		<li>1.	The Company may inevitably suspend all or part of the Service if there are significant reasons for operation, such as natural disasters or force majeure, maintenance, replacement or failure of information and communication facilities such as computers and servers, or communication interruption. </li>
		<li>2.	The Company may modify or terminate all or part of the Service if there is a significant need for service operation or improvement. If all or part of the free Service is modified or terminated, no any compensation shall be made unless there are special provisions in the relevant laws or regulations. </li>
		<li>3.	Only for paid Services, the Company compensates for damages suffered by Users due to the temporary suspension of the provision of Services due to the reasons of paragraphs 1 and 2. However, this is not the case if it is proved that there is no intention or gross negligence of the Company.</li>
		<li>4.	In the event that the Service can no longer be provided due to the change of business category, the kind or abandonment of business, Company’s closure, merger, division, transfer of business, the Company shall notify Users with a grace period of at least 30 days in advance. </li>
	</ul>
	<h3 className="pt-5">7. Registration</h3>
	<ul>
		<li>1.	The Service is only allowed to be used by Dentists and Dental technicians legitimate in the relevant jurisdictions</li>
		<li>2.	The Users shall create an account to use the Service. Those who wish to sign up for membership apply by filling in User information according to the registration form set by the Company and expressing their intention to agree to this Agreement. You agree that you will provide truthful and accurate information when registering to use the Service. </li>
		<li>3.	The Company accepts a registration for the person who applied for membership as a member as described in the preceding paragraph, unless he/she falls under any of the following subparagraphs. If the Company rejects or suspends the approval of the membership application, the Company shall notify him/her of the related grounds in accordance with Article 9:<br/><br/>
			<ul>
				<li>A.	if the Company previously cancelled your account as a result of you being in br/each of these terms and conditions;</li>
				<li>B.	If one month has not passed since the member's request for withdrawal;</li>
				<li>C.	If there is a false entry, omission or error in the user information entered by the applicant for membership; </li>
				<li>D.	If the Company cannot verify the identity or it is confirmed that the person is not the person when the Company verifies the identity; </li>
				<li>E.	If the applicant is under the age of 18 or a minor under the laws of the relevant country; and/or
				</li><li>F.	If it is determined that it is impossible for the applicant to register as a member due to the technical issues of the Platform, or that it significantly interferes with the operation, maintenance, or management of the Platform.</li><br/>
			</ul>
		</li>
		<li>4.	The membership contract shall be established when the application for membership is completed with the approval of the Company. </li>
		<li>5.	If there is a change in member information, the User shall update the change without delay by modifying User information. The Company is not responsible for any damage to Users arising from not modifying User information. </li>
		<li>6.	 A User is solely responsible for maintaining the confidentiality of the User’s password and account information such as ID, and the User is solely responsible for all activities that occur under your password on or through your account. Under no circumstances shall the User provide or reveal his/her account information to anyone else. In case the User does not change the password timely even though he/she is aware of the leakage of his/her ID and password or the use by a third party, or if the User does not notify the Company of such circumstances or does not respond to the Company's actions, he/she is responsible for any loss or damage caused by non-compliance with the obligations of these terms and conditions.  </li>
		<li>7.	Users agree not to duplicate, copy, reproduce, sell, resell, rent or trade the Service or any part thereof for any purpose.</li>
		<li>8.	A User agrees to notify the Company without delay of any security br/each into his/her account, and his/her account and/or password shall not be shared with anyone else. The Company shall use reasonable technology and take due care, but shall not be liable for any damages resulting from the unauthorized use of the account due to the User's non-compliance with these terms and conditions.</li>
	</ul>
	<h3 className="pt-5">8. Membership Withdrawal and Cancellation</h3>
	<ul>
		<li>1.	 A User may request the Company to withdraw from membership at any time, and the Company shall process the User’s withdrawal without delay when the User requests for withdrawal. When a User cancel his/her membership, his/her membership qualifications are lost, and the various benefits provided by the Company are extinguished.</li>
		<li>2.	When the User withdraws from membership, the data stored by the User is immediately destroyed, so the User shall perform a self-backup of the data stored on the Platform before membership withdrawal.</li>
		<li>3.	Users agree to use the Service only for the purposes permitted under this Agreement, and agree to use the Service within the scope of applicable laws, regulations, or generally accepted practice in the applicable jurisdiction.</li>
		<li>4.	If  A User falls under any of the following reasons, the Company may limit or cancel the User’s membership, and if the User loses the membership, all benefits as the User shall be extinguished:<br/><br/>
			<ul>
				<li>A.	Violation of this Agreement or any other policies or guidelines referred to in this Agreement and/or posted on the Service;</li>
				<li>B.	Requests or orders from law enforcement agencies, other competent government agencies, or any judicial bodies;</li>
				<li>C.	If the action or attempt of providing the Service to the User is or may be illegal;</li>
				<li>D.	If the User enters false information in user information or steals another person's identify, such as if he/she is not a legitimate Dentist or Dental technician; </li>
				<li>E.	The User participation in illegal activities; </li>
				<li>F.	If it is threatening the rules of e-commerce, such as interfering with other people's use of the Platform or stealing the information;</li>
				<li>G.	Unexpected technical or security issues;</li>
				<li>H.	In the event of using the Platform to conduct an act prohibited by laws or this Agreement or contrary to public morals; and/or
				</li><li>I.	If the account has been dormant for 1 year.</li><br/>
			</ul>
		</li>
		<li>5.	If the same problematic action is repeated twice or more after the Company restricts the membership, or if the cause is not corrected within 10 days, the Company may cancel the membership.</li>
		<li>6.	In the event that the Company cancels membership, the Company notifies him/her in accordance with Article 9, and gives him/her an opportunity to explain by setting a period of at least 10 days before the membership cancellation. </li>
		<li>7.	As an effect of such withdrawal or cancellation as above, upon termination of the User account, the User may lose all access rights to the Service and parts thereof in addition to the account and contents. In addition, the Company shall delete the information and materials stored in or as part of the account after a certain period of time.</li>
	</ul>
	<h3 className="pt-5">9. Notification </h3>
	<ul>
		<li>1.	When the Company notifies  a User, it can be done by e-mail, text message, phone, fax or so according to the method specified by the User in advance with the Company.</li>
		<li>2.	In the case of a notice to an unspecified number of Users, the Company may substitute individual notice by posting it on the notice board (customer center) of the Platform for more than 7 days. However, in the case of changes that significantly affect the rights and obligations of Users, notice is given with a grace period of at least 30 days prior to the effective date.</li>
	</ul>
	<h3 className="pt-5">10. Obligations of the Company</h3>
	<ul>
		<li>1.	The Company does not engage in acts that are prohibited by this Agreement or that are contrary to applicable laws or public morals, and does its best to provide the Services continuously and stably as stipulated in this Agreement.</li>
		<li>2.	The Company shall take appropriate technical and organizational measures to protect Users' personal information (including special categories of personal data) so that Users can safely use the Service. The Company shall have the Privacy Policy posted to the website and comply with it. The Company does not disclose or distribute Users' personal information to third parties without their consent. However, this is not the case in accordance with legal procedures under the provisions of relevant laws, such as the legitimate request of competent judicial and law enforcement agencies. </li> 
		<li>3.	In order to provide stable Service, the Company shall repair or restore it without delay when there is a failure in the facility or network failure, unless there is an unavoidable reason,.</li>
		<li>4.	The Company does not send advertising e-mails for commercial purposes that Users do not agree to.</li>
		<li>5.	The Company shall promptly deal with complaints raised by Users if they are objectively justified. However, if prompt processing is difficult, the User shall be notified of the reason and processing schedule.</li>
		<li>6.	The Company has the right to take measures reasonably necessary to enforce and comply with this Agreement. Only in the following cases, a User expressly consent that the Company shall not be liable to the User and the Company determines that it is reasonably necessary and takes appropriate measures to access his/her account information and business contents, or to disclosure to the appropriate authorities to the extent specifically required or permissible under applicable laws and regulations upon a legitimate request from a regulatory and/or judicial authority: 
			<ul>
				<li>(1) when necessary for the purpose of compliance with applicable laws and regulations; (2) when reasonably necessary for the enforcement of this Agreement; (3) when necessary for the discovery and solution of technical and/or security problems; and/or (4) legally necessary to protect the rights and property of the Company, its Users and/or third parties.</li><br/>
			</ul>
	</li>

	</ul>
	<h3 className="pt-5">11. Obligations of Users</h3>
	<ul>
		<li>1.	Users shall comply with the provisions of this Agreement, the Company's guidelines for use, and related laws and regulations, and shall not engage in any acts that interfere with the Company's business.</li>
		<li>2.	Users shall not use the Services provided by the Company for purposes other than the permitted purpose of use, or engage in any of the following acts:<br/><br/>
			<ul>
				<li>A.	Making false statements as if he/she is a Dentist or Dental Technician when he/she is not a legitimate Dentist or Dental Technician;</li>
				<li>B.	Providing other people's information, or false information when registering personal information with the Company for the purpose of applying for membership or changing information;</li>
				<li>C.	Misconduct in transactions inconsistent with normal e-commerce practices, such as interfering with the Company's business activities or unfairly taking advantage of the Platform; </li>
				<li>D.	Using the Service in br/each of this Agreement or accessing the Information Processing System of the Company other than the method of using the Service provided by the Company; </li>
				<li>E.	Uploading, downloading, posting, e-mailing, transmitting, or storing contents that are illegal, infringe on other people's data privacy rights, or violate medical information-related laws;</li>
				<li>F.	Impersonating a person or group other than the User himself/herself; Users may not impersonate themselves as other persons, groups, other members, other employees, etc., or otherwise misrepresent the relationship of individuals or organizations;</li>
				<li>G.	Collecting or providing medical information without the consent of the patient or illegally;</li>
				<li>H.	Engaging in copyright or other intellectual property right infringement (including uploading content without the right to upload) or disclosing trade secrets or confidential information in violation of any confidentiality agreement, nondisclosure agreement or employment contract;</li>
				<li>I.	Uploading, posting, e-mailing, transmitting, storing or using other methods to provide any material that contains viruses or computer code, files or programs designed to interfere with, limit, or harm the normal operation of the Service (or any part thereof) or other computer software or hardware;</li>
				<li>J.	Doing or planning to do any illegal activity;</li>
				<li>K.	Collecting and storing personal information and medical information of other Service Users, or using it in connection with any of the prohibited acts listed in this Section11.2;</li>
				<li>L.	Interfering with the Company's business by making unrelated or unreasonable requests for Services provided by the Company, or acts against public morals; and/or</li>
				<li>M.	Other acts that infringe or threaten the rights of the Company or a third party or threaten the rules of e-commerce in accordance with the above items.</li><br/>
			</ul>
		</li>
		<li>3.	The Company may take measures such as membership cancellation, membership restriction , and civil and criminal legal measures against Users who violate this Section 11.2.</li>
		<li>4.	Users are responsible for all contents uploaded, downloaded, emailed, transmitted, stored or otherwise provided using the Service. Users understand and agree that the Company does not control the contents posted through the Service, nor does it guarantee the quality, integrity, or accuracy of the contents, and that Users take the full risk of using the Service and contents.</li>
		<li>5.	Users are solely responsible for all contents submitted or posted by themselves, guarantee that they do not infringe or impair the rights of other parties, including the intellectual property rights of third parties, or violate any law, and they do not commit any illegal acts. </li>
		<li>6.	Users clearly understand and agree that Users are responsible for backing up all data and business contents generated by generation, reception or transmission, etc. while using the Service to the User’s computer or other device under the User’s full responsibility in accordance with the applicable laws and regulations. The Company shall use reasonable care and skill in providing the Service, but does not guarantee that any contents stored or accessed by the Users through the Service  will not suffer from unintended damage or lost. </li>
		<li>7.	Users acknowledge and guarantee that they are Covered Entities and/or Business Associates as defined in the U.S. Health Insurance Portability and Accountability Act (HIPAA), and Users warrant that they do not illegally create, maintain, receive or transmit using the Company's Services to obtain Protected Health Information (PHI) specified in US Federal Code 45 C.F.R § 160.103.</li>
	</ul>
	<h3 className="pt-5">12. Privacy</h3>
	The Company establishes a Privacy Policy to protect User’s privacy, including User’s account information, in accordance with relevant laws and regulations, and designates a person in charge of personal information protection and publishes it. The privacy protection of Users is governed by the relevant laws and regulations and the Privacy Policy set by the Company.<br/><br/>
	<ul>
		<li>1.	The Company collects the minimum personal information necessary to provide Services in accordance with the principle of minimum collection when collecting personal information of Users.</li>
		<li>2.	When the Company collects and uses a User’s personal information, the Company notifies the User of the purpose and obtains consent.</li>
		<li>3.	The Company cannot use the collected personal information for any purposes other than the purpose, and even if a new purpose of use occurs or if it is provided to a third party, the Company shall notify the User in advance of the purpose and obtain consent. However, exceptions are made if there are other provisions in the relevant laws and regulations.</li>
		<li>4.	If the Company obtains the User’s consent pursuant to paragraphs 2 and 3 of this agreement, the Company shall specify or notify in advance the identity of the person in charge of personal information management (organization, name, and other contact information), the purpose of collection and use of information, and matters related to providing personal information to a third party (recipient, purpose of provision, and content of information to be provided), etc. stipulated in the Act on Promotion of Information and Communications Network Utilization and Information Protection and the Personal Information Protection Act, and the User can withdraw the consent at any time.</li>
		<li>5.	Users can request to view and correct errors in their personal information held by the Company at any time, and the Company is obliged to take necessary measures without delay. If a User requests correction of an error, the Company does not use the personal information until the error is corrected.</li>
		<li>6.	For the protection of personal information, the Company shall limit the persons handling the personal information of Users to a minimum, and take all responsibility for the damage of the User arising from loss, theft, leakage, providing personal information to a third party without consent, falsification, etc.</li>
		<li>7.	The Company or a third party receiving personal information from it destroys the personal information without delay when the purpose of collecting or receiving personal information has been achieved.</li>
		<li>8.	The Company does not pre-select the consent box for collection, use, and providing of personal information. In addition, when a User refuses to consent to the collection, use, and providing of personal information, the restricted service, if any, shall be specified in detail, and membership shall not be refused because of the User’s refusal to consent to the collection, use, and providing of personal information that is not a mandatory collection item.</li>
		<li>9.	The Company's Privacy Policy does not apply to linked sites other than the Platform. Regarding the handling of personal information by third parties that provide linked sites and products, Users are responsible for checking the privacy policy of the linked sites and the third parties, and the Company is not responsible for this.</li>
		<li>10.	The Company may provide User’s personal information to a third party within the scope permitted by the applicable law in the following cases:<br/><br/>
			<ul>
				<li>A.	When a request is made to provide information to appropriate authorities such as regulatory, investigative or judicial authorities to the extent specifically required or permissible under applicable laws and regulations;</li>
				<li>B.	When it is necessary to check for suspicious misconducts which violate relevant and applicable laws and regulations; and/or</li>
				<li>C.	When required by other relevant laws.</li><br/>
			</ul>
		</li>
		<li>11.	The Company securely encrypts, stores and transmits all data related to patient information including special categories of personal information, and complies with the Data Privacy Laws and Regulations such as the Personal Information Protection Act, Medical Act, HIPAA and GDPR Regulations.</li>
	</ul>
	<h3 className="pt-5">13. Restrictions on the Use of IP Rights </h3>
	<ul>
		<li>1.	Copyrights and other intellectual property rights for works created by the Company belong to the Company.</li>
		<li>2.	Except for cases granted under a separate service contract between the Company and a User, the Company grants  Users only the right to use the account, ID, and contents in accordance with the terms and conditions set by the Company in relation to the Service, Users shall not use the information or provide a third party with the information which belongs to the Company's intellectual property rights among the information obtained by using the Service for commercial purposes by copying, sending, transmitting, publishing, distributing, sending or other methods without the prior consent of the Company.
		The copyright of various posts written or generated by a User while using the Service provided by the Company belongs to the User, and the User is responsible for all civil and criminal liabilities including copyright infringement of the posts.</li>
		<li>3.	Except for the use of Services permitted in this Agreement, it is strictly prohibited to use all or part of the software or Services, or to use other people's works without separate permission from a legitimate owner. This is infringing on the intellectual property rights of others, and Users may be liable for civil and criminal liabilities such as compensation for damages due to copyright  and/or other IP related infringement.</li>
		<li>4.	RAYTeams, the Ray logo and other product and/or service names contained on this site are trademarks or registered trademarks of the Company. The Company reserves the right to do so, and other products and Company names may be trademarks of their respective owners.</li>
		<li>5.	The Company respects the rights of others, including intellectual property rights, and expects Users to do the same. If necessary, the Company may, at its discretion, take measures to restrict the use of Services without prior notice to Users who infringe or violate the rights of others.</li>
	</ul>
	<h3 className="pt-5">14. LIMITATION OF LIABILITY </h3>
	<ul>
		<li>1.	AS A PLATFORM SERVICE PROVIDER, THE COMPANY MEDIATES ALREADY PRODUCED INFORMATION “AS-IS”. SINCE THE CONTENT CREATED BY THE USER OR THE THIRD-PARTY DOMAIN SITE CONNECTED THROUGH THE SERVICE IS INFORMATION INDEPENDENTLY CREATED OR PRODUCED BY THE PROVIDER OF THE INFORMATION, THE PARTY WHO CREATED AND PRODUCED THE INFORMATION GUARANTEES AND ASSUMES RESPONSIBILITY FOR THE ACCURACY, RELIABILITY OR LEGALITY OF THE INFORMATION IN PRINCIPLE EVEN IF IT IS MEDIATED BY THE SERVICE.</li>
		<li>2.	EVEN IF THE INFORMATION NOT PREPARED OR PRODUCED BY THE COMPANY IS TRANSMITTED THROUGH THE SERVICE OF THE COMPANY, UNLESS THERE ARE SEPARATE REGULATIONS IN THE RELEVANT LAWS OR SPECIAL CIRCUMSTANCES IN WHICH THE COMPANY DOES NOT MEDIATE THE INFORMATION “AS-IS”, NO WARRANTY IS MADE AS TO ITS ACCURACY, RELIABILITY OR LEGALITY. IN ADDITION, THE COMPANY SHALL BE LIABLE FOR DAMAGES SUFFERED BY THE USER AS A RESULT OF TRUSTING IT ONLY IF IT IS SEPARATELY STIPULATED IN THE RELEVANT LAWS OR IF THERE IS AN INTENTION OR NEGLIGENCE OF THE COMPANY.</li>
		<li>3.	THE COMPANY IS EXEMPTED FROM LIABILITY IN THE CASE OF TRANSACTIONS BETWEEN USERS OR BETWEEN USERS AND THIRD PARTIES THROUGH THE SERVICE AS A MEDIUM.</li>
		<li>4.	THE COMPANY IS NOT RESPONSIBLE FOR THE USE OF SERVICES PROVIDED FREE OF CHARGE UNLESS THERE ARE SPECIAL PROVISIONS IN THE RELEVANT LAWS AND REGULATIONS.</li>
		<li>5.	IF THE PAID SERVICE IS UNABLE TO USE OR THERE IS AN OBSTACLE TO THE USE, THE COMPANY COMPENSATES THE PAID SERVICE USER FOR THE DAMAGE. HOWEVER, THE COMPANY IS NOT RESPONSIBLE FOR ANY DAMAGE CAUSED TO THE USER WITHOUT INTENTION OR NEGLIGENCE OF THE COMPANY. THE COMPANY IS NOT RESPONSIBLE FOR THE FACT THAT THE USER IS UNABLE TO USE OR IF IT IS RECOVERED WITHIN 72 HOURS AFTER RECEIVING THE FACT OF USE FAILURE TO THE COMPANY. </li>
		<li>6.	IF THE COMPANY RECEIVES VARIOUS OBJECTIONS, SUCH AS CLAIMS FOR DAMAGES OR LAWSUITS, FROM A THIRD PARTY OTHER THAN THE USER DUE TO AN ILLEGAL ACT COMMITTED BY THE USER IN USING THE SERVICE OR AN ACT IN VIOLATION OF THIS AGREEMENT, THE USER SHALL TAKE RESPONSIBILITY AND EXPENSE FOR INDEMNIFYING THE COMPANY AND IF THE COMPANY IS NOT EXEMPTED, THE USER IS RESPONSIBLE FOR COMPENSATING THE COMPANY FOR DAMAGE CAUSED BY IT.</li>
		<li>7.	IF THE LAWS OF SOME JURISDICTIONS DO NOT ALLOW OR EXPRESSLY PROHIBIT THE EXCLUSION OF CERTAIN WARRANTIES, THE DISCLAIMER BELOW MAY NOT APPLY. THE COMPANY SHALL USE A REASONABLE LEVEL OF TECHNOLOGY AND EXERCISE DUE CARE IN PROVIDING AND MAINTAINING THE SERVICES. <br/><br/>
			<ul>
				<li>A.	THE COMPANY DOES NOT GUARANTEE THAT THE SERVICE SHALL NOT BE INTERRUPTED OR THAT ERRORS SHALL NOT OCCUR, AND THE USERS AGREE ON THE CONDITION THAT THE COMPANY FULFILLS THE NOTICE OBLIGATION SET FORTH IN THIS AGREEMENT THAT THE COMPANY MAY CHANGE OR SUSPEND THE SERVICE FOR A CERTAIN PERIOD OF TIME IF REASONABLY NECESSARY OR MAY CANCEL THE SERVICE SUBJECT TO THIS AGREEMENT.</li>
				<li>B.	USERS CLEARLY UNDERSTAND AND AGREE THAT THE SERVICES ARE PROVIDED ON AN “AS-IS” AND “AS-AVAILABLE” BASIS.</li>
				<li>C.	THE COMPANY DOES NOT REPRESENT OR GUARANTEE THAT THE SERVICE IS SAFE FROM SECURITY THREATS SUCH AS VIRUS ATTACKS AND HACKING, AND DOES NOT BEAR ANY RESPONSIBILITY IN THIS REGARD. THEREFORE, THE USER AGREES THAT THE USER IS RESPONSIBLE FOR THE LOSS OF DATA OBTAINED BY UPLOADING OR DOWNLOADING THROUGH THE SERVICE.</li>
				<li>D.	THE COMPANY HAS NO OBLIGATION TO TAKE SECURITY MEASURES IN ACCORDANCE WITH EACH NATIONAL MEDICAL INFORMATION RELATED LAWS, AND IF HE USER UPLOADS, DOWNLOADS, POSTS, E-MAILS, TRANSMITS, STORES, OR USES OTHER METHODS OR PROVIDES MEDICAL INFORMATION THROUGH THE SERVICE, THE COMPANY ASSUMES NO RESPONSIBILITY IN THIS REGARD.</li>
				<li>E.	GDPR<br/>
				IN THE CASE OF HE USER WITHIN THE EEA, THE USER SHALL REVIEW THE DATA PRIVACY-RELATED REGULATIONS, APPROPRIATE LEVEL OF SECURITY, AND METHODS FOR DATA PROTECTION SUCH AS TECHNICAL, PHYSICAL, AND ADMINISTRATIVE SAFEGUARDS IN ORDER TO PERFORM LEGAL DUTIES. EXCEPT AS EXPRESSLY STIPULATED IN THE RELEVANT LAWS AND REGULATIONS, THE COMPANY DOES NOT BEAR ANY RESPONSIBILITY FOR THE USER’S VIOLATION OF GDPR REGULATIONS, AND THE USER AGREES TO FULFILL THE RESPONSIBILITIES AND OBLIGATIONS REQUIRED BY THE GDPR TO PROTECT THE PERSONAL INFORMATION SUBJECT'S INFORMATION WHILE USING THE SERVICE.<br/><br/>
				THE COMPANY HAS APPOINTED A PERSONAL INFORMATION PROTECTION OFFICER TO MANAGE INTERNAL INFORMATION PROTECTION ACTIVITIES AND CHECK COMPLIANCE WITH LAWS RELATED TO PERSONAL INFORMATION PROTECTION. IN ADDITION, TO COMPLY WITH THE GDPR, THE INFORMATION OF USERS IN EUROPE IS ENCRYPTED AND STORED IN EUROPE, AND TECHNICAL MEASURES ARE TAKEN TO STORE THE DATA.
				THE COMPANY STORES AND TRANSMITS ALL DATA RELATED TO PATIENT INFORMATION/SENSITIVE INFORMATION SECURELY ENCRYPTED, AND COMPLIES WITH THE MEDICAL ACT, HIPAA REGULATIONS AND GDPR REGULATIONS.<br/><br/>

				CONTACT PERSON: RAYTEAMS PRIVACY OFFICER <br/>
				CONTACT: <a href="mailto:privacy@rayteams.com">PRIVACY@RAYTEAMS.COM</a><br/><br/>

				USERS ARE OBLIGATED TO TAKE INDIVIDUAL MEASURES IMPOSED BY THE GDPR, SUCH AS THE PROCEDURE FOR COLLECTING PATIENT INFORMATION INCLUDING SPECIAL CATEGORIES OF PERSONAL DATA AND APPOINTMENT OF A DPO, AND AGREE THAT USERS ARE SOLELY RESPONSIBLE FOR THE VIOLATION OF GDPR OBLIGATIONS.</li><br/>
			</ul>
		</li>
	</ul>
	<h3 className="pt-5">15. Dispute Resolution</h3>
	<ul>
		<li>1.	The Company do its best to handle complaints submitted by Users. However, if prompt handling is difficult, the Users shall be notified of the reason and processing schedule immediately.</li>
		<li>2.	 Users clearly understands and agrees that in relation to the interpretation of this Agreement and any disputes arising from this Agreement between the Company and a User, excluding conflict of laws, the laws of the Republic of Korea are applied, and that the Suwon District Court located in Gyeonggi-do of the Republic of Korea has a personal and exclusive jurisdiction.
		If a User does not reside in the Republic of Korea, does not access the Service within the territory of the Republic of Korea, and belongs to a citizen/resident in the EU countries, Switzerland or the United States, the court of the User’s legal residence shall have a jurisdiction and its law shall apply.  </li>
	</ul>
	<h3 className="pt-5"> 16. Electronic Contracting </h3>
	The User’s acceptance of this Agreement and use of the Service confirms and assures his/her ability and intention to enter into a legally binding electronic agreement and to make transactions in an electronic manner. The User acknowledges and agrees that his/her electronic submissions shall be construed to be bound as a legally binding agreement. <br/><br/>

	These Terms and Conditions shall come into effect on August 19, 2022.
</div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TermOfUseEN;
