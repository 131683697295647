const TermOfUseKO = () => {

    return (
        <section className="wrapper bg-gray">
            <div className="container pt-6 pt-md-12 pb-14">
                <div className="row gx-0">
                    <div className="col-xl-12">
                        <section className="wrapper policy">
                            <h1 className="display-3 mb-6">이용약관 </h1>
                            <div className="bg-light p-10 pt-5 rounded">
                                <h3 className="pt-5">제1조(목적)</h3>
                                이 약관은 주식회사 레이(이하 “회사”)가 운영하는 RAYTeams(이하”플랫폼”)에서 무료로 제공하는 서비스(이하 “서비스”)를 이용함에 있어 플랫폼과 회원의 권리, 의무 및 책임사항, 서비스 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다. <br />
                                이 약관은 회원과 회사 사이에 체결된 계약으로 회원의 플랫폼 이용에 적용됩니다. 다음 계약조건을 읽고 숙지하시기 바라며, 귀하가 세부 계약 조건에 대하여 명확한 이해를 토대로 “동의”를 클릭함으로써 귀하가 서비스에 접속하거나 이를 이용하는 경우, 본 약관이 적용된다는 사실에 관하여 귀하는 명시적으로 동의하게 됩니다. <br />
                                회사는 플랫폼 서비스 제공자로서 본 약관에 기재된 계약조건들에 따라 귀하가 귀하의 업무 컨텐츠(예를 들어, 귀하의 이름 및 소속기관명, 주소, 직업, 연락처, 이메일, 비밀번호 및 환자의 스캔데이터, 해당 서비스 이용 시 회원 간 계약이행을 위해 상대방에게 전달되는 메모사항 및 채팅 등의 부가 정보)를 저장하고 이를 이용하도록 허용합니다.
                                <h3 className="pt-5">제2조(용어의 정의)</h3>
                                이 약관에서 사용하는 용어의 정의는 다음과 같습니다. <br /><br />
                                <ul className="list-unstyled">
                                    <li>1.	“서비스 플랫폼”이란 회사가 회원들 간의 파트너십 체결을 위해 양 당사자 상호 간에 이용약관에 대한 동의를 토대로 파트너십 요청 및 수락하는 과정을 통한 계약체결 과정 및 이행에 필요한 서비스를 회원에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 거래를 할 수 있도록 설정한 가상의 영업장(https://www.rayteams.com) 등(회사가 운영하는 웹사이트 및 모바일 웹, 앱 등을 모두 포함)을 뜻합니다. </li>
                                    <li>2.	“서비스”란 치과와 기공소 간의 커뮤니케이션을 효율적으로 도와줄 수 있도록 최적화된 workflow를 제공하는 것을 말합니다. </li>
                                    <li>3.	“컨텐츠”란 게시물을 포함하여, 회원 간 소통 시 생성되는 각종 데이터 등을 말합니다. 컨텐츠는 서비스 이용 과정에서 발생하거나 접하게 되는 스캔 이미지, X-ray 영상, 분석 및 계획 데이터, 문서 텍스트, 그래픽, 사진, 메시지 및 기타 유사 형태의 모든 정보를 의미합니다.</li>
                                    <li>4.	“회원”이란 플랫폼에 회원가입을 통해 회원 등록을 한 자로서, 이 약관에 따라 회사와 서비스 이용계약을 체결하고 계속적으로 플랫폼에서 제공하는 서비스를 이용할 수 있는 자를 말합니다.</li>
                                    <li>5.	“아이디(ID)”라 함은 회원의 식별과 서비스 이용을 위하여 회원이 설정하고 회사가 승인하여 등록된 전자우편주소를 뜻합니다.</li>
                                    <li>6.	“비밀번호(password)”라 함은 회원의 동일성 확인과 회원의 권익 및 비밀보호를 위하여 회원 스스로가 설정하여 플랫폼에 등록한 문자와 숫자 등의 조합을 뜻합니다. </li>
                                </ul>
                                제2조에서 정의되지 않은 용어의 의미는 관련법령 및 일반적인 거래관행을 따릅니다.
                                <h3 className="pt-5">제3조 (약관 동의 명시와 설명 및 개정)</h3>
                                <ul className="list-unstyled">
                                    <li>1.	회사는 이 약관의 내용과 상호 및 대표자 성명, 영업장 소재지 주소(회원의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 전자우편번호, 사업자등록번호, 통신판매업 신고번호, 개인정보관리책임자 등을 회원이 쉽게 알 수 있도록 플랫폼의 메인 화면 하단에 게시합니다. 다만, 약관의 내용은 회원이 연결화면을 통해 볼 수 있도록 할 수 있습니다. </li>
                                    <li>2.	회사는 합리적인 사유가 발생하면 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 전자문서 및 전자거래기본법, 전자금융거래법, 전자서명법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 소비자기본법 등 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다. 이 약관에 동의하는 것은 회사가 운영하는 인터넷 사이트를 정기적으로 방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다.</li>
                                    <li>3.	회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 개정약관의 시행일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자의 권리, 의무에 중대한 영향을 주는 변경의 경우에는 적용일자 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 플랫폼은 개정 전 내용과 개정 후 내용을 명확하게 비교하여 회원이 알기 쉽도록 표시합니다. </li>
                                    <li>4.	전항에 의해 변경된 약관은 관련 법령에 특별한 규정 및 기타 부득이한 사유가 없는 한 그 적용일자 이전으로 소급하여 적용하지 아니합니다. </li>
                                    <li>5.	본 조 제3항에 따라 공지된 적용일자 이후에 회원이 명시적으로 거부의사를 표명하지 않을 경우에는 개정된 약관에 동의하는 것으로 간주하며, 변경된 약관에 동의하지 않는 회원은 회원 탈퇴를 요청할 수 있습니다. 제3항의 조치에도 불구하고 약관의 개정 사실을 알지 못함으로써 발생하는 회원의 피해에 대하여 회사는 책임을 지지 않습니다. </li>
                                    <li>6.	회사는 제공하는 서비스 내의 개별 서비스에 대한 별도의 약관 또는 이용조건을 둘 수 있으며, 개별 서비스에서 별도로 적용되는 약관에 대한 동의는 회원이 개별 서비스를 이용할 경우 별도의 동의절차를 거치게 됩니다. 이 경우 개별서비스에 대한 약관 등이 이 약관에 우선합니다.</li>
                                    <li>7.	이 약관의 규정이 대한민국의 강행법규와 상충하는 경우에는 그 강행법규에 따르고, 이 경우 다른 조항의 효력에는 영향을 미치지 아니합니다. </li>

                                </ul>
                                <h3 className="pt-5">제4조 (서비스 대상 및 이용 가능성)</h3>
                                <ul className="list-unstyled">
                                    <li>1.	서비스는 치과의사 및 치기공사에 한하여 이용이 허용됩니다. 단, 회원이 치과의사 및 치기공사의 지시 및 감독하에 업무를 수행하는 경우, 치과의사 및 치기공사의 요청에 따라 회원에게 서비스 아이디가 제공되며, 해당 치과의사 및 치기공사의 책임하에 회원의 서비스 이용이 허용됩니다. 서비스는 18세 미만 또는 관련 국가 법령에 따른 연령 미만의 미성년자의 개인정보를 고의로 수집 및 사용하지 않습니다. </li>
                                    <li>2.	서비스의 적법한 이용을 위해 회원은 회원이 거주하는 국가 또는 서비스를 이용하는 국가를 포함하여 한국 또는 그 밖의 해당 관할국의 법률에 의하여 서비스를 이용하는 행위가 금지되는 자가 아님을 보증합니다. </li>
                                    <li>3.	서비스의 원활한 이용을 위해 안정적인 인터넷 접속, 호환 가능한 기기 및 소프트웨어와 해당 소프트웨어의 주기적인 업데이트가 필요 할 수 있습니다. </li>
                                    <li>4.	나라 및 지역에 따라 서비스 및 그 일부의 이용이 가능하지 않을 수 있습니다. 회원은 본 약관이 허용하는 범위내에서 회원의 결정에 따라 서비스를 이용해야 하며 적용되는 법률을 준수할 것을 보증합니다. </li>
                                </ul>
                                <h3 className="pt-5">제5조 (서비스의 제공 및 변경)  </h3>
                                <ul className="list-unstyled">
                                    <li>1.	회사는 치과와 기공소 간의 효율적인 커뮤니케이션이 가능하도록 최적화된 workflow를 무상으로 제공합니다. </li>
                                    <li>2.	회사는 서비스의 구성 및 내용의 변경 등이 있는 경우 변경된 서비스의 적용일자 7일 이전부터 적용일 전일까지 플랫폼 화면에 서비스 변경사유 및 내용을 공지합니다. </li>
                                    <li>3.	회사는 회원에게 서비스의 중대한 변경에 대해 30일이전부터 적용일자 전일까지 플랫폼 화면에 변경 사유 및 내용을 공지한다는 조건하에, 필요 시, 서비스를 수정할 수 있으며, 회원의 서비스 사용에 대해 새로운 또는 부가적인 조건을 설정할 권한을 가집니다. 다만, 법, 규정 또는 정부의 활동으로 인해 발생된 상황들로 인한 경우, 사용자 프라이버시 또는 기술 문제를 다루기 위한 경우, 다른 사용자에게 방해가 되는 서비스를 피하기 위한 경우, 또는 자연재해, 참사, 전쟁 등의 불가항력적인 상황 이외의 다른 유사한 상황들로 인해 서비스를 변경하는 것은 부당하지 않다는 점에 동의합니다. </li>
                                </ul>
                                <h3 className="pt-5">제6조 (서비스의 중단)</h3>
                                <ul className="list-unstyled">
                                    <li>1.	회사는 천재지변 또는 이에 준하는 불가항력, 컴퓨터, 서버 등 정보통신설비의 보수점검, 교체 또는 고장, 통신두절 등 운영상 상당한 이유가 있는 경우 부득이하게 서비스의 전부 또는 일부를 중단할 수 있습니다. </li>
                                    <li>2.	회사는 서비스 운영 또는 개선을 위해 상당한 필요성이 있는 경우 서비스의 전부 또는 일부를 수정, 변경 또는 종료할 수 있습니다. 무료로 제공되는 서비스의 전부 또는 일부를 수정, 변경 또는 종료하게 된 경우 관련 법령에 특별한 규정이 없는 한 별도의 보상을 하지 않습니다. </li>
                                    <li>3.	회사는 유료서비스에 한하여, 제1항, 2항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 회원이 입은 손해에 대하여 배상합니다. 단, 회사의 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다. </li>
                                    <li>4.	사업종목의 전환, 사업의 종료 또는 포기, 회사의 폐업, 합병, 분할, 영업양도 등의 이유로 서비스를 더 이상 제공할 수 없는 경우에는 회사는 30일 이상의 사전 유예기간을 두고 공지합니다. </li>
                                </ul>
                                <h3 className="pt-5">제7조 (회원가입)</h3>
                                <ul className="list-unstyled">
                                    <li>1.	서비스는 치과의사 및 치기공사에 한하여 이용이 허용됩니다. </li>
                                    <li>2.	회원은 서비스 이용을 위해 계정을 만들어야 합니다. 회원 가입을 희망하는 자는 회사가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로써 회원가입을 신청합니다. </li>
                                    <li>3.	회사는 전항과 같이 회원으로 가입할 것을 신청한 자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다. 만약, 회사가 회원가입신청의 승인을 거부하거나 유보하는 경우, 회사는 이를 제9조에 따라 회원가입 신청자에게 통지합니다.<br /><br />
                                        <ul>
                                            <li>가.	가입신청자가 이전에 회원자격을 상실한 적이 있는 경우</li>
                                            <li>나.	회원의 요청에 의하여 탈퇴한 때로부터 1개월이 지나지 아니한 경우</li>
                                            <li>다.	가입신청자가 기입한 회원정보에 허위 기재, 누락 또는 오기가 있는 경우</li>
                                            <li>라.	회사가 본인확인 절차를 실시할 경우에 본인 확인이 되지 않거나 본인이 아님이 확인된 경우</li>
                                            <li>마.	가입신청자가 18세 미만 또는 관련 국가 법령에 따른 연령 미만의 미성년자인 경우</li>
                                            <li>바.	기타 가입신청자가 회원으로 등록하는 것이 플랫폼의 기술상 불가능하거나, 플랫폼의 운영 또는 유지, 관리에 현저한 지장을 준다고 판단되는 경우 </li><br />
                                        </ul>
                                    </li>
                                    <li>4.	회원가입계약의 성립시기는 회사의 승낙으로 가입 신청이 완료된 시점으로 합니다. </li>
                                    <li>5.	회원은 회원정보에 변경이 있는 경우, 회원정보 수정 등의 방법으로 해당 변경사항을 지체없이 반영하여야 합니다. 회원정보 수정을 하지 않음으로써 발생하는 회원의 손해에 대하여 회사는 아무런 책임을 부담하지 않습니다. </li>
                                    <li>6.	회원의 아이디(ID)와 비밀번호(password)등의 계정정보에 대한 관리 책임은 회원 본인에게 있으며, 회원은 본인의 계정정보를 제3자에게 제공, 공개하거나 이용하도록 해서는 아니됩니다. 회원이 자신의 아이디(ID) 및 비밀번호(password)의 유출 또는 제3자 사용에 대해 인지하고도 비밀번호를 변경하지 않는 경우 또는 이와 같은 사정을 회사에 통지하지 않거나 회사의 조치에 응하지 않은 경우 등 회원이 본 조의 의무를 준수하지 않아 발생하는 모든 손실이나 손해 등 불이익에 대한 책임은 회원에게 있습니다. </li>
                                    <li>7.	회원은 어떠한 목적으로도 서비스 또는 그 일부의 복제, 복사, 재생산, 판매, 재판매, 대여 또는 거래를 하지 않을 것에 동의합니다. </li>
                                    <li>8.	회원은 회원의 계정에 대한 어떠한 보안 침입에 대하여 회사에 지체없이 통지하는데 동의하며, 회원의 계정 및/또는 비밀번호를 다른 사람과 공유해서는 아니됩니다. 회사는 합리적인 기술을 사용하면서 적절한 주의를 기울이겠지만, 회원의 이러한 규정을 준수하지 않는 데에 따른 계정의 무단 사용에서 기인하는 어떠한 손해에 대하여 책임을 부담하지 않습니다. </li>
                                </ul>
                                <h3 className="pt-5">제8조 (회원 탈퇴 및 자격 상실 등)</h3>
                                <ul className="list-unstyled">
                                    <li>1.	회원은 회사에 언제든지 탈퇴를 요청할 수 있으며, 회사는 회원의 탈퇴요청 시, 지체없이 회원탈퇴를 처리합니다. 회원탈퇴 시 회원자격이 상실되며, 회사가 제공하는 각종 혜택 등이 소멸됩니다. </li>
                                    <li>2.	회원 탈퇴 시, 회원이 저장한 데이터는 즉시 파기되며, 회원은 탈퇴하기 전에 플랫폼에 저장한 데이터 백업을 직접 수행해야 합니다.</li>
                                    <li>3.	회원은 본 약관에 따라 허용되는 목적 내에서만 서비스를 사용함에 동의하며, 관련 법령, 규제 또는 해당 관할국가/지역에서 일반적으로 인정되는 관행의 범위 내에서 서비스를 사용함에 동의합니다. </li>
                                    <li>4.	회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원의 자격을 제한 또는 상실 시킬 수 있으며, 회원자격을 상실할 경우 회원으로서의 모든 혜택은 전부 소멸됩니다. <br /><br />
                                        <ul>
                                            <li>가.	본 약관 또는 본 약관에서 언급 및/또는 서비스에 게시된 다른 정책 또는 가이드라인의 위반</li>
                                            <li>나.	사법집행기관, 사법기관 또는 기타 정부기관의 요청이나 명령</li>
                                            <li>다.	회원에 대한 서비스의 제공이 불법 또는 불법이 될 수 있는 경우</li>
                                            <li>라.	적법한 치과의사 또는 치기공사가 아닌 경우 등 회원정보에 허위 내용을 입력하거나 타인의 정보를 도용한 경우 </li>
                                            <li>마.	불법적인 행위에 대한 회원의 참여 </li>
                                            <li>바.	다른 사람의 플랫폼 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우 </li>
                                            <li>사.	예상치 못한 기술 또는 보안문제 발생 </li>
                                            <li>아.	플랫폼을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우 </li>
                                            <li>자.	계정이 1년간 휴면상태에 있는 경우</li><br />
                                        </ul>
                                    </li>
                                    <li>5.	회사가 회원 자격을 제한시킨 후 동일한 행위가 2회 이상 반복되거나 10일 이내에 그 사유가 시정되지 아니하는 경우 회사는 회원자격을 상실 시킬 수 있습니다. </li>
                                    <li>6.	회사가 회원 자격을 상실시키는 경우에는 회원등록을 말소할 수 있으며, 이 경우 회사는 이를 회원에게 제9조에 따라 통지하고, 회원등록 말소 전 최소한 10일 이상의 기간을 정하여 소명할 기회를 부여합니다. </li>
                                    <li>7.	상기와 같은 모든 종료의 효과로서, 회원계정의 종료에 따라 해당 계정 및 컨텐츠 이외에도 회원은 서비스 및 그 일부에 대한 모든 접속 권한을 잃게 될 수 있습니다. 또한, 회사는 일정 기간이 지난 후에 해당 계정의 일부로서 또는 그 안에 저장되어 있는 정보 및 자료를 삭제할 것입니다. </li>
                                </ul>
                                <h3 className="pt-5">제9조 (회원에 대한 통지)</h3>
                                <ul className="list-unstyled">
                                    <li>1.	회사가 회원에 대한 통지를 하는 경우, 회원이 회사와 미리 약정하여 지정한 방법에 따라 전자우편, SMS, 전화, 팩스 등으로 할 수 있습니다.</li>
                                    <li>2.	회사는 불특정다수 회원에 대한 통지의 경우 7일 이상 플랫폼의 공지사항(고객센터)에 게시함으로써 개별 통지를 갈음할 수 있습니다. 단, 회원의 권리, 의무에 중대한 영향을 주는 변경의 경우에는 적용일자 30일 이상의 사전 유예기간을 두고 공지합니다. </li>
                                </ul>
                                <h3 className="pt-5">제10조 (회사의 의무)</h3>
                                <ul className="list-unstyled">
                                    <li>1.	회사는 이 약관이 금지하거나 관련 법령 기타 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고 안정적으로 서비스를 제공하는데 최선을 다합니다. </li>
                                    <li>2.	회사는 회원이 안전하게 서비스를 이용할 수 있도록 회원의 개인정보(민감정보 포함)보호를 위한 보안시스템을 갖추어야 하며, 개인정보처리방침을 공개하고 준수합니다. 회사는 회원의 개인정보를 본인의 동의 없이 제3자에게 누설, 배포하지 않습니다. 다만, 관계법령에 의해 국가기관의 요구나 전기통신관련법령 및 정보통신윤리위원회의 요청 등 법률의 규정에 따른 적법한 절차에 의한 경우는 그러하지 아니합니다. </li>
                                    <li>3.	회사는 안정적인 서비스의 제공을 위하여 설비에 장애가 생기거나 네트워크 장애 등의 문제가 발생한 때에는 부득이한 사유가 없는 한 지체없이 이를 수리 또는 복구합니다. </li>
                                    <li>4.	회사는 회원이 동의하지 않는 영리목적의 광고성 전자우편 등을 발송하지 않습니다. </li>
                                    <li>5.	회사는 회원으로부터 제기되는 의견이나 불만이 객관적으로 정당하다고 인정될 경우에는 신속히 처리하여야 합니다. 다만, 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 통보하여야 합니다. </li>
                                    <li>6.	회사는 본 약관의 집행 및 준수를 위해 합리적으로 필요한 조치를 취할 권리가 있습니다. 회원은 아래의 경우에 한해 회원에 대하여 법률적인 책임을 지지 않고 회사가 합리적으로 필요하다는 판단과 적절한 조치하에 회원의 계정 정보 및 업무 컨텐츠에 대한 접속하거나 또는 사법기관 등 규제당국의 정당하고 적법한 요구가 있을 시, 관련 법률상 허용되는 범위내에서 해당기관에 공개하는 데 명시적으로 동의합니다.<br /><br />
                                        <ul>
                                            <li>(1) 법령 및 규정의 준수 목적에 필요한 경우, (2) 본 약관의 집행에 필요한 경우, (3) 기술적 문제의 발견 및 해결에 필요한 경우, 또는 (4) 회사, 회원 및/또는 제3자의 권리 및 재산을 지키기 위해 법적으로 필요가 있는 경우</li><br />
                                        </ul>
                                    </li>

                                </ul>
                                <h3 className="pt-5">제11조 (회원의 의무)</h3>
                                <ul className="list-unstyled">
                                    <li>1.	회원은 이 약관의 규정, 회사의 이용안내 사항 및 관련 법령 등을 준수하여야 하며, 기타 회사 업무에 방해되는 행위를 하여서는 안 됩니다. </li>
                                    <li>2.	회원은 회사에서 제공하는 서비스를 본래의 이용 목적 이외의 용도로 사용하거나 다음 각 호에 해당하는 행위를 해서는 안됩니다. <br /><br />
                                        <ul>
                                            <li>가.	적법한 치과의사 또는 치기공사가 아니면서 그러한 것처럼 진술하는 행위</li>
                                            <li>나.	가입신청 또는 정보변경을 목적으로 회사에 개인정보 등록 시 실명이 아닌 정보 또는 다른 사람의 정보를 사용하거나 허위 사실을 기재하는 행위 </li>
                                            <li>다.	회사의 영업 활동을 방해하거나 플랫폼을 이용하여 부당한 이득을 취하는 등 통상적인 전자상거래 관행에 부합하지 않는 거래상의 부정 행위 </li>
                                            <li>라.	회사가 제공하는 서비스 이용방법에 의하지 아니하고 비정상적인 방법으로 서비스를 이용하거나 회사의 정보처리시스템에 접근하는 행위 </li>
                                            <li>마.	불법행위, 타인의 개인정보 침해, 의료정보관련 법령 위반 컨텐츠를 업로드, 다운로드, 게시, 이메일 발송, 전송, 저장 또는 그 외의 방식으로 제공하는 행위 </li>
                                            <li>바.	회원 본인이 아닌 다른 사람 또는 단체로 가장하는 것. 회원은 자신을 다른 사람, 단체, 다른 회원, 직원 등으로 가장 또는 사칭하거나 그 밖에 개인 또는 단체의 관계를 허위표시 할 수 없습니다. </li>
                                            <li>사.	환자의 동의 없이 또는 의료정보를 불법으로 수집, 제공하는 것 </li>
                                            <li>아.	저작권 또는 기타 지적재산권 침해에 관여하는 것(업로드할 권리가 없는, 컨텐츠를 업로드하는 것 포함) 또는 영업비밀을 공개하는 것, 비밀유지 약정, 비공개 약정 또는 근로계약을 위반하여 영업비밀 또는 비밀정보를 공개하는 것 </li>
                                            <li>자.	서비스(또는 그 일부) 또는 다른 컴퓨터 소프트웨어나 하드웨어의 정상적인 운영을 방해 또는 제한하거나 해를 끼치도록 설계된 바이러스나 컴퓨터 코드, 파일 또는 프로그램을 포함하는 자료를 업로드, 게시, 이메일 발송, 전송, 저장 또는 기타의 방법으로 제공하는 것 </li>
                                            <li>차.	어떤 불법적인 행위를 하거나 계획하는 것 </li>
                                            <li>카.	다른 서비스 이용자들의 개인정보, 의료정보를 수집, 저장하는 것. 또는 이를 상기 금지된 행위 중 어느 하나와 관련하여 이용하는 것 </li>
                                            <li>타.	회사가 제공하는 서비스와 관련 없거나 비합리적인 요청, 공서양속에 반하는 행위 등으로 회사의 업무를 방해하는 행위</li>
                                            <li>파.	기타 위 각 호에 준하여 회사 또는 제3자의 권리를 침해 또는 침해할 우려가 있거나, 전자상거래 질서를 위협하는 행위 </li><br />
                                        </ul>
                                    </li>
                                    <li>3.	회사는 전항을 위반한 회원에 계약 해제, 회원 자격의 제한 등의 조치 및 민, 형사상의 법적조치를 취할 수 있습니다. </li>
                                    <li>4.	회원은 서비스를 이용하여 업로드, 다운로드, 이메일 발송, 전송, 저장 또는 그 외의 방식으로 제공한 모든 컨텐츠에 대한 책임을 부담합니다. 회사는 서비스를 통해 게시된 컨텐츠를 통제하지 않을 뿐만 아니라 컨텐츠의 품질, 무결성, 정확성을 보증하지 않으며, 회원은 서비스 및 컨텐츠의 이용에 따른 위험을 전적으로 감수한다는 점에 이해하고 동의합니다. </li>
                                    <li>5.	회원에 의해 제출 또는 게시된 모든 컨텐츠는 회원이 전적으로 책임을 지며, 제3자의 지적재산권을 포함하는 다른 당사자의 권리를 침해 또는 훼손하거나 어떠한 법률도 위반하지 않고 권리의 침해 또는 기타 불법적인 행위에 해당하지 않는다는 것을 보증합니다. </li>
                                    <li>6.	회원은 본인이 서비스를 이용하면서 생성, 수신, 전송 등의 방식으로 발생된 모든 데이터 및 업무 컨텐츠를 관련 법령에 따라 적법하게 회원의 책임하에 회원의 컴퓨터 또는 다른 기기에 백업할 책임이 있음을 명확히 이해하고 동의합니다. 회사는 서비스를 제공함에 있어 적절한 주의와 합리적인 수준의 기술을 사용하겠지만, 회원이 서비스를 통해 저장 또는 접속한 어떤 컨텐츠도 의도하지 않은 손상 또는 손실되지 않을 것이라는 점을 보증하지 않습니다. </li>
                                    <li>7.	회원은 미국 의료정보보호법(HIPAA)에 정의된 Covered Entities 및/또는 Business associate에 해당하며, 회원은 회사의 서비스 및 기타 구성을 이용하여 US Federal Code 45 C.F.R § 160.103에 명시된 의료정보(PHI)를 불법으로 생성, 유지, 수신 및 전송하지 않을 것을 보증합니다. </li>
                                </ul>
                                <h3 className="pt-5">제12조 (개인정보보호) </h3>
                                회사는 관련법령이 정하는 바에 따라 회원의 계정정보를 포함한 회원의 개인정보를 보호하기 위한 정책을 수립하고 개인정보보호 책임자를 지정하여 이를 게시합니다. 회원의 개인정보보호는 관련 법령 및 회사가 정하는 개인정보처리방침에 정한 바를 따릅니다.<br /><br />
                                <ul className="list-unstyled">
                                    <li>1.	회사는 회원의 개인정보 수집 시 최소 수집의 원칙에 따라 서비스 제공을 위하여 필요한 최소한의 개인정보를 수집합니다. </li>
                                    <li>2.	회사는 회원의 개인정보를 수집, 이용하는 때에는 당해 회원에게 그 목적을 고지하고 동의를 받습니다.</li>
                                    <li>3.	회사는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용 목적이 발생한 경우 또는 제3자에게 제공하는 경우에도 사전에 해당 회원에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다. </li>
                                    <li>4.	회사가 본 조 제2항과 제3항에 의해 회원의 동의를 받는 경우에는 개인정보 관리책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은 자, 제공목적 및 제공할 정보의 내용) 등 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 개인정보호법에서 규정한 사항을 미리 명시하거나 고지해야 하며 회원은 언제든지 이 동의를 철회할 수 있습니다. </li>
                                    <li>5.	회원은 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 회사는 이에 대에 지체없이 필요한 조치를 취할 의무를 집니다. 회원이 오류의 정정을 요구한 경우에는 회사는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다. </li>
                                    <li>6.	회사는 개인정보 보호를 위하여 회원의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며, 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 회원의 손해에 대하여 모든 책임을 집니다. </li>
                                    <li>7.	회사 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.</li>
                                    <li>8.	회사는 개인정보의 수집, 이용, 제공에 관한 동의란을 미리 선택한 것으로 미리 설정해두지 않습니다. 또한 개인정보의 수집, 이용, 제공에 관한 동의 거절 시 제한되는 서비스를 구체적으로 명시하고, 필수 수집항목이 아닌 개인정보의 수집, 이용, 제공에 관한 회원의 동의 거절을 이유로 회원가입을 거절하지 않습니다. </li>
                                    <li>9.	플랫폼 이외의 링크된 사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다. 링크된 사이트 및 상품들을 제공하는 제3자의 개인정보 취급과 관련하여서는 해당 사이버몰 및 제3자의 개인정보처리방침을 확인할 책임은 회원에게 있으며, 회사는 이에 대하여 책임을 부담하지 않습니다. </li>
                                    <li>10.	회사는 다음과 같은 경우에 법이 허용하는 범위 내에서 회원의 개인정보를 제3자에게 제공할 수 있습니다.<br /><br />
                                        <ul>
                                            <li>가.	수사기관이나 기타 정부기관으로부터 적법한 절차에 따른 정보제공을 요청받은 경우</li>
                                            <li>나.	회원이 관련 법령을 위반하는 등 부정행위를 확인하기 위해 필요한 경우</li>
                                            <li>다.	기타 관련 법령에 의해 요구되는 경우 </li><br />
                                        </ul>
                                    </li>
                                    <li>11.	회사는 환자정보/민감정보와 관련된 모든 데이터를 안전하게 암호화하여 저장 및 전송하며, 개인정보보호법, 의료법과 HIPAA규정 및 GDPR 규정등 Data Privacy Laws and Regulations을 준수합니다.</li>
                                </ul>
                                <h3 className="pt-5">제13조 (저작권 및 상표권 등의 지적재산권 이용제한)</h3>
                                <ul className="list-unstyled">
                                    <li>1.	회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다.</li>
                                    <li>2.	회사와 회원 간의 서비스에 관한 별도 계약에 따라 부여되는 경우를 제외하면, 회사는 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라 계정, 아이디, 컨텐츠 등을 이용할 수 있는 이용권한만을 부여하며, 회원은 서비스를 이용함으로써 얻은 정보 중 회사의 지적재산권에 해당하는 정보를 회사의 사전 승낙 없이 복제, 송신, 전송, 출판, 배포, 발송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.
                                        회원이 회사가 제공하는 서비스를 이용하면서 작성한 각종 게시물의 저작권은 회원 본인에게 있으며, 해당 게시물의 저작권 침해를 비롯한 민, 형사상 모든 책임은 회원 본인이 집니다.
                                    </li>
                                    <li>3.	본 약관에서 허용하는 서비스 이용을 제외하고 소프트웨어 또는 서비스 전부 또는 일부를 이용하거나, 다른 사람의 저작물을 별도의 허락 없이 사용하는 것은 엄격하게 금지됩니다. 이는 다른 사람의 지적재산권을 침해하는 것이며, 회원은 저작권 침해로 인하여 손해배상 등 민형사상의 책임을 부담할 수 있습니다.</li>
                                    <li>4.	RAYTeams, 레이 로고 및 기타 본 사이트에 포함된 제품 및/또는 서비스 명칭은 회사의 상표 또는 등록 상표입니다. 회사는 이에 대한 권리를 가지고 있으며, 다른 제품 및 회사의 명칭은 그 해당 사용자의 상표일 수 있습니다.</li>
                                    <li>5.	회사는 지적재산권을 포함한 타인의 권리를 존중하며 사용자들도 마찬가지로 행동해주시기를 기대합니다. 회사는 필요한 경우 그 재량에 따라 타인의 권리를 침해하거나 위반하는 사용자에 대하여 사전 통지 없이 서비스 이용 제한조치를 취할 수 있습니다.  </li>
                                </ul>
                                <h3 className="pt-5">제14조 (책임의 제한)</h3>
                                <ul className="list-unstyled">
                                    <li>1.	회사는 플랫폼 서비스 제공자로서, 이미 생산된 정보를 “있는 그대로(As-Is)” 중개 또는 매개합니다. 회원이 작성한 컨텐츠 등이나 서비스를 통해 연결되는 제3자 도메인 사이트는 해당 정보의 제공자가 독립적으로 작성 또는 제작한 정보이기 때문에, 서비스에서 중개 또는 매개된 정보라도 그 정보의 정확성, 신뢰성 또는 적법성은 원칙적으로 해당 정보를 작성 및 제작한 당사자가 보장하고 그에 따른 책임을 부담합니다. </li>
                                    <li>2.	회사는 회사 서비스를 매개로 전달되었더라도 회사가 작성 또는 제작하지 않는 정보에 대하여, 관련 법령에 별도의 규정이 있거나 회사가 해당정보를 “있는 그대로(As-Is)” 중개 또는 매개하지 않는 특별한 사정이 없는 한, 그 정확성, 신뢰성 또는 적법성에 관한 어떠한 보증도 하지 않습니다. 그리고 회원이 이를 신뢰함으로써 입게 된 손해에 대해 관련 법령에서 별도로 규정하였거나 회사의 고의 또는 과실이 있는 경우에 한하여 배상책임을 부담합니다. </li>
                                    <li>3.	회사는 회원 간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다. </li>
                                    <li>4.	회사는 무료로 제공되는 서비스 이용과 관련하여 관련법령에 특별한 규정이 없는 한 책임을 지지 않습니다. </li>
                                    <li>5.	회사는 유료 서비스 이용이 불가하거나 이용에 장애가 있는 경우, 회사는 유료서비스 회원에게 그 손해를 배상합니다. 다만, 회사의 고의 또는 과실 없이 회원에게 발생한 손해에 대해서는 책임을 지지 않습니다. 회원이 이용불가 또는 이용 장애의 사실을 회사에 접수한 이후 72시간 이내에 정상화된 경우, 회사는 이에 대한 책임을 지지 않습니다. </li>
                                    <li>6.	회원이 서비스를 이용함에 있어 행한 불법행위 또는 본 약관을 위반한 행위로 회사가 당해 회원 외의 제3자로부터 손해배상청구 또는 소송 등 각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 회사를 면책 시켜야 하며, 회사가 면책되지 못한 경우 당해 회원은 그로 인하여 회사에 발생한 손해를 배상할 책임이 있다.  </li>
                                    <li>7.	일부 관할국가의 법률이 특정한 보증의 배제를 허용하지 않거나 명시적으로 금지하는 경우, 아래의 책임제한 규정은 적용되지 않을 수 있습니다. 회사는 서비스를 제공 및 유지하는데 있어 합리적인 수준의 기술을 사용하고 적절한 주의를 기울입니다. <br /><br />
                                        <ul>
                                            <li>가.	회사는 서비스가 중단되지 않고, 오류가 발생하지 않을 것이라고 보증하지 아니하며, 회원은 회사가 본 약관에서 정한 통지의무를 이행한다는 조건하에, 합리적으로 필요한 경우 일정기간 동안 서비스를 변경 및 중단하거나 본 약관의 조건에 따라 서비스를 취소할 수 있다는 사실에 동의합니다. </li>
                                            <li>나.	회원은 “있는 그대로(As-Is)”, 그리고 “이용 가능한 그대로(As-Available)”의 상태로 서비스가 제공되는 것에 대해 분명히 이해하고 동의합니다.</li>
                                            <li>다.	회사는 서비스가 바이러스로 인한 공격, 해킹 등의 보안적인 위협으로부터 안전하다는 점에 대하여 진술하거나 보증하지 아니하며, 이와 관련된 어떤 책임도 부담하지 않습니다. 따라서, 회원은 서비스를 통해 업로드, 다운로드 방식으로 얻은 자료들의 손실에 대한 책임은 회원에게 있음에 동의합니다. </li>
                                            <li>라.	회사는 국가별 의료정보 관련법령에 준하는 보안적 조치를 취할 의무가 없으며, 회원이 서비스를 통하여 의료정보를 업로드, 다운로드, 게시, 이메일 발송, 전송, 저장 또는 그 외의 방식으로 이용, 제공하는 경우, 이와 관련된 어떤 책임도 지지 않습니다. </li>
                                            <li>마.	GDPR<br />
                                                EEA내의 회원인 경우, 회원은 적법한 업무 수행을 위해 Data Privacy 관련 규정, 적절한 수준의 보안, 기술적, 물리적, 관리적 보호조치 등의 데이터 보호를 위한 방법에 대하여 검토해야 합니다. 회사는 관련 법령에서 명시적으로 정한 경우를 제외하고, 회원의 GDPR 규정 위반으로 인한 어떠한 책임도 부담하지 아니하며 회원은 서비스를 이용하는 동안 개인정보주체의 정보를 보호하기 위해 GDPR에서 요구하는 책임과 의무를 부담함에 동의합니다. <br />
                                                회사는 개인정보보호책임자를 지정하여 내부정보보호활동 관리 및 개인정보보호 관련법규 준수 상황을 확인하고 있습니다. 또한, GDPR 준수를 위해 유럽 내 이용자의 정보는 유럽 내에 암호화하여 보관하고 있으며, 데이터 보관을 위한 기술적 조치를 취하고 있습니다. <br />
                                                회사는 환자정보/민감정보와 관련된 모든 데이터는 안전하게 암호화되어 저장 및 전송되며, 의료법과 HIPAA규정 및 GDPR 규정을 준수합니다.<br /><br />

                                                담당자: RAYTeams 개인정보 보호책임자<br />
                                                연락처: <a href="mailto:privacy@rayteams.com">privacy@rayteams.com</a><br /><br />

                                                회원은 환자정보의 수집 절차, DPO 임명 등 GDPR에서 부과하고 있는 개별 조치를 취할 의무가 있으며, 이러한 GDPR 의무위반에 대한 전적인 책임은 회원에게 있음에 동의합니다.
                                            </li><br />
                                        </ul>
                                    </li>
                                </ul>
                                <h3 className="pt-5">제15조 (분쟁해결)</h3>
                                <ul className="list-unstyled">
                                    <li>1.	회사는 회원이 제출하는 불만사항 및 의견을 우선적으로 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 즉시 통보해 드립니다. </li>
                                    <li>2.	회원은 본 약관의 해석 및 계약관련 회사와 회원 간의 분쟁에 대하여, 국제사법 규정을 배제하고, 대한민국 법률이 적용된다는 점과 수원지방법원이 대인관할권 및 전속관할권을 갖는 것에 대하여 명확히 이해하고 동의합니다. <br />
                                        만약, 회원이 한국에 거주하지 않고, 대한민국 영토내에서 서비스에 접속하지 아니했으며, EU국가, 스위스 또는 미국인에 해당한다면, 회원의 법정 거주지의 법원이 관할권을 가지며 법정거주지의 법률이 적용됩니다.
                                    </li>
                                </ul>
                                <h3 className="pt-5">전자계약 체결</h3>
                                회원이 서비스를 이용하는 것은 회원이 전자적인 계약을 체결하고 거래할 권한을 행사하는 것을 포함합니다. 회원은 자신이 전자적으로 제출하는 행위가 그 계약에 구속될 의사나 그 거래의 비용을 지불할 의사가 있다고 해석되는 것에 동의합니다. 회원의 계약과 전자적 제출에 구속된다는 의사표시에는 취소의 통지, 약관, 계약 등을 포함하여 회원이 서비스를 이용하면서 시작된 모든 거래와 관련된 기록도 포함됩니다. 회원의 전자기록에 접근하고 유지하려면 회원은 회원의 전적인 책임하에 특정 하드웨어와 소프트웨어를 보유하고 있어야 합니다. <br /><br />
                                이 약관은 2022년 8월 15일부터 시행합니다.
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TermOfUseKO;
