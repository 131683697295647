import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import Header from "./Header";
import { history } from "../history";
import { AuthAction, UserAction } from "../redux/action";

const Config = ({ Unregister, UpdateUserSetting }) => {

    const { t, i18n } = useTranslation(['translation']);
    const user = JSON.parse(localStorage.getItem("user"));

    const unregister = async () => {
        if (confirm('탈퇴??')) {
            await Unregister({ sub: auth.sub, region: auth.region, email: auth.email });
        }
    }

    const setLang = async (e) => {
        console.log(user);
        if(user && user._id && user?.settings?.lang !== e){
            await UserAction.UpdateUserSetting(user._id, "lang", e);
            i18n.changeLanguage(e);
        }
    }

    return (
        <>
            <Header />
            <section className="wrapper">
                <div className="container py-15 py-md-16 pb-13 tb-account">
                    <div className="row">
                        <div className="col-xl-3 sidebar doc-sidebar xl-block">
                            <div className="widget">
                                <h4 className="mb-1"><img src="./assets/img/img_avatar_dark.svg" className="" />{user?.name}</h4>
                                <div className="accordion accordion-wrapper" id="accordion1">
                                    <div className="accordion-item">
                                        <div className="card-header" id="branch-1">
                                            <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#branch-collapse-1" aria-expanded="true" aria-controls="branch-collapse-1"><span>계정 관리</span></button>
                                        </div>
                                        <div id="branch-collapse-1" className="card-body accordion-collapse collapse show" aria-labelledby="branch-1">
                                            <nav id="collapse-style">
                                                <ul className="list-unstyled text-reset">
                                                    <li><a href="#" onClick={(e) => { e.preventDefault(); history.push('/profile') }}>프로필</a></li>
                                                    <li className="active">개인 설정</li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-9 order-2">
                            <section className="wrapper">
                                <h2>개인 설정</h2>
                                {/*
                                <div className="mt-4">
                                    <h5 className="mt-8">계정 삭제</h5>
                                    <p>계정을 삭제하면 RAYTeams 계정 서비스에 액세스할 수 없으며, 개인 데이터가 영구적으로 삭제됩니다.</p>
                                    <p><a href="#" className="btn btn-danger rounded-pill">계정 및 데이터 삭제</a></p>
                                    <p><a href="#" className="text-dark"><i className="uil uil-external-link-alt"></i>&nbsp;자세히 보기</a></p>
                                </div>
                                <hr />
                                */}
                                <div className="mt-4">
                                    <h5 className="mt-5">언어</h5>
                                    <p>언어를 변경하면 RAYTeams Cloud 및 RAYTeams Client 전체에 반영됩니다.</p>
                                    <div className="form-select-wrapper mb-4 col-12 col-md-4">
                                        <select className="form-select" aria-label="Language" onChange={(e) => setLang(e.target.value)}>
                                            <option selected value="ko">Korean</option>
                                            <option value="en">English</option>
                                        </select>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapDispatch = dispatch => ({
    Unregister: () => dispatch(AuthAction.Unregister()),
});

export default connect(null, mapDispatch)(Config);
