import { useState, useEffect } from "react";
import Header from "./Header";
import { history } from "../history";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { getVersion, rayTeamsClientDownloadURL, moveTo } from '../lib/function';
import { AppAction } from "../redux/action";

const AboutCloud = ({ version, GetAppInfo }) => {
    const [rtcDownloadLink, setRtcDownloadLink] = useState('');
    const [t] = useTranslation(['translation']);

    useEffect(() => {
        GetAppInfo('RayLink', 'ko');
        rayTeamsClientDownloadURL().then(link => setRtcDownloadLink(link));
    }, []);

    return (
        <>
            <Header />
            <section className="wrapper image-wrapper bg-content bg-cloud" data-image-src="./assets/img/bg_cloud.png"
                style={{ backgroundImage: "url('./assets/img/bg_cloud.png')" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 z-index-9 pt-16 pb-0 pt-lg-18">
                            <h2 className="display-1 mb-3 text-light">RAYTeams Cloud</h2>
                            <div className="fs-lg text-light">
                                <p className="mb-8 opacity-75">{t("Whatever the Dental Service needs,")}<br />{t("RAYTeams Cloud Platform provides an excellent service experience.")}</p>
                                <p>
                                    <a href={rtcDownloadLink} className="btn btn-lg btn-primary rounded-pill btn-icon me-2">
                                        <i className="uil uil-arrow-to-bottom me-2"></i>{t("Client Download")}</a>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 z-index-9 text-center pb-2 pt-lg-15" data-cue="fadeIn" data-delay="1800" data-show="true"
                            style={{
                                animationName: "fadeIn",
                                animationDuration: "1800ms",
                                animationTimingFunction: "ease",
                                animationDelay: "400ms",
                                animationDirection: "normal",
                                animationFillMode: "both",
                            }}>
                            <img src="./assets/img/cloud_visual.png" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="wrapper bg-soft-primary">
                <div className="container">
                    <div className="alert alert-icon no-padd fw-light" role="alert">
                        <i className="uil uil-folder-upload"></i>
                        RAYTeams Client <b>{getVersion(version)}</b> has been released. Check out the <a href="#" className="alert-link hover" onClick={(e) => moveTo(history, e, '/releasenotes')}>Release Notes</a>.
                    </div>
                </div>
            </section>
            <section className="wrapper bg-light whatis" id="whatis">
                <div className="container">
                    <div className="row mt-12 mb-10 mb-md-13">
                        <div className="">
                            <h2 className="display-3 mb-6">{t("RAYTeams Cloud Platform is...")}</h2>
                            <div className="bg-soft-primary round-3">
                                <div className="card shadow-none">
                                    <div className="card-body p-10 p-md-13 pt-md-10 pb-md-10">
                                        <div className="row align-items-center">
                                            <div className="feature_img order-lg-2 col-lg-3">
                                                <img src="./assets/img/img_open_platform.png" srcSet="./assets/img/img_open_platform_lg.png 2x" alt="Open Platform for Anyone" />
                                            </div>
                                            <div className="col-lg-9 text-center text-lg-start" data-cues="slideInDown" data-group="page-title" data-delay="600" data-disabled="true">
                                                <h3 className="display-4 mt-5 mb-5" data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                                    style={{
                                                        animationName: 'slideInDown',
                                                        animationDuration: '700ms',
                                                        animationTimingFunction: 'ease',
                                                        animationDelay: '600ms',
                                                        animationDirection: 'normal',
                                                        animationFillMode: 'both'
                                                    }}>Open Platform for Anyone</h3>
                                                <p data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                                    style={{
                                                        animationName: 'slideInDown',
                                                        animationDuration: '700ms',
                                                        animationTimingFunction: 'ease',
                                                        animationDelay: '900ms',
                                                        animationDirection: 'normal',
                                                        animationFillMode: 'both'
                                                    }}>{t("RAYTeams Cloud Platform is an open platform that anyone can participate in.")}<br />
                                                    {t("Anyone can easily create and introduce the RAYTeams App to multiple users.")}<br />
                                                    {t("To that end, RAYTEAMS Cloud")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card shadow-none">
                                    <div className="card-body p-10 p-md-13 pt-md-10 pb-md-10">
                                        <div className="row align-items-center">
                                            <div className="feature_img col-lg-3">
                                                <img src="./assets/img/img_workflow.png" srcSet="./assets/img/img_workflow_lg.png 2x" alt="Simple & Powerful Workflows" />
                                            </div>
                                            <div className="col-lg-8 text-center text-lg-start offset-lg-1" data-cues="slideInDown" data-group="page-title" data-delay="600" data-disabled="true">
                                                <h3 className="display-4 mt-5 mb-5" data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                                    style={{
                                                        animationName: 'slideInDown',
                                                        animationDuration: '700ms',
                                                        animationTimingFunction: 'ease',
                                                        animationDelay: '600ms',
                                                        animationDirection: 'normal',
                                                        animationFillMode: 'both'
                                                    }}>Simple & Powerful Workflows</h3>
                                                <p data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                                    style={{
                                                        animationName: 'slideInDown',
                                                        animationDuration: '700ms',
                                                        animationTimingFunction: 'ease',
                                                        animationDelay: '900ms',
                                                        animationDirection: 'normal',
                                                        animationFillMode: 'both'
                                                    }}>
                                                    {t("There are several workflows in dentistry and dental lab.")}<br />
                                                    {t("There are also workflows based on treatment methods such as correction, prosthetics, denture, etc., and there are various workflows such as pore delivery, schedule management, and simple to do management.")}<br />
                                                    {t("Workflows provided by several related apps are available, and you can also create and use or provide a workflow that fits your organization directly into an app.")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card shadow-none">
                                    <div className="card-body p-10 p-md-13 pt-md-10 pb-md-10">
                                        <div className="row align-items-center">
                                            <div className="feature_img order-lg-2 col-lg-3">
                                                <img src="./assets/img/img_global.png" srcSet="./assets/img/img_global_lg.png 2x" alt="Global Partner & Community" />
                                            </div>
                                            <div className="col-lg-9 text-center text-lg-start" data-cues="slideInDown" data-group="page-title" data-delay="600" data-disabled="true">
                                                <h3 className="display-4 mt-5 mb-5" data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                                    style={{
                                                        animationName: 'slideInDown',
                                                        animationDuration: '700ms',
                                                        animationTimingFunction: 'ease',
                                                        animationDelay: '600ms',
                                                        animationDirection: 'normal',
                                                        animationFillMode: 'both'
                                                    }}>Global Partner & Community</h3>
                                                <p data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                                    style={{
                                                        animationName: 'slideInDown',
                                                        animationDuration: '700ms',
                                                        animationTimingFunction: 'ease',
                                                        animationDelay: '900ms',
                                                        animationDirection: 'normal',
                                                        animationFillMode: 'both'
                                                    }}>
                                                    {t("The RAYTeams Cloud Platform is based in 13 regions worldwide.")}<br />
                                                    {t("A variety of services are available using RAYTeams Apps provided or collaborated with Global Patner.")}<br />
                                                    {t("In addition, it provides a powerful community so that you can share and view effective and diverse information.")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card shadow-none">
                                    <div className="card-body p-10 p-md-13 pt-md-10 pb-md-10">
                                        <div className="row align-items-center">
                                            <div className="feature_img col-lg-3">
                                                <img src="./assets/img/img_customize.png" srcSet="./assets/img/img_customize_lg.png 2x" alt="Customize & Flexable Platform" />
                                            </div>
                                            <div className="col-lg-8 text-center text-lg-start offset-lg-1" data-cues="slideInDown" data-group="page-title" data-delay="600" data-disabled="true">
                                                <h3 className="display-4 mt-5 mb-5" data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                                    style={{
                                                        animationName: 'slideInDown',
                                                        animationDuration: '700ms',
                                                        animationTimingFunction: 'ease',
                                                        animationDelay: '600ms',
                                                        animationDirection: 'normal',
                                                        animationFillMode: 'both',
                                                    }}>Customize & Flexable Platform</h3>
                                                <p data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                                    style={{
                                                        animationName: 'slideInDown',
                                                        animationDuration: '700ms',
                                                        animationTimingFunction: 'ease',
                                                        animationDelay: '900ms',
                                                        animationDirection: 'normal',
                                                        animationFillMode: 'both'
                                                    }}>{t("The RAYTeams Cloud Platform provides powerful customization for large hospitals and large air stations. You can also build another ecosystem of your own.")}<br />
                                                {t("You can build a RAYTEAMS Cloud Platform specifically for a specific hospital or a specific laboratory to provide services.")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="wrapper bg-soft-primary">
                <div className="container py-10 py-md-14 ">
                    <div className="row gx-lg-0 gy-10  align-items-center">
                        <div className="col-lg-6">
                            <div className="row g-6 text-center">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <figure className="rounded mb-6"><img src="./assets/img/img_app_store_1.png" srcSet="./assets/img/img_app_store_1@2x.png 2x" alt="App Store Image" /></figure>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="card shadow-lg card-border-top border-purple">
                                                <div className="card-body">
                                                    <h4 className="h3 text-purple">ExoPlan</h4>
                                                    <p className="mb-2 opacity-75">ExoPlan is an integration app for linking Dental Avatar produced by RAYFace. The configuration of the Exoplan is shared with the configuration of the Exocad Converter.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-lg-12 order-md-2">
                                            <figure className="rounded mb-6 mb-md-0"><img src="./assets/img/img_app_store_2.png" srcSet="./assets/img/img_app_store_2@2x.png 2x" alt="App Store Image" /></figure>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="card shadow-lg mb-md-6 mt-lg-6">
                                                <div className="card-body card-border-top border-purple">
                                                    <h4 className="h3 text-purple">3Shape Trios</h4>
                                                    <p className="mb-2 opacity-75">The patient information and Case information in 3Shape Service can be integrated. You can import 3Shape patient information and images from applications such as RAYFace.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1">
                            <h2 className="display-2 mb-3">We offer an App Store</h2>
                            <p className="lead">We offer an App Store where you can try RAYTeams Apps developed by Global Partners and several developers.</p>
                            <div className="app-sample-list">
                                <img src="./assets/img/app-icon/trio-clear.png" alt="trio-clear" title="Trio Clear Service" />
                                <img src="./assets/img/app-icon/3shape-connector.png" alt="3shape-connector" title="3shape Connector" />
                                <img src="./assets/img/app-icon/apps-languagepack.svg" alt="languagepack" title="Languagepack" />
                                <img src="./assets/img/app-icon/apps-order.svg" alt="order" title="Order" />
                                <img src="./assets/img/app-icon/smart-check.png" alt="smart-check" title="Smart Check" />
                                <img src="./assets/img/app-icon/apps-webmeeting.svg" alt="webmeeting" title="Webmeeting" />
                                <img src="./assets/img/app-icon/apps-logcenter.svg" alt="logcenter" title="Log Center" />
                                <img src="./assets/img/app-icon/exoplan.png" alt="exoplan" title="ExoPlan" />
                            </div>
                            <p className="lh-sm">RAYTeams Cloud Platform is an open platform that anyone can participate in. Anyone can easily create and introduce the RAYTeams App to multiple users. Experience a variety of apps on RAYTeams.</p>
                        </div>
                    </div>

                </div>
            </section> */}
            <section className="wrapper bg-light">
                <div className="container py-14  pb-md-14">
                    <div className="row">
                        <div className="">
                            <div className="row gx-md-8 gx-xl-12 gy-10">
                                <h3 className="display-4">Frequently asked questions</h3>
                                <div className="col-lg-6">
                                    <div className="d-flex flex-row">
                                        <div>
                                            <span className="icon btn btn-sm btn-circle btn-primary pe-none me-5"><i className="uil uil-comment-exclamation"></i></span>
                                        </div>
                                        <div>
                                            <h4>What is RAYTeams Client?</h4>
                                            <p className="mb-0">RAYTeams Client is the default client software that uses the RAYTeams Cloud Platform.<br />
                                                RAYTeams Cloud is available by installing RAYTeams Client.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="d-flex flex-row">
                                        <div>
                                            <span className="icon btn btn-sm btn-circle btn-primary pe-none me-5"><i className="uil uil-comment-exclamation"></i></span>
                                        </div>
                                        <div>
                                            <h4>Who can use RAYTeams Cloud?</h4>
                                            <p className="mb-0">RAYTeams Cloud Platform is an open platform that anyone can participate in. Anyone can easily create and introduce the RAYTeams App to multiple users.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="d-flex flex-row">
                                        <div>
                                            <span className="icon btn btn-sm btn-circle btn-primary pe-none me-5"><i className="uil uil-comment-exclamation"></i></span>
                                        </div>
                                        <div>
                                            <h4>Is it possible to communicate with users?</h4>
                                            <p className="mb-0">We basically provide communication channels with organizations that are cooperating regardless of whether they are hospitals or lab.<br />
                                                You can send and receive files starting with simple communication about your project, and you can change and share your project status. Of course, chatting is also provided by default.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="d-flex flex-row">
                                        <div>
                                            <span className="icon btn btn-sm btn-circle btn-primary pe-none me-5"><i className="uil uil-comment-exclamation"></i></span>
                                        </div>
                                        <div>
                                            <h4>Is it possible to manage the project?</h4>
                                            <p className="mb-0">The RAYTeams Client includes an effective tool for project-based management of patient image information. You can experience various equipment linked to the project.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapState = (state) => {
    const version = state.AppReducer.appInfo.version;
    return { version };
}

const mapDispatch = dispatch => ({
    GetAppInfo: (appCode, lang) => dispatch(AppAction.GetAppInfo(appCode, lang)),
})

export default connect(mapState, mapDispatch)(AboutCloud);
