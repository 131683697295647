import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Router, Switch, Redirect } from 'react-router-dom';
import { history } from './history';
import { Provider, useDispatch } from 'react-redux';
import { Store } from './store';
import axios from 'axios';
import './i18n';
import * as Sentry from '@sentry/react';
import SSO from './components/SSO';
import OAuth20 from './components/OAuth20';
import Main from './components/Main';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Profile from './components/Profile';
import Config from './components/Config';
import Manual from './components/Manual';
import Releasenotes from './components/Releasenotes';
import ReleasenotesRC from './components/ReleasenotesRC';
import AboutCloud from './components/AboutCloud';
import ContactUs from './components/ContactUs';
import PrivacyPolicy from './components/PrivacyPolicy';
import Subscriptions from './components/Subscriptions';
import PaymentsOkmsg from './components/PaymentsOkmsg';
import SubPayments from './components/SubPayments';
import TermOfUse from './components/TermOfUse';
import BoardList from './components/BoardList';
import BoardView from './components/BoardView';
import App from './components/App';
import Apps from './components/Apps';
import AppList from './components/AppList';
import { AppAction, AuthAction } from './redux/action';
import { getApiUrl, getAuthApiUrl, setRtConfig, getRAYTeamsConfig } from './lib/function';
import Alert from './components/Alert';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import PasswordChange from './components/PasswordChange';
import NoApps from './components/NoApps';
import SmileNation from './components/SmileNation';
import SignUpComplete from './components/SignUpComplete';
import Payments from './components/Payments';
import Pay from './components/Pay';
import PaymentsBefore from './components/PaymentsBefore';
import Layout from './components/Layout';
import { useTranslation } from 'react-i18next';
import EmailVerify from './components/EmailVerify';
import Test from './components/Test';
import PaymentsResult from './components/PaymentsResult';
import PaymentsOk from './components/PaymentsOk';
import "./css/index.css";
// import ReactGA from 'react-ga';

import SupportHome from './components/support/SupportHome';
import SupportSection from './components/support/SupportSection';
import SupportContentView from './components/support/SupportContentView';

// AutoSub
import AutoSub from './components/AutoSub';
import AppManuals from './components/AppManuals';

import PaymentsInfo from './components/PaymentsInfo';
import PaymentsChange from './components/PaymentsChange';

const Setting = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      setRtConfig();
    } catch (error) {
      //
    }
  }, []);

  useEffect(() => {
    if (!i18n) {
      return;
    }
    const searchString = window?.location?.search;
    if (!searchString?.trim()) {
      return;
    }
    const search = new URLSearchParams(searchString);
    const ln = search.get("lang");
    if (ln) {
      if (i18n?.options?.resources && i18n.options.resources[ln]) {
        console.log(4);
        i18n.changeLanguage(ln);
      }
      search.delete("lang");
      history.replace({
        search: search.toString(),
      });
    }
  }, [i18n]);

  useEffect(() => {
	try{
		const t = JSON.parse(localStorage.getItem('user'))
	}catch{
		dispatch(AuthAction.Logout());
	}
    axios.interceptors.request.use(
		function (config) {
			config.headers["x-ray-deviceuniqueid"] = "WEB";
			config.headers["x-ray-device"] = "RAYLINK";
			return config;
		},
		function (error) {
			return Promise.reject(error);
		});

    axios.interceptors.response.use((response) => response,
      async (error) => {
        const auto_login = localStorage.getItem('AUTO_LOGIN');
        const originalRequest = error.config;
        if (error.response?.status > 401)
          return Promise.reject(error);

        if (error.response?.status === 401 && auto_login === "Y") {
          const refreshToken = localStorage.getItem('MYLAB_AUTH_REFRESHTOKEN');
          const tokenParts = JSON.parse(localStorage.getItem('user'));
          if (refreshToken && tokenParts?.email && tokenParts?.exp) {
            const now = Math.ceil(Date.now() / 1000);
            if (tokenParts.exp < now) {
              // const apiUrl = getAuthApiUrl("/refreshtoken");
              const apiUrl = getApiUrl("user") + "/refreshtoken";
              return axios.post(apiUrl, {
                refresjtoken: refreshToken,
                email: tokenParts.email
              }).then((response) => {
                const newToken = response.data.data?.AuthenticationResult || response.data.data?.data?.AuthenticationResult;
                localStorage.setItem('userToken', newToken.IdToken);
                originalRequest.headers['token'] = newToken.IdToken;
                return axios(originalRequest);
              })
                .catch(err => {
                  console.log(err)
                  dispatch(AuthAction.Logout());
                });
            } else {
              console.log("token is unexpired", tokenParts.exp, now);
              dispatch(AuthAction.Logout());
            }
          } else {
            dispatch(AuthAction.Logout());
          }
        } else {
          dispatch(AuthAction.Logout());
        }
        return Promise.reject(error);
      }
    );
  }, [])

  return <></>;
}

// //Google Anaytics
// //if (location.href.indexOf("rayteams.com") > -1) {
// 	ReactGA.initialize('G-HS48V5S6S0');
// 	ReactGA.pageview(window.location.pathname + window.location.search);
// //}

const getEnv = () => {
  if (location.href.indexOf("localhost") > -1) {
    return "dev-local";
  } else if (location.href.indexOf("raydevelop") > -1) {
    return "development";
  }
  return "production";
}

Sentry.init({
  dsn: getEnv() === "production" ?
    "https://9c54d3b70de94fd39f2411f0944b0569@o4504930404925440.ingest.sentry.io/4504930652782592" :
    "",
  release: "1.0.6",
  ignoreErrors: [
    "InvalidSignatureException",
    "RequestAbortedError",
    "CRC32CheckFailed"
  ],
  integrations: [
    new Sentry.BrowserTracing()
  ],
  environment: getEnv(),
  tracesSampleRate: 1.0,
});

const run = async () => {
  await getRAYTeamsConfig();

  function FallbackComponent() {
    return (
      <div>An error has occured</div>
    )
  }
  AppAction.GetCountryCodeByIP();

  ReactDOM.render(
    <Provider store={Store}>
      <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
        <BrowserRouter>
          <Router history={history}>
            <Layout>
              <Switch>
              <Route exact path={"/sso"} component={SSO} />
              <Route exact path="/oauth20" component={OAuth20} />
              <Route path="/download" render={() => <Redirect to="/rayface" />} />
              <Route path="/download/manual/rayface" render={() => <Redirect to="/rayface" />} />
              <Route path="/guide" render={() => <Redirect to="/rayface" />} />

                <Route path="/rayteams/releasenotes" render={() => <Redirect to="/releasenotes" />} />
                <Route exact path={"/rayteams/rc-releasenotes/:version"} component={ReleasenotesRC} />

                <Route exact path={"/"} component={Main} />
                <Route exact path={"/login"} component={Login} />
                <Route exact path={"/login/:_appId"} component={Login} />
                <Route exact path={"/login/:subPage/:param"} component={Login} />
                <Route exact path={"/autosub/:_appId/:_lcType"} component={AutoSub} />
                <Route exact path={"/signup"} component={SignUp} />
                <Route exact path={"/forgotpw"} component={ForgotPassword} />
                <Route exact path={"/resetpw"} component={ResetPassword} />
                <Route exact path={"/changepw"} component={PasswordChange} />
                <Route exact path={"/profile"} component={Profile} />
                <Route exact path={"/subscriptions"} component={Subscriptions} />
                <Route exact path={"/subscriptions/:_id"} component={Subscriptions} />
                <Route exact path={"/payments"} component={SubPayments} />
                <Route exact path={"/config"} component={Config} />
                <Route exact path={"/manual/:feature?"} component={Manual} />
                <Route exact path={"/releasenotes"} component={Releasenotes} />
                <Route exact path={"/releasenotes/:version"} component={Releasenotes} />
                <Route exact path={"/aboutcloud"} component={AboutCloud} />
                <Route exact path={"/contact"} component={ContactUs} />
                <Route exact path={"/board/:bid/list"} component={BoardList} />
                <Route exact path={"/board/view/:_id"} component={BoardView} />
                <Route exact path={"/privacy-policy/:date?"} component={PrivacyPolicy} />
                <Route exact path={"/term-of-use"} component={TermOfUse} />
                <Route exact path={"/apps"} component={Apps} />
                <Route exact path={"/applist"} component={AppList} />
                <Route exact path={"/app-manuals"} component={AppManuals} />
                <Route exact path={"/noapps"} component={NoApps} />

                <Route exact path={"/submsg/:type"} component={PaymentsOkmsg} />
                
                <Route exact path={"/pay/:appCode"} component={Pay} />
                <Route exact path={"/payment"} component={Payments} />
                <Route exact path={"/payments-before"} component={PaymentsBefore} />
                <Route exact path={"/payments-result"} component={PaymentsResult} />
                <Route exact path={"/payments-ok"} component={PaymentsOk} />
              
              <Route exact path={"/payments-change"} component={PaymentsChange} />
              <Route exact path={"/paymentsinfo"} component={PaymentsInfo} />

                <Route exact path={"/test111"} component={Test} />

                <Route exact path={"/support"} component={SupportHome} />
                <Route exact path={"/support/:section"} component={SupportSection} />
                <Route exact path={"/support/view/:contentId"} component={SupportContentView} />

                <Route exact path={"/smile-nation"} component={SmileNation} />
                <Route exact path={"/email/verify"} component={EmailVerify} />

                <Route exact path={"/:appCode"} component={App} />
                <Route exact path={"/:appCode/manual/:feature?"} component={Manual} />
                <Route exact path={"/:appCode/releasenotes/:version"} component={Releasenotes} />
                <Route exact path={"/:appCode/rc-releasenotes/:version"} component={ReleasenotesRC} />
                <Route exact path={"/:appCode/releasenotes/"} component={Releasenotes} />
                <Route exact path={"/:appCode/helpcenter"} component={null} />

                <Route path={"*"} component={() => {
                  return (
                    <>404 NOT FOUND</>
                  )
                }} />

              </Switch>
            </Layout>
            <Alert />
          </Router>
        </BrowserRouter>
        <Setting />
      </Sentry.ErrorBoundary>
    </Provider>
    ,
    document.getElementsByClassName("content-wrapper")[0]
  );
}

run();
