
/**
 * HTML string을 파싱하여 h1~h3 의 트리구조 hierarchies 배열과 각 H태그의 고유 아이디를 생성하여 적용한 htmlString을 반환
 * @param {string} htmlString document innerHtml string
 * @returns {{ hierarchies: { tagName: string, contents: string, id: string }[], htmlString: string }}
 */
export const convertHnElsToTreeByHTMLString = (htmlString) => {
  try {
    if (!Array.prototype.at) {
      Object.defineProperty(Array.prototype, 'at', {
        value: function(index) {
          const length = this.length;
          if (index < 0) {
            index = length + index;
          }
          if (index >= 0 && index < length) {
            return this[index];
          } else {
            return undefined;
          }
        },
        writable: true,
        configurable: true
      });
    }
    
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const headings = ['h1', 'h2', 'h3'];
    const els = doc.body.children;

    let hierarchies = [];
    let elCount = 0;
    let i = 1;
    let hasH1 = false;
    let hasH2 = false;
    for (const el of els) {
      const tagName = el.tagName.toLocaleLowerCase();
      if (tagName === "h1") {
        if (!hasH2) {
          hasH1 = true;
        }
      }
      if (tagName === "h2") {
        if (!hasH1) {
          hasH2 = true;
        }
      }
      if (headings.includes(tagName)) {
        const contents = el.textContent.trim();
        if (contents) {
          el.id = `release_${tagName}-${i}`;
          i++;
          hierarchies.push({
            tagName,
            id: el.id,
            contents,
          });
          elCount++;
        }
      }
    }

    hierarchies = hierarchies.reduce((acc, cur) => {
      if (hasH1) {
        if (cur.tagName === "h1") {
          acc.push({ ...cur, children: [] });
        }
        if (cur.tagName === "h2") {
          const h1 = acc.at(-1);
          if (h1) {
            if (h1.tagName === "h1") {
              h1.children.push({ ...cur, children: [] });
            } else {
              acc.push({ ...cur, children: [] });
            }
          } else {
            acc.push({ ...cur, children: [] });
          }
        }
        if (cur.tagName === "h3") {
          const h1 = acc.at(-1);
          if (!h1) {
            acc.push({ ...cur, children: [] });
          } else {
            const h2 = h1.children.at(-1);
            if (h2) {
              if (h2.tagName !== "h3") {
                h2.children.push({ ...cur, children: [] });
              } else {
                h1.children.push({ ...cur, children: [] });
              }
            } else {
              if (h1.tagName !== "h3") {
                h1.children.push({ ...cur, children: [] });
              } else {
                acc.push({ ...cur, children: [] });
              }
            }
          }
        }
      } else if (hasH2) {
        if (cur.tagName === "h2") {
          acc.push({ ...cur, children: [] });
        }
        if (cur.tagName === "h3") {
          const h2 = acc.at(-1);
          if (h2) {
            if (h2.tagName !== "h3") {
              h2.children.push({ ...cur, children: [] });
            } else {
              acc.push({ ...cur, children: [] });
            }
          } else {
            acc.push({ ...cur, children: [] });
          }
        }
      } else {
        acc.push(cur);
      }
      return acc;
    }, []);

    return {
      hierarchies,
      htmlString: doc.body.innerHTML !== "undefined" ? doc.body.innerHTML : htmlString,
      elCount,
    };
  } catch (error) {
    console.log("ERROR[convertHnElsToTreeByHTMLString]", error.toString());
  }
  return {
    hierarchies: [],
    htmlString: htmlString,
  };
};