import axios from "axios";
import Geocode from "react-geocode";
import { AppConfig } from "../config";

Geocode.setApiKey(AppConfig.GOOGLE_MAP_KEY);
Geocode.setLanguage(localStorage.getItem('i18nextLng') || 'en');
Geocode.setLocationType("ROOFTOP"); // ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE

const CUSTOM_LANG_SET = {
    zhchs: 'zh-CN',
    zhcht: 'zh-TW'
};

export const changeLang = (lang) => {
    const ln = CUSTOM_LANG_SET[lang] || lang;
    Geocode.setLanguage(ln);
};

export const fromLatLng = (lat, lng) => {
    return new Promise((resolve, reject) => {
        Geocode.fromLatLng(lat, lng).then(
            (response) => {
                const address = response.results[0].formatted_address;
                let city, state, country;
                for (let i = 0; i < response.results[0].address_components.length; i++) {
                    for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                        switch (response.results[0].address_components[i].types[j]) {
                            case "locality":
                                city = response.results[0].address_components[i].long_name;
                                break;
                            case "administrative_area_level_1":
                                state = response.results[0].address_components[i].long_name;
                                break;
                            case "country":
                                country = response.results[0].address_components[i].long_name;
                                break;
                            default: continue;
                        }
                    }
                }
                resolve({
                    address,
                    city,
                    state,
                    country
                })
            },
            (error) => {
                reject(error);
            }
        );
    });
};

export const formAddressList = (addr) => {
    return new Promise((resolve, reject) => {
        Geocode.fromAddress(addr).then(
            (response) => {
                console.log(response)
                resolve(response.results);
            },
            (error) => {
                reject(error);
            }
        );
    });
}

export const fromAddress = (addr) => {
    return new Promise((resolve, reject) => {
        Geocode.fromAddress(addr).then(
            (response) => {
                if (response.results.length === 0) {
                    resolve(false);
                } else {
                    const result = response.results.sort((a, b) => a.address_components.length > b.address_components.length ? -1 : 1)[0];
                    const countryItem = result.address_components.find(x => x.types.includes('country'));
                    const postalItem = result.address_components.find(x => x.types.includes('postal_code'));
                    const { lat, lng } = result.geometry.location;
                    const data = {
                        coords: { lat, lng },
                        code: countryItem?.short_name,
                        postalCode: postalItem?.long_name,
                        result
                    }
                    resolve(data);    
                }
                
            },
            (error) => {
                reject(error);
            }
        );
    });
};

export const formAddressApi = async (addr, language, apiDomain) => {
    if (!addr || !apiDomain) {
        return false;
    }
    try {
        const data = {
            data: {
                address: addr,
                language,
            }
        };
        const ret = await axios.post(apiDomain + '/geocoding', data);
        if (ret.data.status === 'success') {
            const response = ret.data.data;
            if (response.results.length === 0) {
                return false;
            } else {
                const result = response.results.sort((a, b) => a.address_components.length > b.address_components.length ? -1 : 1)[0];
                const countryItem = result.address_components.find(x => x.types.includes('country'));
                const postalItem = result.address_components.find(x => x.types.includes('postal_code'));
                const { lat, lng } = result.geometry.location;
                const data = {
                    coords: { lat, lng },
                    code: countryItem?.short_name,
                    postalCode: postalItem?.long_name,
                    result
                }
                return data;    
            }
        }
    } catch (error) {
        return false;
    }
};

export const getMyLocation = ({ lat, lng }) => {
    return new Promise(resolve => {
        if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(pos => {
                const coords = pos.coords;
                resolve({
                    lat: coords.latitude,
                    lng: coords.longitude,
                });
            });
        } else {
            resolve({ lat, lng });
        }
    });
};