const Privacy20231211_en = ({ date }) => {

    return <>
        <section className="wrapper bg-gray">
            <div className="container pt-6 pt-md-12 pb-14">
                <div className="row gx-0">
                    <div className="col-xl-12">
                        <section className="wrapper policy">
                            <h1 className="display-3 mb-6">Privacy Policy</h1>
                            <h4 className="text-muted">1th Oct. 2023 ~ 10th Dec. 2023</h4>
                            <div className="bg-light p-10 pt-5 pb-5 rounded">
            <div className="p-5">
            <h4 className="pt-5">1. Significance of the Privacy Policy</h4>
            <p>Ray Co., Ltd. (hereinafter referred to as “Ray") complies with the Personal Information Protection Act, the Act on Promotion of Information and Communications Network Use and Information Protection, etc., and is doing its best to protect users' personal information. Ray values the personal information of Users and has created a Privacy Policy to inform Users of the purpose and method of using personal information provided by Users and what measures are being taken to protect personal information.</p>
            <p>This Privacy Policy refers to how we collect, use, disclose, transfer and store your personal information and the measures we take to protect your personal information on the website where this policy is posted (https://www.rayteams.com) and in the software and registration process, and it applies to Rayteams and all related services (including mobile web/app).This policy will take effect on August 2022, 8. If we revise this policy in the future, we will notify you through website notice or e-mail.</p>
            <p>This Policy is effective as of August 19, 2022. If we revise this Policy in the future, we will notify you via a notice on our website or by email.</p>
            <h4 className="pt-5">2. Personal Information to be Collected and Methods of Collection</h4>
            <p>When you sign up to use the service, Ray collects the minimum personal information necessary to use the service.</p>
            <ul>
            <li>
            <strong>The personal information collected by Ray from users at the time of membership registration is as follows.</strong>
            <br/>
            <p className="pt-2">Ray collects your name, ID (email), telephone number, dental name, laboratory name, and address to maintain our contact database.
            Ray may only access/collect information that you voluntarily provide by email or other channels, and you will be notified of any matters related to the collection of personal information in accordance with applicable law. Ray does not sell or rent this information to anyone.

            </p>
            </li>
            <li>
            <strong>Personal information collected from users in the process of using the service is as follows.</strong>
            <br/>
            <br/>
            <table className="table">
            <colgroup>
            <col width="8%" />
            <col width="12%" />
            <col width="40%" />
            <col width="40%" />
            </colgroup>
            <thead>
            <tr>
            <th>type</th>
            <th>category</th>
            <th>Items Collected/Used</th>
            <th>Purpose of collection/use</th>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td className="text-center" rowSpan="3">Essential</td>
            <td className="text-center" rowSpan="2">All Members</td>
            <td>Name, ID (email), password, telephone number, dental or laboratory name, address</td>
            <td>User identification, contact for contract performance, provision of information according to service use (delivery of notices, confirmation of personal opinion, consultation related to service, handling of complaints, precautions, etc.), service satisfaction survey</td>
            </tr>
            <tr>
            <td>Name, ID (e-mail), service use record, IP address, access log</td>
            <td>Prevention of an illegal transaction</td>
            </tr>
            <tr>
            <td className="text-center">Members of Clinic/Laboratory</td>
            <td>Name, ID (email), password, telephone number, dental or laboratory name, address</td>
            <td>User identification, prevention of duplicate registrations, contact for contract performance, provision of information according to service use (delivery of notices, confirmation of personal opinion, service-related consultation, handling of complaints, precautions, etc.), service satisfaction survey</td>
            </tr>
            </tbody>
            </table>
            <p>When additional personal information is collected, the user will be informed of the "items of personal information to be collected, the purpose of collection and use of personal information, and the retention period of personal information" at the time of collection of the personal information and obtain consent.</p>
            </li>
            <li>
            <strong>IP address, cookies, service use records, device information, and location information may be generated and collected in the process of using the service.</strong>
            <p className="pt-2">Specifically, 1) information about the user is generated in an automated way and stored (collected) in the process of using the service, or 2) unique information on the user's device is safely converted so that the original value cannot be checked.
            The information collected in this way may or may not correspond to personal information depending on whether it is linked to personal information.

            </p>
            </li>
            <li>
            <strong>Ray collects personal information through the following methods.</strong>
            <ul>
            <li>In the process of membership registration and service use, if the user agrees to the collection of personal information and enters the information directly, the personal information will be collected.</li>
            <li>During the consultation process through the customer center, the user's personal information may be collected through the web page, e-mail, fax, phone, etc.</li>
            <li>Personal information may be collected in writing at offline events, seminars, etc.</li>
            <li>Personal information may be provided by external companies or organizations affiliated with Ray, and in this case, it will be provided to Ray after obtaining consent from the partner to provide personal information to the user in accordance with the Personal Information Protection Act.</li>
            <li>Generated information such as device information may be automatically generated and collected in the process of using PC web and mobile web/app.</li>
            </ul>
            </li>
            </ul>
            <h4 className="pt-5">3. Use of Collected Personal Information</h4>
            <p>The personal information collected by Ray is used for the following purposes. Ray uses personal information only for membership management of various services (including mobile web/app), service development, provision and enhancement, and establishment of a safe Internet environment.</p>
            <ul>
            <li>Use of personal information for member management, such as confirmation of intention to join the member registration, user identification, and confirmation of intention to withdraw the membership</li>
            <li>Member response, such as providing answers to users' questions</li>
            <li>For statistical and monitoring purposes</li>
            <li>In addition to providing existing services such as content, improving existing services such as demographic analysis, analysis of service visits, and usage records</li>
            <li>Preservation of records for conciliation of disputes, protection of users such as handling of complaints, etc. Establishment of other marketing plans</li>
            <li>Delivery of notices such as amendments to the terms and conditions</li>
            <li>Restriction of use for members who violate laws and company terms of use, prevention and sanctions for acts that interfere with the smooth operation of the service, including fraudulent use, and prevention of account theft and illegal transactions.</li>
            <li>Establishment of a service use environment that users can use without fear in terms of security, privacy, and safety</li>
            </ul>
            <p>We will only use your personal data to send you marketing materials if you have obtained your prior consent.</p>
            <h4 className="pt-5">4. Provision and consignment of personal information</h4>
            <p>Ray does not provide personal information to external parties without the prior consent of the user, except when the user has directly consented to the provision of personal information in order to use the services of an external partner, and when Ray is obligated to submit personal information in accordance with applicable laws and regulations, and when there is an immediate danger to the life or safety of the user and it is necessary to resolve it.</p>
            <p>Ray does not share your information with third parties outside of Ray except as necessary to fulfill your request, when the provision of your information is required by law, when separate consent is obtained from the data subject, when a de-identified form is offered for purposes such as statistical compilation and academic research, when necessary to provide to foreign governments for the implementation of treaties and other international agreements, when necessary to conduct criminal investigations and trials, and when necessary to enforce protective orders.</p>
            <p>Ray entrusts some of the tasks necessary for the provision of services to external companies, and stipulates the matters necessary for the entrusted company to safely process personal information through the personal information processing entrustment agreement, and manages and supervises it. The entrustment of personal information is terminated upon withdrawal of membership or termination of the contract.</p>
            <p>The trustees of personal information and the contents of the entrusted tasks are as follows, and if necessary, the trustees may be added or changed, and in this case, this Privacy Policy will be notified to reflect the changes.</p>
            <table className="table">
            <colgroup>
            <col width="20%" />
            <col width="40%" />
            <col width="40%" />
            </colgroup>
            <thead>
            <tr>
            <th>Trustee</th>
            <th>Outsourcing and purpose</th>
            <th>Retention and use period</th>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td className="text-center">Amazon Web Services, Inc. </td>
            <td className="text-center">Data storing</td>
            <td className="text-center">Destroy immediately after achieving the purpose</td>
            </tr>
            </tbody>
            </table>
            <h4 className="pt-5">4.1 International Transfer of Personal Information</h4>
            <p>The consignment of personal information processing is handled by overseas subsidiaries as follows.</p>
            <p>Ray safely backs up (stores) data domestically and internationally to provide uninterrupted service even in the event of a disaster or catastrophe. AWS, which handles overseas data storage, performs data management tasks under strict control of Ray.</p>
            <table className="table">
            <thead>
            <tr>
            <th>Items of
            personal information transferred

            </th>
            <th>Countries to which you are transferred</th>
            <th>Transfer date and time</th>
            <th>Transfer method</th>
            <th>Transferee</th>
            <th>Contact information of the transferee's information
            manager

            </th>
            <th>Purpose of use of
            personal information of the transferee

            </th>
            <th>Period of retention and use of the transferee's
            personal information

            </th>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td className="text-center">Personal information and log information collected during service use</td>
            <td>1. United States (Ohio, Virginia, California, Oregon)<br/>
            2. India (Mumbai)<br/>
            3. Korea (Seoul)<br/>
            4. Singapore<br/>
            5. Australia (Sydney)<br/>
            6. Japan (Tokyo)<br/>
            7. Canada<br/>
            8. Germany (Frankfurt)<br/>
            9. Ireland<br/>
            (10). United Kingdom (London)<br/>
            11. France (Paris)<br/>
            12. Sweden (Stockholm)<br/>
            13. Bahrain<br/>
            (14). Brazil (Sao Paulo)<br/>
            </td>
            <td className="text-center">Transfer from time to time during the course of service provision</td>
            <td className="text-center">Online</td>
            <td className="text-center">AWS</td>
            <td className="text-center">AWS Korea Privacy<br/>aws-korea-privacy@amazon.com </td>
            <td className="text-center">Data storage for the provision of RAYTeams services</td>
            <td className="text-center">Retained during the user's subscription period</td>
            </tr>
            </tbody>
            </table>
            <h4 className="pt-5">5. Retention period and destruction of personal information</h4>
            <ul>
            <li>
            <strong>Retention period of personal information</strong>
            <br/>
            <p className="pt-3">In principle, Ray destroys users' personal information without delay when withdrawing from membership. However, if it is necessary to preserve personal information in accordance with the provisions of related laws and regulations and Ray’s internal policy, Ray will keep the personal information for a certain period of time.</p>
            <p>In addition, in accordance with Article 39-6 of the Personal Information Protection Act and Article 49-5 of the Enforcement Decree of the same law, Ray converts the user to a dormant member if there is no record of service use for one year or the period specified by the user, and if the user is converted to a dormant member, the relevant information is destroyed or stored separately in a separate DB.</p>
            <p>At least 30 days prior to the dormancy transition, Ray will notify the dormant member by email of the fact that the personal information will be destroyed, the date of destruction, and the items of personal information to be destroyed in the case of personal information destruction, and the fact that the personal information will be kept separately in the case of separate storage, the date of separate storage, and the items of personal information to be kept separately in the case of separate storage.</p>
            <p>However, when a separate consent is obtained from the user for the period of personal information storage, or in the event that a law stipulates the obligation to store information for a certain period of time, the Company shall store personal information in accordance with the provisions of the law during this period.</p>
            <ul>
            <li>A. If there are provisions of related laws and regulations<ul className="list-unstyled">
            <li>1) Records on contract or subscription withdrawal: 5 years (Act on Consumer Protection in Electronic Commerce, etc.)</li>
            <li>2) Records on payment and supply of goods: 5 years (Act on Consumer Protection in Electronic Commerce, etc.)</li>
            <li>3) Records of consumer complaints or dispute handling: 3 years (Act on Consumer Protection in Electronic Commerce, etc.)</li>
            <li>4) User's log records, access point tracking data, and website visit records: 3 months (Act on the Protection of Communications Secrets)</li>
            <li>5) Records on electronic finance under the Electronic Financial Transactions Act: 5 years (Electronic Documents and Electronic Transactions Basic Act)</li>
            </ul>
            </li>
            <li>B. Retention period of personal information retained in accordance with the company's internal policies<ul className="list-unstyled">
            <li>1) When the consent of the user is obtained individually: Period of consent obtained</li>
            <li>2) Information necessary for identification of unauthorized subscription and use of the service (ID, device information, service use logs and collected items, date of withdrawal, reason for withdrawal): Until the relevant dispute is resolved</li>
            <li>3) In the event of a dispute such as a complaint or lawsuit between the company and the user, if the dispute is not resolved within the retention period: Until the time of resolution of the dispute</li>
            <li>4) Personal information of withdrawn members: 3 months from the date of withdrawal</li>
            <li>5) Personal information collected at call center and website A/S reception and consultation: 5 years from the date of collection</li>
                                <li>6) Personal information collected when inquiring on the website: 3 years from the date of collection.</li>
                            </ul>
                            </li>
                            </ul>
                            </li>
                            <li className="pt-5">
                            <strong>Destruction of Personal Information</strong>
                            <br/>
                            <p className="pt-3">Personal information that has achieved the purpose of collecting and using personal information, such as membership withdrawal, service termination, and the arrival of the personal information retention period agreed to by the user, is destroyed in a way that cannot be reproduced.<br/>If Ray keeps it in accordance with laws and regulations, it will be transferred to a separate database, used only for retention purposes unless provided by law, and will be disposed of within the specified period.<br/>In the case of electronic file types, it is safely deleted using technical methods to prevent recovery and reproduction, and printouts are destroyed by shredding or incineration.</p>
                            </li>
                            </ul>
                            <h4 className="pt-5">6. Rights of users and legal representatives and how to exercise them</h4>
                            <p>You have the following data protection rights:</p>
                            <ul>
                            <li>You may request to access, correct, update, suspend or delete your personal information. However, we may suspend your request if there are special provisions in the law, if we need to comply with a legal obligation, or if there is a risk of harm to another person or unreasonable infringement of the property or interests of another person.</li>
                            <li>You may object to the processing of your personal information and request that we restrict the processing of your personal information.</li>
                            <li>Where we have collected and processed your personal information with your consent, you may withdraw your consent at any time.<br/>The withdrawal of your consent will not affect the lawfulness of any processing carried out prior to the withdrawal, nor will it affect the processing of your personal information carried out on lawful processing grounds other than consent.<br/>If you wish to exercise this right, you may request to change, correct, or notify of your information by sending an email to support@rayteams.com or by writing to "Ray, 221 Pangyo Yeok-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea".<br/>We will respond to your request as soon as possible.</li>
                            </ul>
                            <h4 className="pt-5">7. Ray’s Efforts to Protect Personal Information</h4>
                            <p>Ray is committed to the secure management of your personal information and protects it beyond what is required by the Personal Data Protection Act.</p>
                            <p>Ray takes administrative, technical, and physical measures to protect your personal information from unauthorized access, use, or disclosure by third parties. Ray has established and is implementing an internal management plan for data protection. The personal information you provide is protected from unauthorized access, use or disclosure and is stored securely on our servers.</p>
                            <p>Additional technical measures include managing access rights to personal information processing systems, encrypting unique identifiers, installing security programs, and keeping and encrypting access records.</p>
                            <ul>
                            <li className="pt-5">
                            <strong>Ray has established and implemented an internal management plan for personal information protection.</strong>
                            <p className="pt-3">Ray establishes an internal management plan for personal information, including matters related to the composition and operation of the personal information protection organization, such as the appointment of a person in charge of personal information protection, and check whether the internal management plan is well implemented every year.</p>
                            </li>
                            <li className="">
                            <strong>Ray takes measures to control access and restrict access to personal information.</strong>
                            <p className="pt-3">In order to block illegal access to personal information, Ray has established and implemented standards for granting, changing, and canceling access to the personal information processing system, and has installed and operated an intrusion prevention system. In addition, Ray minimizes the management of employees who process personal information and reduces the leakage of personal information by separating the external Internet network from the internal network for the work PCs of employees who can download personal information from the personal information processing system.</p>
                            </li>
                            <li className="">
                            <strong>Ray takes encryption measures to safely store and transmit personal information.</strong>
                            <p className="pt-3">In addition to passwords and unique identification information required by laws and regulations, e-mails, addresses, and phone numbers are additionally encrypted and stored. In addition, we safely transmit and receive personal information on the network through encrypted communication.</p>
                            </li>
                            <li className="">
                            <strong>Ray takes measures to keep personal information access records and prevent falsification and alteration.</strong>
                            </li>
                            </ul>
                            <h4 className="pt-5">8. International Transfer of Personal Information</h4>
                            <p>Ray may transmit or manage the user's information overseas to provide services and improve the convenience of users. <br/>This Privacy Policy applies to the transfer of personal information to other countries. The Company will obtain your consent in the event of an international transfer of personal information and will take appropriate protective measures to ensure that your personal information is protected.</p>
                            <h4 className="pt-5">9. Cookie Policy</h4>
                            <p>Ray wants to make your access to our Services as easy, efficient, and useful as possible. To do this, we use cookies and similar technologies to improve your user experience and enhance site security.  </p>
                            <p>Cookies are small text files that are automatically generated on your device when you visit almost any website. Cookies are stored by your internet br/owser and contain basic information about your internet usage. Each time you return to a website, your br/owser sends a cookie back to the website so that the website can recognize your device, provide you with personalized content, and improve your user experience. We also use cookies to store your login information so that you do not have to re-enter your login information over and over again when using the Services. We may use other cookies to help us understand your preferences.</p>
                            <p>Ray only uses essential cookies. Essential cookies are required to access and navigate the Website and use all of its features. Without these cookies, the Website will not function properly and certain important features will not be available to you. For example, we use cookies to keep you logged in during your visit, so you do not have to log in repeatedly to access different pages on the Website. We may also use essential cookies to detect and prevent fraud. Strictly necessary cookies are required to access and use the features of the website and cannot be turned off through the cookie consent tool.</p>
                            <h4 className="pt-5">10. Information on the person in charge of personal information protection and the department that receives/processes access requests</h4>
                            <p>Ray designates a person in charge of personal information protection as follows to protect your personal information and handle complaints related to personal information. You may report all complaints related to personal information protection that arise while using Ray’s services to our Personal Information Protection Officer. The Privacy Officer will provide you with a prompt and sufficient response to your complaint.</p>
                            <ul>
                            <li>
                            <strong>Chief Privacy Officer</strong>
                            </li>
                            <li className="pt-1">Name: Lee Min-kyu</li>
                            <li>Affiliation &amp; Position: Software Development Division</li>
                            <li>Job Title: Managing Director</li>
                            <li>Email address: privacy@rayteams.com</li>
                            </ul>
                            <ul>
                            <li>
                            <strong>Personal Information Protection Officer</strong>
                            </li>
                            <li className="pt-1">Name: Bang Seongwon</li>
                            <li>Department: Software Development Division</li>
                            <li>Job Title: Professional</li>
                            <li>Phone: +82-31-605-1000</li>
                            <li>Email address: privacy@rayteams.com</li>
                            </ul>
                            <p>If you need to report or consult about other personal information infringements, you can contact the following organizations.<br/>Personal Information Infringement Report Center (privacy.kisa.or.kr / 118 without area code) Cyber Investigation Division of the Supreme Prosecutors' Office (www.spo.go.kr / 1301 without area code) National Police Agency Cyber Safety Keeper (http://www.police.go.kr/www/security/cyber.jsp / 182 without area code)</p>
                            <h4 className="pt-5">11. Obligation to notify before revision</h4>
                            <p>If there are additions, deletions, or revisions to this Privacy Policy, we will notify you in advance through 'Notice' at least 7 days before the revision.<br/>However, if there is a significant change in the user's rights, such as a change in the items of personal information to be collected or the purpose of use, etc., the user will be notified at least 30 days in advance, and if necessary, the user's consent may be obtained again.</p>
                            <p>Date of Announcement: September 22, 2023 <br/>Date of Implement: October 1, 2023</p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        </section>
    </>
}
export default Privacy20231211_en;
