import { useEffect } from 'react';
import { AppAction } from '../../redux/action';
import Header from '../Header';
import { connect } from "react-redux";
import { moveTo, payableSQA, getIsSubscribableKr } from '../../lib/function';
import { history } from '../../history';
import { useTranslation } from 'react-i18next';
import { APPCATEGORY } from '../../constant/appconst';
import SupportFAQ from './SupportFAQ';
import { useState } from 'react';
import { BoardAction } from '../../redux/action/BoardAction';
import SupportNotice from './SupportNotice';

const SupportHome = ({ appList, GetAppList }) => {
    const { t, i18n } = useTranslation(['translation']);

    const [menus, setMenus] = useState([]);

    useEffect(() => {
        getData();
    }, [i18n.language]);

    const getData = async () => {
        const ret = await BoardAction.GetSupportBoardSections(i18n.language)
        setMenus(ret);
        console.log(ret)
    }

    return (
        <>
            <Header />
            <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-300 text-white help-center" style={{ backgroundImage: "url('./assets/img/bg1.jpg')"}} >
                <div className="container pt-14 pt-md-15 pb-4 pb-md-3">
                    <div className="row gx-lg-9">
                        <div className="col-lg-9">
                            <div className="app-avatar">
                                <img className="avatar" src="./assets/img/app-icon/img_rayteams.png" alt="rayteams" />
                            </div>
                            <div className="app-title">
                                <h2 className="fs-15 text-opacity60">RAYTeams Cloud</h2>
                                <h3 className="display-5 mf-300 b-0 text-white mb-1">{t("Help Center")}</h3>
                                </div>
                        </div>
                        <div className="col-lg-3">
                            {/*<div className="search-form mt-2 mt-md-1 sch-box">
                                <input id="" type="text" className="form-control sch-box" placeholder="Search..." />
                                <label for="">Search...</label>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </section>
            <SupportNotice />
            <section className="wrapper bg-gray help-center">
                <div className="container pt-11 pb-13">
                    <div className="row">
                        <h2 className="fs-15 text-uppercase text-muted mb-3">{t("안녕하세요, 무엇을 도와 드릴까요?")}</h2>
                        <h3 className="display-2 mb-9">{t("어떤 도움이 필요하세요?")}</h3>
                    </div>
                    <div className="row gx-md-8 gy-8 help-category">
                        {menus.map((x, idx) => <div className="col" key={idx}>
                            <div className="card lift">
                                <a href="#" onClick={() => history.push("/support/" + x.sk.replace(":" + i18n.language, ""))}>
                                    <div className="card-body">
                                        <div className={"icon btn btn-block btn-lg pe-none mb-5 " + x.color}> <i className={"uil " + (x.ico)}></i> </div>
                                        <h4>{x.title}</h4>
                                        <div className="mb-0 text-dark keep-all" dangerouslySetInnerHTML={{ __html: x.content }}></div>
                                    </div>
                                </a>
                            </div>
                        </div>)}
                    </div>
                </div>
            </section>
            <SupportFAQ />
            {/*<section class="wrapper bg-gray help-center">
                <div class="container py-11 pb-14">
                    <div class="row align-items-center mb-6">
                        <div class="col-md-8 col-lg-9 col-xl-8 col-xxl-7 pe-xl-20">
                            <h2 class="display-6 mb-0">{t("주요 앱 바로가기")}</h2>
                        </div>
                        <div class="col-md-4 col-lg-3 ms-md-auto text-md-end mt-5 mt-md-0">
                            <a href="#" onClick={() => history.push("/applist")} class="more hover link-primary">{t("More view")}</a>
                        </div>
                    </div>
                    <div class="row gy-6">
                        <div class="col-md-6 col-lg-3">
                            <a href="#" class="card shadow-lg lift h-100">
                                <div class="card-body p-5 d-flex flex-row align-items-center">
                                    <div class="app-card-box">
                                    <img class="app-icon-40" src="./assets/img/app-icon/img_rayface.png" alt="RAYFace" />
                                    </div>
                                    <div><span class="badge bg-application rounded">Application</span>
                                    <h4>RAYFace</h4>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-md-6 col-lg-3">
                            <a href="#" class="card shadow-lg lift h-100">
                                <div class="card-body p-5 d-flex flex-row align-items-center">
                                    <div class="app-card-box">
                                        <img class="app-icon-40" src="./assets/img/app-icon/img_converter.png" alt="RAYConverter" />
                                    </div>
                                    <div>
                                        <span class="badge bg-converter rounded">Converter</span>
                                        <h4>RAYConverter</h4>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-md-6 col-lg-3">
                            <a href="#" class="card shadow-lg lift h-100">
                                <div class="card-body p-5 d-flex flex-row align-items-center">
                                    <div class="app-card-box">
                                        <img class="app-icon-40" src="./assets/img/app-icon/apps-logcenter.svg" alt="Logcenter" />
                                    </div>
                                    <div>
                                        <span class="badge bg-utillity rounded">Utillity</span>
                                        <h4>Logcenter</h4>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-md-6 col-lg-3">
                            <a href="#" class="card shadow-lg lift h-100">
                                <div class="card-body p-5 d-flex flex-row align-items-center">
                                    <div class="app-card-box">
                                        <img class="app-icon-40" src="./assets/img/app-icon/apps-languagepack.svg" alt="languagepack" />
                                    </div>
                                    <div>
                                        <span class="badge bg-productivity rounded">Productivity</span>
                                        <h4>Languagepack</h4>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wrapper">
                <div className="container pb-11 pt-11 pt-md-14">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                            <p className="text-center"><img src="/assets/img/logo-light.png" className="rounded-2 w-10" alt="RAYTeams" /></p>
                            <h2 className="display-6 mb-6 text-center">All services to dental, RAYTeams.</h2>
                            <p className="text-center mb-6 text-secondary">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                            <p className="text-center"><a className="btn btn-md btn-primary rounded-pill text-white text-decoration-none mb-1" onClick={(e) => moveTo(history, e, "/aboutcloud")}>RAYTeams Download</a> </p>
                        </div>
                    </div>
                </div>
            </section>*/}
        </>
    )
}

const mapState = (state) => {
    const appList = state.AppReducer.appList;
    return { appList };
}

const mapDispatch = dispatch => ({
    GetAppList: (lang) => dispatch(AppAction.GetAppList(lang)),
})

export default connect(mapState, mapDispatch)(SupportHome);
