import { useLocation } from "react-router";
import { history } from "../history";
import queryString from 'query-string';
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { PaymentsAction } from "../redux/action";

const PaymentsOk = () => {
    const [t] = useTranslation(['translation']);
    const { search } = useLocation();
    const q = queryString.parse(search);

    useEffect(() => {
    }, []);
    console.log(q.search);

    return (
        <section className="h-100 d-flex align-items-center wrapper image-wrapper bg-overlay bg-image bg-white main-visual">
            <div className="container">
                <div className="text-center">
                    <h3>{t(q.search.indexOf("success") > -1 ? "구독이 정상적으로 완료되었습니다." : "결제가 취소 되었습니다.")}</h3>
                    <div>
                        <button onClick={() => history.push("/subscriptions")} className="btn btn-info">{t("My subscriptions")}</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PaymentsOk;
