import { useEffect, useState } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import Header from './Header';
import { useParams } from 'react-router-dom';
import AppHeader from './AppHeader';
import { AppAction } from '../redux/action';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { history } from '../history';
import { getRealAppName } from '../lib/function';

const Manual = ({ appTitle, appIcon, manual, GetApp, GetManual }) => {
    const [lang, setLang] = useState('');
    const [contents, setContents] = useState({});
    const { appCode, feature } = useParams();
    const location = useLocation();
    const [realAppName] = useState(getRealAppName(appCode));

    const { t, i18n } = useTranslation(['translation']);
    
    useEffect(() => {
        setLang(i18n.language);
    }, [i18n.language]);

    useEffect(() => {
        GetApp(realAppName || 'RayLink');
        GetManual(realAppName || 'RayLink', lang);
    }, [realAppName, lang]);

    useEffect(() => {
        if (manual && manual?.length > 0) {
            if (feature?.trim()) {
                const targetManual = manual.find(x => x.intro === feature || x.title === feature);
                if (targetManual?.code) {
                    setContents(targetManual);
                    return;
                }
            }
            const targetManual = manual[0];
            setContents(targetManual);
            const newPathname = location.pathname.split("/");
            if (newPathname.at(-1) !== "manual") {
                newPathname.pop();
            }
            newPathname.push((targetManual.intro || targetManual.title));
            history.replace({
                pathname: newPathname.join("/")
            });
            
        }
    }, [manual, feature]);

    const selectContents = (e, code) => {
        e && e.preventDefault();
        // setContents(manual.find(x => x.code === code));
        const targetManual = manual.find(x => x.code === code);
        if (contents.code === targetManual.code) {
            return;
        }
        const newPathname = location.pathname.split("/");
        newPathname.pop();
        if (feature === (targetManual?.intro || targetManual?.title)) {
            return;
        }
        newPathname.push(targetManual?.intro || targetManual.title);
        history.push({
            ...location,
            pathname: newPathname.join("/"),
        });
    }


    return (
        <>
            {appCode ? <AppHeader /> : <Header />}

            <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-300 text-white" data-image-src="/assets/img/bg1.jpg" style={{ backgroundImage: "url('/assets/img/bg1.jpg')" }}>
                <div className="container pt-14 pt-md-15 pb-4 pb-md-3">
                    <div className="row gx-lg-9">
                        <div className="col-lg-9">
                            <div className="app-avatar">
                                <img className="avatar" src={appIcon || "/assets/img/app-icon/img_rayteams.png"} alt={appTitle || 'RAYTeams Client'} />
                            </div>
                            <div className="app-title">
                                <h2 className="fs-15 text-opacity60">{appTitle || 'RAYTeams Client'}</h2>
                                <h3 className="display-5 mf-300 b-0 text-white mb-1">{t('매뉴얼')}</h3>
                            </div>
                        </div>
                        <div className="col-lg-3" style={{ display: 'none' }}>
                            <div className="search-form mt-2 mt-md-1 sch-box">
                                <input id="" type="text" className="form-control sch-box" placeholder="Search..." />
                                <label htmlFor="">Search...</label>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container manual">
                <div className="row">
                    <aside className="col-xl-3 order-xl-1 sidebar doc-sidebar py-md-8 py-6 d-xl-block order-1">
                        <div className="widget">
                            <h6 className="widget-title fs-17 mb-2">{t('매뉴얼')}</h6>
                            <hr />
                            <div className="accordion accordion-wrapper" id="accordion1">
                                <div className="accordion-item">
                                    <div className="card-header" id="branch-1">
                                        <button className="" data-bs-toggle="collapse" data-bs-target="#branch-collapse-1" aria-expanded="true" aria-controls="branch-collapse-1"><span>{t('기능')}</span></button>
                                    </div>
                                    <div id="branch-collapse-1" className="card-body accordion-collapse collapse show" aria-labelledby="branch-1">
                                        <nav id="collapse-style">
                                            <ul className="list-unstyled fs-sm lh-sm text-reset">
                                                {
                                                    manual?.map((x, idx) => {
                                                        return (
                                                            <li key={'header_' + idx}>
                                                                <a href="#"
                                                                    className={contents?.code === x.code ? ' active' : ''}
                                                                    onClick={(e) => { selectContents(e, x.code) }}>{x.title}</a>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </aside>
                    <div className="col-xl-9 order-2 pt-md-8 pb-10">
                        <section className="wrapper">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#">{appTitle || 'RAYTeams Client'}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{t('매뉴얼')}</li>
                                </ol>
                            </nav>
                            <h2 className="mt-5">{contents?.title}</h2>
                            <div className="board-content" dangerouslySetInnerHTML={{ __html: contents?.contents }}></div>
                        </section>
                        {/* <hr /> */}
                        <div className="quick-btn" style={{ display: 'none' }}>
                            <div className="row g-6">
                                <div className="col-lg-4">
                                    <div className="card shadow-none bg-soft-yellow">
                                        <div className="card-body">
                                            <p className="card-text text-center text-navy">Check out the latest additions and updates to RAYTeams.</p>
                                            <div className="btn btn-yellow rounded-pill text-center w-100 shadow-none">
                                                <a href="/releasenotes" className="text-white" target="_blank">What's new</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card shadow-none bg-soft-yellow">
                                        <div className="card-body">
                                            <p className="card-text text-center text-navy">Learn how to use RAYTeams and get answers to your questions.</p>
                                            <div className="btn btn-yellow rounded-pill text-center w-100 shadow-none">
                                                <a href="/helpcenter" className="text-white" target="_blank">Help Center</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card shadow-none bg-soft-yellow">
                                        <div className="card-body">
                                            <p className="card-text text-center text-navy">Still have more questions? Please contact us.</p>
                                            <div className="btn btn-yellow rounded-pill text-center w-100 shadow-none">
                                                <a href="/contact/#inquiry" className="text-white" target="_blank">1:1 Inquiry</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapState = (state) => {
    const appTitle = state.AppReducer.appInfo.appTitle;
    const appIcon = state.AppReducer.appInfo.appIcon;
    const manual = state.AppReducer.manual;
    return { appTitle, appIcon, manual };
}

const mapDispatch = dispatch => ({
    GetApp: (appCode) => dispatch(AppAction.GetAppInfo(appCode, 'ko')),
    GetManual: (appCode, lang) => dispatch(AppAction.GetManual(appCode, lang))
})

export default connect(mapState, mapDispatch)(Manual);

