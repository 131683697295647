import axios from 'axios';
import { JWT } from '../../config';
import { getApiUrl } from '../../lib/function';
import { getRegion } from '../../constant/country';
import { Store } from '../../store';
import { AuthAction } from './AuthAction';
import { AuthConstant, GroupConstant, AlertConstant } from '../reducer';

const CreateWithoutAuth = (info) => async (dispatch) => {
	const API_URL = getApiUrl('group', info.region);
	const data = { data: info };
	const ret = await axios.post(API_URL + '/group', data, {
		// headers: { 'x-ray-device': Store.getState().ProgramReducer?.site?.toUpperCase() }
        headers: { 'x-ray-device': 'RAYLINK' }        
	});
	return ret.data;
}

const GetAllGroupsByCountryCode = async (countryCode) => {
	const regionObj = getRegion(countryCode);
	if (!regionObj) return;

	const API_URL = getApiUrl('group', regionObj.region);
	try {
		const formData = { data: { countryCode } };
		const ret = await axios.post(API_URL + '/groupbycountrycode', formData, { headers: { 'x-ray-device': 'RAYLINK' } });

        console.log(ret);

		return ret.data;
	} catch (error) {
		return []
	}
}

const Get = (id) => async (dispatch) => {
	// var auth = Store.getState().AuthReducer.user;
	// if(!auth._id)
	// 	dispatch(AuthAction.Logout());
		
	const API_URL = getApiUrl('group');
	const ret = await axios.get(API_URL + '/group/' + id, JWT());
	// if(ret.message === "Unauthorized"){
	// 	dispatch(AuthAction.Logout());
	// 	return;
	// }

	if (ret.data.status === 'success') {
		const auth = Store.getState().AuthReducer.user;
		if (ret.data?.data?._id === auth.groupId) {
			const item = defineGroupExtra(ret.data.data, true);
			dispatch({ type: AuthConstant.SET_GROUP, item });
		}
		dispatch({ type: GroupConstant.GET, item: ret.data.data });
		dispatch({ type: AlertConstant.NOTICE, data: "Success" });
		return ret.data;
	} else {
		dispatch({ type: AlertConstant.ERROR, data: ret.data.err });
	}
};

const GetLocal = async (id) => {
	const API_URL = getApiUrl('group');
	const ret = await axios.get(API_URL + '/group/' + id, JWT());
	if (ret.data.status === 'success') {
		return ret.data.data;
	}
	return null;
};


export const defineGroupExtra = (item, writeLicense = false) => {
    const extra = item?.extra;
    if (!extra || !Array.isArray(extra)) {
        return;
    }
    const dispatch = Store.dispatch;
    const relatedGroups = []; // 연결된 그룹 ID 배열, 기존 group의 relatedGroups 필드를 오버라이드함. 
    const licenseObj = {};
    const licenses = [];
    const contracts = [];
    const receivedLicense = [];
    const contractHistories = [];
    const count = { total: 0, used: 0, unused: 0 };

    for (const row of extra) {
        if (row.sk.startsWith('lc:')) {
            licenses.push(row);
            count.total += row.total;
            licenseObj[row.subtype] = row.expired;
            continue;
        }
        if (row.sk.startsWith('sct:')) {
            const skArr = row.sk.split(':');
            contracts.push(row);
            skArr[1] !== 'none' && count.used++;
            relatedGroups.push(skArr[2]);
            continue;
        }
        if (row.sk.startsWith('rct:')) {
            const skArr = row.sk.split(':');
            const subtype = skArr[1];
            if (licenseObj[subtype]) {
                if (licenseObj[subtype].expired < row.expired) {
                    licenseObj[subtype].expired = row.expired
                }
            } else {
                licenseObj[subtype] = row.expired;
            }
            receivedLicense.push(row);
            continue;
        }
        if (row.sk.startsWith('ch:')) {
            contractHistories.push(row);
            continue;
        }
    }
    item.relatedGroups =  Array.from(new Set(relatedGroups));

    return item;
}

const UpdateHasMember = async (_id, hasMember, region) => {
	const API_URL = getApiUrl('group', region);
	try {
		const formData = { hasMember };
		const ret = await axios.post(API_URL + `/group/${_id}/hasmember`, formData, { headers: { 'x-ray-device': 'RAYLINK'}});
		return ret.data;
	} catch (error) {
		return {}
	}
}

export const GroupAction = {
    CreateWithoutAuth,
    GetAllGroupsByCountryCode,
    Get,
    GetLocal,
    UpdateHasMember
};
