import React, { useEffect, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import Header from "./Header";
import { useTranslation } from 'react-i18next';
import { rayTeamsClientDownloadURL } from '../lib/function'
import { history } from "../history";
import EventModal from "./EventModal";
import moment from "moment/moment";
import { BoardAction } from "../redux/action/BoardAction";
import { ProgramConstant } from "../redux/reducer";

const Main = ({notifyItems}) => {
    const [t] = useTranslation(['translation']);
    const [rtcDownloadLink, setRtcDownloadLink] = useState('');
    const dispatch = useDispatch();
    useEffect(() => {
        rayTeamsClientDownloadURL().then(link => setRtcDownloadLink(link));
    }, []);

    useEffect(async() => {
        await GetNotifyItems();
    }, [])

    const GetNotifyItems = async () => {
        const list = await BoardAction.GetNotifyItems();
        dispatch({type: ProgramConstant.GET_NOTIFY_ITEMS, items: list});
    };

    return (
        <>
            <Header />
            <section className="wrapper image-wrapper bg-overlay bg-image bg-content text-white main-visual" data-image-src="./assets/img/bg_main_visual.jpg" style={{ backgroundImage: "url('./assets/img/bg_main_visual.jpg')" }}>
                <div className="container pt-18 pb-14 pt-md-20 pb-md-18" style={{ zIndex: '5', position: 'relative' }}>
                    <div className="row gx-0 gy-12 align-items-center">
                        <div className="col-lg-5 content text-center text-lg-start" data-cues="slideInDown" data-group="page-title" data-delay="600" data-disabled="true">
                            <h1 className="display-1 mb-5 text-white fw-lighter" data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                style={{
                                    animationName: 'slideInDown',
                                    animationDuration: '700ms',
                                    animationTimingFunction: 'ease',
                                    animationDelay: '600ms',
                                    animationDirection: 'normal',
                                    animationFillMode: 'both'
                                }}>
                                <strong>Everything you need</strong><br />for Dental Service
                            </h1>
                            <p className="lead fs-lg lh-sm mb-10 pe-xl-10 keep-all" data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                style={{
                                    animationName: 'slideInDown',
                                    animationDuration: '700ms',
                                    animationTimingFunction: 'ease',
                                    animationDelay: '900ms',
                                    animationDirection: 'normal',
                                    animationFillMode: 'both',
                                }}>{t("RAYTeams Cloud Platform is building an amazing ecosystem specialized in Dental Services.")}<br />{t("Join the RAYTeams Cloud and get started.")}
                            </p>
                            <div className="d-flex justify-content-center justify-content-lg-start" data-cues="slideInDown" data-group="page-title-buttons" data-delay="900" data-cue="slideInDown" data-disabled="true" data-show="true"
                                style={{
                                    animationName: 'slideInDown',
                                    animationDuration: '700ms',
                                    animationTimingFunction: 'ease',
                                    animationDelay: '900ms',
                                    animationDirection: 'normal',
                                    animationFillMode: 'both'
                                }}>
                                <span data-cue="slideInDown" data-group="page-title-buttons" data-delay="900" data-show="true"
                                    style={{
                                        animationName: 'slideInDown',
                                        animationDuration: '700ms',
                                        animationTimingFunction: 'ease',
                                        animationDelay: '1200ms',
                                        animationDirection: 'normal',
                                        animationFillMode: 'both'
                                    }}>
                                    <a className="btn btn-lg btn-primary rounded-pill me-2" onClick={() => history.push('/aboutcloud')}>{t("Explore more")}</a>
                                </span>
                                {/* <span data-cue="slideInDown" data-group="page-title-buttons" data-delay="900" data-show="true"
                                    style={{
                                        animationName: 'slideInDown',
                                        animationDuration: '700ms',
                                        animationTimingFunction: 'ease',
                                        animationDelay: '1500ms',
                                        animationDirection: 'normal',
                                        animationFillMode: 'both'
                                    }}>
                                    <a className="btn btn-lg btn-outline-white rounded-pill" onClick={() => history.push('/manual')} >{t('시작하기')}</a>
                                </span> */}
                            </div>
                        </div>
                        <div className="col-lg-6 offset-lg-1">
                        </div>
                    </div>
                </div>
            </section>

            <section className="wrapper bg-light feature_list">
                <div className="p-10 pt-md-12 pb-md-0">
                    <div className="row gx-md-8 gx-xl-10">
                        <div className="col-12 col-lg-3">
                            <div className="card bg-soft-purple mb-10 shadow-none lift">
                                <div className="card-body p-10">
                                    <p className="mb-4 text-center"><img src="./assets/img/img_open_platform.png" /></p>
                                    <h3 className="h2 post-title mb-3">{t('Open Platform for Healthcare Professionals')}</h3>
                                    <p>{t('RAYTeams Cloud Platform is an open platform for healthcare professionals')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className="card bg-soft-purple mb-10 shadow-none lift">
                                <div className="card-body p-10">
                                    <p className="mb-4 text-center"><img src="./assets/img/img_workflow.png" /></p>
                                    <h3 className="h2 post-title mb-3">{t('Simple & Powerful Workflows')}</h3>
                                    <p>{t('Create, use, or provide the workflow for your organization as an app.')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className="card bg-soft-purple mb-10 shadow-none lift">
                                <div className="card-body p-10">
                                    <p className="mb-4 text-center"><img src="./assets/img/img_global.png" /></p>
                                    <h3 className="h2 post-title mb-3">{t('Global Partner & Community')}</h3>
                                    <p>{t('Try RAYTeams Apps, provided or collaborated with Global Patner.')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className="card bg-soft-purple mb-10 shadow-none lift">
                                <div className="card-body p-10">
                                    <p className="mb-4 text-center"><img src="./assets/img/img_customize.png" style={{ opacity: '1' }} /></p>
                                    <h3 className="h2 post-title mb-3">{t('Customize & Flexable Platform')}</h3>
                                    <p>{t('Provides powerful customization for hospitals and clinic.')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center pb-10 my-n5">
                        <a href="#" className="more hover link-primary learn-more" onClick={(e) => { history.push('/aboutcloud') }}>Learn more details</a>
                    </div>
                </div>
            </section>

            <section className="wrapper bg-light feature_list">
                <hr className="my-0 my-md-0" />
                <div className="p-10 pb-5">
                    <div className="row gx-md-8 gx-xl-10">
                        <div className="col-lg-8 client-download">
                            <div className="card bg-soft-purple mb-10 shadow-none lift">
                                <div className="card-body p-10">
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="card-title mb-3 text-primary">{t("RAYTeams CLIENT DOWNLOAD")}</div>
                                            <p className="text-dark keep-all">{t("The RAYTeams Client is the default client software required when using the RAYTeams Cloud Platform.")}</p>
                                            <p className="mb-0"><a href={rtcDownloadLink} className="btn btn-lg btn-primary rounded-pill me-2">{t("Client Download")}</a></p>
                                        </div>
                                        <div className="col-lg-5 mt-5 mt-md-0">
                                            <p className="text-end mb-0 mx-md-3"><img src="./assets/img/img_app_store.png" style={{ opacity: '1' }} /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-3">
                            <div className="card bg-gray mb-10 h-300 shadow-none lift">
                                <a href="#" onClick={() => history.push("/manual")}>
                                    <div className="card-body p-10">
                                        <div className="card-title mb-3 text-dark">{t("MANUAL")}</div>
                                        <p className="text-dark mb-0">{t("Do you need any questions or instructions for installing RAYTeams?")}</p>
                                        <p className="text-dark">{t("Please check out the manual.")}</p>
                                        <span className="more hover link-soft-gray">{t("more view")}</span>
                                    </div>
                                </a>
                            </div>
                        </div> */}
                        <div className="col-lg-4">
                            <div className="card bg-gray mb-10 h-300 shadow-none lift">
                                <a href="#" onClick={() => history.push("/releasenotes")}>
                                    <div className="card-body p-10">
                                        <div className="card-title mb-3 text-dark">{t("WHAT'S NEW")}</div>
                                        <p className="text-dark mb-0">{t("Let's take a look at the new features of RAYTeams.")}</p>
                                        <p className="text-dark">{t("Please check out the what's new.")}</p>
                                        <span className="more hover link-soft-gray">{t("more view")}</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* {moment().isBefore(moment("2024-03-01T09:00:00")) && (
                <EventModal />
            )} */}
            {notifyItems && notifyItems.map(x => {
                    return x?.ishomepage && <EventModal notifyItem={x}/>
                })
            }
        </>
    );
}

const mapState = (state) => {
    const notifyItems = state.ProgramReducer.notifyItems;
    return { notifyItems };
}
export default connect(mapState, null)(Main);
