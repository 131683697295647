let _user = {};
let _userToken = "";
let _loginResult = false;
try {
    _user = JSON.parse(window?.localStorage?.getItem("user") || "{}");
    _userToken = window?.localStorage?.getItem("userToken") || "";
    _loginResult = !!_userToken && !!_user?._id;
} catch (error) {
    //
}

const initialState = {
    user: _user,
    loginResult: _loginResult,
    logged: false,
    userToken: _userToken,    
    redirect_url : ""
};

export const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case AuthConstant.LOGOUT:
            return { userToken: "", logged: false, loginResult: false };

        case AuthConstant.LOGIN_SUCCESS:
            return {
                logged: true,
                user: checkUserData(action.user),
                loginResult: true,
                userToken: action.userToken,
                redirect_url : action.redirect_url || "/"
            };
        case AuthConstant.SET_USER:
            return {
                ...state,
                user: action.item
            }
        case AuthConstant.LOGIN_FAIL:
            return {
                loginResult: true,
                logged: false
            };

        default:
            return state;
    }
}

const checkUserData = (user) => {
    if (!user)
        return user;
    if (!user.utype)
        user.utype = ['manager'];
    window?.localStorage?.setItem('user', JSON.stringify(user));

    return user;
}

export const AuthConstant = {
    LOGIN_SUCCESS: "AUTH_LOGIN_SUCCESS",
    LOGIN_FAIL: "AUTH_LOGIN_FAIL",
    UPDATED: "AUTH_UPDATED",
    LOGOUT: "AUTH_LOGOUT",
    SET_GROUP: "AUTH_SET_GROUP",
    ADD_RELATED_GROUP: "AUTH_ADD_RELATED_GROUP",
    SUB_RELATED_GROUP: "AUTH_SUB_RELATED_GROUP",
    SET_FIRST_SIGNIN_DATA: "AUTH_SET_FIRST_SIGNIN_DATA",
    SET_IS_WEB: "AUTH_SET_IS_WEB",
    SET_AUTH_REDIRECT: "AUTH_SET_AUTH_REDIRECT",
    SET_USER: "AUTH_SET_USER",
};
