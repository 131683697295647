const SimpleLoader = ({ title, message }) => {
    return (
        <>
            <div className="loadingio-spinner-dual-ball-sw6sftzohqk" style={{ 
                    position : "initial", 
            }}><div className="ldio-rhxguy7x4r" style={{ 
                    position : "initial", 
            }}>
                <div></div>
                <div></div>
                <div></div>
            </div></div>
            <div className="text-center text-primary">
                <h5>{title}</h5>
                {message}
            </div>
        </>
    )
}

export default SimpleLoader;
