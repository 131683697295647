import { combineReducers } from "redux";

import { AuthReducer, AuthConstant } from "./AuthReducer";
import { GroupReducer, GroupConstant } from "./GroupReducer";
import { AlertReducer, AlertConstant } from "./AlertReducer";
import { ProgramReducer, ProgramConstant } from "./ProgramReducer";
import { UserReducer, UserConstant } from "./UserReducer";
import { AppReducer, AppConstant } from './AppReducer'
import { SubscriptionReducer, SubscriptionConstant } from './SubscriptionReducer'
import { PaymentsReducer, PaymentsConstant } from "./PaymentsReducer";

const appReducer = combineReducers({
    AuthReducer,
    GroupReducer,
    ProgramReducer,
    AlertReducer,
    UserReducer,
    AppReducer,
    SubscriptionReducer,
    PaymentsReducer,
});

const RootReducer = (state, action) => {
    if (action.type === AuthConstant.LOGOUT) {
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
};

export default RootReducer;
export {
    AuthConstant,
    GroupConstant,
    ProgramConstant,
    AlertConstant,
    UserConstant,
    AppConstant,
    SubscriptionConstant,
    PaymentsConstant
};
