import { useEffect, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const Alert = ({ alert, pos = "middle-center" }) => {
	const [t] = useTranslation(['translation']);
	const [data, setData] = useState({});
	const [show, setShow] = useState(false);

	useEffect(() => {
		setData(alert);
	}, [alert]);

	useEffect(() => {
		setShow(data.show);
	}, [data]);

	return <ToastContainer position={pos} className='rounded me-2' style={{  zIndex: 1100 }}>
		<Toast show={show || false} delay={3000} onClose={() => setShow(false)} style={{width:'600px'}}>
			<Toast.Header>
				<strong className="me-auto">RAYTeams</strong>
			</Toast.Header>
			<Toast.Body>
                {t(data.msg)}
			</Toast.Body>
		</Toast>
	</ToastContainer>
}

const mapState = (state) => {
	const alert = state.AlertReducer.alert;
	const site = state.ProgramReducer.site;
	return { alert, site };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(Alert);