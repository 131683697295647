import axios from 'axios';
import { AppConfig } from "../../config";
import { JWT } from '../../config';
import { getApiUrl } from '../../lib/function';
import { Store } from '../../store';
import { AuthAction } from './AuthAction';
import { GroupAction } from './GroupAction';
import { AlertConstant, AuthConstant, UserConstant } from '../reducer';

const CheckEmail = async (email) => {
    const API_URL = getApiUrl('user');
    const ret = await axios.post(API_URL + '/emaildupcheck', { email }, JWT());
    return ret.data;
}

const CreateWithoutAuth = (info) => async (dispatch) => {
	const API_URL = getApiUrl('user', info.region);
	const data = { data: info };
	const ret = await axios.post(API_URL + '/user', data, {
		headers: { 'x-ray-device': Store.getState().ProgramReducer?.site?.toUpperCase() }
	});
	return ret.data;
}

const Unregister = (info) => async (dispatch) => {
	const API_URL = getApiUrl('user');
	const user = Store.getState().AuthReducer.user;
	const ret = await axios.post(API_URL + '/unregister', { data : info }, JWT());
	if (ret.data.status == 'success') {
		if(user.email === info.email){
			const data_users = await UserAction.GetUserIdsByGroupId(user.groupId);
			if(data_users.data.length === 0)
				await GroupAction.UpdateHasMember(user.groupId, false, user.region);

			dispatch(AuthAction.Logout());
		}else{
			dispatch({ type: UserConstant.REMOVED, email : info.email });
		}
	} else {
		dispatch({ type: AlertConstant.ERROR, data: ret.data.err, msg : "Error", show : true });
	}
};

const GetUserIdsByGroupId = async (_id) => {
	const API_URL = getApiUrl('user');
	const formData = {
		data: { _id }
	};
	const ret = await axios.post(API_URL + '/useridsbygroupid', formData, JWT());
	return ret.data;
}

const GetUserIdsByGroupIdNoAuth = async (_id, region) => {
	const API_URL = getApiUrl('user');
	const formData = {
		data: { _id, region }
	};
	const ret = await axios.post(API_URL + '/getusersbygroupidnoauth', formData, JWT());
	return ret.data;
}

const UpdateUserSetting = async (_id, key, val) => {
    const dc = new AWS.DynamoDB.DocumentClient();

		const params = {
			TableName: 'rayteams-user',
			Key: {
				_id: _id,
				sk: "info"
			}
		};
		const { Item: item } = await dc.get(params).promise();
		const uInfo = item;

    // const userInfo = await dc.query({
    //     TableName: 'rayteams-user',
    //     KeyConditionExpression: '#id=:id and #sk=:sk',
    //     ExpressionAttributeNames: { '#id': '_id', '#sk' : "sk" },
    //     ExpressionAttributeValues: { ':id': _id, ':sk' : "info" }
    // }).promise();

    // const uInfo = userInfo.Items[0];

	const API_URL = getApiUrl('user');
	const formData = {
		data: { 
            _id : _id, 
            settings : {...uInfo.settings, [key] : val} 
        }
	};
	const ret = await axios.post(API_URL + '/user/' + _id, formData, JWT());
    const user = JSON.parse(localStorage.getItem("user"));
    console.log({...user, ...ret.data.data});
    localStorage.setItem("user", JSON.stringify({...user, ...ret.data.data}));
};

const Update = (info, preventToast = false) => async (dispatch) => {
	try {
		const API_URL = getApiUrl('user');
		var data = {
			type: 'update',
			data: info,
		};
		const ret = await axios.post(API_URL + '/user/' + info._id, data, JWT());
		if (ret.data.status === 'success') {
			if (Store.getState().AuthReducer.user._id === info._id) {
				localStorage.setItem('user', JSON.stringify(ret.data.data));
				dispatch({ type: AuthConstant.UPDATED, user: ret.data.data });
			}
			dispatch({ type: UserConstant.UPDATE, item: ret.data.data });
			//!preventToast && dispatch({ type: AlertConstant.NOTICE, msg: "Success", show: true });
		} else {
			dispatch({ type: AlertConstant.ERROR, data: ret.data.err, msg: "Error", show: true });
		}	
	} catch (error) {
		console.log("ERROR[UpdateUser]", error.toString());
	}
};

const GetMe = () => async (dispatch) => {
	try {
		const token = window?.localStorage?.getItem('userToken');
		if (!token) {
			return;
		}
		const API_URL = getApiUrl('user');
		const ret = await axios.get(API_URL + '/me', JWT());
		if (ret?.data?.status === 'success') {
			let userJson = ret.data.data;
			console.log("GetMe", userJson);
			userJson = { ...userJson, utype: userJson.utype || ["manager"] };
			window?.localStorage?.setItem('user', JSON.stringify(userJson));
			window?.localStorage?.setItem('RAYLINK_USER_EMAIL', userJson.email);
			dispatch({ type: AuthConstant.SET_USER, item: userJson });
			return userJson;
		}
	} catch (error) {
		console.log("ERROR[GetMe]", error.toString());
	}
};

const GetUserItems = async ({ sk, match = false, filters, names, values, projections }) => {
	try {
		const user = Store.getState().AuthReducer.user;
		const API_URL = getApiUrl("user");
		const formData = { data: { sk, match, filters, names, values, projections } };
		const ret = await axios.post(`${API_URL}/user/${user?._id}/items`, formData, JWT());
		return ret.data.data;
	} catch (error) {
		console.log("ERROR[GetUserItems]", error?.response?.data || error?.toString());
	}
	return [];
};

export const UserAction = {
	CheckEmail,
	CreateWithoutAuth,
	Unregister,
	GetUserIdsByGroupId,
	GetUserIdsByGroupIdNoAuth,
    UpdateUserSetting,
	GetMe,
	GetUserItems,
	Update
};
