export const COUNTRIES = [
    { countryCode: "AD", lat: 42.546245, lng: 1.601554, name: "Andorra" },
    { countryCode: "AE", lat: 23.424076, lng: 53.847818, name: "United Arab Emirates" },
    { countryCode: "AF", lat: 33.93911, lng: 67.709953, name: "Afghanistan" },
    { countryCode: "AG", lat: 17.060816, lng: -61.796428, name: "Antigua and Barbuda" },
    { countryCode: "AI", lat: 18.220554, lng: -63.068615, name: "Anguilla" },
    { countryCode: "AL", lat: 41.153332, lng: 20.168331, name: "Albania" },
    { countryCode: "AM", lat: 40.069099, lng: 45.038189, name: "Armenia" },
    { countryCode: "AN", lat: 12.226079, lng: -69.060087, name: "Netherlands Antilles" },
    { countryCode: "AO", lat: -11.202692, lng: 17.873887, name: "Angola" },
    { countryCode: "AQ", lat: -75.250973, lng: -0.071389, name: "Antarctica" },
    { countryCode: "AR", lat: -38.416097, lng: -63.616672, name: "Argentina" },
    { countryCode: "AS", lat: -14.270972, lng: -170.132217, name: "American Samoa" },
    { countryCode: "AT", lat: 47.516231, lng: 14.550072, name: "Austria" },
    { countryCode: "AU", lat: -25.274398, lng: 133.775136, name: "Australia" },
    { countryCode: "AW", lat: 12.52111, lng: -69.968338, name: "Aruba" },
    { countryCode: "AZ", lat: 40.143105, lng: 47.576927, name: "Azerbaijan" },
    { countryCode: "BA", lat: 43.915886, lng: 17.679076, name: "Bosnia and Herzegovina" },
    { countryCode: "BB", lat: 13.193887, lng: -59.543198, name: "Barbados" },
    { countryCode: "BD", lat: 23.684994, lng: 90.356331, name: "Bangladesh" },
    { countryCode: "BE", lat: 50.503887, lng: 4.469936, name: "Belgium" },
    { countryCode: "BF", lat: 12.238333, lng: -1.561593, name: "Burkina Faso" },
    { countryCode: "BG", lat: 42.733883, lng: 25.48583, name: "Bulgaria" },
    { countryCode: "BH", lat: 25.930414, lng: 50.637772, name: "Bahrain" },
    { countryCode: "BI", lat: -3.373056, lng: 29.918886, name: "Burundi" },
    { countryCode: "BJ", lat: 9.30769, lng: 2.315834, name: "Benin" },
    { countryCode: "BM", lat: 32.321384, lng: -64.75737, name: "Bermuda" },
    { countryCode: "BN", lat: 4.535277, lng: 114.727669, name: "Brunei" },
    { countryCode: "BO", lat: -16.290154, lng: -63.588653, name: "Bolivia" },
    { countryCode: "BQ", lat: 12.1683718, lng: -68.308183, name: "Bonaire, Sint Eustatius and Saba" },
    { countryCode: "BR", lat: -14.235004, lng: -51.92528, name: "Brazil" },
    { countryCode: "BS", lat: 25.03428, lng: -77.39628, name: "Bahamas" },
    { countryCode: "BT", lat: 27.514162, lng: 90.433601, name: "Bhutan" },
    { countryCode: "BV", lat: -54.423199, lng: 3.413194, name: "Bouvet Island" },
    { countryCode: "BW", lat: -22.328474, lng: 24.684866, name: "Botswana" },
    { countryCode: "BY", lat: 53.709807, lng: 27.953389, name: "Belarus" },
    { countryCode: "BZ", lat: 17.189877, lng: -88.49765, name: "Belize" },
    { countryCode: "CA", lat: 56.130366, lng: -106.346771, name: "Canada" },
    { countryCode: "CC", lat: -12.164165, lng: 96.870956, name: "Cocos [Keeling] Islands" },
    { countryCode: "CD", lat: -4.038333, lng: 21.758664, name: "Congo [DRC]" },
    { countryCode: "CF", lat: 6.611111, lng: 20.939444, name: "Central African Republic" },
    { countryCode: "CG", lat: -0.228021, lng: 15.827659, name: "Congo [Republic]" },
    { countryCode: "CH", lat: 46.818188, lng: 8.227512, name: "Switzerland" },
    { countryCode: "CI", lat: 7.539989, lng: -5.54708, name: "Côte d'Ivoire" },
    { countryCode: "CK", lat: -21.236736, lng: -159.777671, name: "Cook Islands" },
    { countryCode: "CL", lat: -35.675147, lng: -71.542969, name: "Chile" },
    { countryCode: "CM", lat: 7.369722, lng: 12.354722, name: "Cameroon" },
    { countryCode: "CN", lat: 35.86166, lng: 104.195397, name: "China" },
    { countryCode: "CO", lat: 4.570868, lng: -74.297333, name: "Colombia" },
    { countryCode: "CR", lat: 9.748917, lng: -83.753428, name: "Costa Rica" },
    { countryCode: "CU", lat: 21.521757, lng: -77.781167, name: "Cuba" },
    { countryCode: "CV", lat: 16.002082, lng: -24.013197, name: "Cape Verde" },
    { countryCode: "CW", lat: 12.2135221, lng: -68.9495816, name: "Curacao" },
    { countryCode: "CX", lat: -10.447525, lng: 105.690449, name: "Christmas Island" },
    { countryCode: "CY", lat: 35.126413, lng: 33.429859, name: "Cyprus" },
    { countryCode: "CZ", lat: 49.817492, lng: 15.472962, name: "Czech Republic" },
    { countryCode: "DE", lat: 51.165691, lng: 10.451526, name: "Germany" },
    { countryCode: "DJ", lat: 11.825138, lng: 42.590275, name: "Djibouti" },
    { countryCode: "DK", lat: 56.26392, lng: 9.501785, name: "Denmark" },
    { countryCode: "DM", lat: 15.414999, lng: -61.370976, name: "Dominica" },
    { countryCode: "DO", lat: 18.735693, lng: -70.162651, name: "Dominican Republic" },
    { countryCode: "DZ", lat: 28.033886, lng: 1.659626, name: "Algeria" },
    { countryCode: "EC", lat: -1.831239, lng: -78.183406, name: "Ecuador" },
    { countryCode: "EE", lat: 58.595272, lng: 25.013607, name: "Estonia" },
    { countryCode: "EG", lat: 26.820553, lng: 30.802498, name: "Egypt" },
    { countryCode: "EH", lat: 24.215527, lng: -12.885834, name: "Western Sahara" },
    { countryCode: "ER", lat: 15.179384, lng: 39.782334, name: "Eritrea" },
    { countryCode: "ES", lat: 40.463667, lng: -3.74922, name: "Spain" },
    { countryCode: "ET", lat: 9.145, lng: 40.489673, name: "Ethiopia" },
    { countryCode: "FI", lat: 61.92411, lng: 25.748151, name: "Finland" },
    { countryCode: "FJ", lat: -16.578193, lng: 179.414413, name: "Fiji" },
    { countryCode: "FK", lat: -51.796253, lng: -59.523613, name: "Falkland Islands [Islas Malvinas]" },
    { countryCode: "FM", lat: 7.425554, lng: 150.550812, name: "Micronesia" },
    { countryCode: "FO", lat: 61.892635, lng: -6.911806, name: "Faroe Islands" },
    { countryCode: "FR", lat: 46.227638, lng: 2.213749, name: "France" },
    { countryCode: "GA", lat: -0.803689, lng: 11.609444, name: "Gabon" },
    { countryCode: "GB", lat: 55.378051, lng: -3.435973, name: "United Kingdom" },
    { countryCode: "GD", lat: 12.262776, lng: -61.604171, name: "Grenada" },
    { countryCode: "GE", lat: 42.315407, lng: 43.356892, name: "Georgia" },
    { countryCode: "GF", lat: 3.933889, lng: -53.125782, name: "French Guiana" },
    { countryCode: "GG", lat: 49.465691, lng: -2.585278, name: "Guernsey" },
    { countryCode: "GH", lat: 7.946527, lng: -1.023194, name: "Ghana" },
    { countryCode: "GI", lat: 36.137741, lng: -5.345374, name: "Gibraltar" },
    { countryCode: "GL", lat: 71.706936, lng: -42.604303, name: "Greenland" },
    { countryCode: "GM", lat: 13.443182, lng: -15.310139, name: "Gambia" },
    { countryCode: "GN", lat: 9.945587, lng: -9.696645, name: "Guinea" },
    { countryCode: "GP", lat: 16.995971, lng: -62.067641, name: "Guadeloupe" },
    { countryCode: "GQ", lat: 1.650801, lng: 10.267895, name: "Equatorial Guinea" },
    { countryCode: "GR", lat: 39.074208, lng: 21.824312, name: "Greece" },
    { countryCode: "GS", lat: -54.429579, lng: -36.587909, name: "South Georgia and the South Sandwich Islands" },
    { countryCode: "GT", lat: 15.783471, lng: -90.230759, name: "Guatemala" },
    { countryCode: "GU", lat: 13.444304, lng: 144.793731, name: "Guam" },
    { countryCode: "GW", lat: 11.803749, lng: -15.180413, name: "Guinea-Bissau" },
    { countryCode: "GY", lat: 4.860416, lng: -58.93018, name: "Guyana" },
    { countryCode: "GZ", lat: 31.354676, lng: 34.308825, name: "Gaza Strip" },
    { countryCode: "HK", lat: 22.396428, lng: 114.109497, name: "Hong Kong" },
    { countryCode: "HM", lat: -53.08181, lng: 73.504158, name: "Heard Island and McDonald Islands" },
    { countryCode: "HN", lat: 15.199999, lng: -86.241905, name: "Honduras" },
    { countryCode: "HR", lat: 45.1, lng: 15.2, name: "Croatia" },
    { countryCode: "HT", lat: 18.971187, lng: -72.285215, name: "Haiti" },
    { countryCode: "HU", lat: 47.162494, lng: 19.503304, name: "Hungary" },
    { countryCode: "ID", lat: -0.789275, lng: 113.921327, name: "Indonesia" },
    { countryCode: "IE", lat: 53.41291, lng: -8.24389, name: "Ireland" },
    { countryCode: "IL", lat: 31.046051, lng: 34.851612, name: "Israel" },
    { countryCode: "IM", lat: 54.236107, lng: -4.548056, name: "Isle of Man" },
    { countryCode: "IN", lat: 20.593684, lng: 78.96288, name: "India" },
    { countryCode: "IO", lat: -6.343194, lng: 71.876519, name: "British Indian Ocean Territory" },
    { countryCode: "IQ", lat: 33.223191, lng: 43.679291, name: "Iraq" },
    { countryCode: "IR", lat: 32.427908, lng: 53.688046, name: "Iran" },
    { countryCode: "IS", lat: 64.963051, lng: -19.020835, name: "Iceland" },
    { countryCode: "IT", lat: 41.87194, lng: 12.56738, name: "Italy" },
    { countryCode: "JE", lat: 49.214439, lng: -2.13125, name: "Jersey" },
    { countryCode: "JM", lat: 18.109581, lng: -77.297508, name: "Jamaica" },
    { countryCode: "JO", lat: 30.585164, lng: 36.238414, name: "Jordan" },
    { countryCode: "JP", lat: 36.204824, lng: 138.252924, name: "Japan" },
    { countryCode: "KE", lat: -0.023559, lng: 37.906193, name: "Kenya" },
    { countryCode: "KG", lat: 41.20438, lng: 74.766098, name: "Kyrgyzstan" },
    { countryCode: "KH", lat: 12.565679, lng: 104.990963, name: "Cambodia" },
    { countryCode: "KI", lat: -3.370417, lng: -168.734039, name: "Kiribati" },
    { countryCode: "KM", lat: -11.875001, lng: 43.872219, name: "Comoros" },
    { countryCode: "KN", lat: 17.357822, lng: -62.782998, name: "Saint Kitts and Nevis" },
    { countryCode: "KP", lat: 40.339852, lng: 127.510093, name: "North Korea" },
    { countryCode: "KR", lat: 35.907757, lng: 127.766922, name: "South Korea" },
    { countryCode: "KW", lat: 29.31166, lng: 47.481766, name: "Kuwait" },
    { countryCode: "KY", lat: 19.513469, lng: -80.566956, name: "Cayman Islands" },
    { countryCode: "KZ", lat: 48.019573, lng: 66.923684, name: "Kazakhstan" },
    { countryCode: "LA", lat: 19.85627, lng: 102.495496, name: "Laos" },
    { countryCode: "LB", lat: 33.854721, lng: 35.862285, name: "Lebanon" },
    { countryCode: "LC", lat: 13.909444, lng: -60.978893, name: "Saint Lucia" },
    { countryCode: "LI", lat: 47.166, lng: 9.555373, name: "Liechtenstein" },
    { countryCode: "LK", lat: 7.873054, lng: 80.771797, name: "Sri Lanka" },
    { countryCode: "LR", lat: 6.428055, lng: -9.429499, name: "Liberia" },
    { countryCode: "LS", lat: -29.609988, lng: 28.233608, name: "Lesotho" },
    { countryCode: "LT", lat: 55.169438, lng: 23.881275, name: "Lithuania" },
    { countryCode: "LU", lat: 49.815273, lng: 6.129583, name: "Luxembourg" },
    { countryCode: "LV", lat: 56.879635, lng: 24.603189, name: "Latvia" },
    { countryCode: "LY", lat: 26.3351, lng: 17.228331, name: "Libya" },
    { countryCode: "MA", lat: 31.791702, lng: -7.09262, name: "Morocco" },
    { countryCode: "MC", lat: 43.750298, lng: 7.412841, name: "Monaco" },
    { countryCode: "MD", lat: 47.411631, lng: 28.369885, name: "Moldova" },
    { countryCode: "ME", lat: 42.708678, lng: 19.37439, name: "Montenegro" },
    { countryCode: "MG", lat: -18.766947, lng: 46.869107, name: "Madagascar" },
    { countryCode: "MH", lat: 7.131474, lng: 171.184478, name: "Marshall Islands" },
    { countryCode: "MK", lat: 41.608635, lng: 21.745275, name: "Macedonia [FYROM]" },
    { countryCode: "ML", lat: 17.570692, lng: -3.996166, name: "Mali" },
    { countryCode: "MM", lat: 21.913965, lng: 95.956223, name: "Myanmar [Burma]" },
    { countryCode: "MN", lat: 46.862496, lng: 103.846656, name: "Mongolia" },
    { countryCode: "MO", lat: 22.198745, lng: 113.543873, name: "Macau" },
    { countryCode: "MP", lat: 17.33083, lng: 145.38469, name: "Northern Mariana Islands" },
    { countryCode: "MQ", lat: 14.641528, lng: -61.024174, name: "Martinique" },
    { countryCode: "MR", lat: 21.00789, lng: -10.940835, name: "Mauritania" },
    { countryCode: "MS", lat: 16.742498, lng: -62.187366, name: "Montserrat" },
    { countryCode: "MT", lat: 35.937496, lng: 14.375416, name: "Malta" },
    { countryCode: "MU", lat: -20.348404, lng: 57.552152, name: "Mauritius" },
    { countryCode: "MV", lat: 3.202778, lng: 73.22068, name: "Maldives" },
    { countryCode: "MW", lat: -13.254308, lng: 34.301525, name: "Malawi" },
    { countryCode: "MX", lat: 23.634501, lng: -102.552784, name: "Mexico" },
    { countryCode: "MY", lat: 4.210484, lng: 101.975766, name: "Malaysia" },
    { countryCode: "MZ", lat: -18.665695, lng: 35.529562, name: "Mozambique" },
    { countryCode: "NA", lat: -22.95764, lng: 18.49041, name: "Namibia" },
    { countryCode: "NC", lat: -20.904305, lng: 165.618042, name: "New Caledonia" },
    { countryCode: "NE", lat: 17.607789, lng: 8.081666, name: "Niger" },
    { countryCode: "NF", lat: -29.040835, lng: 167.954712, name: "Norfolk Island" },
    { countryCode: "NG", lat: 9.081999, lng: 8.675277, name: "Nigeria" },
    { countryCode: "NI", lat: 12.865416, lng: -85.207229, name: "Nicaragua" },
    { countryCode: "NL", lat: 52.132633, lng: 5.291266, name: "Netherlands" },
    { countryCode: "NO", lat: 60.472024, lng: 8.468946, name: "Norway" },
    { countryCode: "NP", lat: 28.394857, lng: 84.124008, name: "Nepal" },
    { countryCode: "NR", lat: -0.522778, lng: 166.931503, name: "Nauru" },
    { countryCode: "NU", lat: -19.054445, lng: -169.867233, name: "Niue" },
    { countryCode: "NZ", lat: -40.900557, lng: 174.885971, name: "New Zealand" },
    { countryCode: "OM", lat: 21.512583, lng: 55.923255, name: "Oman" },
    { countryCode: "PA", lat: 8.537981, lng: -80.782127, name: "Panama" },
    { countryCode: "PE", lat: -9.189967, lng: -75.015152, name: "Peru" },
    { countryCode: "PF", lat: -17.679742, lng: -149.406843, name: "French Polynesia" },
    { countryCode: "PG", lat: -6.314993, lng: 143.95555, name: "Papua New Guinea" },
    { countryCode: "PH", lat: 12.879721, lng: 121.774017, name: "Philippines" },
    { countryCode: "PK", lat: 30.375321, lng: 69.345116, name: "Pakistan" },
    { countryCode: "PL", lat: 51.919438, lng: 19.145136, name: "Poland" },
    { countryCode: "PM", lat: 46.941936, lng: -56.27111, name: "Saint Pierre and Miquelon" },
    { countryCode: "PN", lat: -24.703615, lng: -127.439308, name: "Pitcairn Islands" },
    { countryCode: "PR", lat: 18.220833, lng: -66.590149, name: "Puerto Rico" },
    { countryCode: "PS", lat: 31.952162, lng: 35.233154, name: "Palestinian Territories" },
    { countryCode: "PT", lat: 39.399872, lng: -8.224454, name: "Portugal" },
    { countryCode: "PW", lat: 7.51498, lng: 134.58252, name: "Palau" },
    { countryCode: "PY", lat: -23.442503, lng: -58.443832, name: "Paraguay" },
    { countryCode: "QA", lat: 25.354826, lng: 51.183884, name: "Qatar" },
    { countryCode: "RE", lat: -21.115141, lng: 55.536384, name: "Réunion" },
    { countryCode: "RO", lat: 45.943161, lng: 24.96676, name: "Romania" },
    { countryCode: "RS", lat: 44.016521, lng: 21.005859, name: "Serbia" },
    { countryCode: "RU", lat: 61.52401, lng: 105.318756, name: "Russia" },
    { countryCode: "RW", lat: -1.940278, lng: 29.873888, name: "Rwanda" },
    { countryCode: "SA", lat: 23.885942, lng: 45.079162, name: "Saudi Arabia" },
    { countryCode: "SB", lat: -9.64571, lng: 160.156194, name: "Solomon Islands" },
    { countryCode: "SC", lat: -4.679574, lng: 55.491977, name: "Seychelles" },
    { countryCode: "SD", lat: 12.862807, lng: 30.217636, name: "Sudan" },
    { countryCode: "SE", lat: 60.128161, lng: 18.643501, name: "Sweden" },
    { countryCode: "SG", lat: 1.352083, lng: 103.819836, name: "Singapore" },
    { countryCode: "SH", lat: -24.143474, lng: -10.030696, name: "Saint Helena" },
    { countryCode: "SI", lat: 46.151241, lng: 14.995463, name: "Slovenia" },
    { countryCode: "SJ", lat: 77.553604, lng: 23.670272, name: "Svalbard and Jan Mayen" },
    { countryCode: "SK", lat: 48.669026, lng: 19.699024, name: "Slovakia" },
    { countryCode: "SL", lat: 8.460555, lng: -11.779889, name: "Sierra Leone" },
    { countryCode: "SM", lat: 43.94236, lng: 12.457777, name: "San Marino" },
    { countryCode: "SN", lat: 14.497401, lng: -14.452362, name: "Senegal" },
    { countryCode: "SO", lat: 5.152149, lng: 46.199616, name: "Somalia" },
    { countryCode: "SR", lat: 3.919305, lng: -56.027783, name: "Suriname" },
    { countryCode: "SS", lat: 7.8626845, lng: 29.6949232, name: "South Sudan" },
    { countryCode: "ST", lat: 0.18636, lng: 6.613081, name: "São Tomé and Príncipe" },
    { countryCode: "SV", lat: 13.794185, lng: -88.89653, name: "El Salvador" },
    { countryCode: "SX", lat: 18.0347188, lng: -63.0681114, name: "Sint Maarten (Dutch part)" },
    { countryCode: "SY", lat: 34.802075, lng: 38.996815, name: "Syria" },
    { countryCode: "SZ", lat: -26.522503, lng: 31.465866, name: "Eswatini" },
    { countryCode: "SZ", lat: -26.522503, lng: 31.465866, name: "Swaziland" },
    { countryCode: "TC", lat: 21.694025, lng: -71.797928, name: "Turks and Caicos Islands" },
    { countryCode: "TD", lat: 15.454166, lng: 18.732207, name: "Chad" },
    { countryCode: "TF", lat: -49.280366, lng: 69.348557, name: "French Southern Territories" },
    { countryCode: "TG", lat: 8.619543, lng: 0.824782, name: "Togo" },
    { countryCode: "TH", lat: 15.870032, lng: 100.992541, name: "Thailand" },
    { countryCode: "TJ", lat: 38.861034, lng: 71.276093, name: "Tajikistan" },
    { countryCode: "TK", lat: -8.967363, lng: -171.855881, name: "Tokelau" },
    { countryCode: "TL", lat: -8.874217, lng: 125.727539, name: "Timor-Leste" },
    { countryCode: "TM", lat: 38.969719, lng: 59.556278, name: "Turkmenistan" },
    { countryCode: "TN", lat: 33.886917, lng: 9.537499, name: "Tunisia" },
    { countryCode: "TO", lat: -21.178986, lng: -175.198242, name: "Tonga" },
    { countryCode: "TR", lat: 38.963745, lng: 35.243322, name: "Turkey" },
    { countryCode: "TT", lat: 10.691803, lng: -61.222503, name: "Trinidad and Tobago" },
    { countryCode: "TV", lat: -7.109535, lng: 177.64933, name: "Tuvalu" },
    { countryCode: "TW", lat: 23.69781, lng: 120.960515, name: "Taiwan" },
    { countryCode: "TZ", lat: -6.369028, lng: 34.888822, name: "Tanzania" },
    { countryCode: "UA", lat: 48.379433, lng: 31.16558, name: "Ukraine" },
    { countryCode: "UG", lat: 1.373333, lng: 32.290275, name: "Uganda" },
    { countryCode: "UM", lat: 13.3220405, lng: 152.5271471, name: "U.S. Minor Outlying, Islands" },
    { countryCode: "US", lat: 37.09024, lng: -95.712891, name: "United States" },
    { countryCode: "UY", lat: -32.522779, lng: -55.765835, name: "Uruguay" },
    { countryCode: "UZ", lat: 41.377491, lng: 64.585262, name: "Uzbekistan" },
    { countryCode: "VA", lat: 41.902916, lng: 12.453389, name: "Vatican City" },
    { countryCode: "VC", lat: 12.984305, lng: -61.287228, name: "Saint Vincent and the Grenadines" },
    { countryCode: "VE", lat: 6.42375, lng: -66.58973, name: "Venezuela" },
    { countryCode: "VG", lat: 18.420695, lng: -64.639968, name: "British Virgin Islands" },
    { countryCode: "VI", lat: 18.335765, lng: -64.896335, name: "U.S. Virgin Islands" },
    { countryCode: "VN", lat: 14.058324, lng: 108.277199, name: "Vietnam" },
    { countryCode: "VU", lat: -15.376706, lng: 166.959158, name: "Vanuatu" },
    { countryCode: "WF", lat: -13.768752, lng: -177.156097, name: "Wallis and Futuna" },
    { countryCode: "WS", lat: -13.759029, lng: -172.104629, name: "Samoa" },
    { countryCode: "XK", lat: 42.602636, lng: 20.902977, name: "Kosovo" },
    { countryCode: "YE", lat: 15.552727, lng: 48.516388, name: "Yemen" },
    { countryCode: "YT", lat: -12.8275, lng: 45.166244, name: "Mayotte" },
    { countryCode: "ZA", lat: -30.559482, lng: 22.937506, name: "South Africa" },
    { countryCode: "ZM", lat: -13.133897, lng: 27.849332, name: "Zambia" },
    { countryCode: "ZW", lat: -19.015438, lng: 29.154857, name: "Zimbabwe" }
];

export const S3_REGIONS = [
    { countryCode: 'KR', region: 'ap-northeast-2', name: 'Seoul', location: { lat: 37.56, lng: 126.98 } },
    { countryCode: 'AU', region: 'ap-southeast-2', name: 'Sydney', location: { lat: -33.86, lng: 151.2 } },
    { countryCode: 'CA', region: 'ca-central-1', name: 'Canada Central', location: { lat: 45.5, lng: -73.6 } },
    { countryCode: 'DE', region: 'eu-central-1', name: 'Frankfurt', location: { lat: 50, lng: 8 } },
    { countryCode: 'FR', region: 'eu-west-3', name: 'Paris', location: { lat: 48.86, lng: 2.35 } },
    { countryCode: 'IE', region: 'eu-west-1', name: 'Ireland', location: { lat: 53, lng: -8 } },
    { countryCode: 'IN', region: 'ap-south-1', name: 'Mumbai', location: { lat: 19.08, lng: 72.88 } },
    { countryCode: 'JP', region: 'ap-northeast-1', name: 'Tokyo', location: { lat: 35.41, lng: 139.42 } },
    { countryCode: 'SE', region: 'eu-north-1', name: 'Stockholm', location: { lat: 59.25, lng: 17.81 } },
    { countryCode: 'GB', region: 'eu-west-2', name: 'London', location: { lat: 51, lng: -0.1 } },
    { countryCode: 'US', region: 'us-east-1', name: 'Virginia', location: { lat: 38.13, lng: -78.45 } },
    { countryCode: 'BR', region: 'sa-east-1', name: 'Sao Paulo', location: { lat: -23.34, lng: -46.38 } },
    { countryCode: 'SG', region: 'ap-southeast-1', name: 'Singapore', location: { lat: 1.37, lng: 103.8 } },
];

const S3_REGIONS_EXTRA = {
    HK: { countryCode: 'CN', region: 'ap-east-1', name: 'Hong Kong', location: { lat: 22.27, lng: 114.16} },
    CN: { countryCode: 'CN', region: 'ap-east-1', name: 'China', location: { lat: 22.27, lng: 114.16} },
};

export const getRegion = (fromCountryCode) => {
    if (!!S3_REGIONS_EXTRA[fromCountryCode]) {
        const _country = S3_REGIONS_EXTRA[fromCountryCode];
        return { ..._country, dist: 0 };
    }
    const country = COUNTRIES.find(x => x.countryCode === fromCountryCode);
    if (!country) {
        return null;
    }
    const { lat: lat1, lng: lng1 } = country;
    let minRegion = null;
    for (const s3Region of S3_REGIONS) {
        if (fromCountryCode === s3Region.countryCode) {
            minRegion = { ...s3Region, dist: 0 };
            break;
        }
        const { lat: lat2, lng: lng2 } = s3Region.location;
 
        var radLat1 = Math.PI * lat1 / 180;
        var radLat2 = Math.PI * lat2 / 180;
        var theta = lng1 - lng2;
        var radTheta = Math.PI * theta / 180;
        var dist = Math.sin(radLat1) * Math.sin(radLat2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
        if (dist > 1)
            dist = 1;

        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515 * 1.609344 * 1000;
        if (dist < 100) dist = Math.round(dist / 10) * 10;
        else dist = Math.round(dist / 100) * 100;
        if (!minRegion) {
            minRegion = { ...s3Region, dist }
        } else if (minRegion.dist > dist) {
            minRegion = { ...s3Region, dist }
        }
    }
    return minRegion;
}