import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Header from "./Header"
import AppHeader from "./AppHeader";
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { BoardAction } from '../redux/action/BoardAction';

const BoardView = () => {

    const { t, i18n } = useTranslation(['translation']);
    const { _id } = useParams();
    const [ info, setInfo ] = useState({});

    useEffect(() => {
        getInfo()
    }, [i18n.language, _id]);

    const getInfo = async () => {
        const tmp = await BoardAction.GetSupportBoardContents(_id, i18n.language);
        setInfo(tmp);
    }

    const extractInnerHTML = (htmlString, splitStr = " ") => {
        if (!htmlString || typeof htmlString !== "string")
            return htmlString;
        const regex = /(<([^>]+)>)/ig;
        return htmlString.replace(regex, splitStr);
    };

    return (
        <>
            <Header />
            <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-300 text-white" data-image-src="/assets/img/bg1.jpg" style={{ backgroundImage: 'url("/assets/img/bg1.jpg")' }}>
                <div className="container pt-14 pt-md-15 pb-4 pb-md-3">
                    <div className="row gx-lg-9">
                        <div className="col-lg-9">
                            <div className="app-avatar">
                                <img className="avatar" src={'/assets/img/app-icon/img_rayteams.png'} alt={'Notice'} />
                            </div>
                            <div className="app-title">
                                <h2 className="fs-15 text-opacity60">RAYTeams Cloud</h2>
                                <h3 className="display-5 mf-300 b-0 text-white mb-1">{t('Notice')}</h3>
                            </div>
                        </div>
                        <div className="col-lg-3" style={{ display: 'none' }}>
                            <div className="search-form mt-2 mt-md-1 sch-box">
                                <input id="" type="text" className="form-control sch-box" placeholder="Search..." />
                                <label htmlFor="">Search...</label>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container manual">
                <div className="col-xl-12 order-1 pt-md-8 pb-10 board-list">
                    <section className="wrapper">
                        <nav className="breadcrumb" style={{ display : "flex", marginBottom : 0 }}>
                            <ol className="breadcrumb" style={{ display : "flex", marginBottom : 0 }}>
                                <li className="breadcrumb-item">
                                    <Link to={"/board/" + info.bid +  "/list"}>Notice</Link>
                                </li>
                            </ol>
                        </nav>
                        <h2 className="mt-2">{info.title}</h2>
                        <div className="applies-to-text-container mb-5">
							<span className="publish-date-label">{t('Last updated on')}</span>
							<span className="publish-date ms-1">{moment(info.created).format("LL")}</span>
						</div>
                        <div className="board-content">
                            <div className="card-body" dangerouslySetInnerHTML={{ __html: info?.content }}></div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default BoardView;
