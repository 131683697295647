import React, { useEffect, useState } from 'react';
import { history } from '../history';
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { AuthAction } from '../redux/action';
import { emailRegex, passwordRegex } from '../lib/regex';
import Loader from './Loader';

const ForgotPassword = ({ RequestPasswordEmailLambda, VerifyPasswordEmailLambda }) => {
    const [t] = useTranslation(['translation']);
    const [data, setData] = useState({ email: '', password: '', code: '', errMsg: '', status: '' });    
    const [showLoader, setShowLoader] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if(document.getElementsByTagName('footer').length > 0)
            document.getElementsByTagName('footer')[0].style.display = 'none';
    }, []);

    const checkEmail = async () => {
        if (!data.email) {
            setData({ ...data, errMsg: t('이메일을 입력해주세요.') });
			return;
        }

        if (!emailRegex.test(data.email)) {
            setData({ ...data, errMsg: t('올바르지 않은 형식의 이메일입니다.') });
			return;
		}

        setShowLoader(true);
        const ret = await RequestPasswordEmailLambda({ email: data.email, appName: 'RAYTeams' });
		setShowLoader(false);

        if (ret.status === 'success') {
            setData({ ...data, errMsg: '', status: 'EMAIL_CHECKED' });
        } else {
            switch (ret?.error) {
                case 'NOT_FIND_USER':
                    setData({ ...data, errMsg: t('존재하지 않는 이메일입니다.') });
                    break;
                case 'ACCESSDENY':
                    setData({ ...data, errMsg: t('오류가 발생하였습니다.') });
                    break;
                case 'FAIL_SAND_MAIL':
                    setData({ ...data, errMsg: t('이메일 발송에 실패하였습니다.') });
                    break;
                default:
                    setData({ ...data, errMsg: t('오류가 발생하였습니다.') }); 
                    break;
            }
        }
    }

    const resetPassword = async () => {
        if (!data.email) {
            setData({ ...data, errMsg: t('이메일을 입력해주세요.') });
            return;
        }
		if (!emailRegex.test(data.email)) {
            setData({ ...data, errMsg: t('올바르지 않은 형식의 이메일입니다.') });
			return;
		}
		if (!data.code) {
			setData({ ...data, errMsg: t('인증코드를 입력해주세요.') });
			return;
		}
		if (!passwordRegex.test(data.password)) {
            setData({ ...data, errMsg: t('패스워드 생성규칙에 위배됩니다.') });
			return;
        }

		setShowLoader(true);
        const ret = await VerifyPasswordEmailLambda({ email: data.email, password: data.password, code: data.code });
		setShowLoader(false);

		if (ret.status === 'success') {
            setData({ ...data, status: 'VERIFIED' });
		} else {
			switch (ret?.error) {
				case 'NOT_FIND_REQUESTED_USER':
                    setData({ ...data, errMsg: t('요청 이메일이 일치하지 않습니다.') });
					break;
				case 'ACCESSDENY':
                    setData({ ...data, errMsg: t('오류가 발생하였습니다.') });
					break;
				case 'EXPIRED_TIME':
					setData({ ...data, errMsg: t('인증 제한시간이 만료되었습니다.') });
					break;
				default:
					setData({ ...data, errMsg: t('오류가 발생하였습니다.') });
					break;
			}
        }
    }

    return (
        <div className="content-wrapper rt-site image-wrapper bg-image bg-overlay bg-overlay-400 bg-content" data-image-src="/assets/img/bg_signin.jpg" style={{ backgroundImage: 'url(/assets/img/bg_signin.jpg)', height: '100%' }}>
            <section className="wrapper text-white">
                <div className="container pt-12 pb-10 pt-md-21 pb-md-18 signin" style={{ zIndex: 5, position: 'relative' }}>
                    <div className="row gx-0 gy-8 align-items-center">
                        <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-6 content text-center text-lg-start signin-logo" data-cues="slideInDown" data-group="page-title" data-delay="600" data-disabled="true">
                            <a href="#" onClick={(e) => { e.preventDefault(); history.push('/') }}>
                                <img src="/assets/img/bi_rayteams_light.svg" className="mb-5" alt="RAYTeams" data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                    style={{
                                        animationName: 'slideInDown',
                                        animationDuration: '700ms',
                                        animationTimingFunction: 'ease',
                                        animationDelay: '600ms',
                                        animationDirection: 'normal',
                                        animationFillMode: 'both'
                                    }}
                                />
                            </a>
                            <p className="lead fs-lg lh-sm" data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                style={{
                                    animationName: 'slideInDown',
                                    animationDuration: '700ms',
                                    animationTimingFunction: 'ease',
                                    animationDelay: '900ms',
                                    animationDirection: 'normal',
                                    animationFillMode: 'both'
                                }}
                            >
                                <span className="underline-2 violet">Log in</span> or sign up for RAYTeams.<br />One account for RAYTeams Cloud and client.
                            </p>
                        </div>
                        <div className="col-lg-5 offset-lg-1 signup">
                            <div className="card">
                                <div className="card-body p-11 text-center text-dark">
                                    {
                                        data.status === '' &&
                                        <>
                                            <h2 className="mb-3 text-start">{t('비밀번호 분실')}</h2>
                                            <p className="mb-2 text-start text-primary">{t('비밀번호를 재설정하려면 이메일을 입력해주세요.')}</p>
                                            <p className="mb-6 text-start">
                                                {t('입력하신 이메일 주소로 인증 코드를 발송합니다.')}<br />
                                                {t('다음 화면에서 이메일로 전송된 인증 코드를 입력하십시오.')}
                                                </p>
                                            <div className="form-floating mb-4">
                                                <input type="email" className="form-control" placeholder="Email" value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} onKeyDown={(e) => e.key === 'Enter' && checkEmail()} />
                                                <label htmlFor="loginEmail">{t('이메일')}</label>
                                            </div>

                                            {data.errMsg && <p className="text-danger mt-n4" style={{textAlign:'left'}}>{data.errMsg}</p>}

                                            <div className="row">
                                                <div className="col-12 col-md-3 order-1 mt-2 mt-lg-0 mt-md-0">
                                                    <a className="btn btn-soft-primary rounded-pill btn-login w-100" onClick={() => history.goBack()}>
                                                        {t('이전')}
                                                    </a>
                                                </div>
                                                <div className="col-12 col-md-9 order-2">
                                                    <a className="btn btn-primary rounded-pill btn-login w-100" onClick={() => checkEmail()}>{t('다음')}</a>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {
                                        data.status === 'EMAIL_CHECKED' &&
                                        <>
                                            <h2 className="mb-3 text-start">{t('비밀번호 분실')}</h2>
                                            <p className="mb-4 text-start">
                                                <span className="text-primary">{t('이메일을 확인해주세요.')}</span><br />
                                            {t('이메일로 인증 코드를 발송하였습니다.')}
                                            </p>
                                            <form className="text-start mb-3">
                                                <div className="form-floating mb-2">
                                                    <input type="email" className="form-control" value={data.email} readOnly={true} />
                                                    <label>{t('이메일')}</label>
                                                </div>
                                                <div className="form-floating mb-2">
                                                    <input type="text" className="form-control" placeholder="Verification code" onChange={(e) => setData({ ...data, code: e.target.value })} />
                                                    <label>{t('인증코드')} *</label>
                                                </div>
                                                <div className="form-floating password-field mb-6">
                                                    <input type={showPassword ? 'input' : 'password'} className="form-control" placeholder="Password" onChange={(e) => setData({ ...data, password: e.target.value })} />
                                                    <span className="password-toggle" onClick={() => setShowPassword(!showPassword)}><i className={`uil uil-eye${showPassword ? '-slash' : ''}`}></i></span>
                                                    <label>{t('새로운 비밀번호')} *</label>
                                                </div>

                                                {data.errMsg && <p className="text-danger mt-n4" >{data.errMsg}</p>}
                                                
                                                <div className="row">
                                                    <div className="col-12 col-md-3 order-1 mt-2 mt-lg-0 mt-md-0">
                                                        <a className="btn btn-soft-primary rounded-pill btn-login w-100" onClick={() => setData({ ...data, errMsg: '', status: '' })}>{t('이전')}</a>
                                                    </div>
                                                    <div className="col-12 col-md-9 order-2">
                                                        <a className="btn btn-primary rounded-pill btn-login w-100" onClick={() => resetPassword()}>{t('확인')}</a>
                                                    </div>
                                                </div>
                                            </form>
                                        </>
                                    }   

                                    {
                                        data.status === 'VERIFIED' &&
                                        <>
                                            <h2 class="mb-8 text-center text-primary">{t('비밀번호 변경이 완료되었습니다.')}</h2>
                                            <p class="text-center mb-6"><img src="./assets/img/img_signup_ok.png" /></p>
                                            <div class="row">
                                                <div class="col-12 col-md-4 order-1 mt-2 mt-lg-0 mt-md-0">
                                                    <a class="btn btn-soft-primary rounded-pill btn-login w-100" onClick={() => history.push('/')}>{t('메인페이지')}</a>
                                                </div>
                                                <div class="col-12 col-md-8 order-2">
                                                    <a class="btn btn-primary rounded-pill btn-login w-100" onClick={()=> history.push('/login')}>{t('로그인')}</a>
                                                </div>
                                            </div>
                                        </>
                                    }                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Loader isShow={showLoader} />            
        </div>
    )
}

const mapState = (state) => {
	const loginresult = state.AuthReducer.loginresult;
	return { loginresult };
};

const mapDispatch = dispatch => ({
	RequestPasswordEmailLambda: (data) => dispatch(AuthAction.RequestPasswordEmailLambda(data)),
	VerifyPasswordEmailLambda: (data) => dispatch(AuthAction.VerifyPasswordEmailLambda(data))
})

export default connect(mapState, mapDispatch)(ForgotPassword);
