import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../history';
import Modal from 'react-bootstrap/Modal';

const EventModal = ({notifyItem}) => {
    const { t, i18n } = useTranslation(['translation']);
    const [show, setShow] = useState(true);
    const [notiItem, setNotiItem] = useState({});

    const userLang = localStorage.getItem('i18nextLng') || "en";

    useEffect(() => {
        if (localStorage.getItem('showEventModal_rayIos') === 'false') {
            setShow(false);
          } else {
            setShow(true);
          }
    }, []);

    useEffect(() => {
        if (notifyItem) {
            setNotiItem(notifyItem?.content.find(f => f?.sk.split(":")[1] == userLang));
        }
    }, []);

    const handleClose = () => {
        localStorage.setItem('showEventModal_rayIos', 'false');
        setShow(false);
    };
    
    return <>
        {notifyItem && notiItem &&
        <Modal size="lg" centered show={show} onHide={() => setShow(false)} keyboard={false}>
            <div className="modal-content text-center" style={{ borderRadius: "0", background: "#21272f" }}>
                <div dangerouslySetInnerHTML={{ __html: notiItem?.content }}></div>
                <div className="text-end px-5 pt-1 pb-2 pb-md-1 pt-md-0">
                    <div className="fs-13 text-muted fw-normal py-2" style={{ cursor: "pointer" }} onClick={handleClose}>
                        <i className="uil uil-multiply me-1"></i>{t("Don't show this window again.")}
                    </div>
                </div>
            </div>
        </Modal>
        }
    </>
};

export default EventModal;