import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { history } from "../history";

const Footer = () => {
    const { t, i18n } = useTranslation(['translation']);
    useEffect(() => {

    }, []);

    const getEnv = () => {
        if (location.href.indexOf("localhost") > -1) {
            return "dev-local";
        } else if (location.href.indexOf("raydevelop") > -1) {
            return "development";
        }
        return "production";
    }

    return <>
        <footer id="teams-homepage-footer" className="bg-navy text-inverse">
            <div className="container p-6 pb-5">
                <div className="d-md-flex justify-content-between">
                    <nav className="nav">
                        <address>
                            <a href="/">
                                <img src="/assets/img/logo-light.png" srcSet="/assets/img/logo-light@2x.png 2x" className="logo" alt="RAYTeams" />
                            </a>
                            <div className="company-info">
                                <span>{t('Business number')}: 135-81-73282</span>
                                <span>{t('Mail order sales approval number')}: 2022-성남분당A-0880</span>
                                <span className="d-inline-block">
                                    <span 
                                        style={{ border: "0.5px solid #aaa", padding: 2, borderRadius: 4, cursor: "pointer" }} 
                                        className="fs-12"
                                        onClick={() => window.open(`https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1358173282&apv_perm_no=`, "_black", `popup=yes,width=600,height=800,left=${window.screenX},top=0,noreferrer`)}
                                    >
                                        {t('사업자 번호 확인')}
                                    </span>
                                </span>
                            </div>
                            <div className="company-info">
                                <span>{t('Address')}: {t('12 Floor, TWOSUN WORLD Bldg, 221 Pangyoyeok-ro, Bundang-gu Seongnam-si, Gyeonggi-do Republic of Korea')}</span>
                                <span>{t('CEO')}: {t('Sang Chul Lee')}</span>
                            </div>
                            <div className="company-info"><span>{t('Phone')}: {t('+82 (31) 605-1000')}</span><span>{t('Email')}: <a href="mailto:support@rayteams.com">support@rayteams.com</a></span></div>
                            <div className="d-md-flex justify-content-between align-items-center mt-2">
                                <span className="copyright"><strong>{t('RAY')}</strong> © RAY Co. 2023, All rights reserved.</span>
                            </div>
                        </address>
                    </nav>
                    <div className="nav social social-white text-md-end">
                        <ul>
                            <li><a className="privacy" href="#" onClick={() => history.push(getEnv() === "production" ? "/board/51d7aa2b-1739-40cd-b220-d7db043fd27d/list" : "/board/9e02c214-9d9f-4361-81ee-b6231c020348/list")}>{t('Notice')}</a></li>
                            <li><a className="privacy" href="#" onClick={() => history.push("/privacy-policy")}><span>{t('Privacy Policy')}</span></a></li>
                            <li><a className="privacy" href="#" onClick={() => history.push("/term-of-use")}>{t('Terms and Conditions')}</a></li>
                            {/* <li><a className="privacy iubenda-nostyle no-brand iubenda-embed "
                            href="https://www.iubenda.com/privacy-policy/94780237/cookie-policy" title="Cookie Policy">Cookie
                            preferences</a></li> */}
                        </ul>
                        <div className="social-icon">
                            <a href="https://www.facebook.com/Raymedical/" target="_blank"><i className="uil uil-facebook-f"></i></a>
                            <a href="https://www.youtube.com/channel/UCNGnoi2JyiQVtagXIlhBQdw/videos?view_as=subscriber"
                                target="_blank"><i className="uil uil-youtube"></i></a>
                            <a href="https://www.linkedin.com/company/ray-co.-ltd?trk=biz-companies-cym" target="_blank"><i
                                className="uil uil-linkedin"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        {/* <footer className="bg-navy text-inverse">
            <div className="container pt-3 pt-md-3 pb-3">
                <div className="d-md-flex align-items-center justify-content-between">
                    <a href="/"><img src="./assets/img/logo-light.png" srcSet="./assets/img/logo-light@2x.png 2x" className="logo" alt="RAYTeams" /></a>
                    <nav className="nav social text-md-end align-items-center">
                        <p className="mb-2 mb-lg-0">(C) Ray, All rights reserved.</p>
                        <a className="privacy" href="/privacy-policy">Privacy</a>
                        <a className="privacy" href="/term-of-use">Terms of Use</a>
                        <a className="privacy" href="https://www.iubenda.com/privacy-policy/94780237/cookie-policy">Cookie preferences</a>
                    </nav>
                    <nav className="nav social social-white text-md-end align-items-center">
                        <a href="https://www.facebook.com/Raymedical/" target="_blank"><i className="uil uil-facebook-f"></i></a>
                        <a href="https://www.youtube.com/channel/UCNGnoi2JyiQVtagXIlhBQdw/videos?view_as=subscriber" target="_blank"><i className="uil uil-youtube"></i></a>
                        <a href="https://www.linkedin.com/company/ray-co.-ltd?trk=biz-companies-cym" target="_blank"><i className="uil uil-linkedin"></i></a>
                    </nav>
                </div>
            </div>
        </footer> */}
    </>
}

export default Footer;
