import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import queryString from 'query-string';
import { PaymentsAction } from "../redux/action";
import { useStripe, useElements, Elements, CardElement } from "@stripe/react-stripe-js";
import { loadTossPayments } from '@tosspayments/payment-sdk'
import { loadStripe } from "@stripe/stripe-js";
import { AppConfig } from "../config";
import Loader from "./Loader";

const Payments = () => {
    const stripePromise = loadStripe(AppConfig.STRIPE_PUBLISHABLE_KEY);

    const { search } = useLocation();
    const q = queryString.parse(search);

    const [data, setData] = useState({
        pg: '',
        action: '',        
        user_info: {
            user_id: '',
            user_name: '',
            email: ''
        },
    })

    const [stripeData, setStripeData] = useState({ client_secret: '', customer_id: '', price_id: '', title: '', price: 0 });

    useEffect(() => document.getElementsByTagName('footer')[0].style.display = 'none', []);

    useEffect(() => {
        const pg = q?.pg;                   //  paypal
        const action = q?.action;           //  sub
        const product_id = q?.product_id;   //  PROD-6199812348538494M
        const price_id = q?.price_id;       //  P-1VY12521BW175310FMREHMHY
        const sub_id = q?.sub_id;           //  P-1VY12521BW175310FMREHMHY
        const user_id = q?.user_id;         //  CV3DS5ZKLQGSW
        const user_name = q?.user_name;     //  
        const email = q?.email;             //  sb-jcvxy25753984@business.example.com

        const user_info = { _id: user_id, user_id, user_name, email, notyMethods: ["alarm"] };

        if (action === 'sub')
            setData({ ...data, pg, action, product_id, price_id, user_info });

        if (action === 'sub_cancel')
            setData({ ...data, pg, action, sub_id, user_info });
    }, [search]);

    useEffect(() => {
        if (data.pg && data.action) {
            const pg = data.pg;
            const action = data.action;

            if (pg === 'paypal' && action === 'sub')
                PayPalCreateSubscription(data);

            if (pg === 'paypal' && action === 'sub_cancel')
                PayPalCancelSubscription(data);

            if (pg === 'stripe' && action === 'sub')
                PrepareStripeSubscription(data);

            if (pg === 'stripe' && action === 'sub_cancel')
                StripeCancelSubscription(data);

            if (pg === 'toss' && action === 'sub') {
                loadTossPayments(AppConfig.TOSS_CLIENT_KEY).then(tossPayments => {                    
                    tossPayments.requestBillingAuth('카드', {
                        // 결제 정보 파라미터
                        // 더 많은 결제 정보 파라미터는 결제창 Javascript SDK에서 확인하세요.
                        // https://docs.tosspayments.com/reference/js-sdk#requestbillingauth카드-결제-정보
                        customerKey: data.user_info?.user_id, // 고객 ID로 상점에서 만들어야 합니다. 빌링키와 매핑됩니다. 자세한 파라미터 설명은 결제 정보 파라미터 설명을 참고하세요.
                        successUrl: `${location.origin}/payments-result?pg=toss&result=success`, // 카드 등록에 성공하면 이동하는 페이지(직접 만들어주세요)
                        failUrl: `${location.origin}/payments-result?pg=toss&result=fail`,       // 카드 등록에 실패하면 이동하는 페이지(직접 만들어주세요)
                    }).catch(function (error) {
                        if (error.code === 'USER_CANCEL') {
                            // 결제 고객이 결제창을 닫았을 때 에러 처리
                        }
                    })
                })
            }
        }
    }, [data]);

    const PrepareStripeSubscription = async (data) => {
        let customer = await PaymentsAction.StripeSearchCustomerByEmail(data.user_info.email);

        if (!customer.hasOwnProperty('id'))
            customer = await PaymentsAction.StripeCreateCustomer(data.user_info.user_name, data.user_info.email);

        const product = await PaymentsAction.StripeSearchProductByProductId(data.product_id);        
        const price_id = product?.default_price;
        const price = await PaymentsAction.StripeSearchPriceByPriceId(price_id);
        const paymentIntent = await PaymentsAction.StripePaymentIntents(price.unit_amount);

        setStripeData({
            client_secret: paymentIntent.data.client_secret,
            customer_id: customer.id,
            price_id,
            title: product.name,
            price: price.unit_amount / 100
        });
    }

    return (
        <>
            {
                stripeData.client_secret &&
                <div style={{ width: '600px', margin: '10px' }}>
                    <div style={{ padding: '15px', backgroundColor: '#fff', border: '1px solid #ddd', borderRadius: '5px', marginBottom: '10px' }}>
                            <div>{stripeData.title}</div>
                        <div>
                            <label>${stripeData.price}</label> <label>per month</label>
                        </div>
                    </div>
                        <Elements stripe={stripePromise} options={{ clientSecret: stripeData.client_secret }}>
                            <StripeSubscriptionForm subInfo={stripeData} userInfo={data.user_info} />
                        </Elements>
                </div>
            }

            {

            }
        </>
    )
}

const StripeSubscriptionForm = ({ subInfo, userInfo }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [errMsg, setErrMsg] = useState('');
    const [showLoader, setShowLoader] = useState(false);

    const CreateStripeSubscription = async () => {
        setErrMsg('');

        const paymentMethod = await stripe?.createPaymentMethod({
            type: 'card',
            card: elements?.getElement(CardElement)
        });

        if (paymentMethod.error) {
            setErrMsg(paymentMethod.error.message);
        }
        else {
            setShowLoader(true);
            const response = await PaymentsAction.StripeCreateSubscription(subInfo.customer_id, subInfo.price_id, paymentMethod.id, userInfo);
            if (response?.error)
                setErrMsg(response.error.message);
            setShowLoader(false);
        }
    }

    return (
        <>            
            <div style={{ padding: '15px', backgroundColor: '#fff', border: '1px solid #ddd', borderRadius: '5px', marginBottom: '10px' }}>
                <CardElement options={{ hidePostalCode: true }} />
            </div>

            {
                errMsg &&
                <div style={{color:'red'}}>{errMsg}</div>
            }
            
            <button
                style={{
                    marginTop: '15px',
                    width: '100%',
                    borderRadius: '5px',
                    border: 0,
                    height: '50px',
                    color: '#fff',
                    backgroundColor: 'rgb(25,37,82)'
                }} onClick={CreateStripeSubscription}>Subscribe</button>

            <Loader isShow={showLoader} />
        </>
    )
}

const StripeCancelSubscription = async (sub_id) => {
    const response = await PaymentsAction.StripeCancelSubscription(sub_id);
    
    if (response?.canceled_at) {
        
    }
}

const PayPalCreateSubscription = async (data) => {
    const response = await PaymentsAction.PayPalCreateSubscription(data.price_id, data.product_id, data.user_info)

    if (response.data?.status === 'APPROVAL_PENDING') {    
        const link = response.data?.links;
        const redirect_path = link.find(x => x.rel === 'approve')?.href;
        location.href = redirect_path;
    }
}

const PayPalCancelSubscription = (data) => {
    PaymentsAction.PayPalCancelSubscription(data.item_id, '').then(response => {
        alert(response);
    });
}

export default Payments;
